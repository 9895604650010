import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Transport } from '../../../interfaces';
import { payloadCreateTransport, payloadSetTransports, payloadUpdateTransport } from './payloadTypes';

interface Props {
  transports: Transport[];
}

const initialState: Props = {
  transports: [],
};

export const transportsSlice = createSlice({
  name: 'transports',
  initialState,
  reducers: {
    setTransports: (state, action: PayloadAction<payloadSetTransports>) => {
      state.transports = action.payload.transports || [];
    },
    createNewTransport: (state, action: PayloadAction<payloadCreateTransport>) => {
      state.transports.push(action.payload.transport);
    },
    updateNowTransport: (state, action: PayloadAction<payloadUpdateTransport>) => {
      const updateList = state.transports.map((transport) => {
        if (transport._id === action.payload.id) {
          return action.payload.transport;
        }
        return transport;
      });
      state.transports = updateList;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTransports,
  createNewTransport,
  updateNowTransport,
} = transportsSlice.actions;

export default transportsSlice.reducer;
