import { FC } from 'react';
import { Button, Card, CardBody } from 'reactstrap';

interface Props {
  url: string;
}

export const CardURL: FC<Props> = ({ url }) => {
  return (
    <Card>
      <CardBody>
        <div>
          <h3>
            URL
          </h3>
        </div>
        <div>
          <p>
            {url}
          </p>
        </div>
        <div>
          <Button
            disabled
          >
            Generar URL
          </Button>
        </div>
      </CardBody>
    </Card>

  );
};
