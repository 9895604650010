import dashboardBackendApi from '../api/dashboardBackendApi';
import { serialize } from '../helpers/serialize';
import { setClientList } from '../store/slices/businesses';
import { BusinessClientsResponse } from '../interfaces';
import { handleAxiosError } from '../helpers/handleErrors';

export const getBusinessClientsByBusinessId = (id: string, filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const resp = await dashboardBackendApi.get<BusinessClientsResponse>(`/support/businessClients/business/${id}?${_filters}`);
    dispatch(setClientList({
      businessClients: resp.data.businessClients,
      totalBusinessClients: resp.data.totalBusinessClients,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};
