import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeliveryService } from '../../../interfaces';
import { payloadSetDeliveryService, payloadSetDeliveryServices } from './payloadTypes';

interface DeliveryServicesState {
  deliveryServices: DeliveryService[];
  totalDeliveryServices: number;
  deliveryService: DeliveryService | null;
  // filters
  limit: number;
  pageIndex: number;
  from: any;
  to: any;
  searchText: string;
  filters: {
    status: string;
    zoneId: string;
    provider: string;
    isSandbox: string;
    requiredCash: string;
    integration: string;
  };
}

const initialState: DeliveryServicesState = {
  deliveryServices: [],
  totalDeliveryServices: 0,
  deliveryService: null,
  // filters
  limit: 10,
  pageIndex: 0,
  from: null,
  to: null,
  searchText: '',
  filters: {
    status: 'all',
    zoneId: 'all',
    provider: 'all',
    isSandbox: 'all',
    requiredCash: 'all',
    integration: 'all',
  },
};

export const deliveryServicesSlice = createSlice({
  name: 'deliveryServices',
  initialState,
  reducers: {
    setDeliveryServices: (state, action: PayloadAction<payloadSetDeliveryServices>) => {
      state.deliveryServices = action.payload.deliveryServices || [];
      state.totalDeliveryServices = action.payload.totalDeliveryServices;
      state.deliveryService = null;
    },
    setDeliveryService: (state, action: PayloadAction<payloadSetDeliveryService>) => {
      state.deliveryService = action.payload.deliveryService;
    },
    setLimit: (state, { payload }) => {
      state.limit = payload;
    },
    setPageIndex: (state, { payload }) => {
      state.pageIndex = payload;
    },
    setFrom: (state, { payload }) => {
      state.from = payload;
    },
    setTo: (state, { payload }) => {
      state.to = payload;
    },
    setSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    setFilters: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        [payload.filter]: payload.value,
      };
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.from = initialState.from;
      state.to = initialState.to;
      state.pageIndex = initialState.pageIndex;
      state.searchText = initialState.searchText;
      state.limit = initialState.limit;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setDeliveryServices,
  setDeliveryService,
  setLimit,
  setPageIndex,
  setFrom,
  setTo,
  setSearchText,
  setFilters,
  resetFilters,
} = deliveryServicesSlice.actions;

export default deliveryServicesSlice.reducer;
