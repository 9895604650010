import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { ModalRight } from '../../../components/modals/ModalRight';
import { FormSupportAdmin } from '.';
import { createSupportAdmin, updateSupportAdmin } from '../../../services/supportAdmin';
import { SupportAdmin } from '../../../interfaces';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  supportAdmin: SupportAdmin | null;
}

export const ModalSupportAdmin: FC<Props> = ({ isOpen, setIsOpen, supportAdmin = null }) => {
  const dispatch = useDispatch();
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onSubmit = (data: any) => {
    data.userType = data.userType.value;
    if (supportAdmin) {
      dispatch(updateSupportAdmin(supportAdmin._id, data));
    } else {
      dispatch(createSupportAdmin(data));
    }
    setIsOpen(false);
  };

  return (
    <ModalRight
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={supportAdmin ? 'Editar' : 'Nuevo'}
    >
      <FormSupportAdmin onSubmit={onSubmit} supportAdmin={supportAdmin} />
    </ModalRight>
  );
};
