import { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import ResizableReactTable from '../../../components/ResizableReactTable';
import CreateTableData from '../../../helpers/createTableData';
import { RequestDeliveryService } from '../../../interfaces';
import { changeStatusLabel } from '../../../helpers/status';
import { formatTime } from '../../../helpers';
import { ButtonView } from '../../../components/buttons';

interface Props {
  requests: RequestDeliveryService[];
}

export const CardNotifications: FC<Props> = ({ requests }) => {
  const history = useHistory();
  const [data, setData] = useState<any[]>([]);
  const columns = [
    { Header: 'Tiempo', accessor: 'createdAt' },
    { Header: 'Nombre', accessor: 'driverName' },
    { Header: 'Status', accessor: 'status' },
    { Header: 'Acciones', accessor: 'actions' },
  ];

  useEffect(() => {
    setData(requests.map(request => ({
      ...request,
      status: changeStatusLabel(request.status, 'request'),
      createdAt: formatTime(request.createdAt),
      actions: (
        <ButtonView
          onClick={() => history.push(`/dashboard/deliveryDrivers/${request.driverId}`)}
        />
      ),
    })));
  }, []);

  const reactTableData = CreateTableData(data, columns);
  return (
    <Card>
      <CardBody>
        <div>
          <h3>
            Notificaciones
          </h3>
        </div>
        <ResizableReactTable
          reactTableData={reactTableData}
          totalRecords={1}
          manualPageSize={[0]}
          limit={10}
          setLimit={() => { }}
          pageIndex={0}
          setPageIndex={() => { }}
          totalNoOfPages={1}
        />
      </CardBody>
    </Card>
  );
};
