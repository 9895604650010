import dashboardBackendApi from '../../api/dashboardBackendApi';
import { serialize } from '../../helpers/serialize';
import { setBusinessEstablishmentsByBusiness } from '../../store/slices/businessEstablishments';
import { BusinessEstablishmentsResponse } from '../../interfaces/businessEstablishment';
import { handleAxiosError } from '../../helpers/handleErrors';

export const getEstablishmentsByBusinessIdByHunter = (id: string, filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const resp = await dashboardBackendApi.get<BusinessEstablishmentsResponse>(`/hunter/businessEstablishments/business/${id}?${_filters}`);
    dispatch(setBusinessEstablishmentsByBusiness({
      businessEstablishments: resp.data.businessEstablishments,
      totalBusinessEstablishments: resp.data.totalBusinessEstablishments,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};
