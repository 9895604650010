import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Invoice } from '../../../interfaces';

interface Props {
  invoices: Invoice[];
  invoicesPending: Invoice[];
  totalInvoices: number;
  totalInvoicesPending: number;
  txtFile: string,
  filters: {
    isSandbox: string,
  },
}

const initialState: Props = {
  invoices: [],
  invoicesPending: [],
  totalInvoices: 0,
  totalInvoicesPending: 0,
  txtFile: '',
  filters: {
    isSandbox: 'all',
  },
};

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setInvoices: (state, action: PayloadAction<any>) => {
      state.invoices = action.payload.invoices || [];
      state.totalInvoices = action.payload.totalInvoices || 0;
    },
    setInvoicesPending: (state, action: PayloadAction<any>) => {
      state.invoicesPending = action.payload.invoices || [];
      state.totalInvoicesPending = action.payload.totalInvoices || 0;
    },
    setTxtFile: (state, action: PayloadAction<any>) => {
      state.txtFile = action.payload.txtFile || '';
    },
    setFilters: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        [payload.filter]: payload.value,
      };
    },
    updateInvoice: (state, { payload }) => {
      state.invoices = state.invoices.map(invoice => (invoice._id === payload._id ? payload : invoice));
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setInvoices,
  setInvoicesPending,
  setTxtFile,
  setFilters,
  updateInvoice,
} = invoicesSlice.actions;

export default invoicesSlice.reducer;
