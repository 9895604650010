import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface Props extends RouteProps {
  isAuthenticated: boolean;
  component: React.ComponentType<RouteProps>;
}

export const PublicRoute = ({ isAuthenticated, component: Component, ...rest }: Props) => (
  <Route
    {...rest}
    component={(props: any) => (
      (isAuthenticated)
        ? (<Redirect to="/dashboard/home" />)
        : (<Component {...props} />)
    )}
  />
);
