import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import renderSelectField from '../shared/form/Select';

export const DropdownInputField = ({ label, name, options, onSelect, placeholder }) => {
  return (
    <div className="form__form-group">
      <label className="form__form-group-label typography-message" htmlFor={name}>{label}</label>
      <div className="form__form-group-field">
        <Field
          name={name}
          component={renderSelectField}
          options={options}
          placeholder={placeholder}
          parse={onSelect}
        />
      </div>
    </div>
  );
};

DropdownInputField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf().isRequired,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
};

DropdownInputField.defaultProps = {
  placeholder: '',
  onSelect: () => { },
};
