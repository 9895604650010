import React, { FC } from 'react';
import { ModalRight } from '../../../components/modals/ModalRight';
import { formatDate } from '../../../helpers';
import { Invoice } from '../../../interfaces';
import s from './styles.module.scss';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  invoice: Invoice;
}

export const ModalInvoice: FC<Props> = ({ isOpen, setIsOpen, invoice }) => {
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  return (
    <ModalRight
      isOpen={isOpen}
      toggleModal={toggleModal}
      title="Factura"
    >
      <div className={s.container}>
        <h5>ID: {invoice._id}</h5>
        <br />
        <p>Emisor: {invoice.issuedBy.name}</p>
        <p>Receptor: {invoice.receivedBy.name}</p>
        <p>Status: {invoice.status}</p>
        <p>Concepto: {invoice.concept}</p>
        <p>Divisa: {invoice.currency}</p>
        <p>Subtotal: ${invoice.subtotal.toFixed(2)} MXN</p>
        <p>Impuesto: ${invoice.tax.toFixed(2)} MXN</p>
        <p>Total: ${invoice.total.toFixed(2)} MXN</p>
        <p>Fecha de pago: {formatDate(invoice.paymentDate)}</p>
      </div>
    </ModalRight>
  );
};
