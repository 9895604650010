import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { ButtonPrimary } from '../../components/buttons/ButtonPrimary';
import { ButtonView, ButtonEdit } from '../../components/buttons/ButtonsIcon';
import ResizableReactTable from '../../components/ResizableReactTable';
import { formatDate } from '../../helpers';
import CreateTableData from '../../helpers/createTableData';
import { getApiDevelopers } from '../../services/apiDeveloper';
import { RootState } from '../../store';
import { APIDeveloper } from '../../interfaces/apiDeveloper';
import { ModalApiDeveloper } from './components';

const columns = [
  { Header: 'Fecha de creación', accessor: 'createdAt' },
  { Header: 'Nombre', accessor: 'name' },
  { Header: 'Activo', accessor: 'isActive' },
  { Header: 'Modo Sandbox', accessor: 'sandboxMode' },
  { Header: 'Acciones', accessor: 'actions' },
];

const ApiDevelopers: FC = () => {
  const { apiDevelopers, totalApiDevelopers } = useSelector((state: RootState) => state.apiDevelopers);
  const dispatch = useDispatch();
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [manualPageSize] = useState([10, 20, 50, 100]);
  const [limit, setLimit] = useState(manualPageSize[0]);
  const [pageIndex, setPageIndex] = useState(0);
  const [apiDeveloper, setApiDeveloper] = useState<APIDeveloper | null>(null);


  const getFilters = () => {
    const filters: any = {};
    filters.limit = limit;
    filters.page = pageIndex + 1;
    filters.sortBy = '_id:desc';
    return filters;
  };

  const getAll = () => {
    try {
      const filters = getFilters();
      dispatch(getApiDevelopers(filters));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAll();
  }, [limit, pageIndex]);

  useEffect(() => {
    if (apiDevelopers.length > 0) {
      const newData: any[] = [];
      apiDevelopers.map((item) => {
        const obj = {
          ...item,
          actions: (
            <>
              <ButtonView
                onClick={() => history.push(`/dashboard/apiDevelopers/${item._id}`)}
              />
              <ButtonEdit
                onClick={() => {
                  setApiDeveloper(item);
                  setIsOpen(true);
                }}
              />
            </>
          ),
          isActive: item.isActive ? 'Si' : 'No',
          sandboxMode: item.sandboxMode ? 'Si' : 'No',
        };
        if (obj.createdAt) {
          obj.createdAt = formatDate(item.createdAt);
        }
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [apiDevelopers]);

  const reactTableData = CreateTableData(data, columns);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title">
                  <h5 className="bold-text">Developers API</h5>
                </div>
                <div>
                  <ButtonPrimary
                    label="Crear developer api"
                    onClick={() => {
                      setApiDeveloper(null);
                      setIsOpen(true);
                    }}
                  />
                </div>
              </div>
              <ModalApiDeveloper isOpen={isOpen} setIsOpen={setIsOpen} apiDeveloper={apiDeveloper} />
              {
                data.length === 0 ? <h3> No hay info que se pueda mostrar</h3> : (
                  <ResizableReactTable
                    reactTableData={reactTableData}
                    totalRecords={totalApiDevelopers}
                    manualPageSize={manualPageSize}
                    limit={limit}
                    setLimit={setLimit}
                    pageIndex={pageIndex}
                    setPageIndex={setPageIndex}
                    totalNoOfPages={Math.ceil(totalApiDevelopers / limit)}
                  />
                )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ApiDevelopers;
