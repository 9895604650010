import { FC, useState } from 'react';
import { Form } from 'react-final-form';
import { FormApi, SubmissionErrors } from 'final-form';
import { Button } from 'reactstrap';
import { InputField } from '../../../components/form/InputField';
import { PasswordInputField } from '../../../components/form/PasswordInputField';
import { ButtonPrimary } from '../../../components/buttons/ButtonPrimary';
import { BusinessAdmin } from '../../../interfaces/businessAdmin';

interface Props {
  onSubmit: (values: any, form: FormApi<any, any>, callback?: ((errors?: SubmissionErrors) => void) | undefined) => void;
  businessAdmin: BusinessAdmin | null;
}

export const FormAdmin: FC<Props> = ({ onSubmit, businessAdmin = null }) => {
  const [showPasswordField, setShowPasswordField] = useState(false);
  const validate = (values: any) => {
    const errors: any = {};
    if (!values.firstName) {
      errors.firstName = 'El nombre es obligatorio';
    }
    if (!values.lastName) {
      errors.lastName = 'El apellido es obligatorio';
    }
    if (!values.phone) {
      errors.phone = 'El celular es obligatorio';
    }
    if (!values.email) {
      errors.email = 'El correo es obligatorio';
    }
    if (!businessAdmin) {
      if (!values.password) {
        errors.password = 'La contraseña es obligatoria';
      }
    }
    if (showPasswordField) {
      if (!values.password) {
        errors.password = 'La contraseña es obligatoria';
      }
    }
    return errors;
  };

  return (
    <Form onSubmit={onSubmit} validate={validate} initialValues={businessAdmin}>
      {({ handleSubmit }) => (
        <form className="form" onSubmit={handleSubmit}>
          <InputField
            label="Nombre"
            name="firstName"
            placeholder="Nombre del administrador"
          />
          <InputField
            label="Apellido"
            name="lastName"
            placeholder="Apellidos del administrador"
          />
          <InputField
            label="Celular"
            name="phone.number"
            placeholder="Celular del administrador"
          />
          <InputField
            label="Correo"
            name="email"
            placeholder="Correo del administrador"
          />
          {
            businessAdmin && !showPasswordField ? (
              <ButtonPrimary
                label="Cambiar password"
                onClick={() => setShowPasswordField(true)}
              />
            ) : (
              <PasswordInputField
                label="Contraseña"
                name="password"
                placeholder="Contraseña del administrador"
              />
            )
          }

          <div>
            <Button
              size="sm"
              color="primary"
              type="submit"
            >
              {businessAdmin ? 'Editar Administrador' : 'Crear Administrador'}
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};
