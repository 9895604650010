import axios from 'axios';

const baseURL = process.env.REACT_APP_BACKEND_URL;

const backendApi = axios.create({ baseURL });

backendApi.interceptors.request.use(
  async (config) => {
    const root = JSON.parse(localStorage.getItem('persist:root') || '');
    const { jwtAccessToken } = JSON.parse(root.auth);
    if (jwtAccessToken) {
      // config.headers['Authorization'] = `Bearer ${jwt}`;
      config.headers = { Authorization: jwtAccessToken, ...config.headers };
    }
    return config;
  },
);

export default backendApi;
