import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
// import TopbarMenuLink from './TopbarMenuLink';
import TopbarMenuButton from './TopbarMenuButton';
import { logout } from '../../store/slices/auth';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

const TopbarProfile = () => {
  const dispatch = useDispatch();
  const { admin } = useSelector(state => state.auth);

  const history = useHistory();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const onClickLogout = () => {
    dispatch({ type: 'RESET' });
    localStorage.removeItem('persist:root');
    dispatch(logout());
  };

  const onClickConfig = () => {
    history.push('/dashboard/adminSettings');
    handleToggleCollapse();
  };

  return (
    <div className="topbar__profile">
      <button type="button" className="topbar__avatar" onClick={handleToggleCollapse}>
        <img className="topbar__avatar-img" src={Ava} alt="avatar" />
        <p className="topbar__avatar-name">{`${admin.firstName} ${admin.lastName}`}</p>
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          type="button"
          aria-label="button collapse"
          className="topbar__back"
          onClick={handleToggleCollapse}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
          {/* <TopbarMenuLink title="Page one" icon="list" path="/pages/one" />
          <TopbarMenuLink title="Page two" icon="inbox" path="/pages/two" />
          <div className="topbar__menu-divider" /> */}
          <TopbarMenuButton title="Configuraciones" icon="cog" onClick={onClickConfig} />
          <TopbarMenuButton title="Cerrar sesión" icon="exit" onClick={onClickLogout} />
        </div>
      </Collapse>
    </div>
  );
};

export default TopbarProfile;
