import { useDispatch } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
} from 'reactstrap';
import { useAppSelector } from '../../../store/index';
import s from './styles.module.scss';
import { refreshInttegrationSecret } from '../../../services/business';

export const CardIntegrations = () => {
  const dispatch = useDispatch();
  const { business } = useAppSelector(state => state.businesses);
  const { isSandbox } = useAppSelector(state => state.app);
  if (!business) {
    return (
      <Card>
        <CardBody>
          <h3>Cargando...</h3>
        </CardBody>
      </Card>
    );
  }

  const refreshSecret = async () => {
    console.log('@refreshSecret');
    dispatch(refreshInttegrationSecret(business._id));
  };
  return (
    <>
      <Card>
        <CardBody>
          {
            isSandbox ? (
              <div className={s.container}>
                <h4>Integraciones</h4>
                <label htmlFor="secret" className="form__form-group-label typography-message">Secret</label>
                <br />
                <input
                  id="secret"
                  style={{
                    width: '100%',
                    background: '#efefef',
                    borderRadius: 4,
                    border: 0,
                    padding: 10,
                  }}
                  type="text"
                  disabled
                  value={business.integrationSecret}
                />
                <br />
                <br />
                <Button onClick={refreshSecret}>Regenerar secret</Button>
              </div>
            )
          : false
          }
        </CardBody>
      </Card>
    </>
  );
};
