import dashboardBackendApi from '../../api/dashboardBackendApi';
import { handleAxiosError } from '../../helpers/handleErrors';
import { serialize } from '../../helpers/serialize';
import { BusinessesResponse } from '../../interfaces';
import { setBusiness, setBusinesses, setDeliveryServicesByBusiness } from '../../store/slices/businesses';
import { resetStateBusinessEstablishments } from '../../store/slices/businessEstablishments';


export const getBusinessesByHunter = (filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const { data } = await dashboardBackendApi.get<BusinessesResponse>(`/hunter/businesses?${_filters}`);
    dispatch(setBusinesses({
      businesses: data.businesses,
      totalBusinesses: data.totalBusinesses,
    }));
    dispatch(resetStateBusinessEstablishments());
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};
export const getBusinessByHunter = (id: string) => async (dispatch: any) => {
  try {
    const { data } = await dashboardBackendApi.get<any>(`/hunter/businesses/${id}`);
    const { business, totalServicesDone } = data;
    dispatch(setBusiness({
      business: { ...business, totalServicesDone },
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const getDeliveryServicesByBusinessByHunter = (id: string, filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const { data } = await dashboardBackendApi.get(`/hunter/businesses/${id}/services?${_filters}`);
    dispatch(setDeliveryServicesByBusiness({
      businessDeliveryServices: data.businessDeliveryServices,
      totalBusinessDeliveryServices: data.totalBusinessDeliveryServices,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};