import dashboardBackendApi from '../api/dashboardBackendApi';

export const sendSocketNotificationForBusiness = async ({ businessId, title, message }: { businessId: string, title: string, message: string }) => {
  console.log('@sendSocketNotificationForBusiness');
  try {
    await dashboardBackendApi.post(`/support/socket/business/${businessId}`, {
      title,
      message,
    });
  } catch (error) {
    console.error({ error });
  }
};