/* eslint-disable no-nested-ternary */
import { FC, useState } from 'react';
import { ClientSelector, EstablishmentSelector } from './';
import { InputGooglePlaces } from '../../../components/inputs';

type OptionsType = 'maps' | 'establishment' | 'client';

const OPTIONS: OptionsType[] = ['maps', 'establishment', 'client'];

interface Props {
  onSelect: (data: any) => void;
  value: {
    address: any;
    contact: any;
    id: string;
    instructions: string;
    optionType: OptionsType;
    label?: string;
  };
}

export const LocationSearch: FC<Props> = ({ onSelect, value }) => {
  const [option, setOption] = useState<OptionsType>(value.optionType);

  return (
    <div>
      <h3>Buscar locación por</h3>
      <div className="options-container">
        {OPTIONS.map(opt => (
          <button
            className={`option ${opt === option && 'active'}`}
            key={opt}
            type="button"
            onClick={() => setOption(opt)}
          >
            {opt === 'maps' ? 'Dirección' : opt === 'establishment' ? 'Sucursal' : 'Cliente'}
          </button>
        ))}
      </div>
      <div>
        {
          option === 'maps' && (
            <InputGooglePlaces
              onChange={data => onSelect({ ...value, ...data, optionType: 'maps' })}
            // className={(option !== 'maps' && 'inactive').toString()}
            />
          )
        }
        <EstablishmentSelector
          value={value?.label && { value, label: value?.label }}
          onChange={data => onSelect({ ...value, ...data, optionType: 'establishment' })}
          className={(option !== 'establishment' && 'inactive').toString()}
        />
        <ClientSelector
          value={value?.label && { value, label: value?.label }}
          onChange={data => onSelect({ ...value, ...data, optionType: 'client' })}
          className={(option !== 'client' && 'inactive').toString()}
        />
      </div>
    </div>
  );
};
