import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ResizableReactTable from '../../../components/ResizableReactTable';
import { ButtonView, ButtonPrimary } from '../../../components/buttons';
import { ModalRight } from '../../../components/modals';
import { changeAccoutTypeLabel, formatDate, valueAccountType } from '../../../helpers';
import CreateTableData from '../../../helpers/createTableData';
import { useAppDispatch, useAppSelector } from '../../../store';
import s from './styles.module.scss';
import { getInvoicesIssuedByBusiness, payPendingInvoicesByBusinessId } from '../../../services/business';
import { BankData } from '../../../interfaces';
import { getBankDataByBusiness } from '../../../services/bankData';

interface Props {
  business: any;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const columns = [
  { Header: 'Fecha', accessor: 'createdAt' },
  { Header: 'Monto', accessor: 'total' },
  { Header: '', accessor: 'actions' },
];

export const ModalBusinessInvoices: FC<Props> = ({ business, isOpen, setIsOpen }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { invoices, totalInvoices } = useAppSelector(state => state.businesses);
  const { isSandbox } = useAppSelector(state => state.app);

  // table
  const [data, setData] = useState<any[]>([]);
  const [bankData, setBankData] = useState<BankData | null>(null);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const payInvoices = () => {
    dispatch(payPendingInvoicesByBusinessId({ businessId: business._id, isSandbox }));
    setTimeout(() => {
      setIsOpen(false);
    }, 1000);
  };

  useEffect(() => {
    const getBankData = async () => {
      if (business) {
        const data = await getBankDataByBusiness(business._id);
        setBankData(data);
      }
    };
    getBankData();
  }, [business]);

  useEffect(() => {
    if (business) {
      const filters = {
        status: 'pending',
        isSandbox,
      };
      dispatch(getInvoicesIssuedByBusiness(business._id, filters));
    } else {
      setIsOpen(false);
    }
  }, [business]);

  useEffect(() => {
    if (invoices.length > 0) {
      const newData: any[] = [];
      invoices.map((item: any) => {
        const obj = {
          ...item,
          createdAt: formatDate(item.createdAt),
          total: `$${item.total.toFixed(2)} MXN`,
          actions: (
            <>
              <ButtonView
                onClick={() => history.push(`/dashboard/services/${item.deliveryServiceId}`)}
              />
            </>
          ),
        };
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [invoices]);

  const reactTableData = CreateTableData(data, columns);
  return (
    <ModalRight
      isOpen={isOpen}
      toggleModal={toggleModal}
      title="Facturas pendientes"
    >
      <div className={s.container}>
        <p>{business.name}</p>
        <span>{business._id}</span>
        <br />
        <br />
        <ButtonPrimary
          label="Ir al negocio"
          onClick={() => history.push(`/dashboard/businesses/${business._id}`)}
        />
        <br />
        {
          bankData ? (
            <div className="d-flex flex-column">
              <h4>Información bancaria</h4>
              <span>
                <strong>Nombre del banco: </strong> {bankData.bankName}
              </span>
              <span>
                <strong>Nombre del titular: </strong> {bankData.holderName}
              </span>
              <span>
                <strong>Tipo: </strong> {changeAccoutTypeLabel(bankData.accountType)}
              </span>
              <span>
                <strong>{changeAccoutTypeLabel(bankData.accountType)}: </strong> {valueAccountType(bankData)}
              </span>
            </div>
          ) : (
            <p>No cuenta con información bancaria</p>
          )
        }
        <br />
        <p>Total a pagar: {business.productBalance}</p>
        <br />
        <ButtonPrimary
          label="Pagar facturas"
          onClick={payInvoices}
        />
        <br />
        <ResizableReactTable
          reactTableData={reactTableData}
          totalRecords={totalInvoices}
        // manualPageSize={manualPageSize}
        // limit={limit}
        // setLimit={setLimit}
        // pageIndex={pageIndex}
        // setPageIndex={setPageIndex}
        // totalNoOfPages={Math.ceil(totalInvoices / limit)}
        />
      </div>
    </ModalRight>
  );
};
