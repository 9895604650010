import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { ButtonPrimary } from '../../../components/buttons/ButtonPrimary';
import { ButtonEdit, ButtonView } from '../../../components/buttons/ButtonsIcon';
import ResizableReactTable from '../../../components/ResizableReactTable';
import CreateTableData from '../../../helpers/createTableData';
import { getEstablishmentsByBusinessId } from '../../../services/businessEstablishment';
import { RootState } from '../../../store';
import { ModalFormEstablishment } from './ModalFormEstablishment';
import { BusinessEstablishment } from '../../../interfaces/businessEstablishment';
import { ModalCardEstablishment } from './ModalCardEstablishment';

export const CardEstablishments: FC = () => {
  const { business } = useSelector((state: RootState) => state.businesses);
  const { businessEstablishmentsByBusiness, totalBusinessEstablishmentsByBusiness } = useSelector((state: RootState) => state.businessEstablishments);
  const dispatch = useDispatch();
  const [data, setData] = useState<BusinessEstablishment[]>([]);
  const [businessEstablishmentId, setBusinessEstablishmentId] = useState('');
  const [isCardOpen, setIsCardOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [businessEstablishment, setBusinessEstablishment] = useState<BusinessEstablishment | null>(null);
  const columns = [
    { Header: 'Nombre', accessor: 'name' },
    { Header: 'Teléfono', accessor: 'phone.number' },
    { Header: 'RappiId', accessor: 'rappiId' },
    { Header: 'Acciones', accessor: 'actions' },
  ];

  const getFilters = () => {
    const filters: any = {};
    filters.page = pageIndex + 1;
    filters.sortBy = '_id:desc';
    return filters;
  };

  useEffect(() => {
    if (business) {
      const filters = getFilters();
      dispatch(getEstablishmentsByBusinessId(business._id, filters));
    }
  }, [business, pageIndex]);

  useEffect(() => {
    if (businessEstablishmentsByBusiness.length > 0) {
      const newData: any[] = [];
      businessEstablishmentsByBusiness.map((item) => {
        const obj = {
          ...item,
          actions: (
            <>
              <ButtonView
                onClick={() => {
                  setBusinessEstablishmentId(item._id);
                  setIsCardOpen(true);
                }}
              />
              <ButtonEdit
                onClick={() => {
                  setBusinessEstablishment(item);
                  setIsFormOpen(true);
                }}
              />
            </>
          ),
        };
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [businessEstablishmentsByBusiness]);

  const reactTableData = CreateTableData(data, columns);
  return (
    <Card>
      <CardBody>
        <div>
          <div>
            <h4>Sucursales</h4>
          </div>
        </div>
        <ButtonPrimary
          label="Añadir nueva sucursal"
          onClick={() => {
            setBusinessEstablishment(null);
            setIsFormOpen(true);
          }}
        />
        <ModalCardEstablishment isOpen={isCardOpen} setIsOpen={setIsCardOpen} id={businessEstablishmentId} />
        <ModalFormEstablishment isOpen={isFormOpen} setIsOpen={setIsFormOpen} businessEstablishment={businessEstablishment} />
        <ResizableReactTable
          reactTableData={reactTableData}
          totalRecords={totalBusinessEstablishmentsByBusiness}
          manualPageSize={[0]}
          limit={10}
          setLimit={() => { }}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          totalNoOfPages={Math.ceil(totalBusinessEstablishmentsByBusiness / 10)}
        />
      </CardBody>
    </Card>
  );
};
