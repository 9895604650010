import { FC, useEffect, useState } from 'react';
import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import s from './styles.module.scss';
import { Location } from '../../../interfaces';
import { changeStatusLabel } from '../../../helpers/status';
import { formatDateTime } from '../../../helpers';

interface Props {
  locations: Location[];
}

interface List extends Location {
  label: string;
  active: boolean;
}

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const CardLocations: FC<Props> = ({ locations }) => {
  const [list, setList] = useState<List[]>([]);
  const [activeTab, setActiveTab] = useState('A');

  useEffect(() => {
    setList(locations.map((location, i) => ({
      ...location,
      label: ALPHABET[i],
      active: i === 0,
    })));
  }, []);

  const clickActiveTab = (label: string) => {
    setActiveTab(label);
    setList(list.map((item) => {
      item.active = item.label === label;
      return item;
    }));
  };

  return (
    <Card>
      <CardBody>
        <div>
          <Nav
            tabs
          >
            {
              list.map(item => (
                <NavItem key={item.label}>
                  <NavLink
                    active={item.active}
                    href="#"
                    onClick={() => clickActiveTab(item.label)}
                  >
                    {item.label}
                  </NavLink>
                </NavItem>
              ))
            }
          </Nav>
          <TabContent activeTab={activeTab}>
            {
              list.map(item => (
                <TabPane tabId={item.label} key={item.label}>
                  <ul className={s.table}>
                    <li>
                      <p>Nombre</p>
                      <span>{' '}</span>
                    </li>
                    <li>
                      <p>Dirección</p>
                      <span>{item.address}</span>
                    </li>
                    <li>
                      <p>Status</p>
                      <span>{changeStatusLabel(item.status, 'location')}</span>
                    </li>
                    <li>
                      <p>Contacto</p>
                      <span>{item.contactName || '---'}</span>
                    </li>
                    <li>
                      <p>Teléfono de contacto</p>
                      <span>{item.contactPhoneNumber || '---'}</span>
                    </li>
                    <li>
                      <p>Gastos del repartidor</p>
                      <span>$0</span>
                    </li>
                    <li>
                      <p>Inicio</p>
                      <span>{formatDateTime(item.createdAt)}</span>
                    </li>
                    <li>
                      <p>Empezó</p>
                      <span>{item.timeStarted ? formatDateTime(item.timeStarted) : '--'}</span>
                    </li>
                    <li>
                      <p>Llegó</p>
                      <span>{item.timeArrived ? formatDateTime(item.timeArrived) : '--'}</span>
                    </li>
                    <li>
                      <p>Terminó</p>
                      <span>{item.timeDone ? formatDateTime(item.timeDone) : '--'}</span>
                    </li>
                    <li>
                      <p>Evidencias</p>
                      <span>Ninguna</span>
                    </li>
                    {
                      item.paymentLink?.url && (
                        <li>
                          <p>Link de pago</p>
                          <span>{item.paymentLink.url}</span>
                        </li>
                      )
                    }
                    {
                      item.paymentLink?.status && (
                        <li>
                          <p>Status de link de pago</p>
                          <span>{item.paymentLink.status}</span>
                        </li>
                      )
                    }
                  </ul>
                </TabPane>
              ))
            }
          </TabContent>
        </div>
      </CardBody>
    </Card>
  );
};
