import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { ModalRight } from '../../../components/modals/ModalRight';
import { FormTransport } from './';
import { createTransport, updateTransport } from '../../../services/transport';
import { Transport } from '../../../interfaces/';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  transport: Transport | null;
}

export const ModalTransport: FC<Props> = ({ isOpen, setIsOpen, transport = null }) => {
  const dispatch = useDispatch();
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onSubmit = (data: any) => {
    if (transport) {
      dispatch(updateTransport(transport._id, data));
    } else {
      dispatch(createTransport(data));
    }
    setIsOpen(false);
  };

  return (
    <ModalRight
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={transport ? 'Editar Transporte' : 'Nuevo Transporte'}
    >
      <FormTransport onSubmit={onSubmit} transport={transport} />
    </ModalRight>
  );
};
