import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DeliveryQuote,
} from '../../../interfaces';

interface Props {
  deliveryQuotes: DeliveryQuote[];
  totalDeliveryQuotes: number;
  deliveryQuote: DeliveryQuote | null;
}

const initialState: Props = {
  deliveryQuotes: [],
  totalDeliveryQuotes: 0,
  deliveryQuote: null,
};

export const deliveryQuotesSlice = createSlice({
  name: 'deliveryQuotes',
  initialState,
  reducers: {
    setDeliveryQuotes: (state, action: PayloadAction<any>) => {
      state.deliveryQuotes = action.payload.deliveryQuotes || [];
      state.totalDeliveryQuotes = action.payload.totalDeliveryQuotes || 0;
    },
    setDeliveryQuote: (state, action: PayloadAction<any>) => {
      state.deliveryQuote = action.payload.deliveryQuote || [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setDeliveryQuotes,
  setDeliveryQuote,
} = deliveryQuotesSlice.actions;

export default deliveryQuotesSlice.reducer;
