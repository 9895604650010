import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { useAppSelector } from '../../store';

const SidebarContent = ({
  onClick,
  sidebarCollapse,
}) => {
  const { userType } = useAppSelector(state => state.auth);
  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        {
          userType === 'supportAdmin' && (
            <>
              <SidebarLink title="Home" icon="home" route="/dashboard/home" onClick={onClick} />
              <SidebarLink title="Servicios" icon="list" route="/dashboard/services" onClick={onClick} />
              <SidebarLink title="Repartidores" icon="users" route="/dashboard/deliveryDrivers" onClick={onClick} />
              <SidebarLink title="Negocios" icon="store" route="/dashboard/businesses" onClick={onClick} />
              <SidebarLink title="Sucursales" icon="store" route="/dashboard/businessEstablishments" onClick={onClick} />
              <SidebarCategory title="Zonas" icon="earth" sidebarCollapse={sidebarCollapse}>
                <SidebarLink title="Zonas" route="/dashboard/zones" onClick={onClick} />
                <SidebarLink title="Nueva Zona" route="/dashboard/zones/form/0" onClick={onClick} />
              </SidebarCategory>
              <SidebarCategory title="Pagos" icon="earth" sidebarCollapse={sidebarCollapse}>
                <SidebarLink title="Cobranza Repartidores" route="/dashboard/deliveryDrivers/income" onClick={onClick} />
                <SidebarLink title="Pagos a repartidores" icon="file-empty" route="/dashboard/invoicespending" onClick={onClick} />
                <SidebarLink title="Cobranza Negocios" route="/dashboard/businesses/income" onClick={onClick} />
                <SidebarLink title="Pagos Negocios" route="/dashboard/businesses/expense" onClick={onClick} />
              </SidebarCategory>
              <SidebarLink title="Notificaciones" icon="bullhorn" route="/dashboard/notifications" onClick={onClick} />
              <SidebarLink title="Facturas" icon="file-empty" route="/dashboard/invoices" onClick={onClick} />
              <SidebarLink title="Transacciones" icon="inbox" route="/dashboard/payments" onClick={onClick} />
              <SidebarLink title="Configuración" icon="cog" route="/dashboard/config" onClick={onClick} />
              <SidebarLink title="API" icon="code" route="/dashboard/apiDevelopers" onClick={onClick} />
              <SidebarLink title="Chat" icon="bubble" route="/dashboard/chatMessages" onClick={onClick} />
              <SidebarLink title="Delivery" icon="bubble" route="/dashboard/delivery" onClick={onClick} />
              <SidebarLink title="DeliveryProviders" icon="bubble" route="/dashboard/deliveryProviders" onClick={onClick} />
              <SidebarLink title="DeliveryQuotes" icon="bubble" route="/dashboard/deliveryQuotes" onClick={onClick} />
              <SidebarLink title="Logs" icon="bubble" route="/dashboard/logs" onClick={onClick} />
              <SidebarLink title="SupportAdmins" icon="bubble" route="/dashboard/supportAdmins" onClick={onClick} />
              <SidebarLink title="Whatsapp" icon="bubble" route="/dashboard/whatsapp" onClick={onClick} />
              <SidebarLink title="Whatsapp Templates" icon="bubble" route="/dashboard/whatsapptemplates" onClick={onClick} />
              <SidebarLink title="Campañas" icon="bubble" route="/dashboard/campaigns" onClick={onClick} />
            </>
          )
        }
        {
          userType === 'hunter' && (
            <>
              <SidebarLink title="Home" icon="home" route="/dashboard/home" onClick={onClick} />
              <SidebarLink title="Negocios" icon="store" route="/dashboard/businesses" onClick={onClick} />
            </>
          )
        }
      </ul>
    </div>
  );
};

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;
