import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { ButtonPrimary, ButtonEdit, ButtonView, ButtonRequest } from '../../components/buttons';
import { DatePicker } from '../../components/DatePicker';
import { InputSearch } from '../../components/inputs';

import ResizableReactTable from '../../components/ResizableReactTable';
import CreateTableData from '../../helpers/createTableData';
import { getBusinesses, getBusiness } from '../../services/business';
import { ModalBusiness } from './components/ModalBusiness';
import { useAppSelector, useAppDispatch } from '../../store/index';
import { Business } from '../../interfaces/business';
import { formatDate } from '../../helpers';
import { setFrom, setLimit, setPageIndex, setSearchText, setTo } from '../../store/slices/businesses';
import { IconFlask } from '../../components/icons';

const columns = [
  { Header: 'Sandbox', accessor: 'sandbox' },
  { Header: 'Fecha de creación', accessor: 'createdAt' },
  { Header: 'Nombre', accessor: 'name' },
  { Header: 'Saldo', accessor: 'accountBalance.paymentBalance' },
  { Header: 'Max crédito', accessor: 'maxNegativeBalance' },
  { Header: 'Sucursales', accessor: 'totalEstablishments' },
  { Header: 'Clientes', accessor: 'totalClients' },
  { Header: 'Acciones', accessor: 'actions' },
];

const Businesses = () => {
  const {
    businesses,
    totalBusinesses,
    limit,
    pageIndex,
    from,
    to,
    searchText,
  } = useAppSelector(state => state.businesses);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { app } = useAppSelector(state => state);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<Business[]>([]);
  const [manualPageSize] = useState([10, 20, 50, 100]);
  const [business, setBusiness] = useState<Business | null>(null);

  const getFilters = () => {
    const filters: any = {};
    filters.limit = limit;
    filters.page = pageIndex + 1;
    if (from && to) {
      filters.from = from;
      filters.to = to;
    }
    if (searchText) {
      filters.search = searchText;
    }
    filters.isSandbox = app.isSandbox ? 'yes' : 'no';
    filters.sortBy = '_id:desc';
    return filters;
  };

  const getAll = () => {
    try {
      const filters = getFilters();
      dispatch(getBusinesses(filters));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAll();
  }, [limit, pageIndex, from, to, searchText, app.isSandbox]);

  useEffect(() => {
    if (businesses.length > 0) {
      const newData: any[] = [];
      businesses.map((item) => {
        const obj = {
          ...item,
          actions: (
            <>
              <ButtonView
                onClick={() => history.push(`/dashboard/businesses/${item._id}`)}
              />
              <ButtonEdit
                onClick={() => {
                  setBusiness(item);
                  setIsOpen(true);
                }}
              />
              <ButtonRequest
                onClick={() => {
                  getBusiness(item._id);
                  history.push(`/dashboard/businesses/serviceRequest/${item._id}`);
                }}
              />
            </>
          ),
          sandbox: item.isSandbox === true ? <IconFlask color="#00E5B2" /> : '',
          totalEstablishments: item.totalEstablishments || 0,
          totalClients: item.totalClients || 0,
        };
        if (obj.createdAt) {
          obj.createdAt = formatDate(item.createdAt);
        }
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [businesses]);

  const reactTableData = CreateTableData(data, columns);

  const handleDateChange = (fromDate: any, toDate: any) => {
    dispatch(setFrom(fromDate));
    dispatch(setTo(toDate));
    dispatch(setPageIndex(0));
  };

  // const changeIsSandboxFilter = (value: any) => {
  //   setIsSandboxDropdown({
  //     ...isSandboxDropdown,
  //     selectedKey: value,
  //   });
  //   dispatch(setFilters({
  //     filter: 'isSandbox',
  //     value,
  //   }));
  // };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title">
                  <h5 className="bold-text">Negocios</h5>
                </div>
                <div>
                  <ButtonPrimary
                    label="Crear negocio"
                    onClick={() => {
                      setBusiness(null);
                      setIsOpen(true);
                    }}
                  />
                </div>
              </div>
              {/* <Row
                style={{ paddingLeft: '20px' }}
              >
                <CustomDropdown
                  {...isSandboxDropdown}
                  onClick={changeIsSandboxFilter}
                />
              </Row> */}
              <InputSearch
                value={searchText}
                onChange={(e: any) => {
                  dispatch(setPageIndex(0));
                  dispatch(setSearchText(e.target.value));
                }}
                placeholder="Introduce nombre, email de admin, teléfono de admin"
                style={{
                  marginBottom: '5px',
                  width: '75%',
                }}
              />
              <Row>
                <DatePicker handleDateChange={handleDateChange} from={from} to={to} />
              </Row>
              <ModalBusiness isOpen={isOpen} setIsOpen={setIsOpen} business={business} />
              {
                data.length === 0 ? <h3> No hay info que se pueda mostrar</h3> : (
                  <ResizableReactTable
                    reactTableData={reactTableData}
                    totalRecords={totalBusinesses}
                    manualPageSize={manualPageSize}
                    limit={limit}
                    setLimit={l => dispatch(setLimit(l))}
                    pageIndex={pageIndex}
                    setPageIndex={p => dispatch(setPageIndex(p))}
                    totalNoOfPages={Math.ceil(totalBusinesses / limit)}
                  />
                )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Businesses;
