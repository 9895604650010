import { FC } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import './modals.scss';

interface Props {
  isOpen: boolean;
  toggleModal: () => void;
  title: string;
  size?: string;
}

export const ModalCenter: FC<Props> = ({ children, isOpen, toggleModal, title, size }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      backdrop
      size={size}
    >
      <ModalHeader toggle={toggleModal}>
        <p>{title}</p>
      </ModalHeader>
      <ModalBody>
        {children}
      </ModalBody>
    </Modal>
  );
};
