import { useContext, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import Notification from 'rc-notification';
import { BasicNotification } from '../../../../components/notifications';
import { SocketContext } from '../../../../context/SocketContext';
import { createNotificationAlert } from '../../../../helpers';
import Layout from '../../../../Layout';
import Dashboard from './Dashboard';

const objectNotification: any = {
  RU: null,
};

Notification.newInstance({ style: { top: 65 } }, (n) => { objectNotification.RU = n; });

export default () => {
  const { socket } = useContext(SocketContext);
  const history = useHistory();

  useEffect(() => {
    socket?.on('new-notification', (data: any) => {
      show(showNotification, createNotificationAlert(data));
      console.log({ data });
    });
    return () => {
      socket?.off('new-notification');
    };
  }, [socket]);

  const showNotification = ({ notification }: { notification: any }) => {
    const notificationDefaultProps = {
      content: notification({
        className: 'theme-dark',
      }),
      key: new Date().getTime(),
      duration: 5,
      closable: true,
      className: 'right-up rtl-support',
    };

    const style = { top: 0, left: 'calc(100vw - 100%)' };
    const type = 'RU';

    objectNotification[type].notice({
      ...notificationDefaultProps,
      style,
    });
  };

  const show = (showNotification: any, data: any) => showNotification({
    notification() {
      return (
        <BasicNotification
          {...data}
          history={history}
        />
      );
    },
    position: 'right-up',
  });
  return (
    <div>
      <Layout />
      <div className="container__wrap">
        <Route path="/dashboard" component={Dashboard} />
      </div>
    </div>
  );
};
