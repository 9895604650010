/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-for */

import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody } from 'reactstrap';
import { RWebShare } from 'react-web-share';
import { ButtonPrimary, SwitchButton } from '../../../components/buttons';
import { DatePicker } from '../../../components/DatePicker';
import { formatDate, formatDateTime } from '../../../helpers';
import { RootState } from '../../../store/index';
import { ModalSendMessage } from './ModalSendMessage';
import { getUrl } from '../../../helpers/cloudinary';
import { IconFlask } from '../../../components/icons';
import { getDeliveryDriver, updateDeliveryDriver } from '../../../services/deliveryDrivers';
import backendApi from '../../../api/backendApi';

interface Props {
  from: any;
  setFrom: React.Dispatch<React.SetStateAction<null>>;
  to: any;
  setTo: React.Dispatch<React.SetStateAction<null>>;
}

export const CardInfo: FC<Props> = ({ from, setFrom, to, setTo }) => {

  const dispatch = useDispatch();

  const { deliveryDriver } = useSelector((state: RootState) => state.deliveryDrivers);

  const [isOpenSendMessage, setIsOpenSendMessage] = useState(false);

  if (!deliveryDriver) {
    return (
      <Card>
        <CardBody className="profile__card">
          <div className="profile__information">
            <p>cargando...</p>
          </div>
        </CardBody>
      </Card>
    );
  }

  const { _id, firstName, lastName, email, phoneNumber, isApproved, createdAt, isVisible, isSandbox, profileImage, isFounder, appOpenedAt, referralCode } = deliveryDriver;

  const handleDateChange = (fromDate: any, toDate: any) => {
    setFrom(fromDate);
    setTo(toDate);
  };

  const handleIsVisibleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget;
    dispatch(updateDeliveryDriver(deliveryDriver._id, { isVisible: checked }));
  };

  const handleIsFounderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget;
    dispatch(updateDeliveryDriver(deliveryDriver._id, { isFounder: checked }));
  };

  const profileImageId = profileImage.imageId ? `url('${getUrl(profileImage.imageId)}')` : 'url("https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/OOjs_UI_icon_userAvatar.svg/2048px-OOjs_UI_icon_userAvatar.svg.png")';

  const generateReferralCode = async () => {
    try {
      // const resp = await dashboardBackendApi.get(`/support/deliveryDrivers/${deliveryDriver._id}/qrcode`);
      const { data } = await backendApi.get(`/api/support/driver/${deliveryDriver._id}/qrcode`);
      console.log({ data });
      dispatch(getDeliveryDriver(deliveryDriver._id));
    } catch (error) {
      console.error({ error });
    }
  };

  return (
    <>
      <Card>
        <CardBody className="profile__card">
          <div className="profile__information">
            {/* <div className="profile__avatar">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/OOjs_UI_icon_userAvatar.svg/2048px-OOjs_UI_icon_userAvatar.svg.png"
                alt="avatar"
              />
            </div> */}
            <div
              className="avatar"
              style={{
                width: 180,
                height: 180,
                borderRadius: 99,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundImage: profileImageId,
              }}
            />
            <div className="profile__data">

              <p className="profile__name">{isSandbox && <IconFlask />} {`${firstName} ${lastName}`}</p>
              {createdAt && <p className="profile__work">Fecha de registro {formatDate(createdAt)}</p>}
              <p className="profile__contact">{email}</p>
              {
                phoneNumber?.verificationCode && (
                  <p className="profile__contact" dir="ltr">
                    Código de verificación: {phoneNumber?.verificationCode}
                  </p>
                )
              }
              <p className="profile__contact" dir="ltr">
                {phoneNumber?.number || '00000000'} - {phoneNumber?.isVerified ? 'Verificado' : 'No verificado'}
              </p>
              <p className="profile__contact" dir="ltr">
                {isApproved ? '' : 'No '}Aprobado
              </p>
              <p className="profile__contact" dir="ltr">
                Visible: <SwitchButton checked={isVisible} onChange={handleIsVisibleChange} />
              </p>
              {/* <input type="checkbox" defaultChecked={isVisible} onChange={handleIsVisibleChange} /> */}
              <p className="profile__contact" dir="ltr">
                Sandbox: {isSandbox === true ? 'Si' : 'No '}
              </p>
              <p className="profile__contact" dir="ltr">
                Fundador: <SwitchButton checked={isFounder} onChange={handleIsFounderChange} />
              </p>
              <p className="profile__contact" dir="ltr">
                Última apertura: {formatDateTime(appOpenedAt)}
              </p>
              {
                referralCode ? (
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="profile__contact" dir="ltr">
                      Código de referido: {referralCode}
                    </p>
                    <div>
                      <RWebShare
                        data={{
                          text: 'Link de referido',
                          url: `https://negocios.repartimos.mx/user/register?referralCode=${referralCode}`,
                          title: 'Código de referido',
                        }}
                        onClick={() => console.log('shared successfully!')}
                      >
                        <Button>Share 🔗</Button>
                      </RWebShare>
                    </div>
                  </div>
                ) : (
                  <ButtonPrimary
                    label="Generar código de referido"
                    onClick={generateReferralCode}
                  />
                )
              }
              <ButtonPrimary
                label="Enviar notificación"
                onClick={() => {
                  setIsOpenSendMessage(true);
                }}
              />
            </div>
          </div>

          <DatePicker handleDateChange={handleDateChange} from={from} to={to} />
        </CardBody>
      </Card>

      <ModalSendMessage isOpen={isOpenSendMessage} setIsOpen={setIsOpenSendMessage} id={_id} />
    </>
  );
};
