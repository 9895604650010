import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { ModalRight } from '../../../components/modals/ModalRight';
import { FormBusiness } from './FormBusiness';
import { createBusiness, updateBusiness } from '../../../services/business';
import { Business } from '../../../interfaces/business';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  business: Business | null;
}

export const ModalBusiness: FC<Props> = ({ isOpen, setIsOpen, business = null }) => {
  const dispatch = useDispatch();
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onSubmit = (data: any) => {
    if (business) {
      dispatch(updateBusiness(business._id, data));
    } else {
      dispatch(createBusiness(data));
    }
    setIsOpen(false);
  };

  return (
    <ModalRight
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={business ? 'Editar Negocio' : 'Nuevo Negocio'}
    >
      <FormBusiness onSubmit={onSubmit} business={business} />
    </ModalRight>
  );
};
