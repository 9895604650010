import { Marker } from '@react-google-maps/api';
import { FC } from 'react';
import { MyGoogleMaps } from '../../../components/maps/MyGoogleMaps';

interface Props {
  position: {
    lat: number;
    lng: number;
  };
  onMarkerDragEnd: (x: any) => void;
}

export const MapEstablishment: FC<Props> = ({ position, onMarkerDragEnd }) => {
  return (
    <MyGoogleMaps
      center={position}
    >
      {
        position && (
          <Marker
            position={position}
            draggable
            onDragEnd={onMarkerDragEnd}
          />
        )
      }
    </MyGoogleMaps>
  );
};
