import dashboardBackendApi from '../api/dashboardBackendApi';
import { handleAxiosError } from '../helpers/handleErrors';
import { serialize } from '../helpers/serialize';
import { PaymentsResponse } from '../interfaces';
import { setPayments } from '../store/slices/payments';

export const getPayments = (filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const resp = await dashboardBackendApi.get<PaymentsResponse>(`/support/payments?${_filters}`);
    dispatch(setPayments({
      payments: resp.data.payments,
      totalTrsetPayments: resp.data.totalPayments,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};
