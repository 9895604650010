import { FC, useReducer } from 'react';
import { Card, CardBody } from 'reactstrap';
import { Wizard, Steps, Step } from 'react-albus';
import { TopNavigation, BottomNavigation, StepOne, StepTwo, StepThree } from './';
import { formInitialState, formStateReducer } from '../reducers';
import { extractAddresses } from '../../../helpers/extract';

interface Props { }

export const StepsRequest: FC<Props> = () => {
  const topNavClick = (stepItem: any, push: any) => {
    push(stepItem.id);
  };

  const onClickNext = (goToNext: any, steps: any, step: any) => {
    step.isDone = true;
    if (steps.length - 1 <= steps.indexOf(step)) {
      return;
    }
    goToNext();
  };

  const onClickPrev = (goToPrev: any, steps: any, step: any) => {
    if (steps.indexOf(step) <= 0) {
      return;
    }
    goToPrev();
  };

  const [formState, dispatch] = useReducer(formStateReducer, formInitialState);
  return (
    <Card>
      <h1 className="service heading" style={{ marginBottom: 45 }}>Pide un servicio</h1>
      <CardBody className="wizard wizard-default">
        <Wizard>
          <TopNavigation
            className="justify-content-center"
            disableNav
            topNavClick={topNavClick}
          />
          <Steps>
            <Step
              id="step1"
            // name="Paso 1"
            // desc="Selecciona una dirección"
            >
              <div className="wizard-basic-step">
                <StepOne state={formState} dispatch={dispatch} />
                <button
                  type="button"
                  onClick={() => dispatch({ type: 'addLocation', payload: {} })}
                  style={{
                    border: 'none',
                    background: 'none',
                    color: 'var(--theme-color-2)',
                  }}
                >
                  Añadir locación extra
                </button>
              </div>
            </Step>
            <Step
              id="step2"
            // name="Paso 2"
            // desc="Selecciona tus opciones"
            >
              <div className="wizard-basic-step">
                <StepTwo state={formState} dispatch={dispatch} />
              </div>
            </Step>
            <Step
              id="step3"
            // name="Paso 3"
            // desc="Confirmación"
            >
              <div className="wizard-basic-step">
                <StepThree state={formState} />
              </div>
            </Step>
          </Steps>
          <BottomNavigation
            nextDisabled={!extractAddresses(formState.locations)}
            onClickNext={onClickNext}
            onClickPrev={onClickPrev}
            className="justify-content-center"
            prevLabel="Atras"
            nextLabel="Siguiente"
          />
        </Wizard>
      </CardBody>
    </Card>
  );
};
