import dashboardBackendApi from '../api/dashboardBackendApi';
import { handleAxiosError } from '../helpers/handleErrors';
import { BankData } from '../interfaces';
import { getDeliveryDriver } from './deliveryDrivers';

export const createBankData = (bankData: BankData, type: string) => async (dispatch: any) => {
  try {
    await dashboardBackendApi.post<any>('/support/bankData', { ...bankData, type });
    if (type === 'driver' && bankData.deliveryDriverId) {
      dispatch(getDeliveryDriver(bankData.deliveryDriverId));
    }
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const updateBankData = (bankData: BankData, type: string) => async (dispatch: any) => {
  try {
    await dashboardBackendApi.put<any>('/support/bankData', { ...bankData, type });
    if (type === 'driver' && bankData.deliveryDriverId) {
      dispatch(getDeliveryDriver(bankData.deliveryDriverId));
    }
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const getBankDataByBusiness = async (businessId: string) => {
  try {
    const { data } = await dashboardBackendApi.get<any>(`/support/bankData/business/${businessId}`);
    return data.bankData;
  } catch (error) {
    return null;
  }
};

