import EyeIcon from 'mdi-react/EyeIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import CartOutlineIcon from 'mdi-react/CartOutlineIcon';
import InboxArrowDownIcon from 'mdi-react/InboxArrowDownIcon';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';

interface Props {
  onClick: () => void;
  size?: number;
  color?: string;
  id?: string;
  className?: string;
}

export const ButtonView = ({ onClick, size = 20, color = 'gray' }: Props) => {
  return (
    <EyeIcon
      onClick={onClick}
      style={{ cursor: 'pointer', margin: '0 5px' }}
      size={size}
      color={color}
    />
  );
};

export const ButtonEdit = ({ onClick, size = 20, color = 'gray' }: Props) => {
  return (
    <PencilIcon
      onClick={onClick}
      style={{ cursor: 'pointer', margin: '0 5px' }}
      size={size}
      color={color}
    />
  );
};

export const ButtonRequest = ({ onClick, size = 20, color = 'gray' }: Props) => {
  return (
    <CartOutlineIcon
      onClick={onClick}
      style={{ cursor: 'pointer', margin: '0 5px' }}
      size={size}
      color={color}
    />
  );
};

export const AddToInbox = ({ onClick, size = 20, color = 'gray' }: Props) => {
  return (
    <InboxArrowDownIcon
      onClick={onClick}
      style={{ cursor: 'pointer', margin: '0 5px' }}
      size={size}
      color={color}
    />
  );
};

export const ButtonCopy = ({ onClick, size = 20, color = 'gray', id, className = '' }: Props) => {
  return (
    <ContentCopyIcon
      onClick={onClick}
      style={{ cursor: 'pointer', margin: '0 5px' }}
      size={size}
      color={color}
      id={id}
      className={className}
    />
  );
};
