import { FC } from 'react';
import { Card, CardBody } from 'reactstrap';
import { FormPasswordChange } from './';
import { updatePassword } from '../../../services/supportAdmin';

interface Props { }

export const CardPasswordChange: FC<Props> = () => {

  const onSubmit = async (data: any) => {
    try {
      await updatePassword(data.password);
    } catch (error) {
      console.error({ error });
    }
  };

  return (
    <Card>
      <CardBody>
        <div className="">
          <div className="card__title">
            <h5 className="bold-text">Contraseña</h5>
          </div>
          <FormPasswordChange onSubmit={onSubmit} />
        </div>
      </CardBody>
    </Card>
  );
};
