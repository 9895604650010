import { FC } from 'react';
import { Form, Formik, Field } from 'formik';
import { FormGroup, Label, Button } from 'reactstrap';

interface Props {
  onSelect: (data: any) => void;
  onHide: () => void;
  value: any;
  index: number;
}

export const LocationDetailsForm: FC<Props> = ({ onSelect, onHide, value, index }) => {
  const saveHandler = (data: any) => {
    onSelect(data);
    onHide();
  };

  return (
    <div className="location-details-form">
      <div className="title-container">
        <span className="index">{index + 1}</span>
        <h3>Detalles de contacto</h3>
      </div>
      <Formik
        initialValues={value}
        enableReinitialize
        onSubmit={() => { }}
      >
        {({ values }) => (
          <Form>
            <FormGroup className="group">
              <Label htmlFor="name">
                Nombre
              </Label>
              <Field name="contact.name" id="name" />
            </FormGroup>
            <FormGroup className="group">
              <Label htmlFor="phone">
                Teléfono
              </Label>
              <Field name="contact.phone" id="phone" />
            </FormGroup>
            <FormGroup className="group">
              <Label htmlFor="instructions">
                Instrucciones
              </Label>
              <Field name="instructions" id="instructions" />
            </FormGroup>
            <div className="address">
              <FormGroup className="group">
                <Label htmlFor="exterior">
                  # Exterior
                </Label>
                <Field name="address.exterior" id="exterior" />
              </FormGroup>
              <FormGroup className="group">
                <Label htmlFor="interior">
                  # Interior
                </Label>
                <Field name="address.interior" id="interior" />
              </FormGroup>
            </div>

            <div className="btns">
              <Button onClick={onHide} color="danger" outline>
                Cancelar
              </Button>
              <Button onClick={() => saveHandler(values)}>
                Guardar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
