import React from 'react';
/* eslint-disable jsx-a11y/label-has-for */

interface Props {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SwitchButton = ({ checked, onChange }: Props) => {
  return (
    <label className="switch-checkbox">
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className="slider-checkbox" />
    </label>
  );
};