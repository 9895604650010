import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Props {
  chats: [];
  messages: [];
  totalCount: number;
  session: any;
  templates: any[];
  totalTemplates: number;
}

const initialState: Props = {
  chats: [],
  totalCount: 0,
  messages: [],
  session: {},
  templates: [],
  totalTemplates: 0,
};

export const chatsMessagesSlice = createSlice({
  name: 'chatMessages',
  initialState,
  reducers: {
    setChats: (state, action: PayloadAction<any>) => {
      state.chats = action.payload.chats || [];
      state.totalCount = action.payload.totalCount || 0;
    },
    setMessages: (state, action: PayloadAction<any>) => {
      state.messages = action.payload.messages || [];
    },
    setSession: (state, action: PayloadAction<any>) => {
      state.session = action.payload || {};
    },
    setTemplates: (state, { payload }: PayloadAction<any>) => {
      state.templates = payload.templates || [];
      state.totalTemplates = payload.totalTemplates || 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setChats,
  setMessages,
  setSession,
  setTemplates,
} = chatsMessagesSlice.actions;

export default chatsMessagesSlice.reducer;
