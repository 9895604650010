import { FC, useEffect, useState } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { ButtonView } from '../../../components/buttons/ButtonsIcon';
import ResizableReactTable from '../../../components/ResizableReactTable';
import { changeTransportLabel, formatDateTime, formatDayTime } from '../../../helpers';
import CreateTableData from '../../../helpers/createTableData';
import { DeliveryService } from '../../../interfaces';

interface Props {
  label: string;
  totalRecords: number;
  data: DeliveryService[];
  columnsTable?: Columns;
}

type Columns = {
  Header: string;
  accessor: string;
}[];

const columns: Columns = [
  { Header: 'Fecha de creación', accessor: 'createdAt' },
  { Header: 'Tipo', accessor: 'type' },
  { Header: 'Repartidor', accessor: 'driver.name' },
  { Header: 'Negocio', accessor: 'business.name' },
  { Header: 'Sucursal', accessor: 'business.businessEstablishmentName' },
  { Header: 'Transporte', accessor: 'transport' },
  // { Header: 'Total', accessor: 'priceDetail.finalPriceTotal' },
  { Header: 'Precio Máximo', accessor: 'maxPrice' },
  { Header: 'Total Aceptado', accessor: 'acceptedPriceDetail.finalPriceTotal' },
  { Header: 'Acciones', accessor: 'actions' },
];

const TableCard: FC<Props> = ({ totalRecords, data, label, columnsTable = columns }) => {
  const [list, setList] = useState<any[]>([]);

  const history = useHistory();

  useEffect(() => {
    if (data.length > 0) {
      const newData: any[] = [];
      data.map((item) => {
        // const VAT = (item.priceDetail.finalPriceTotal * item.priceDetail.deliveryVAT) / 100;
        const obj = {
          ...item,
          actions: (
            <>
              <ButtonView
                onClick={() => history.push(`/dashboard/services/${item._id}`)}
              />
            </>
          ),
          transport: changeTransportLabel(item.transport),
          // priceDetail: {
          //   finalPriceTotal: `$${(item.priceDetail.finalPriceTotal).toFixed(2)} MXN`,
          // },
          maxPrice: `$${(item.maxPrice || 0).toFixed(2)} MXN`,
          acceptedPriceDetail: {
            finalPriceTotal: `$${(item.acceptedPriceDetail?.finalPriceTotal || 0).toFixed(2)} MXN`,
          },
        };
        if (obj.createdAt) {
          obj.createdAt = formatDayTime(item.createdAt);
        }
        if (obj.scheduledAt) {
          obj.scheduledAt = formatDateTime(item.scheduledAt);
        }
        newData.push(obj);
        return item;
      });
      setList(newData);
    } else {
      setList([]);
    }
  }, [data]);

  const reactTableData = CreateTableData(list, columnsTable);

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="react-table__wrapper">
            <div className="card__title">
              <h5 className="bold-text">{label}</h5>
            </div>
          </div>
          {/* <ReactTableBase columns={columns} data={data} totalRecords={3} /> */}
          {
            list.length === 0 ? <h3>No hay info que se pueda mostrar</h3> : (
              <ResizableReactTable
                reactTableData={reactTableData}
                totalRecords={totalRecords}
                pagination={false}
              />
            )
          }
        </CardBody>
      </Card>
    </Col>
  );
};

export default TableCard;
