/* eslint-disable react/destructuring-assignment */
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { CSSProperties, FC } from 'react';

interface Props {
  value: any;
  dragging: any;
  index: any;
  offset: any;
}

const sliderHandle: FC<Props> = ({ value, index, offset, ...restProps }) => {
  const positionStyle: CSSProperties = {
    position: 'absolute',
    left: `${offset}%`,
  };
  return (
    <span key={index}>
      <div className="rc-slider-tooltip" style={positionStyle}>
        {`$${value}`}
      </div>
      <Slider.Handle value={value} offset={offset} {...restProps} />
    </span>
  );
};

const SliderTooltip = (props: any) => (
  <Slider handle={props.handle || sliderHandle} {...props} />
);

const RangeTooltip = (props: any) => (
  <Range handle={props.handle || sliderHandle} {...props} />
);
export { SliderTooltip, RangeTooltip };
