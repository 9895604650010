export const statesOfTheRepublic = [
  { label: 'Aguascalientes', lat: 22.072424679550963, lng: -102.3076925037383 },
  { label: 'Baja California Sur', lat: 25.144873396589055, lng: -111.22626712355716 },
  { label: 'Baja California', lat: 32.16361169573461, lng: -116.30314368461677 },
  { label: 'Campeche', lat: 19.157825375285878, lng: -90.49217258430184 },
  { label: 'Chiapas', lat: 16.497999555159172, lng: -92.63970339423166 },
  { label: 'Chihuahua', lat: 28.351050318487175, lng: -106.6160957446624 },
  { label: 'Ciudad de México', lat: 19.34508941956005, lng: -99.15325161549731 },
  { label: 'Coahuila de Zaragoza', lat: 27.171975924532884, lng: -101.55443024813405 },
  { label: 'Colima', lat: 19.17199958520997, lng: -103.85965601988944 },
  { label: 'Durango', lat: 24.936135381568878, lng: -104.71183325398005 },
  { label: 'Guanajuato', lat: 20.70872388079389, lng: -100.97822117196797 },
  { label: 'Guerrero', lat: 17.603072672198287, lng: -99.4330865332077 },
  { label: 'Hidalgo', lat: 20.414745699235, lng: -98.82853802594867 },
  { label: 'Jalisco', lat: 20.503974427252164, lng: -103.47801129744668 },
  { label: 'México', lat: 19.399781139496284, lng: -99.41811914832161 },
  { label: 'Michoacán de Ocampo', lat: 19.592093662002934, lng: -101.72894682760807 },
  { label: 'Morelos', lat: 18.785273073272382, lng: -99.06447562279382 },
  { label: 'Nayarit', lat: 21.612047793914265, lng: -104.9045736885445 },
  { label: 'Nuevo León', lat: 25.677848987480008, lng: -99.97854959979908 },
  { label: 'Oaxaca', lat: 17.09480291837741, lng: -96.85371514883823 },
  { label: 'Puebla', lat: 19.12076780693857, lng: -97.90565393068613 },
  { label: 'Querétaro', lat: 20.78795977100644, lng: -99.91410127758904 },
  { label: 'Quintana Roo', lat: 20.227380499287243, lng: -87.61694630944338 },
  { label: 'San Luis Potosí', lat: 22.21180832515034, lng: -100.01777980432487 },
  { label: 'Sinaloa', lat: 24.79677259561704, lng: -107.39045390406226 },
  { label: 'Sonora', lat: 29.620420823768377, lng: -110.28374094228859 },
  { label: 'Tabasco', lat: 17.95144645624265, lng: -92.70923853415773 },
  { label: 'Tamaulipas', lat: 24.21991134477258, lng: -98.82317373720538 },
  { label: 'Tlaxcala', lat: 19.342950737026232, lng: -98.17709507379548 },
  { label: 'Veracruz de Ignacio de la Llave', lat: 19.346112382279962, lng: -96.69711338579734 },
  { label: 'Yucatán', lat: 20.81451524110606, lng: -89.12400087086701 },
  { label: 'Zacatecas', lat: 22.615264943276273, lng: -102.77211444149302 },
];
