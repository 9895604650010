/* eslint-disable no-nested-ternary */
import { FC, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import ResizableReactTable from '../../components/ResizableReactTable';
import CreateTableData from '../../helpers/createTableData';
import { ButtonView } from '../../components/buttons/ButtonsIcon';
// import { DatePicker } from '../../components/DatePicker';
import { changeStatusLabel } from '../../helpers/status';
import { useAppDispatch, useAppSelector } from '../../store';
import { formatDate } from '../../helpers';
import { getNotifications } from '../../services/notification';
import { setLimit, setPageIndex } from '../../store/slices/notifications';

const Notifications: FC = () => {
  const history = useHistory();
  const { notifications, totalNotifications, limit, pageIndex } = useAppSelector(state => state.notifications);
  const dispatch = useAppDispatch();

  const [data, setData] = useState<any[]>([]);
  const [manualPageSize] = useState([10, 20, 50, 100]);
  // const [from, setFrom] = useState(null);
  // const [to, setTo] = useState(null);

  const columns = [
    { Header: 'Fecha de creación', accessor: 'createdAt' },
    { Header: 'Categoria', accessor: 'category' },
    { Header: 'Tipo', accessor: 'type' },
    { Header: 'Status', accessor: 'data.status' },
    { Header: 'Nombre del repartidor', accessor: 'data.driverName' },
    { Header: 'Nombre del negocio', accessor: 'data.businessName' },
    { Header: 'Acciones', accessor: 'actions' },
  ];

  const getFilters = () => {
    const filters: any = {};
    filters.limit = limit;
    filters.page = pageIndex + 1;
    // if (from && to) {
    //   filters.from = from;
    //   filters.to = to;
    // }
    filters.sortBy = '_id:desc';
    return filters;
  };

  const getAll = () => {
    try {
      const filters = getFilters();
      dispatch(getNotifications(filters));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAll();
  }, [limit, pageIndex]);

  useEffect(() => {
    if (notifications.length > 0) {
      const newData: any[] = [];
      notifications.map((item) => {
        let url = '';
        let id = '';
        switch (item.category) {
          case 'deliveryDriver':
            id = item.data.deliveryDriverId;
            url = 'dashboard/deliveryDrivers';
            break;
          case 'deliveryServiceLocation':
          case 'deliveryService':
            id = item.data.deliveryServiceId;
            url = 'dashboard/services';
            break;
          case 'business':
            id = item.data.businessId;
            url = 'dashboard/businesses';
            break;
          default:
            break;
        }
        const obj = {
          ...item,
          actions: (
            <>
              <ButtonView
                onClick={() => history.push(`/${url}/${id}`)}
              />
            </>
          ),
          status: changeStatusLabel(item.status, 'service'),
        };
        if (obj.createdAt) {
          obj.createdAt = formatDate(item.createdAt);
        }
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [notifications]);

  const reactTableData = CreateTableData(data, columns);

  // const handleDateChange = (fromDate: any, toDate: any) => {
  //   setFrom(fromDate);
  //   setTo(toDate);
  //   setPageIndex(0);
  // };

  // const changeStatusFilter = (status: string) => {
  //   setStatusDropdown({
  //     ...statusDropdown,
  //     selectedKey: status,
  //   });
  // };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title">
                  <h5 className="bold-text">Notificaciones</h5>
                </div>
              </div>
              {/* <Row>
                <DatePicker handleDateChange={handleDateChange} from={from} to={to} />
              </Row> */}
              {
                data.length === 0 ? <h3>No hay info que se pueda mostrar</h3> : (
                  <ResizableReactTable
                    reactTableData={reactTableData}
                    totalRecords={totalNotifications}
                    manualPageSize={manualPageSize}
                    limit={limit}
                    setLimit={l => dispatch(setLimit(l))}
                    pageIndex={pageIndex}
                    setPageIndex={p => dispatch(setPageIndex(p))}
                    totalNoOfPages={Math.ceil(totalNotifications / limit)}
                  />
                )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Notifications;
