/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC } from 'react';

interface Props {
  color?: string;
  title?: string;
  message: string;
  url: string | null;
  history: any;
}

export const BasicNotification: FC<Props> = ({ color = 'primary', title = '', message, url = null, history }) => {
  return (
    <div
      className={`notification notification--${color} notification--theme-light`}
      onClick={() => url && history.push(url)}
    >
      <h5 className="notification__title bold-text">{title}</h5>
      <p className="notification__message">{message}</p>
    </div>
  );
};