import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { ButtonPrimary } from '../../../components/buttons/ButtonPrimary';
import { ButtonEdit } from '../../../components/buttons/ButtonsIcon';
import ResizableReactTable from '../../../components/ResizableReactTable';
import CreateTableData from '../../../helpers/createTableData';
import { getBusinessAdminsByBusinessId } from '../../../services/businessAdmin';
import { RootState } from '../../../store';
import { ModalAdmin } from './ModalAdmin';
import { BusinessAdmin } from '../../../interfaces/businessAdmin';

export const CardAdmins: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { business, businessAdmins, totalBusinessAdmins } = useSelector((state: RootState) => state.businesses);
  const dispatch = useDispatch();
  const [businessAdmin, setBusinessAdmin] = useState<BusinessAdmin | null>(null);
  const [data, setData] = useState<BusinessAdmin[]>([]);
  const [pageIndex, setPageIndex] = useState(0);
  const columns = [
    { Header: 'Nombre', accessor: 'firstName' },
    { Header: 'Correo', accessor: 'email' },
    { Header: 'Teléfono', accessor: 'phone.number' },
    { Header: 'Acciones', accessor: 'actions' },
  ];

  const getFilters = () => {
    const filters: any = {};
    filters.page = pageIndex + 1;
    filters.sortBy = '_id:desc';
    return filters;
  };

  useEffect(() => {
    if (business) {
      const filters = getFilters();
      dispatch(getBusinessAdminsByBusinessId(business._id, filters));
    }
  }, [business, pageIndex]);

  useEffect(() => {
    if (businessAdmins.length > 0) {
      const newData: any[] = [];
      businessAdmins.map((item) => {
        const obj = {
          ...item,
          actions: (
            <ButtonEdit
              onClick={() => {
                setBusinessAdmin(item);
                setIsOpen(true);
              }}
            />
          ),
        };
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [businessAdmins]);

  const reactTableData = CreateTableData(data, columns);
  return (
    <Card>
      <CardBody>
        <div>
          <div>
            <h4>Administradores</h4>
          </div>
        </div>
        <ButtonPrimary
          label="Añadir nuevo administrador"
          onClick={() => setIsOpen(true)}
        />
        <ModalAdmin isOpen={isOpen} setIsOpen={setIsOpen} businessAdmin={businessAdmin} />
        <ResizableReactTable
          reactTableData={reactTableData}
          totalRecords={totalBusinessAdmins}
          manualPageSize={[0]}
          limit={10}
          setLimit={() => { }}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          totalNoOfPages={Math.ceil(totalBusinessAdmins / 10)}
        />
      </CardBody>
    </Card>
  );
};
