import { DeliveryServiceStatus, LocationStatus, QuoteStatus, RequestStatusType } from '../interfaces';

export const changeStatusLabel = (status: DeliveryServiceStatus | LocationStatus | RequestStatusType | QuoteStatus, type: 'service' | 'location' | 'request' | 'quote') => {
  switch (type) {
    case 'service':
      switch (status) {
        // DeliveryServiceStatus
        case 'accepted':
          return 'Aceptado';
        case 'canceled':
          return 'Cancelado';
        case 'done':
          return 'Terminado';
        case 'noDrivers':
          return 'Sin repartidores';
        case 'onRoute':
          return 'En camino';
        case 'requestingDriver':
          return 'Preguntando a repartidores';
        case 'scheduled':
          return 'Programado';
        case 'unassgined':
          return 'Sin asignar';
        default:
          return status;
      }
    case 'location':
      switch (status) {
        case 'initial':
          return 'Iniciada';
        case 'done':
          return 'Terminada';
        case 'onRoute':
          return 'En camino';
        case 'arrived':
          return 'Llego';
        default:
          return status;
      }
    case 'request':
      switch (status) {
        case 'accepted':
          return 'Aceptado';
        case 'ignored':
          return 'Ignorado';
        case 'request':
          return 'Preguntando';
        case 'rejected':
          return 'Rechazado';
        default:
          return status;
      }
    case 'quote':
      switch (status) {
        case 'quote':
          return 'Cotizando';
        case 'assiningDriver':
          return 'Asignando repartidor';
        case 'pickUp':
          return 'Recogiendo';
        case 'dropOff':
          return 'Dejando';
        case 'completed':
          return 'Completado';
        case 'canceled':
          return 'Cancelado';
        default:
          return status;
      }
    default:
      return status;
  }
};