import { ComponentWhatsApp, WTButton } from '../interfaces';

interface Data {
  templateName: string;
  componentHeader: ComponentWhatsApp[];
  componentBody: ComponentWhatsApp[];
  languageCode: string;
  buttons?: WTButton[];
}

export const parseWhatsAppData = ({ templateName, componentHeader, componentBody, languageCode, buttons = [] }: Data) => {
  const components = [];
  const header = componentHeader.length > 0 ? {
    type: 'header',
    parameters: componentHeader,
  } : undefined;
  const body = componentBody.length > 0 ? {
    type: 'body',
    parameters: componentBody,
  } : undefined;
  if (header) components.push(header);
  if (body) components.push(body);
  buttons.forEach((button, index) => {
    components.push({
      type: 'button',
      sub_type: button.type.toLowerCase(),
      index,
    });
  });
  const obj = {
    templateName,
    components,
    languageCode,
    text: templateName,
  };
  return obj;
};