import { useHistory } from 'react-router-dom';
import { FC, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Button } from 'reactstrap';
import { RootState } from '../../store';
import { ChatChat } from '../../components/applications/Chat';
import { 
  getChatsMesaggesBySessionId,
  sendChatMessage,
  deactivateSession,
 } from '../../services/whatsapp';
import { SocketContext } from '../../context/SocketContext';

const View: FC = () => {
  // const history = useHistory();
  const { location } = useHistory();
  const array = location.pathname.split('/');
  const dispatch = useDispatch();
  const { socket, online } = useContext(SocketContext);
  
  const { messages, session } = useSelector((state: RootState) => state.whatsapp);
  useEffect(() => {
    // console.log('useffect socket', socket);
    socket?.on('whatsAppMessage', (data:any) => {  
      dispatch(getChatsMesaggesBySessionId(sessionId));
    });
    return () => {
      socket?.off('whatsAppMessage');
    };
  }, [socket]);

  const sessionId = array[array.length - 1];
  // console.log('session', session);
  useEffect(() => {
    // console.log('useEffect');
    dispatch(getChatsMesaggesBySessionId(sessionId));
  }, [sessionId]);
  
  // TODO ====
  const submitChatMessage = async ({ text }:{ text: string }) => {
    const data = {
      text,
      // businessAdminId: adminId,
    };
    dispatch(sendChatMessage(sessionId, data));
  };
  const _deactivateSession = () => {
    dispatch(deactivateSession(sessionId));
  };
 
  let isDisabled = !session.isSupport;
  isDisabled = !session.isActive;
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} lg={12}>
          <Button
            color="primary"
            className="icon-button large ml-1"
            onClick={() => _deactivateSession()}
            disabled={!session.isActive}
          >
            Finalizar sesión
            {/* <i className="simple-icon-arrow-right" /> */}
          </Button>
          <ChatChat
            messages={messages}
            onSubmitChatMessage={submitChatMessage}
            recipient={{}}
            currentUser={{}}
            disabled={isDisabled}
          />
          {/* asdf */}
        </Col>
      </Row>
    </Container>
  );
};

export default View;
