import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { getBusinessByHunterId, getSupportAdmin } from '../../services/supportAdmin';
import { useAppSelector, useAppDispatch } from '../../store';
import { CardBusiness, CardInfo } from './components';
import { Business } from '../../interfaces';

interface Props { }

const SupportAdmin: FC<Props> = () => {
  const { location } = useHistory();
  const dispatch = useAppDispatch();
  const { supportAdmin } = useAppSelector(state => state.supportAdmins);

  const [businesses, setBusinesses] = useState<Business[]>([]);

  const fetchData = async () => {
    const array = location.pathname.split('/');
    const id = array[array.length - 1];
    dispatch(getSupportAdmin(id));
    const data = await getBusinessByHunterId(id);
    setBusinesses(data);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={6} lg={6}>
          {supportAdmin && <CardInfo supportAdmin={supportAdmin} />}
          {
            businesses.map(business => (
              <CardBusiness key={business._id} business={business} />
            ))
          }
        </Col>
      </Row>
    </Container>
  );
};

export default SupportAdmin;
