/* eslint-disable no-nested-ternary */
import { FC, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { changeAccoutTypeLabel } from '../../../helpers';
import { useAppSelector } from '../../../store';
import { ButtonPrimary } from '../../../components/buttons';
import { ModalBankData } from './ModalBankData';

const titleCard = 'Info Bancaria';

interface Props { }

export const CardBankData: FC<Props> = () => {

  const { deliveryDriver } = useAppSelector(state => state.deliveryDrivers);

  const [isOpen, setIsOpen] = useState(false);

  if (!deliveryDriver) {
    return (
      <Card>
        <CardBody>
          <div>
            <p>cargando...</p>
          </div>
        </CardBody>
      </Card>
    );
  }

  const { bankData } = deliveryDriver;

  if (!bankData) {
    return (
      <>
        <Card>
          <CardBody>
            <div className="d-flex justify-content-between">
              <h4>{titleCard}</h4>
              <ButtonPrimary
                onClick={() => setIsOpen(true)}
                label="Nueva info"
              />
            </div>
            <p>
              No existe información bancaria
            </p>
          </CardBody>
        </Card>
        <ModalBankData driverId={deliveryDriver._id} isOpen={isOpen} setIsOpen={setIsOpen} bankData={null} />
      </>
    );
  }

  const { bankName, holderName, accountType, accountNumber, cardNumber, clabe } = bankData;

  const valueAccountType = () => {
    const regex = new RegExp('\\d{1,4}', 'g');

    switch (accountType) {
      case 'account':
        return accountNumber;
      case 'card':
        return cardNumber?.match(regex)?.join(' ');
      case 'clabe':
        return clabe;
      default:
        return '';
    }
  };
  return (
    <>
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between">
            <h4>{titleCard}</h4>
            <ButtonPrimary
              onClick={() => setIsOpen(true)}
              label="Editar info"
            />
          </div>
          <p>
            <strong>Nombre del banco: </strong> {bankName}
          </p>
          <p>
            <strong>Nombre del titular: </strong> {holderName}
          </p>
          <p>
            <strong>Tipo: </strong> {changeAccoutTypeLabel(accountType)}
          </p>
          <p>
            <strong>{changeAccoutTypeLabel(accountType)}: </strong> {valueAccountType()}
          </p>
        </CardBody>
      </Card>
      <ModalBankData driverId={deliveryDriver._id} isOpen={isOpen} setIsOpen={setIsOpen} bankData={bankData} />
    </>
  );
};
