import dashboardBackendApi from '../api/dashboardBackendApi';
import { handleAxiosError } from '../helpers/handleErrors';
import { serialize } from '../helpers/serialize';
import {
  setChats, setMessages,
  setSession,
  setTemplates,
} from '../store/slices/whatsapp';

export const getChatsMesagges = (filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const resp = await dashboardBackendApi.get<any>(`/support/whatsapp?${_filters}`);
    console.log('RESP', resp);
    dispatch(setChats({
      chats: resp.data.chats,
      totalCount: resp.data.totalCount,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};
export const deactivateSession = (sesionId: string) => async (dispatch: any) => {
  try {
    // const _filters = serialize(filters);
    const resp = await dashboardBackendApi.post<any>(`/support/whatsapp/${sesionId}/deactivate`);
    if (resp.data && resp.data.data) {
      dispatch(setSession(resp.data.data));
    }
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

const getBgColor = (type: string) => {
  if (type === 'bot') return 'rgb(130, 255, 164)';
  if (type === 'user') return 'rgb(130, 222, 255)';
  return '#efefef';
};
export const getChatsMesaggesBySessionId = (sessionId: string) => async (dispatch: any) => {
  console.log('@getChatsMesaggesBySessionId');
  try {
    // const _filters = serialize(filters);
    const resp = await dashboardBackendApi.get<any>(`/support/whatsapp/${sessionId}`);
    console.log('RESP', resp.data.messages);
    const messages = resp.data.messages.map((v: any, key: number) => {
      let senderId = 0;
      const currentUserId = 0;
      let { text } = v;
      if (v.options.length > 0) {
        v.options.map((o: any) => {
          text = `${text} /\n-${o.title}`;
          return true;
        });
      }
      // console.log('v', v);
      if (v.sender && v.sender.userId) {
        senderId = v.sender.userId;
        // console.log('sender', senderId);
      } else {
        senderId = 0;
      }
      return ({
        key,
        sender: {
          thumb: '',
          name: '',
        },
        item: {
          sender: senderId,
          text,
        },
        // currentUserId: service?.user._id || 0,
        currentUserId,
        backgroundColor: getBgColor(v.sender.type),
      });
    });
    console.log('messages', messages);
    dispatch(setMessages({
      messages,
      // totalCount: resp.data.totalCount,
    }));
    // return resp;
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const sendChatMessage = (sessionId: string, data: any) => async (dispatch: any) => {
  console.log('@sendChatMessage');
  try {
    const resp = await dashboardBackendApi.post<any>(`/support/whatsapp/${sessionId}/message`, data);
    console.log('response', resp);
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const getTemplates = (filters = {}) => async (dispatch: any) => {
  console.log('@getTemplates');
  try {
    const _filters = serialize(filters);
    const { data } = await dashboardBackendApi.get<any>(`/support/whatsapp/templates?${_filters}`);
    dispatch(setTemplates({
      templates: data.templates,
      totalTemplates: data.totalTemplates,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const createTemplate = (template: any) => async (dispatch: any) => {
  console.log('@createTemplate');
  try {
    const { data } = await dashboardBackendApi.post<any>('/support/whatsapp/templates', template);
    console.log({ data });
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const updateTemplates = () => async (dispatch: any) => {
  console.log('@updateTemplates');
  try {
    await dashboardBackendApi.get<any>('/support/whatsapp/templates/update');
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};