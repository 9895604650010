import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, CardBody } from 'reactstrap';
import { ButtonPrimary } from '../../../components/buttons/ButtonPrimary';
import { DeliveryService, Driver } from '../../../interfaces';
import { cancelDeliveyService, liberateService } from '../../../services/deliveryServices';
import { ModalDrivers } from './ModalDrivers';
import { SwitchButton } from '../../../components/buttons';

interface Props {
  deliveryService: DeliveryService;
  getDriversByCoords: () => Promise<void>;
  removeDrivers: () => void;
  drivers: Driver[];
  toggleModalNotifications: () => void;
  toggleModalLogs: () => void;
}

export const CardActions: FC<Props> = ({ deliveryService, getDriversByCoords, removeDrivers, drivers, toggleModalNotifications, toggleModalLogs }) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [titleModal, setTitleModal] = useState<'Asignar' | 'Reasignar'>('Asignar');
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(drivers.length > 0);
  }, [drivers]);

  const onCancel = () => dispatch(cancelDeliveyService(deliveryService._id));

  const liberate = () => dispatch(liberateService(deliveryService._id));

  const onClickAssign = () => {
    setTitleModal('Asignar');
    setIsOpen(true);
  };

  const onClickReAssign = () => {
    setTitleModal('Reasignar');
    setIsOpen(true);
  };

  const onChangeSwitchDrivers = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (checked) {
      return getDriversByCoords();
    }
    return removeDrivers();
  };

  return (
    <>
      <Card>
        <CardBody>
          <div>
            <h3>
              Acciones
            </h3>
          </div>
          <br />
          <p>
            Ver repartidores <SwitchButton checked={isChecked} onChange={onChangeSwitchDrivers} />
          </p>
          {
            deliveryService.status === 'requestingDriver' && (
              <>
                <ButtonPrimary
                  label="Asignar"
                  onClick={onClickAssign}
                />
                <br />
              </>
            )
          }
          {
            deliveryService.status !== 'requestingDriver' && (
              <>
                <ButtonPrimary
                  label="Mostrar notificaciones"
                  onClick={toggleModalNotifications}
                />
                <br />
              </>
            )
          }
          {
            (deliveryService.status === 'accepted' || deliveryService.status === 'onRoute') && (
              <>
                <ButtonPrimary
                  label="Reasignar"
                  onClick={onClickReAssign}
                />
                <br />
                <Button
                  onClick={liberate}
                  color="warning"
                >
                  Liberar Servicio
                </Button>
                <br />
              </>
            )
          }
          {
            (deliveryService.status === 'requestingDriver' || deliveryService.status === 'accepted' || deliveryService.status === 'onRoute') && (
              <>
                <ButtonPrimary label="Cancelar servicio" onClick={() => onCancel()} />
                <br />
              </>
            )
          }
          <ButtonPrimary
            label="Mostrar logs"
            onClick={toggleModalLogs}
          />
          <br />
        </CardBody>
      </Card>
      <ModalDrivers
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={titleModal}
        zoneId={deliveryService.zone._id}
        coords={[deliveryService.locations[0].longitude, deliveryService.locations[0].latitude]}
        serviceId={deliveryService._id}
      />
    </>
  );
};
