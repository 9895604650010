import React, { FC } from 'react';
import { ModalRight } from '../../../components/modals/ModalRight';
import { Payment } from '../../../interfaces';
import { formatDate } from '../../../helpers/date';
import s from './styles.module.scss';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  payment: Payment;
}

export const ModalPayment: FC<Props> = ({ isOpen, setIsOpen, payment }) => {
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  return (
    <ModalRight
      isOpen={isOpen}
      toggleModal={toggleModal}
      title="Pago"
    >
      <div className={s.container}>
        <h5>ID: {payment._id}</h5>
        <br />
        <p>Emisor: {payment.issuedBy.name}</p>
        <p>Receptor: {payment.receivedBy.name}</p>
        <p>Status: {payment.status}</p>
        <p>Procesador: {payment.processor}</p>
        <p>Subtotal: ${payment.subtotal.toFixed(2)} MXN</p>
        <p>Impuesto: ${payment.tax.toFixed(2)} MXN</p>
        <p>Total: ${payment.total.toFixed(2)} MXN</p>
        {payment.stripePaymentIntentId && <p>ID stripe: {payment.stripePaymentIntentId}</p>}
        <p>Fecha de pago: {formatDate(payment.paymentDate)}</p>
      </div>
    </ModalRight>
  );
};
