import dashboardBackendApi from '../api/dashboardBackendApi';
import { handleAxiosError } from '../helpers/handleErrors';
import { serialize } from '../helpers/serialize';
import { setNotifications } from '../store/slices/notifications';

export const getNotifications = (filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const resp = await dashboardBackendApi.get(`/support/notifications?${_filters}`);
    dispatch(setNotifications({
      notifications: resp.data.notifications,
      totalNotifications: resp.data.totalNotifications,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};