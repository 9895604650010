import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalRight } from '../../../components/modals/ModalRight';
import { FormAdmin } from './FormAdmin';
import { createBusinessAdmin, updateBusinessAdmin } from '../../../services/businessAdmin';
import { BusinessAdmin } from '../../../interfaces/businessAdmin';
import { RootState } from '../../../store';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  businessAdmin: BusinessAdmin | null;
}

export const ModalAdmin: FC<Props> = ({ isOpen, setIsOpen, businessAdmin = null }) => {
  const { business } = useSelector((state: RootState) => state.businesses);
  const dispatch = useDispatch();
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onSubmit = (data: any) => {
    if (businessAdmin) {
      dispatch(updateBusinessAdmin(businessAdmin._id, data));
    } else {
      data.businessId = business?._id;
      data.userType = 'businessAdmin';
      dispatch(createBusinessAdmin(data));
    }
    setIsOpen(false);
  };

  return (
    <ModalRight
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={businessAdmin ? 'Editar Administrador' : 'Nuevo Administrador'}
    >
      <FormAdmin onSubmit={onSubmit} businessAdmin={businessAdmin} />
    </ModalRight>
  );
};
