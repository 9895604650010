import { FC, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
// import { DateRangePicker, DateRange, Calendar } from 'react-date-range';
import { ButtonView } from '../../components/buttons/ButtonsIcon';
import { ButtonPrimary } from '../../components/buttons/ButtonPrimary';
import ResizableReactTable from '../../components/ResizableReactTable';
import CreateTableData from '../../helpers/createTableData';
// import { Invoice } from '../../interfaces';
import { getInvoicesPending, getTxtFile } from '../../services/invoice';
import { useAppDispatch, useAppSelector } from '../../store';
import { setTxtFile } from '../../store/slices/invoices';
import { ModalInvoice } from './components';
// import { DatePickerCalendar } from '../../components/DatePickerCalendar';

export interface InvoiceRow {
  // count: number;
  total: number;
  bankdata?: any;
  _id: any,
}

const columns = [
  { Header: 'Nombre', accessor: 'driverName' },
  { Header: 'Monto', accessor: 'total' },
  { Header: 'Total de facturas', accessor: 'count' },
  { Header: 'Datos bancarios', accessor: 'hasBankData' },
  { Header: 'Acciones', accessor: 'actions' },
];

const Invoices: FC = () => {
  const { isSandbox } = useAppSelector(state => state.app);
  const { invoicesPending, totalInvoicesPending, txtFile } = useAppSelector(state => state.invoices);
  const dispatch = useAppDispatch();

  const [data, setData] = useState<any[]>([]);
  const [manualPageSize] = useState([10, 20, 50, 100]);
  const [limit, setLimit] = useState(manualPageSize[0]);
  const [pageIndex, setPageIndex] = useState(0);
  // const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [driverId, setDriverId] = useState('');
  const [selectedRowData, setSelectedRowData] = useState({});

  // const [from, setFrom] = useState(null);
  // const [to, setTo] = useState(new Date());

  const getFilters = () => {
    const filters: any = {};
    filters.limit = limit;
    filters.page = pageIndex + 1;
    // filters.to = to;
    filters.isSandbox = isSandbox ? 'yes' : 'no';
    return filters;
  };

  const getAll = () => {
    try {
      const filters = getFilters();
      dispatch(getInvoicesPending(filters));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAll();
  }, [limit, pageIndex, isSandbox]);

  useEffect(() => {
    dispatch(setTxtFile({ txtFile: '' }));
  }, []);

  useEffect(() => {
    if (txtFile !== '') {
      console.log('txtFile', txtFile);
      // const url = window.URL.createObjectURL(new Blob(['https://soporte.repartimos.mx/static/export.text']));
      //   const link = document.createElement('a');
      //   link.href = url;
      //   link.setAttribute(
      //       'download',
      //       'export.txt',
      //   );
      //   document.body.appendChild(link);
      //   link.click();

      //   // Clean up and remove the link
      //   if (link.parentNode) {
      //     link.parentNode.removeChild(link);
      //   }
      fetch(txtFile)
        .then((response) => {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'export.txt';
            a.click();
          });
          // window.location.href = response.url;
        });
    }
    dispatch(setTxtFile({ txtFile: '' }));
  }, [txtFile]);

  useEffect(() => {
    if (invoicesPending.length > 0) {
      const newData: any[] = [];
      console.log('DATA', invoicesPending);
      invoicesPending.map((item: InvoiceRow) => {
        const obj = {
          ...item,
          actions: (
            <>
              <ButtonView
                onClick={() => {
                  setDriverId(item._id);
                  setSelectedRowData(item);
                  setIsOpen(true);
                }}
              />
            </>
          ),
          // createdAt: formatDate(item.createdAt),
          // paymentDate: formatDate(item.paymentDate),
          // subtotal: `$${item.subtotal.toFixed(2)} MXN`,
          // driver: `${item.driverName}`, 
          total: `$${item.total.toFixed(2)} MXN`,
          hasBankData: item.bankdata ? '' : 'Incompletos',
        };
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [invoicesPending]);

  const reactTableData = CreateTableData(data, columns);

  // const onChange = (toDate: any) => {
  //   setTo(toDate);
  //   setPageIndex(0);
  // };
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title">
                  <h5 className="bold-text">Pagos a repartidores</h5>
                </div>
                <div>
                  <ButtonPrimary
                    label="Exportar"
                    onClick={() => {
                      const filters = getFilters();
                      dispatch(getTxtFile(filters));
                    }}
                  />
                </div>
              </div>
              {/* <Row>
                <DatePickerCalendar handleDateChange={onChange} to={to} />
              </Row> */}
              {/*
              invoice && <ModalInvoice isOpen={isOpen} setIsOpen={setIsOpen} invoice={invoice} />
              */}
              {driverId && <ModalInvoice isOpen={isOpen} setIsOpen={setIsOpen} driverId={driverId} row={selectedRowData} />}
              {
                data.length === 0 ? <h3> No hay info que se pueda mostrar</h3> : (
                  <ResizableReactTable
                    reactTableData={reactTableData}
                    totalRecords={totalInvoicesPending}
                    manualPageSize={manualPageSize}
                    limit={limit}
                    setLimit={setLimit}
                    pageIndex={pageIndex}
                    setPageIndex={setPageIndex}
                    totalNoOfPages={Math.ceil(totalInvoicesPending / limit)}
                  />
                )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Invoices;
