import { FC } from 'react';
import { Card, CardBody } from 'reactstrap';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import CreditCardIcon from 'mdi-react/CreditCardIcon';
import CashRegisterIcon from 'mdi-react/CashRegisterIcon';
import MotorbikeIcon from 'mdi-react/MotorbikeIcon';
import BicycleIcon from 'mdi-react/BicycleIcon';
import CarIcon from 'mdi-react/CarIcon';
import WalkIcon from 'mdi-react/WalkIcon';
import VanUtilityIcon from 'mdi-react/VanUtilityIcon';
import Cash100Icon from 'mdi-react/Cash100Icon';
// import IntlMessages from '../../../helpers/IntlMessages';

interface Props {
  className?: string;
  icon: string;
  title: string;
  value: any;
  style?: any;
}

export const IconCard: FC<Props> = ({ className = 'mb-4', icon, title, value, children, style = {} }) => {
  return (
    <div className={`icon-row-item ${className}`}>
      <Card style={style}>
        <CardBody className="text-center">
          {renderIcon({
            icon,
            size: 50,
          })}
          <p className="card-text font-weight-semibold mb-0">
            {title}
          </p>
          {value && <p className="lead text-center">{value}</p>}
          {children}
        </CardBody>
      </Card>
    </div>
  );
};

const renderIcon = ({ icon, ...props }: any) => {
  switch (icon) {
    case 'location':
      return (
        <MapMarkerIcon {...props} />
      );
    case 'card':
      return (
        <CreditCardIcon {...props} />
      );
    case 'cash':
      return (
        <CashRegisterIcon {...props} />
      );
    case 'motorcycle':
      return (
        <MotorbikeIcon {...props} />
      );
    case 'bicycle':
      return (
        <BicycleIcon {...props} />
      );
    case 'walking':
      return (
        <WalkIcon {...props} />
      );
    case 'car':
      return (
        <CarIcon {...props} />
      );
    case 'van':
      return (
        <VanUtilityIcon {...props} />
      );
    case 'dollar':
      return (
        <Cash100Icon {...props} />
      );

    default:
      return (
        <MapMarkerIcon {...props} />
      );
  }
};
