import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarSwitch from './TopbarSwitch';

const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility, isSandbox }) => {

  const { userType } = useSelector(state => state.auth);

  return (
    <div className="topbar">
      <div className={`topbar__wrapper ${isSandbox ? 'isSandbox' : ''}`}>
        <div className="topbar__left">
          <TopbarSidebarButton
            onClickMobile={changeMobileSidebarVisibility}
            onClickDesktop={changeSidebarVisibility}
          />
          <Link className="topbar__logo" to="/" />
        </div>
        <div className="topbar__right">
          {
            userType === 'supportAdmin' && (
              <TopbarSwitch />
            )
          }
          <TopbarProfile />
        </div>
      </div>
    </div>
  );
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
