import { FC } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { CardInfo } from './components';

interface Props { }

const HunterHome: FC<Props> = () => {
  return (
    <Container className="dashboard">
      <Row>
        <Col xs={12} md={12} lg={6} xl={6}>
          <CardInfo />
        </Col>
      </Row>
    </Container>
  );
};

export default HunterHome;
