/* eslint-disable no-plusplus */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { ButtonPrimary } from '../../components/buttons/ButtonPrimary';
import { ButtonEdit, ButtonView } from '../../components/buttons/ButtonsIcon';
import ResizableReactTable from '../../components/ResizableReactTable';
import CreateTableData from '../../helpers/createTableData';
import { getTransports } from '../../services/transport';
import { getZones } from '../../services/zone';
import { RootState } from '../../store';
import { Map } from './components/Map';
import { formatDate } from '../../helpers';
import { CustomDropdown } from '../../components/dropdowns';
import { statesOfTheRepublic } from '../../helpers/statesOfTheRepublic';
import { InputSearch } from '../../components/inputs';

const optionsState: any = {};

for (let i = 0; i < statesOfTheRepublic.length; i++) {
  const state = statesOfTheRepublic[i];
  optionsState[state.label] = state.label;
}

const columns = [
  { Header: 'Fecha', accessor: 'createdAt' },
  { Header: 'Nombre', accessor: 'name' },
  { Header: 'Estado', accessor: 'state' },
  { Header: 'Sucursales', accessor: 'totalEstablishments' },
  { Header: 'Acciones', accessor: 'actions' },
];

const Zones = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { zones, totalZones } = useSelector((state: RootState) => state.zones);

  const [data, setData] = useState<any[]>([]);
  const [manualPageSize] = useState([10, 20, 50, 100]);
  const [limit, setLimit] = useState(manualPageSize[0]);
  const [pageIndex, setPageIndex] = useState(0);
  const [isViewMap, setIsViewMap] = useState(false);
  const [sortByDropdown, setSortByDropdown] = useState({
    label: 'Ordenar por',
    selectedKey: 'name',
    options: { createdAt: 'Fecha', name: 'Nombre', state: 'Estado' },
  });
  const [ascDescDropdown, setAscDescDropdown] = useState({
    label: '',
    selectedKey: 'asc',
    options: { asc: 'asc', desc: 'desc' },
  });
  const [stateDropdown, setStateDropdown] = useState({
    label: 'Estado',
    selectedKey: 'all',
    options: { all: 'Todos', ...optionsState },
  });
  const [searchText, setSearchText] = useState('');

  console.log({ isViewMap });
  const getFilters = () => {
    const filters: any = {};
    if (!isViewMap) {
      filters.limit = limit;
      filters.page = pageIndex + 1;
      filters.sortBy = `${sortByDropdown.selectedKey}:${ascDescDropdown.selectedKey}`;
    }
    // proximos filtros
    if (stateDropdown.selectedKey !== 'all') {
      filters.state = stateDropdown.selectedKey;
    }
    if (searchText) {
      filters.search = searchText;
    }
    return filters;
  };

  useEffect(() => {
    const filters = getFilters();
    dispatch(getTransports());
    dispatch(getZones(filters));
  }, [limit, pageIndex, sortByDropdown, ascDescDropdown, stateDropdown, searchText, isViewMap]);

  useEffect(() => {
    if (zones.length > 0) {
      const newData: any[] = [];
      zones.map((item) => {
        const obj = {
          ...item,
          totalEstablishments: item.totalEstablishments || 0,
          createdAt: formatDate(item.createdAt),
          actions: (
            <>
              <ButtonView
                onClick={() => history.push(`/dashboard/zones/${item._id}`)}
              />
              <ButtonEdit
                onClick={() => history.push(`/dashboard/zones/form/${item._id}`)}
              />
            </>
          ),
        };
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [zones]);

  const changeSortByFilter = (sortBy: any) => {
    setSortByDropdown({
      ...sortByDropdown,
      selectedKey: sortBy,
    });
    setPageIndex(0);
  };

  const changeAscDescFilter = (ascDesc: any) => {
    setAscDescDropdown({
      ...ascDescDropdown,
      selectedKey: ascDesc,
    });
    setPageIndex(0);
  };

  const changeStateFilter = (state: any) => {
    setStateDropdown({
      ...stateDropdown,
      selectedKey: state,
    });
    setPageIndex(0);
  };

  const reactTableData = CreateTableData(data, columns);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title">
                  <h5 className="bold-text">Zonas</h5>
                </div>
                <div>
                  <ButtonPrimary
                    label={isViewMap ? 'Ver Lista' : 'Ver Mapa'}
                    onClick={() => setIsViewMap(!isViewMap)}
                  />
                  <ButtonPrimary
                    label="Crear zona"
                    onClick={() => history.push('/dashboard/zones/form/0')}
                  />
                </div>
              </div>
              <Row
                style={{ paddingLeft: '20px' }}
              >
                <CustomDropdown
                  {...sortByDropdown}
                  onClick={changeSortByFilter}
                />
                <CustomDropdown
                  {...ascDescDropdown}
                  onClick={changeAscDescFilter}
                />
                <CustomDropdown
                  {...stateDropdown}
                  onClick={changeStateFilter}
                />
              </Row>
              <InputSearch
                value={searchText}
                onChange={(e: any) => {
                  setPageIndex(0);
                  setSearchText(e.target.value);
                }}
                placeholder="Introduce nombre, estado"
                style={{
                  marginBottom: '5px',
                  width: '75%',
                }}
              />
              {
                !isViewMap && data.length === 0
                  ? <h3>No hay info que se pueda mostrar</h3>
                  : !isViewMap && (
                    <ResizableReactTable
                      reactTableData={reactTableData}
                      totalRecords={totalZones}
                      manualPageSize={manualPageSize}
                      limit={limit}
                      setLimit={setLimit}
                      pageIndex={pageIndex}
                      setPageIndex={setPageIndex}
                      totalNoOfPages={Math.ceil(totalZones / limit)}
                    />
                  )
              }
              {
                isViewMap && <Map />
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Zones;
