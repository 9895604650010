import dashboardBackendApi from '../api/dashboardBackendApi';
import { handleAxiosError } from '../helpers/handleErrors';
import { serialize } from '../helpers/serialize';
import { DeliveryQuoteResponse, DeliveryQuotesResponse } from '../interfaces/deliveryQuote';
import { setDeliveryQuotes } from '../store/slices/deliveryQuotes';

export const getDeliveryQuotes = (filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const { data } = await dashboardBackendApi.get<DeliveryQuotesResponse>(`/support/deliveryQuotes?${_filters}`);
    dispatch(setDeliveryQuotes({
      deliveryQuotes: data.deliveryQuotes,
      totalDeliveryQuotes: data.totalDeliveryQuotes,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const getDeliveryQuote = async (id: string) => {
  try {
    const { data } = await dashboardBackendApi.get<DeliveryQuoteResponse>(`/support/deliveryQuotes/${id}`);
    return {
      deliveryQuote: data.deliveryQuote,
    };
  } catch (error) {
    console.error({ error });
    return null;
  }
};


export const getDeliveryQuotesByBusiness = async (businessId: string, filters = {}) => {
  try {
    const _filters = serialize(filters);
    const { data } = await dashboardBackendApi.get<DeliveryQuotesResponse>(`/support/deliveryQuotes/business/${businessId}?${_filters}`);
    return {
      deliveryQuotes: data.deliveryQuotes,
      totalDeliveryQuotes: data.totalDeliveryQuotes,
    };
  } catch (error) {
    console.error({ error });
    return null;
  }
};

