import { FC, useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import ResizableReactTable from '../../../components/ResizableReactTable';
import CreateTableData from '../../../helpers/createTableData';
import { useAppSelector } from '../../../store';
import { BusinessClient } from '../../../interfaces/businessClient';
import { getDeliveryQuotesByBusiness } from '../../../services/deliveryQuote';
import { DeliveryQuote } from '../../../interfaces';
import { formatDateTime } from '../../../helpers';

interface Props {
  from: any;
  to: any;
}

export const CardQuotes: FC<Props> = ({ from, to }) => {
  const { isSandbox } = useAppSelector(state => state.app);
  const { business } = useAppSelector(state => state.businesses);
  const [data, setData] = useState<BusinessClient[]>([]);
  const [deliveryQuotes, setDeliveryQuotes] = useState<DeliveryQuote[]>([]);
  const [totalDeliveryQuotes, setTotalDeliveryQuotes] = useState<number>(0);
  const [pageIndex, setPageIndex] = useState(0);
  const columns = [
    { Header: 'Fecha', accessor: 'createdAt' },
    { Header: 'Precio máximo', accessor: 'maxPrice' },
    { Header: 'Precio mínimo', accessor: 'minPrice' },
    { Header: 'Repartimos mts', accessor: 'repartimosMts' },
    { Header: 'Rappi mts', accessor: 'rappiMts' },
  ];

  const getFilters = () => {
    const filters: any = {};
    filters.page = pageIndex + 1;
    filters.limit = 10;
    filters.sortBy = '_id:desc';
    filters.isSandbox = isSandbox ? 'yes' : 'no';
    if (from && to) {
      filters.from = from;
      filters.to = to;
    }
    return filters;
  };

  const fetchData = async () => {
    if (business) {
      try {
        const filters = getFilters();
        const data = await getDeliveryQuotesByBusiness(business._id, filters);
        setDeliveryQuotes(data?.deliveryQuotes || []);
        setTotalDeliveryQuotes(data?.totalDeliveryQuotes || 0);
      } catch (error) {
        console.error({ error });
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [business, pageIndex, from, to, isSandbox]);

  useEffect(() => {
    if (deliveryQuotes.length > 0) {
      const newData: any[] = [];
      deliveryQuotes.map((item) => {
        const obj = {
          ...item,
          maxPrice: `$${(item.maxPrice).toFixed(2)} MXN`,
          minPrice: `$${(item.minPrice).toFixed(2)} MXN`,
          repartimosMts: item.quotes.find(q => q.providerSlug === 'repartimos')?.mts?.toFixed(2) || 0,
          rappiMts: item.quotes.find(q => q.providerSlug === 'rappi')?.mts?.toFixed(2) || 0,
        };
        if (obj.createdAt) {
          obj.createdAt = formatDateTime(item.createdAt);
        }
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [deliveryQuotes]);

  const reactTableData = CreateTableData(data, columns);
  return (
    <Card>
      <CardBody>
        <div>
          <div>
            <h4>Quotes</h4>
          </div>
        </div>
        <ResizableReactTable
          reactTableData={reactTableData}
          totalRecords={totalDeliveryQuotes}
          manualPageSize={[0]}
          limit={10}
          setLimit={() => { }}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          totalNoOfPages={Math.ceil(totalDeliveryQuotes / 10)}
        />
      </CardBody>
    </Card>
  );
};
