import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import ResizableReactTable from '../../../components/ResizableReactTable';
import CreateTableData from '../../../helpers/createTableData';
import { getBusinessClientsByBusinessId } from '../../../services/businessClient';
import { RootState } from '../../../store';
import { BusinessClient } from '../../../interfaces/businessClient';

export const CardClients: FC = () => {
  // const [isOpen, setIsOpen] = useState(false);
  const { business, businessClients, totalBusinessClients } = useSelector((state: RootState) => state.businesses);
  const dispatch = useDispatch();
  // const [businessClient, setBusinessClient] = useState<BusinessClient | null>(null);
  const [data, setData] = useState<BusinessClient[]>([]);
  const [pageIndex, setPageIndex] = useState(0);
  const columns = [
    { Header: 'Nombre', accessor: 'name' },
    { Header: 'Teléfono', accessor: 'phone.number' },
  ];

  const getFilters = () => {
    const filters: any = {};
    filters.page = pageIndex + 1;
    filters.sortBy = '_id:desc';
    filters.limit = 10;
    return filters;
  };

  useEffect(() => {
    if (business) {
      const filters = getFilters();
      dispatch(getBusinessClientsByBusinessId(business._id, filters));
    }
  }, [business, pageIndex]);

  useEffect(() => {
    if (businessClients.length > 0) {
      const newData: any[] = [];
      businessClients.map((item) => {
        const obj = {
          ...item,
          name: `${item.firstName} ${item.lastName}`,
        };
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [businessClients]);

  const reactTableData = CreateTableData(data, columns);
  return (
    <Card>
      <CardBody>
        <div>
          <div>
            <h4>Clientes</h4>
          </div>
        </div>
        <ResizableReactTable
          reactTableData={reactTableData}
          totalRecords={totalBusinessClients}
          manualPageSize={[0]}
          limit={10}
          setLimit={() => { }}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          totalNoOfPages={Math.ceil(totalBusinessClients / 10)}
        />
      </CardBody>
    </Card>
  );
};
