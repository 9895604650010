/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { GoogleMap, Marker } from '@react-google-maps/api';
import { LocationSelectorInput } from './';

// zapopan
const INITIAL_CENTER = {
  lat: 20.7167,
  lng: -103.4,
};

interface Props {
  state: any;
  dispatch: React.Dispatch<any>
}

export const StepOne: FC<Props> = ({ state, dispatch }) => {
  const origin = state.locations[0];
  const { locations } = state;

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 15,
      },
    }),
  );
  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id === over.id) return;
    const oldIndex = locations.findIndex((l: any) => l.id === active.id);
    const newIndex = locations.findIndex((l: any) => l.id === over.id);
    const newLocations = arrayMove(locations, oldIndex, newIndex);

    dispatch({ type: 'sortLocations', payload: newLocations });
  };

  return (
    <div className="service step-one container">
      <div className="service step-one options">
        <h1>Selecciona las direcciones</h1>

        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={locations.map((l: any) => l.id)}
            strategy={verticalListSortingStrategy}
          >
            {locations.map((location: any, index: number) => (
              <LocationSelectorInput
                key={location.id}
                id={location.id}
                index={index}
                value={location}
                onSelect={(data: any) => dispatch({ payload: data, type: 'replaceLocation', index })}
                onDelete={() => dispatch({ type: 'removeLocation', index })}
                deleteEnabled={index > 1}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
      <div className="service step-one map">
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100%' }}
          center={{
            lat: origin?.address?.latitude ?? INITIAL_CENTER.lat,
            lng: origin?.address?.longitude ?? INITIAL_CENTER.lng,
          }}
          zoom={origin.address ? 10 : 5}
        >
          {
            locations.map(({ address: { latitude, longitude } }: any, index: number) => latitude && longitude && (
              <Marker
                key={index}
                position={{
                  lat: latitude,
                  lng: longitude,
                }}
              />
            ))
          }
        </GoogleMap>
      </div>
    </div>
  );
};
