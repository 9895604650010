import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Campaign } from '../../../interfaces';

interface Props {
  campaigns: Campaign[];
  totalCampaigns: number;
  campaign: Campaign | null;
}

const initialState: Props = {
  campaigns: [],
  totalCampaigns: 0,
  campaign: null,
};

export const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    setCampaigns: (state, action: PayloadAction<any>) => {
      state.campaigns = action.payload.campaigns || [];
      state.totalCampaigns = action.payload.totalCampaigns || 0;
      state.campaign = null;
    },
    setCampaign: (state, action: PayloadAction<any>) => {
      state.campaign = action.payload.campaign;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCampaigns,
  setCampaign,
} = campaignsSlice.actions;

export default campaignsSlice.reducer;
