import { Col, Container, Row } from 'reactstrap';
import { CardTransports } from './components';

const Config = () => {
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Configuraciones Globales</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <CardTransports />
        </Col>
      </Row>
    </Container>
  );
};

export default Config;
