import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { ModalRight } from '../../../components/modals/ModalRight';
import { formatDate } from '../../../helpers';
import { Invoice } from '../../../interfaces';
import s from './styles.module.scss';
import { ButtonPrimary } from '../../../components/buttons/ButtonPrimary';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  invoice: Invoice;
  markAsPaid: (id: string) => void;
  markAsCancel: (id: string) => void;
}

export const ModalInvoice: FC<Props> = ({ isOpen, setIsOpen, invoice, markAsPaid, markAsCancel }) => {
  const history = useHistory();
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const pay = () => {
    markAsPaid(invoice._id);
  };
  const getStatus = (status: string) => {
    switch (status) {
      case 'paid': return 'Pagado';
      case 'pending': return 'Pendiente';
      default: return status;
    }
  };
  const cancel = () => {
    markAsCancel(invoice._id);
  };

  const status = getStatus(invoice.status);
  console.log(invoice);
  return (
    <ModalRight
      isOpen={isOpen}
      toggleModal={toggleModal}
      title="Factura"
    >
      <div className={s.container}>
        <h5>
          ID: {invoice._id}
        </h5>
        <br />
        <p>Emisor: {invoice.issuedBy.name}</p>
        <p>Receptor: {invoice.receivedBy.name}</p>
        <p>Status: {status}</p>
        <p>Concepto: {invoice.concept}</p>
        <p>Divisa: {invoice.currency}</p>
        <p>Subtotal: ${invoice.subtotal.toFixed(2)} MXN</p>
        <p>Impuesto: ${invoice.tax.toFixed(2)} MXN</p>
        <p>Total: ${invoice.total.toFixed(2)} MXN</p>
        <p>Fecha de emisión: {formatDate(invoice.createdAt)}</p>
        <p>Fecha de pago: {invoice.paymentDate ? formatDate(invoice.paymentDate) : '--'}</p>
        {invoice.status === 'pending' && (
          <p>
            <br />
            <ButtonPrimary
              label="Pagar facturas"
              onClick={pay}
            />
          </p>
        )}
        {
          invoice.deliveryServiceId && (
            <div className="mt-2">
              <ButtonPrimary
                label="Ver servicio"
                onClick={() => history.push(`/dashboard/services/${invoice.deliveryServiceId}`)}
              />
            </div>
          )
        }
        {
          invoice.status === 'pending' && (
            <div className="mt-2">
              <Button
                color="danger"
                onClick={cancel}
              >
                Cancelar
              </Button>
            </div>
          )
        }
      </div>
    </ModalRight>
  );
};
