import { FC } from 'react';
// import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { RootState } from '../../../store/index';

export const MapDeliveryDriver: FC = () => {
  const { deliveryDriver } = useSelector((state: RootState) => state.deliveryDrivers);

  return (
    <GoogleMap
      mapContainerStyle={{ width: '95%', height: '85vh' }}
      zoom={13}
      center={{
        lng: deliveryDriver?.coords ? deliveryDriver.coords[0] : -103.36873,
        lat: deliveryDriver?.coords ? deliveryDriver.coords[1] : 20.6827,
      }}
    >
      {
        deliveryDriver?.coords && (
          <Marker
            position={{
              lng: deliveryDriver?.coords[0],
              lat: deliveryDriver?.coords[1],
            }}
          />
        )
      }
    </GoogleMap>
  );
};