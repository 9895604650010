import dashboardBackendApi from '../api/dashboardBackendApi';
import backendApi from '../api/backendApi';
import { serialize } from '../helpers/serialize';
import { DeliveryServiceResponse, DeliveryServicesResponse, DeliveryServiceStatus } from '../interfaces';
import { setDeliveryService, setDeliveryServices } from '../store/slices/deliveryServices';
import { handleAxiosError } from '../helpers/handleErrors';

export const getDeliveryServices = (filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const resp = await dashboardBackendApi.get<DeliveryServicesResponse>(`/support/deliveryServices?${_filters}`);
    dispatch(setDeliveryServices({
      deliveryServices: resp.data.deliveryServices,
      totalDeliveryServices: resp.data.totalDeliveryServices,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};
export const cancelDeliveyService = (id: string) => async (dispatch: any) => {
  console.log('@services:cancelService', id);
  try {
    const resp = await backendApi.post<DeliveryServiceResponse>(`/api/support/service/cancel/${id}`);
    console.log({ resp });
    dispatch(getDeliveryService(id));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const getDeliveryServicesByStatus = async (status: DeliveryServiceStatus, isSandbox: Boolean) => {
  try {
    const resp = await dashboardBackendApi.get<DeliveryServicesResponse>(`/support/deliveryServices/home?status=${status}&issandbox=${isSandbox}&sortBy=_id:desc`);
    return {
      data: resp.data.deliveryServices,
      totalRecords: resp.data.totalDeliveryServices,
    };
  } catch (error) {
    console.log(error);
    return {
      data: [],
      totalRecords: 0,
    };
  }
};

export const getDeliveryServicesByPendingPaid = async () => {
  try {
    const resp = await dashboardBackendApi.get<DeliveryServicesResponse>('/support/deliveryServices/home/pendingPaid?sortBy=_id:desc');
    return {
      data: resp.data.deliveryServices,
      totalRecords: resp.data.totalDeliveryServices,
    };
  } catch (error) {
    console.log(error);
    return {
      data: [],
      totalRecords: 0,
    };
  }
};

export const getDeliveryService = (id: string) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.get<DeliveryServiceResponse>(`/support/deliveryServices/${id}`);
    dispatch(setDeliveryService({
      deliveryService: resp.data.deliveryService,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const addDriverToRequest = (driverId: string, serviceId: string) => async (dispatch: any) => {
  console.log('@addDriverToRequest', driverId, serviceId);
  try {
    const resp = await dashboardBackendApi.post<DeliveryServiceResponse>(`/support/deliveryServices/${serviceId}/adddriver`, { driverId });
    console.log('resp', resp);
    dispatch(getDeliveryService(serviceId));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const liberateService = (serviceId: string) => async (dispatch: any) => {
  console.log('@liberateService');
  try {
    const resp = await backendApi.post<DeliveryServiceResponse>(`/api/support/service/${serviceId}/liberate`);
    console.log('RESP', resp);
    dispatch(getDeliveryService(serviceId));
    // dispatch(setDeliveryService({
    //   deliveryService: resp.data.deliveryService,
    // }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const reAsignDriverToService = (driverId: string, serviceId: string) => async (dispatch: any) => {
  console.log('@reAsignDriverToService', driverId, serviceId);
  try {
    const resp = await backendApi.post<DeliveryServiceResponse>(`/api/support/service/${serviceId}/changedriver/${driverId}`);
    console.log('resp', resp);
    dispatch(getDeliveryService(serviceId));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const addDriverToService = (driverId: string, serviceId: string) => async (dispatch: any) => {
  console.log('@addDriverToService', driverId, serviceId);
  try {
    const resp = await backendApi.post<DeliveryServiceResponse>(`/api/support/service/${serviceId}/assign/${driverId}`);
    console.log('resp', resp);
    dispatch(getDeliveryService(serviceId));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};
