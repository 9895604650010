import { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useJsApiLoader } from '@react-google-maps/api';
import MainWrapper from '../MainWrapper';
import LogIn from '../../LogIn/index';
import WrappedRoutes from './WrappedRoutes';
import { fetchAdminData, fetchHunterData } from '../../../services/auth';
import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';
import { RootState } from '../../../store';
import { SocketProvider } from '../../../context/SocketContext';

const GOOGLE_MAPS_LIBRARIES: ('places' | 'geometry' | 'drawing' | 'localContext' | 'visualization')[] = ['places', 'geometry'];

const Router = () => {
  const { jwtAccessToken, userType } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();

  const fetchData = () => {
    if (userType === 'supportAdmin') {
      dispatch(fetchAdminData());
    }
    if (userType === 'hunter') {
      dispatch(fetchHunterData());
    }
  };

  useEffect(() => {
    if (jwtAccessToken) {
      fetchData();
    }
  }, []);


  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries: GOOGLE_MAPS_LIBRARIES,
    region: 'MX',
  });
  // wait for google maps to load
  if (!isLoaded) return null;

  return (
    <MainWrapper>
      <main>
        <Switch>
          <PublicRoute
            exact
            path="/"
            component={LogIn}
            isAuthenticated={!!jwtAccessToken}
          />
          <SocketProvider>
            <PrivateRoute
              component={WrappedRoutes}
              isAuthenticated={!!jwtAccessToken}
              path="/"
            />
          </SocketProvider>
        </Switch>
      </main>
    </MainWrapper>
  );
};

export default Router;
