import dashboardBackendApi from '../api/dashboardBackendApi';
import { handleAxiosError } from '../helpers/handleErrors';
import { TransportsResponse, TransportResponse } from '../interfaces';
import { createNewTransport, setTransports, updateNowTransport } from '../store/slices/transports';

export const getTransports = () => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.get<TransportsResponse>('/support/transports');
    dispatch(setTransports({
      transports: resp.data.transports,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const getTransport = async (id: string) => {
  try {
    const resp = await dashboardBackendApi.get<TransportResponse>(`/support/transports/${id}`);
    return resp.data.transport;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const createTransport = (transport: any) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.post<TransportResponse>('/support/transports', transport);
    dispatch(createNewTransport({
      transport: resp.data.transport,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const updateTransport = (id: string, transport: any) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.put<TransportResponse>(`/support/transports/${id}`, transport);
    dispatch(updateNowTransport({
      id,
      transport: resp.data.transport,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};
