import { FC, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { RootState } from '../../../store/index';

export const MapEstablishmentParent: FC = () => {
  const { businessEstablishmentsByBusiness } = useSelector((state: RootState) => state.businessEstablishments);

  const [map, setMap] = useState<any>(null);
  const [center, setCenter] = useState({
    lat: 20.7167,
    lng: -103.4,
  });

  const onLoad = useCallback((map: any) => setMap(map), []);

  const fitBounds = () => {
    if (businessEstablishmentsByBusiness.length > 1) {
      const bounds = new window.google.maps.LatLngBounds();
      businessEstablishmentsByBusiness.map((establishment) => {
        bounds.extend(
          new window.google.maps.LatLng(
            establishment.address.latitude,
            establishment.address.longitude,
          ),
        );
        return establishment;
      });
      map.fitBounds(bounds);
    }
    if (businessEstablishmentsByBusiness.length === 1) {
      setCenter({
        lat: businessEstablishmentsByBusiness[0].address.latitude,
        lng: businessEstablishmentsByBusiness[0].address.longitude,
      });
    }
  };

  useEffect(() => {
    if (map) {
      fitBounds();
    }
  }, [map, businessEstablishmentsByBusiness]);
  return (
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '100%' }}
      zoom={15}
      onLoad={onLoad}
      center={center}
    >
      {
        businessEstablishmentsByBusiness.map((establishment) => {
          if (establishment.address) {
            return (
              <Marker
                key={establishment._id}
                position={{
                  lng: establishment.address.longitude,
                  lat: establishment.address.latitude,
                }}
              />
            );
          }
          return null;
        })
      }
    </GoogleMap>
  );
};
