import React, { FC } from 'react';
import { Card, CardBody } from 'reactstrap';
import { useAppSelector } from '../../../store/index';
import { formatDate } from '../../../helpers';
import s from './styles.module.scss';
import { DatePicker } from '../../../components/DatePicker';
import { IconFlask } from '../../../components/icons';

interface Props {
  from: any;
  setFrom: React.Dispatch<React.SetStateAction<null>>;
  to: any;
  setTo: React.Dispatch<React.SetStateAction<null>>;
}

export const CardInfo: FC<Props> = ({ from, setFrom, to, setTo }) => {
  const { business } = useAppSelector(state => state.businesses);

  if (!business) {
    return (
      <Card>
        <CardBody>
          <h3>Cargando...</h3>
        </CardBody>
      </Card>
    );
  }

  const handleDateChange = (fromDate: any, toDate: any) => {
    setFrom(fromDate);
    setTo(toDate);
  };

  return (
    <Card>
      <CardBody>
        <div className={s.container}>
          <div>
            <h3>
              {business.isSandbox && <IconFlask />}{business.name}
            </h3>
            <span>
              {business._id}
            </span>
          </div>
        </div>
        <div className={s.container}>
          <div className={s.containerImage}>
            <img
              className={s.image}
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/OOjs_UI_icon_userAvatar.svg/2048px-OOjs_UI_icon_userAvatar.svg.png"
              alt="avatar"
            />
          </div>
          <div>
            <p>Fecha de creación</p>
            <span>{formatDate(business.createdAt)}</span>
          </div>
        </div>
        <div className={s.container}>
          <div>
            <p>Saldo de créditos</p>
            <span>{business.accountBalance?.paymentBalance || 0}</span>
          </div>
          <div>
            <p>Servicios Completados</p>
            <span>{business.totalServicesDone || 0}</span>
          </div>
        </div>
        <DatePicker handleDateChange={handleDateChange} from={from} to={to} />
      </CardBody>
    </Card>
  );
};
