import { useState, useEffect, useMemo } from 'react';
import io from 'socket.io-client';
import { getAccessToken } from '../helpers/utils';

export const useSocket = () => {

  const socket = useMemo(() => io(process.env.REACT_APP_SERVER_URL || '', {
    query: {
      token: getAccessToken(),
    },
  }), []); // dashboard-backend
  // const socket = useMemo(() => io(`${process.env.REACT_APP_BACKEND_URL}`), []); // backend

  const [online, setOnline] = useState(false);

  useEffect(() => {
    setOnline(socket.connected);
  }, [socket]);

  useEffect(() => {
    socket.on('connect', () => setOnline(true));
  }, [socket]);

  useEffect(() => {
    socket.on('disconnect', () => setOnline(false));
  }, [socket]);

  return {
    socket,
    online,
  };
};