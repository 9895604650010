import { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface Props extends RouteComponentProps {
  children: any;
}

const ScrollToTop = ({ children, location }: Props) => {
  useEffect(() => {
    if (location && location.pathname) {
      window.scrollTo(0, 0);
    }
  }, [location]);
  return children;
};

export default withRouter(ScrollToTop);
