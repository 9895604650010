/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import CashRegisterIcon from 'mdi-react/CashRegisterIcon';
import CreditCardIcon from 'mdi-react/CreditCardIcon';
import MotorbikeIcon from 'mdi-react/MotorbikeIcon';
import BicycleIcon from 'mdi-react/BicycleIcon';
import CarIcon from 'mdi-react/CarIcon';
import WalkIcon from 'mdi-react/WalkIcon';
import VanUtilityIcon from 'mdi-react/VanUtilityIcon';

interface Props {
  options: {
    icon: string;
    value: string;
  }[];
  onChange: (data: any) => void;
  value: any;
}

export const OptionsSelector: FC<Props> = ({ options, onChange, value }) => {
  return (
    <div className="options-selector">
      {options.map((opt, index) => (
        <button
          key={index}
          type="button"
          className={`option ${(value.value === opt.value && 'active').toString()}`}
          onClick={() => onChange(opt)}
        >
          {
            renderIcon({
              value: opt.value,
              style: { cursor: 'pointer', margin: '0 5px' },
              size: 30,
              color: value.value === opt.value ? 'white' : 'black',
            })
          }
        </button>
      ))}
    </div>
  );
};

const renderIcon = ({ value, ...props }: any) => {
  switch (value) {
    case 'card':
      return (
        <CreditCardIcon {...props} />
      );
    case 'cash':
      return (
        <CashRegisterIcon {...props} />
      );
    case 'motorcycle':
      return (
        <MotorbikeIcon {...props} />
      );
    case 'bicycle':
      return (
        <BicycleIcon {...props} />
      );
    case 'walking':
      return (
        <WalkIcon {...props} />
      );
    case 'car':
      return (
        <CarIcon {...props} />
      );
    case 'van':
      return (
        <VanUtilityIcon {...props} />
      );

    default:
      return (
        <CashRegisterIcon {...props} />
      );
  }
};
