// eslint-disable-next-line import/no-unresolved
import { v4 as uuidv4 } from 'uuid';

const LOCATION_TEMPLATE = {
  id: uuidv4(),
  optionType: 'maps',
  address: {
    street: '',
    city: '',
    country: '',
    exterior: '',
    interior: '',
    neighborhood: '',
    postalCode: '',
    state: '',
    latitude: undefined,
    longitude: undefined,
    name: '',
  },
  contact: {
    phone: '',
    name: '',
  },
  instructions: '',
};

export const formInitialState = {
  locations: [
    { ...LOCATION_TEMPLATE, id: uuidv4() },
    { ...LOCATION_TEMPLATE, id: uuidv4() },
  ],
  cash: 0,
  paymentMethod: 'cash',
  transport: 'motorcycle',
};

export const formStateReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'addLocation':
      return {
        ...state,
        locations: [
          ...state.locations,
          {
            ...LOCATION_TEMPLATE,
            ...action.payload,
            id: uuidv4(),
            optionType: 'maps',
          },
        ],
      };
    case 'replaceLocation':
      // eslint-disable-next-line no-case-declarations
      const newLocations = [...state.locations];
      newLocations[action.index] = {
        ...LOCATION_TEMPLATE,
        ...action.payload,
        id: newLocations[action.index].id ?? action.payload.id ?? uuidv4(),
      };

      return {
        ...state,
        locations: newLocations,
      };
    case 'removeLocation':
      return {
        ...state,
        locations: state.locations.filter((_: any, index: number) => index !== action.index),
      };
    case 'sortLocations':
      return { ...state, locations: action.payload };
    case 'changeCash':
      return { ...state, cash: action.payload };
    case 'changePayment':
      return { ...state, paymentMethod: action.payload };
    case 'changeTransport':
      return { ...state, transport: action.payload };
    default:
      throw new Error();
  }
};
