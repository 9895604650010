import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { ButtonView } from '../../components/buttons/ButtonsIcon';
import ResizableReactTable from '../../components/ResizableReactTable';
import { formatDate } from '../../helpers';
import CreateTableData from '../../helpers/createTableData';
import { Payment } from '../../interfaces';
import { getPayments } from '../../services/payment';
import { RootState } from '../../store';
import { ModalPayment } from './components';
import { CustomDropdown } from '../../components/dropdowns';

import { setFilters } from '../../store/slices/payments';
import { IconFlask } from '../../components/icons';

const columns = [
  { Header: 'Sandbox', accessor: 'sandbox' },
  { Header: 'Fecha de creación', accessor: 'createdAt' },
  { Header: 'Fecha de pago', accessor: 'paymentDate' },
  { Header: 'Status', accessor: 'status' },
  // { Header: 'Número', accessor: 'number' },
  { Header: 'Subtotal', accessor: 'subtotal' },
  { Header: 'Total', accessor: 'total' },
  { Header: 'Emisor', accessor: 'issuedBy.name' },
  { Header: 'Receptor', accessor: 'receivedBy.name' },
  { Header: 'Acciones', accessor: 'actions' },
];

const Payments: FC = () => {
  const { payments, totalPayments, filters } = useSelector((state: RootState) => state.payments);
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [manualPageSize] = useState([10, 20, 50, 100]);
  const [limit, setLimit] = useState(manualPageSize[0]);
  const [pageIndex, setPageIndex] = useState(0);
  const [payment, setPayment] = useState<Payment | null>(null);

  const [isSandboxDropdown, setIsSandboxDropdown] = useState({
    label: 'Sandbox',
    selectedKey: filters?.isSandbox || '',
    options: { all: 'Todos', yes: 'Si', no: 'No' },
  });

  const getFilters = () => {
    const filters: any = {};
    filters.limit = limit;
    filters.page = pageIndex + 1;
    filters.sortBy = '_id:desc';

    if (isSandboxDropdown.selectedKey !== 'all') {
      filters.isSandbox = isSandboxDropdown.selectedKey;
    }

    return filters;
  };

  const getAll = () => {
    try {
      const filters = getFilters();
      dispatch(getPayments(filters));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAll();
  }, [limit, pageIndex, isSandboxDropdown]);

  useEffect(() => {
    if (payments.length > 0) {
      const newData: any[] = [];
      payments.map((item) => {
        const obj = {
          ...item,
          actions: (
            <>
              <ButtonView
                onClick={() => {
                  setPayment(item);
                  setIsOpen(true);
                }}
              />
            </>
          ),
          createdAt: formatDate(item.createdAt),
          paymentDate: formatDate(item.paymentDate),
          subtotal: `$${item.subtotal.toFixed(2)} MXN`,
          total: `$${item.total.toFixed(2)} MXN`,
          // sandbox: `${item.isSandbox === true ? 'Si' : 'No'}`,
          sandbox: item.isSandbox === true ? <IconFlask color="#00E5B2" /> : '',
        };
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [payments]);

  const reactTableData = CreateTableData(data, columns);

  const changeIsSandboxFilter = (value: any) => {
    setIsSandboxDropdown({
      ...isSandboxDropdown,
      selectedKey: value,
    });
    dispatch(setFilters({
      filter: 'isSandbox',
      value,
    }));
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title">
                  <h5 className="bold-text">Transacciones</h5>
                </div>
              </div>
              <Row
                style={{ paddingLeft: '20px' }}
              >
                <CustomDropdown
                  {...isSandboxDropdown}
                  onClick={changeIsSandboxFilter}
                />
              </Row>
              {payment && <ModalPayment isOpen={isOpen} setIsOpen={setIsOpen} payment={payment} />}
              {
                data.length === 0 ? <h3> No hay info que se pueda mostrar</h3> : (
                  <ResizableReactTable
                    reactTableData={reactTableData}
                    totalRecords={totalPayments}
                    manualPageSize={manualPageSize}
                    limit={limit}
                    setLimit={setLimit}
                    pageIndex={pageIndex}
                    setPageIndex={setPageIndex}
                    totalNoOfPages={Math.ceil(totalPayments / limit)}
                  />
                )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Payments;
