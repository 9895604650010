/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, Input } from 'reactstrap';
import { DropdownInputField, InputField } from '../../../components/form';
import { statesOfTheRepublic } from '../../../helpers/statesOfTheRepublic';
import { TransportAvailable } from '../../../interfaces';
import { getTransports } from '../../../services/transport';
import { createZone, getZone, updateZone } from '../../../services/zone';
import { useAppSelector } from '../../../store';
import { changeTransportLabel } from '../../../helpers/transport';
import { getDeliveryProviders } from '../../../services/deliveryProvider';

interface State {
  label: string;
  value: string;
  lat: number;
  lng: number;
}

const options: State[] = statesOfTheRepublic.map((state: any) => {
  return {
    value: state.label,
    ...state,
  };
});

type Path = {
  lat: number;
  lng: number;
}[];
interface Props {
  path: Path;
  setPath: React.Dispatch<React.SetStateAction<Path>>;
  setCenter: React.Dispatch<React.SetStateAction<{
    lat: number;
    lng: number;
  }>>;
  onChangeCheckedTransport: (e: ChangeEvent<HTMLInputElement>, id: string) => void;
  transportsAvailable: TransportAvailable[];
  setTransportsAvailable: React.Dispatch<React.SetStateAction<TransportAvailable[]>>;
}


export const CardForm: FC<Props> = ({ path, setPath, setCenter, onChangeCheckedTransport, transportsAvailable, setTransportsAvailable }) => {
  const [coordinates, setCoordinates] = useState<number[][]>([]);
  const [stateOfTheRepublic, setStateOfTheRepublic] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const { transports } = useAppSelector(state => state.transports);
  const { zone } = useAppSelector(state => state.zones);
  const { deliveryProviders } = useAppSelector(state => state.deliveryProviders);

  const initialValues = {
    VAT: 16,
    deliveryFee: 0,
    requestSecs: 60,
    requestAttempts: 90,
    providerFee: 5,
    deliveryTypes: ['expres'],
    availableDeliveryProviders: ['repartimos', 'rappi'],
    availableDeliveryProvidersCOD: ['repartimos', 'rappi'],
  };

  useEffect(() => {
    const array = history.location.pathname.split('/');
    const id = array[array.length - 1];
    if (id === '0') {
      dispatch(getTransports());
    } else {
      dispatch(getZone(id));
    }
    dispatch(getDeliveryProviders());
  }, []);

  useEffect(() => {
    if (zone) {

      if (zone.transportsAvailable.length > 0) {
        const newTransports: TransportAvailable[] = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < transports.length; i++) {
          if (transports[i].slug === zone.transportsAvailable[i]?.slug) {
            newTransports.push(zone.transportsAvailable[i]);
          } else {
            newTransports.push({
              ...transports[i],
              isEnabled: false,
              useGlobalPrices: false,
            });
          }
        }
        setTransportsAvailable(newTransports);
      } else {
        setTransportsAvailable(transports.map(transport => ({
          ...transport,
          isEnabled: false,
          useGlobalPrices: false,
        })));
      }

      // const coords = zone.area.coordinates[0];
      setCoordinates(zone.area.coordinates[0].filter((coords, i) => i !== zone.area.coordinates[0].length - 1));
      const newPath: Path = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= zone.area.coordinates[0].length - 2; i++) {
        const element = { lat: zone.area.coordinates[0][i][1], lng: zone.area.coordinates[0][i][0] };
        newPath.push(element);
      }
      // setPath(zone.area.coordinates[0].map(coords => ({ lat: coords[1], lng: coords[0] })));
      setPath(newPath);
      setCenter({ lat: zone.area.coordinates[0][0][1], lng: zone.area.coordinates[0][0][0] });
      setStateOfTheRepublic(zone.state);
    } else {
      setTransportsAvailable(transports.map(transport => ({
        ...transport,
        isEnabled: true,
        useGlobalPrices: true,
      })));
    }
  }, [transports, zone]);

  useEffect(() => {
    const newCoordinates: number[][] = [];
    path.map(({ lng, lat }) => {
      return newCoordinates.push([lng, lat]);
    });
    setCoordinates(newCoordinates);
  }, [path]);

  const validate = (values: any) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = 'El nombre es obligatorio';
    }
    return errors;
  };

  const onSubmit = (data: any) => {
    // Toma las primeras coordenadas y se agregan al final, para que el primer punto coincida con el ultimo
    const coords: number[][] = [...coordinates, coordinates[0]];
    data.area = {
      coordinates: [coords],
    };
    data.transportsAvailable = transportsAvailable;
    data.state = stateOfTheRepublic;
    if (zone) {
      dispatch(updateZone(data, zone._id));
    } else {
      dispatch(createZone(data));
    }
    history.push('/dashboard/zones');
  };

  const onSelectState = (state: State) => {
    const { lat, lng } = state;
    setStateOfTheRepublic(state.value);
    setPath([
      { lat: lat - 0.175, lng: lng + 0.005 },
      { lat: lat - 0.073, lng: lng + 0.203 },
      { lat: lat - 0.011, lng: lng + 0.011 },
    ]);
    setCenter({ lat, lng });
    return state;
  };

  return (
    <Card>
      <CardBody>
        <h3>{zone ? 'Editar Zona' : 'Nueva Zona'}</h3>
        <Form onSubmit={onSubmit} validate={validate} initialValues={zone ? { ...zone.area, ...zone } : initialValues}>
          {({ handleSubmit }) => (
            <form className="form" onSubmit={handleSubmit}>
              <InputField
                label="Nombre"
                name="name"
                placeholder="Nombre de la zona"
              />
              <DropdownInputField
                label="Estado"
                name="state"
                options={options}
                placeholder={zone ? zone.state : 'Selecciona un estado'}
                onSelect={onSelectState}
              />
              <InputField
                label="IVA"
                name="VAT"
                placeholder="IVA de la zona"
                defaultValue="16"
              />
              <InputField
                label="Tarifa"
                name="deliveryFee"
                placeholder="Tarifa de la zona"
                defaultValue="0"
              />
              <InputField
                label="Tiempo del request en segundos"
                name="requestSecs"
                placeholder="Tiempo del request en segundos"
                type="number"
                defaultValue="60"
              />
              <InputField
                label="Número de intentos"
                name="requestAttempts"
                placeholder="Número de intentos al repartidor"
                type="number"
                defaultValue="90"
              />
              <InputField
                label="Costo extra por provider"
                name="providerFee"
                placeholder="Costo extra por provider"
                type="number"
                defaultValue="5"
              />
              <div className="form__form-group">
                <label className="form__form-group-label typography-message" htmlFor="deliveryTypes">Tipos de servicio</label>
                <div className="form__form-group-field">
                  <div className="custom-control custom-checkbox custom-control-inline">
                    <Field
                      name="deliveryTypes"
                      component="input"
                      type="checkbox"
                      value="expres"
                      className="custom-control-input"
                      id="expres"
                    />
                    <label className="custom-control-label" htmlFor="expres">
                      Expres
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox custom-control-inline">
                    <Field
                      name="deliveryTypes"
                      component="input"
                      type="checkbox"
                      value="sameDay"
                      className="custom-control-input"
                      id="sameDay"
                    />
                    <label className="custom-control-label" htmlFor="sameDay">
                      Same Day
                    </label>
                  </div>
                </div>
              </div>
              <div className="form__form-group">
                <label className="form__form-group-label typography-message" htmlFor="availableDeliveryProviders">Providers</label>
                <div className="form__form-group-field">
                  {
                    deliveryProviders.map(provider => (
                      <div className="custom-control custom-checkbox custom-control-inline" key={provider._id}>
                        <Field
                          name="availableDeliveryProviders"
                          component="input"
                          type="checkbox"
                          value={provider.slug}
                          className="custom-control-input"
                          id={`provider-${provider.slug}`}
                        />
                        <label className="custom-control-label" htmlFor={`provider-${provider.slug}`}>
                          {provider.name}
                        </label>
                      </div>
                    ))
                  }
                </div>
              </div>
              <div className="form__form-group">
                <label className="form__form-group-label typography-message" htmlFor="availableDeliveryProvidersCOD">Providers COD</label>
                <div className="form__form-group-field">
                  {
                    deliveryProviders.map(provider => (
                      <div className="custom-control custom-checkbox custom-control-inline" key={provider._id}>
                        <Field
                          name="availableDeliveryProvidersCOD"
                          component="input"
                          type="checkbox"
                          value={provider.slug}
                          className="custom-control-input"
                          id={`provider-cod-${provider.slug}`}
                        />
                        <label className="custom-control-label" htmlFor={`provider-cod-${provider.slug}`}>
                          {provider.name}
                        </label>
                      </div>
                    ))
                  }
                </div>
              </div>
              {
                transportsAvailable.map((transport: TransportAvailable) => (
                  <div key={transport._id} style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: '5px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <label htmlFor="isEnabled">{changeTransportLabel(transport.name)}</label>
                      <Input
                        style={{ height: '15px' }}
                        type="checkbox"
                        checked={transport.isEnabled}
                        defaultChecked
                        name="isEnabled"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeCheckedTransport(e, transport._id)}
                      />
                    </div>
                  </div>
                ))
              }
              <div>
                <Button
                  size="sm"
                  color="primary"
                  type="submit"
                >
                  {zone ? 'Editar Zona' : 'Crear Zona'}
                </Button>
              </div>
            </form>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};
