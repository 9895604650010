/* eslint-disable no-undef */
import { Marker } from '@react-google-maps/api';
import { FC } from 'react';

interface Props {
  latitude: number;
  longitude: number;
  imgUrl: string;
  width?: number;
  height?: number;
  onClick?: any,
}

export const CustomMarker: FC<Props> = ({
  latitude, longitude, imgUrl, width = 30, height = 40,
  onClick,
}) => {
  return (
    <Marker
      position={{
        lat: latitude,
        lng: longitude,
      }}
      icon={{
        url: imgUrl,
        scaledSize: new google.maps.Size(width, height), // size: ;
        origin: new google.maps.Point(0, 0), // origin
        // anchor: new google.maps.Point(9, 49) // anchor 
      }}
      onClick={onClick}
    />
  );
};
