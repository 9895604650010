import { format } from 'date-fns';
import { es } from 'date-fns/locale';

export const formatDate = (date: any) => {
  return format(new Date(date), 'dd-MMM-yy', { locale: es });
};
export const formatDateTime = (date: any) => {
  return format(new Date(date), 'dd-MMM-yy HH:mm', { locale: es });
};
export const formatTime = (date: any) => {
  return format(new Date(date), 'HH:mm', { locale: es });
};
export const formatDayTime = (date: any) => {
  return format(new Date(date), 'EEEE HH:mm', { locale: es });
};