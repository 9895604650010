import { FC } from 'react';
import { Card, CardBody } from 'reactstrap';
import s from './styles.module.scss';
import { DeliveryService } from '../../../interfaces';
import { changeStatusLabel } from '../../../helpers/status';
import { changePaymentMethodLabel } from '../../../helpers/paymentMethod';
import { changeTransportLabel } from '../../../helpers/transport';
import { formatDateTime, getIntegrationLogo } from '../../../helpers';
import { IconFlask } from '../../../components/icons';
import { ButtonPrimary } from '../../../components/buttons';

interface Props {
  deliveryService: DeliveryService
}

export const CardService: FC<Props> = ({ deliveryService }) => {
  return (
    <Card>
      <CardBody>
        <div>
          <h3>
            {deliveryService.isSandbox && <IconFlask />} Servicio
          </h3>
          <p>
            {deliveryService._id}
          </p>
          <ButtonPrimary
            label="Ir a url"
            onClick={() => window.open(`https://negocios.repartimos.mx/publicService/${deliveryService._id}`, '_blank')}
          />
        </div>
        <div>
          <ul className={s.table}>
            <li>
              <p>Status</p>
              <span>{changeStatusLabel(deliveryService.status, 'service')}</span>
            </li>
          </ul>

          <ul className={s.table}>
            <li>
              <p>Método de pago</p>
              <span>{changePaymentMethodLabel(deliveryService.paymentMethod)}</span>
            </li>
            <li>
              <p>Transporte</p>
              <span>{changeTransportLabel(deliveryService.transport)}</span>
            </li>
            <li>
              <p>Valor de productos</p>
              <span>${deliveryService.locations[1].orderValue}</span>
            </li>
            <li>
              <p>Efectivo Requerido</p>
              <span>${deliveryService.requiredCash}</span>
            </li>
          </ul>


          <ul className={s.table}>
            <li>
              <p>Creación</p>
              <span>{deliveryService.createdAt ? formatDateTime(deliveryService.createdAt) : '--'}</span>
            </li>
            {
              deliveryService.scheduledAt && (
                <li>
                  <p>Programado</p>
                  <span>{formatDateTime(deliveryService.scheduledAt)}</span>
                </li>
              )
            }
            <li>
              <p>Aceptado</p>
              <span>{deliveryService.acceptedAt ? formatDateTime(deliveryService.acceptedAt) : '--'}</span>
            </li>
            <li>
              <p>Cancelado</p>
              <span>{deliveryService.status === 'canceled' && (deliveryService.endRequestAt || deliveryService.endAt) ? formatDateTime(deliveryService.endRequestAt || deliveryService.endAt) : '--'}</span>
            </li>
            <li>
              <p>Finalizado</p>
              <span>{deliveryService.status !== 'canceled' && deliveryService.endAt ? formatDateTime(deliveryService.endAt) : '--'}</span>
            </li>
            <li>
              <p>Duración</p>
              <span>{deliveryService.totalAcceptedToEndSecs / 60} min</span>
            </li>
          </ul>
          {
            deliveryService.deliveryProvider?.slug && (
              <ul className={s.table}>
                <li>
                  <p>Realizado por</p>
                  <span>{deliveryService.deliveryProvider.name}</span>
                </li>
                {
                  deliveryService.deliveryProvider.slug === 'lalamove' && (
                    <>
                      <li>
                        <p>Soporte</p>
                        <span>55 9302 0500</span>
                      </li>
                      <li>
                        <p>ID</p>
                        <span>{deliveryService.deliveryProvider.orderId}</span>
                      </li>
                    </>
                  )
                }
                {
                  deliveryService.deliveryProvider.slug === 'rappi' && (
                    <>
                      <li>
                        <p>Soporte</p>
                        <span>+57 2 3691694</span>
                      </li>
                      <li>
                        <p>ID</p>
                        <span>{deliveryService.deliveryProvider.orderId}</span>
                      </li>
                    </>
                  )
                }
              </ul>
            )
          }
        </div>
        {
          deliveryService.posIntegrationData?.name && (
            <div className="w-full d-flex" style={{ justifyContent: 'flex-end' }}>
              <img
                src={getIntegrationLogo(deliveryService.posIntegrationData.name).url}
                alt={deliveryService.posIntegrationData.name}
                style={{ width: getIntegrationLogo(deliveryService.posIntegrationData.name).width }}
              />
            </div>
          )
        }
      </CardBody>
    </Card>
  );
};
