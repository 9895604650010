import { TransportType } from '../interfaces';

export const changeTransportLabel = (transport: TransportType) => {
  switch (transport) {
    case 'motorcycle':
      return 'Moto';
    case 'bicycle':
      return 'Bicicleta';
    case 'car':
      return 'Auto';
    case 'walking':
      return 'Caminando';

    default:
      return transport;
  }
};