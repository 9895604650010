import { FormApi, SubmissionErrors } from 'final-form';
import { FC } from 'react';
import { Form } from 'react-final-form';
import { Button } from 'reactstrap';
import { CheckBoxField, InputField, TextAreaField } from '../../../components/form';
import { APIDeveloper } from '../../../interfaces';

interface Props {
  onSubmit: (values: any, form: FormApi<any, any>, callback?: ((errors?: SubmissionErrors) => void) | undefined) => void;
  apiDeveloper?: APIDeveloper | null;
}

export const FormApiDeveloper: FC<Props> = ({ onSubmit, apiDeveloper = null }) => {
  const validate = (values: any) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = 'El nombre es obligatorio';
    }
    return errors;
  };

  return (
    <Form onSubmit={onSubmit} validate={validate} initialValues={apiDeveloper}>
      {({ handleSubmit }) => (
        <form className="form" onSubmit={handleSubmit}>
          <InputField
            label="Nombre"
            name="name"
            placeholder="Nombre del Developer"
          />
          <TextAreaField
            label="Descripción"
            name="description"
            placeholder="Descripción"
          />
          <InputField
            label="Webhook"
            name="webhookUrl"
            placeholder="Webhook URL"
          />
          <CheckBoxField
            label="Activo"
            name="isActive"
          />
          <CheckBoxField
            label="Modo Sandbox"
            name="sandboxMode"
          />
          <div>
            <Button
              size="sm"
              color="primary"
              type="submit"
            >
              {apiDeveloper ? 'Editar Developer api' : 'Crear Developer api'}
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};
