import dashboardBackendApi from '../api/dashboardBackendApi';
import { handleAxiosError } from '../helpers/handleErrors';
import { serialize } from '../helpers/serialize';
import { AuthResponse } from '../interfaces';
import {
  setSupportAdmins,
  setSupportAdmin,
  createNewSupportAdmin,
  updateNowSupportAdmin,
} from '../store/slices/supportAdmins';

export const updatePassword = async (password: string) => {
  try {
    await dashboardBackendApi.post<AuthResponse>('/support/auth/updatePassword', { password });
  } catch (error) {
    console.error({ error });
  }
};

export const getSupportAdmins = (filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const resp = await dashboardBackendApi.get(`/support/supportAdmins?${_filters}`);
    console.log({ data: resp.data });
    dispatch(setSupportAdmins({
      supportAdmins: resp.data.supportAdmins,
      totalSupportAdmins: resp.data.totalSupportAdmins,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const getSupportAdmin = (id: string) => async (dispatch: any) => {
  try {
    const { data } = await dashboardBackendApi.get(`/support/supportAdmins/${id}`);
    dispatch(setSupportAdmin({
      supportAdmin: data.supportAdmin,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
    // return error.response;
  }
};

export const createSupportAdmin = (supportAdmin: any) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.post('/support/supportAdmins', supportAdmin);
    dispatch(createNewSupportAdmin({
      supportAdmin: resp.data.supportAdmin,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
    // return error.response;
  }
};

export const updateSupportAdmin = (id: string, supportAdmin: any) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.put(`/support/supportAdmins/${id}`, supportAdmin);
    dispatch(updateNowSupportAdmin({
      id,
      supportAdmin: resp.data.supportAdmin,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
    // return error.response;
  }
};

export const getBusinessByHunterId = async (hunterId: string) => {
  try {
    const { data } = await dashboardBackendApi.get(`/support/businesses/hunter/${hunterId}`);
    return data.businesses;
  } catch (error) {
    console.log({ error });
    return [];
  }
};
