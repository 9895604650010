import { FC, useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
// import ResizableReactTable from '../../components/ResizableReactTable';
// import { formatDate } from '../../helpers';
// import CreateTableData from '../../helpers/createTableData';
// import { RootState } from '../../store';
// import { ModalInvoice } from './components';
// import { ButtonView } from '../../components/buttons/ButtonsIcon';
import { getAccountBalance, getAccountBalanceSandbox } from '../../services/delivery';

const Invoices: FC = () => {
  // const dispatch = useDispatch();
  // const history = useHistory();
  const defaultValues = {
    totalIncome: 0,
    totalExpense: 0,
    transactionBalance: 0,
    accountsReceivable: 0,
    accountsPayable: 0,
    invoiceBalance: 0,
    revenue: 0,
    productsAmountReceivable: 0,
    productsAmountPayable: 0,
  };
  interface AcountBalance {
    totalIncome?: number,
    totalExpense?: number,
    transactionBalance?: number,
    accountsReceivable?: number,
    accountsPayable?: number,
    invoiceBalance?: number,
    revenue?: number,
    productsAmountReceivable?: number,
    productsAmountPayable?: number,
  }
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [dataSandbox, setDataSandbox] = useState({});
    
  const getAll = async () => {
    try {
      const data = await dispatch(getAccountBalance());
      setData(data);
    } catch (error) {
      console.error(error);
    }
  };
  const getAllSandbox = async () => {
    try {
      const data = await dispatch(getAccountBalanceSandbox());
      console.log('data', data);
      setDataSandbox(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAll();
    getAllSandbox();
  }, []);

  const accountBalance : AcountBalance = data;
  const accountBalanceSandbox : AcountBalance = dataSandbox;
  console.log('accountBalance', accountBalance);
  console.log('accountBalanceSandbo', accountBalanceSandbox);
  return (
    <Container className="dashboard">
      <Row>
        <Col md={6} lg={6}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title">
                  <h4 className="bold-text">AccountBalance</h4>
                  <p>
                    <strong>Total income</strong> {accountBalance.totalIncome}
                  </p>
                  <p>
                    <strong>Total expense</strong> {accountBalance.totalExpense}
                  </p>
                  <p>
                    <strong>Transaction balance</strong> {accountBalance.transactionBalance}
                  </p>
                  <p>
                    <strong>Accounts receivable</strong> {accountBalance.accountsReceivable}
                  </p>
                  <p>
                    <strong>Accoints payable</strong> {accountBalance.accountsPayable}
                  </p>
                  <p>
                    <strong>Invoice balance</strong> {accountBalance.invoiceBalance}
                  </p>
                  <p>
                    <strong>Revenue</strong> {accountBalance.revenue}
                  </p>
                  <p>
                    <strong>productsAmountReceivable</strong> {accountBalance.productsAmountReceivable}
                  </p>
                  <p>
                    <strong>productsAmountPayable</strong> {accountBalance.productsAmountPayable}
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={6} lg={6}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title">
                  <h4 className="bold-text">AccountBalance (sandbox)</h4>
                  <p>
                    <strong>Total income</strong> {accountBalanceSandbox.totalIncome}
                  </p>
                  <p>
                    <strong>Total expense</strong> {accountBalanceSandbox.totalExpense}
                  </p>
                  <p>
                    <strong>Transaction balance</strong> {accountBalanceSandbox.transactionBalance}
                  </p>
                  <p>
                    <strong>Accounts receivable</strong> {accountBalanceSandbox.accountsReceivable}
                  </p>
                  <p>
                    <strong>Accoints payable</strong> {accountBalanceSandbox.accountsPayable}
                  </p>
                  <p>
                    <strong>Invoice balance</strong> {accountBalanceSandbox.invoiceBalance}
                  </p>
                  <p>
                    <strong>Revenue</strong> {accountBalanceSandbox.revenue}
                  </p>
                  <p>
                    <strong>productsAmountReceivable</strong> {accountBalanceSandbox.productsAmountReceivable}
                  </p>
                  <p>
                    <strong>productsAmountPayable</strong> {accountBalanceSandbox.productsAmountPayable}
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Invoices;
