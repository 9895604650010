import { FC } from 'react';
import { SliderTooltip } from '../../../components/sliders';

const MAX = 5000;
const MIN = 0;
const STEP = 50;

interface Props {
  value: any;
  onChange: (data: any) => void;

}

export const CashSelector: FC<Props> = ({ value, onChange }) => (
  <div className="cash-container">
    <span className="numbers">{MIN}</span>
    <SliderTooltip
      value={value}
      min={MIN}
      max={MAX}
      step={STEP}
      onChange={onChange}
    />
    <span className="numbers">{MAX}</span>
  </div>
);
