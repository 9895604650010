/* eslint-disable no-nested-ternary */
import { FC, useCallback, useEffect, useState } from 'react';
import { GoogleMap, InfoWindow } from '@react-google-maps/api';
import { DeliveryDriver, BusinessEstablishment } from '../../../interfaces';
import { CustomMarker } from '../../../components/maps';
import driverImg from '../../../assets/img/pines/pin-repartidor-1.png';
import driverImg2 from '../../../assets/img/pines/pin-repartidor-2.png';
import driverFounderImg from '../../../assets/img/pines/pin-dorado-02.png';
import driverFounderImg2 from '../../../assets/img/pines/pin-dorado-01.png';
import businessImg from '../../../assets/img/pines/pin-negocio-1.png';
import { getUrl } from '../../../helpers/cloudinary';
import { formatDayTime } from '../../../helpers';

interface Props {
  driversInZone?: DeliveryDriver[];
  establishmentsInZone?: BusinessEstablishment[];
  markersType?: string,
  lng: number;
  lat: number;
}
export interface InfoObj {
  _id: string,
  title: string,
  subtitle?: string,
  desc?: string,
  imageUrl?: string,
  linkUrl?: string,
  // position: any,
  latitude: any,
  longitude: any,

  addToRequest?: any;
  addToService?: any;
  reAsignToService?: any;

  isVisible?: boolean;
  appOpenedAt?: string;

}
export const InfoWindowComponent = ({
  _id,
  title,
  imageUrl,
  linkUrl,
  subtitle = '',
  desc = '',
  addToRequest,
  addToService,
  reAsignToService,
  isVisible,
  appOpenedAt,
}: InfoObj) => {
  console.log('isVisble', isVisible);
  return (
    <div>
      <a href={linkUrl} target="_blank" rel="noreferrer">
        <div
          className="avatar"
          style={{
            width: 90,
            height: 90,
            borderRadius: 99,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url('${imageUrl}')`,
            margin: '10px auto',
          }}
        />
        <h4>{title}</h4>
      </a>
      <h5>{subtitle}</h5>
      <span>{desc}</span>
      <br />
      <span>Visible: {isVisible ? 'Si' : 'No'}</span>
      {
        appOpenedAt && (
          <>
            <br />
            <span>Abierto: {formatDayTime(appOpenedAt)}</span>
          </>
        )
      }
      <br />
      {addToRequest}
      {addToService}
      {reAsignToService}
    </div>
  );
};
export const MapZone: FC<Props> = ({ driversInZone = [], establishmentsInZone = [], lng, lat, markersType = 'all' }) => {

  const [map, setMap] = useState<any>(null);
  const onLoad = useCallback((map: any) => setMap(map), []);
  const [infoObj, setInfoObj] = useState<InfoObj | null>();
  // const [center, setCenter] = useState({
  //   lng: lng || -103.4,
  //   lat: lat || 20.7167,
  // });
  const [lastLongitude, setLastLongitude] = useState(lng || -103.4);
  const [lastLatitude, setLastLatitude] = useState(lat || 20.7167);

  const fitBounds = () => {
    const bounds = new window.google.maps.LatLngBounds();

    if (markersType === 'driver' || markersType === 'all') {
      driversInZone.map((v) => {
        bounds.extend(
          new window.google.maps.LatLng(
            v.coords ? v.coords[1] : 0,
            v.coords ? v.coords[0] : 0,
          ),
        );
        return v;
      });
    }

    if (markersType === 'business' || markersType === 'all') {
      establishmentsInZone.map((v) => {
        bounds.extend(
          new window.google.maps.LatLng(
            v.address.latitude,
            v.address.longitude,
          ),
        );
        return v;
      });
    }

    map.fitBounds(bounds);
  };
  const panTo = (longitude: number, latitude: number) => {
    // const bounds = new window.google.maps.LatLngBounds();
    // bounds.extend(new window.google.maps.LatLng(longitude, latitude));
    const center = new window.google.maps.LatLng(latitude, longitude);
    map.panTo(center);
  };
  const fitTo = (longitude: number, latitude: number) => {
    // console.log('@fitTo', longitude, latitude);
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend(new window.google.maps.LatLng(longitude, latitude));
    // const center = new window.google.maps.LatLng(latitude, longitude);
    map.fitBounds(bounds);
  };

  useEffect(() => {
    if (infoObj) {
      panTo(infoObj.longitude, infoObj.latitude);
    }
  }, [infoObj]);

  useEffect(() => {
    if (map) {
      fitBounds();
    }
  }, [map, establishmentsInZone, driversInZone, markersType]);

  const onCloseInfoWindow = () => {
    if (infoObj) {
      setLastLatitude(infoObj.latitude);
      setLastLongitude(infoObj.longitude);
      setInfoObj(null);
    }
  };

  const onCenterChanged = () => {
    // console.log('xc', x);
    if (map) {
      setLastLatitude(map.getCenter().lat());
      setLastLongitude(map.getCenter().lng());
    }
  };

  const setImageDriver = (driver: DeliveryDriver) => {
    return driver.isFounder
      ? driver.isVisible ? driverFounderImg : driverFounderImg2
      : driver.isVisible ? driverImg : driverImg2;
  };

  // console.log(infoObj);

  return (
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '70vh' }}
      zoom={11}
      onLoad={onLoad}
      center={{
        lng: lastLongitude,
        lat: lastLatitude,
      }}
      // center={{
      //   lng: lng || -103.4,
      //   lat: lat || 20.7167,
      // }}
      onCenterChanged={() => onCenterChanged()}
    >
      {markersType === 'driver' || markersType === 'all'
        ? driversInZone.map((driver) => {
          if (driver.coords) {
            const longitude = driver.coords.length > 0 ? driver.coords[0] : 0;
            const latitude = driver.coords.length > 0 ? driver.coords[1] : 0;
            const imageUrl = driver.profileImage.imageId ? getUrl(driver.profileImage.imageId) : 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/OOjs_UI_icon_userAvatar.svg/2048px-OOjs_UI_icon_userAvatar.svg.png';
            return (
              <CustomMarker
                key={driver._id}
                imgUrl={setImageDriver(driver)}
                latitude={driver.coords[1]}
                longitude={driver.coords[0]}
                width={30}
                height={30}
                onClick={() => setInfoObj({
                  _id: driver._id,
                  title: `${driver.firstName} ${driver.lastName}`,
                  subtitle: driver.transport.name || '--',
                  latitude,
                  longitude,
                  imageUrl,
                  desc: `${driver.phoneNumber?.number}`,
                  linkUrl: `https://soporte.repartimos.mx/dashboard/deliveryDrivers/${driver._id}`,
                })}
              />
            );
          }
          return null;
        })
        : false}
      {markersType === 'business' || markersType === 'all'
        ? establishmentsInZone.map((v) => {
          if (v.address) {
            return (
              <CustomMarker
                key={v._id}
                imgUrl={businessImg}
                latitude={v.address.latitude}
                longitude={v.address.longitude}
                width={30}
                height={30}
                onClick={() => setInfoObj({
                  _id: v._id,
                  title: v.businessName || '',
                  subtitle: v.name || '',
                  latitude: v.address.latitude,
                  longitude: v.address.longitude,
                  imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/55/Ic_store_48px.svg/96px-Ic_store_48px.svg.png',
                  linkUrl: `https://soporte.repartimos.mx/dashboard/businesses/${v.businessId}`,
                })}
              />
            );
          }
          return null;
        })
        : false}
      {infoObj && (
        <InfoWindow
          onLoad={() => { }}
          position={{
            lng: infoObj.longitude,
            lat: infoObj.latitude,
          }}
          onCloseClick={() => setInfoObj(null)}
        // options={{ closeBoxURL: '', enableEventPropagation: true }}
        >
          <InfoWindowComponent {...infoObj} />
        </InfoWindow>
      )}
    </GoogleMap>
  );
};
