import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalRight } from '../../../components/modals/ModalRight';
import { createEstablishment, updateEstablishment } from '../../../services/businessEstablishment';
import { FormEstablishment } from './FormEstablishment';
import { MapEstablishment } from './MapEstablishment';
import { RootState } from '../../../store';
import { BusinessEstablishment } from '../../../interfaces/businessEstablishment';
import { InputGooglePlaces } from '../../../components/inputs/InputGooglePlaces';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  businessEstablishment: BusinessEstablishment | null;
}

export const ModalFormEstablishment: FC<Props> = ({ isOpen, setIsOpen, businessEstablishment = null }) => {
  const { business } = useSelector((state: RootState) => state.businesses);
  const dispatch = useDispatch();

  const [longitude, setLongitude] = useState<number>();
  const [latitude, setLatitude] = useState<number>();
  const [place, setPlace] = useState<any>(null);

  useEffect(() => {
    if (businessEstablishment && businessEstablishment.address) {
      setLongitude(businessEstablishment.address.longitude);
      setLatitude(businessEstablishment.address.latitude);
    }
  }, [businessEstablishment]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onSubmit = (data: any) => {
    if (businessEstablishment) {
      dispatch(updateEstablishment(businessEstablishment._id, data));
    } else {
      data.businessId = business?._id;
      data.phone.countryCode = 'MX';
      data.phone.countryPrefix = '52';
      dispatch(createEstablishment(data));
    }
    setIsOpen(false);
  };

  const onMarkerDragEnd = (x: any) => {
    const lat = x.latLng.lat();
    const lng = x.latLng.lng();
    setLatitude(lat);
    setLongitude(lng);
  };
  const updatePlace = (data: any) => {
    setPlace(data);
    setLatitude(data.address.latitude);
    setLongitude(data.address.longitude);
    console.log(data);
    // onChange(data);
  };

  return (
    <ModalRight
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={businessEstablishment ? 'Editar sucursal' : 'Nueva sucursal'}
    >
      {/* <InputSearchGoogle
        value={searchText}
        onChange={onChange}
        placeholder="Introduce la dirección"
        showResults={showResults}
        onSelect={onSelectResult}
        suggestions={suggestions}
      /> */}
      <InputGooglePlaces
        onChange={updatePlace}
      />
      <FormEstablishment onSubmit={onSubmit} businessEstablishment={businessEstablishment} place={place}>
        <MapEstablishment
          position={latitude && longitude ? { lat: latitude, lng: longitude } : { lat: 20.7167, lng: -103.4 }}
          onMarkerDragEnd={onMarkerDragEnd}
        />
      </FormEstablishment>
    </ModalRight>
  );
};
