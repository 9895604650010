import { Polygon } from '@react-google-maps/api';
import { FC, useEffect, useState } from 'react';

type Path = {
  lat: number;
  lng: number;
}[];

interface Props {
  zone: any;
}

export const PolygonZone: FC<Props> = ({ zone }) => {
  const [path, setPath] = useState<Path>([]);

  useEffect(() => {
    const newPath: Path = [];
    zone.area.coordinates[0].map((coords: number[]) => newPath.push({ lng: coords[0], lat: coords[1] }));
    setPath(newPath);
  }, []);

  const options = {
    fillColor: 'lightblue',
    fillOpacity: 0.3,
    strokeColor: 'red',
    editable: false,
    draggable: false,
    strokeOpacity: 1,
    strokeWeight: 2,
    zIndex: 1,
  };

  return (
    <Polygon
      paths={path}
      options={options}
    />
  );
};
