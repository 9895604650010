import dashboardBackendApi from '../api/dashboardBackendApi';
import { handleAxiosError } from '../helpers/handleErrors';
import { serialize } from '../helpers/serialize';
import { setCampaign, setCampaigns } from '../store/slices/campaigns';

export const getCampaigns = (filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const { data } = await dashboardBackendApi.get<any>(`/support/campaigns?${_filters}`);
    console.log({ data });
    dispatch(setCampaigns({
      campaigns: data.campaigns,
      totalCampaigns: data.totalCampaigns,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const getCampaign = (id: string, filters?: any) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const { data } = await dashboardBackendApi.get<any>(`/support/campaigns/${id}?${_filters}`);
    dispatch(setCampaign({
      campaign: data.campaign,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};
