/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
import { useState, useEffect, FC } from 'react';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import * as googlePlacesHelper from '../../helpers/googlePlaces';

interface Props {
  onChange: (data: any) => void;
}

export const InputGooglePlaces: FC<Props> = ({ onChange }) => {
  const [place, setPlace] = useState<any>(null);
  useEffect(() => {
    if (!place) return;
    let canceled = false;
    const { place_id: placeId } = place.value;

    geocodeByPlaceId(placeId)
      .then((resp) => {
        if (canceled) return;
        const match = resp.at(0);
        onChange(googlePlacesHelper.extractData(match));
      })
      .catch(console.error);

    return () => {
      canceled = true;
    };
    // no poner `onChange` como dependencia porque ocasiona que se renderice una y otra vez y mande muchas solicitudes innecesarias
    // intenté usar useCallback en onChange para ponerla como dependencia y quitar la advertencia pero no funcionó
  }, [place]);
  return (
    <div style={{ zIndex: 99, position: 'relative' }}>
      <GooglePlacesAutocomplete
        apiOptions={{
          region: 'MX',
        }}
        selectProps={{
          value: place,
          onChange: setPlace,
        }}
      />
    </div>
  );
};
