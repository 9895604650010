import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Business } from '../../../interfaces/business';
import {
  payloadSetBusinessAdmins,
  payloadCreateBusiness,
  payloadCreateBusinessAdmin,
  payloadSetBusiness,
  payloadSetBusinesses,
  payloadUpdateBusiness,
  payloadUpdateBusinessAdmin,
  payloadSetBusinessClients,
  payloadSetPaymentsByBusiness,
  payloadSetInvoicesByBusiness,
} from './payloadTypes';
import { BusinessAdmin, BusinessClient, DeliveryService, Payment } from '../../../interfaces';

interface Props {
  businesses: Business[];
  totalBusinesses: number;
  business: Business | null;
  businessAdmins: BusinessAdmin[];
  totalBusinessAdmins: number;
  businessDeliveryServices: DeliveryService[];
  totalBusinessDeliveryServices: number;
  businessClients: BusinessClient[];
  totalBusinessClients: number;
  payments: Payment[];
  totalPayments: number;
  invoices: any[];
  totalInvoices: number;
  invoicesIssuedBy: any[];
  totalInvoicesIssuedBy: number;
  invoicesReceivedBy: any[];
  totalInvoicesReceivedBy: number;
  // filters
  limit: number;
  pageIndex: number;
  from: any;
  to: any;
  searchText: string;
  filters: {
    isSandbox: string,
  };
}

const initialState: Props = {
  businesses: [],
  totalBusinesses: 0,
  business: null,
  businessAdmins: [],
  totalBusinessAdmins: 0,
  businessDeliveryServices: [],
  totalBusinessDeliveryServices: 0,
  businessClients: [],
  totalBusinessClients: 0,
  payments: [],
  totalPayments: 0,
  invoices: [],
  totalInvoices: 0,
  invoicesIssuedBy: [],
  totalInvoicesIssuedBy: 0,
  invoicesReceivedBy: [],
  totalInvoicesReceivedBy: 0,
  // filters
  limit: 10,
  pageIndex: 0,
  from: null,
  to: null,
  searchText: '',
  filters: {
    isSandbox: 'all',
  },
};

export const businessesSlice = createSlice({
  name: 'businesses',
  initialState,
  reducers: {
    setBusinesses: (state, action: PayloadAction<payloadSetBusinesses>) => {
      state.businesses = action.payload.businesses || [];
      state.totalBusinesses = action.payload.totalBusinesses;
      state.business = null;
      state.businessAdmins = [];
      state.totalBusinessAdmins = 0;
      state.payments = [];
      state.totalPayments = 0;
      state.invoices = [];
      state.totalInvoices = 0;
    },
    setBusiness: (state, action: PayloadAction<payloadSetBusiness>) => {
      state.business = action.payload.business;
    },
    createNewBusiness: (state, action: PayloadAction<payloadCreateBusiness>) => {
      state.totalBusinesses += 1;
      if (state.totalBusinesses <= 10) {
        state.businesses.push(action.payload.business);
      }
    },
    updateNowBusiness: (state, action: PayloadAction<payloadUpdateBusiness>) => {
      const updateList = state.businesses.map((business) => {
        if (business._id === action.payload.id) {
          return action.payload.business;
        }
        return business;
      });
      state.business = { ...state.business, ...action.payload.business };
      state.businesses = updateList;
    },
    setAdminList: (state, action: PayloadAction<payloadSetBusinessAdmins>) => {
      state.businessAdmins = action.payload.businessAdmins || [];
      state.totalBusinessAdmins = action.payload.totalBusinessAdmins;
    },
    createNewBusinessAdmin: (state, action: PayloadAction<payloadCreateBusinessAdmin>) => {
      state.totalBusinessAdmins += 1;
      if (state.totalBusinessAdmins <= 10) {
        state.businessAdmins.push(action.payload.businessAdmin);
      }
    },
    updateNowBusinessAdmin: (state, action: PayloadAction<payloadUpdateBusinessAdmin>) => {
      const updateAdminList = state.businessAdmins.map((businessAdmin) => {
        if (businessAdmin._id === action.payload.id) {
          return action.payload.businessAdmin;
        }
        return businessAdmin;
      });
      state.businessAdmins = updateAdminList;
    },
    setDeliveryServicesByBusiness: (state, action) => {
      state.businessDeliveryServices = action.payload.businessDeliveryServices || [];
      state.totalBusinessDeliveryServices = action.payload.totalBusinessDeliveryServices;
    },
    setClientList: (state, action: PayloadAction<payloadSetBusinessClients>) => {
      state.businessClients = action.payload.businessClients || [];
      state.totalBusinessClients = action.payload.totalBusinessClients;
    },
    setPaymentsByBusiness: (state, action: PayloadAction<payloadSetPaymentsByBusiness>) => {
      state.payments = action.payload.payments || [];
      state.totalPayments = action.payload.totalPayments || 0;
    },
    setInvoicesByBusiness: (state, action: PayloadAction<payloadSetInvoicesByBusiness>) => {
      state.invoices = action.payload.invoices || [];
      state.totalInvoices = action.payload.totalInvoices || 0;
    },
    setInvoicesIssuedByBusiness: (state, action: PayloadAction<payloadSetInvoicesByBusiness>) => {
      state.invoicesIssuedBy = action.payload.invoices || [];
      state.totalInvoicesIssuedBy = action.payload.totalInvoices || 0;
    },
    setInvoicesReceivedByBusiness: (state, action: PayloadAction<payloadSetInvoicesByBusiness>) => {
      state.invoicesReceivedBy = action.payload.invoices || [];
      state.totalInvoicesReceivedBy = action.payload.totalInvoices || 0;
    },
    setLimit: (state, { payload }) => {
      state.limit = payload;
    },
    setPageIndex: (state, { payload }) => {
      state.pageIndex = payload;
    },
    setFrom: (state, { payload }) => {
      state.from = payload;
    },
    setTo: (state, { payload }) => {
      state.to = payload;
    },
    setSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    setFilters: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        [payload.filter]: payload.value,
      };
    },
    updateBusinessInvoice: (state, { payload }) => {
      state.invoices = state.invoices.map(invoice => (invoice._id === payload._id ? payload : invoice));
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBusinesses,
  setBusiness,
  createNewBusiness,
  updateNowBusiness,
  setAdminList,
  createNewBusinessAdmin,
  updateNowBusinessAdmin,
  setDeliveryServicesByBusiness,
  setClientList,
  setPaymentsByBusiness,
  setInvoicesByBusiness,
  setInvoicesIssuedByBusiness,
  setInvoicesReceivedByBusiness,
  setLimit,
  setPageIndex,
  setFrom,
  setTo,
  setSearchText,
  setFilters,
  updateBusinessInvoice,
} = businessesSlice.actions;

export default businessesSlice.reducer;
