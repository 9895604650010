import dashboardBackendApi from '../api/dashboardBackendApi';
import { handleAxiosError } from '../helpers/handleErrors';
import { serialize } from '../helpers/serialize';
import { setChats, setMessages } from '../store/slices/chatMessages';

export const getChatsMesagges = (filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const resp = await dashboardBackendApi.get<any>(`/support/chatmessages?${_filters}`);
    console.log('RESP', resp);
    dispatch(setChats({
      chats: resp.data.chats,
      totalCount: resp.data.totalCount,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const getChatsMesaggesByAdminId = (admimnId: string) => async (dispatch: any) => {
  console.log('@getChatsMesaggesByAdminId');
  try {
    // const _filters = serialize(filters);
    const resp = await dashboardBackendApi.get<any>(`/support/chatmessages/admin/${admimnId}`);
    console.log('RESP', resp);
    const messages = resp.data.messages.map((v: any) => {
      let senderId = 0;
      const currentUserId = 0;
      console.log('v', v);
      if (v.sender && v.sender.businessAdminId) {
        senderId = v.sender.businessAdminId;
        console.log('sender', senderId);
      } else {
        senderId = 0;
      }
      return ({
        sender: {
          thumb: '',
          name: '',
        },
        item: {
          sender: senderId,
          text: v.text,
        },
        // currentUserId: service?.user._id || 0,
        currentUserId,
      });
    });
    dispatch(setMessages({
      messages,
      // totalCount: resp.data.totalCount,
    }));
    // return resp;
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const sendChatMessage = (data: any) => async (dispatch: any) => {
  console.log('@sendChatMessage');
  try {
    const resp = await dashboardBackendApi.post<any>('/support/chatmessages/', data);
    console.log('response', resp);
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};
