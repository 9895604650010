import React, { FC } from 'react';
import { ModalRight } from '../../../components/modals/ModalRight';
import { Log } from '../../../interfaces';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  log: Log;
}

export const ModalLog: FC<Props> = ({ isOpen, setIsOpen, log }) => {
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  let content;

  try {
    const jsonData = JSON.parse(log.data);

    content = <pre>{JSON.stringify(jsonData, null, 2)}</pre>;
  } catch (error) {
    content = <p>{log.data}</p>;
  }

  return (
    <ModalRight
      isOpen={isOpen}
      toggleModal={toggleModal}
      title="Factura"
    >
      <div>
        <h5>
          ID: {log._id}
        </h5>
        <br />
        <p>Name: {log.name}</p>
        <p>Env: {log.env}</p>
        {content}
      </div>
    </ModalRight>
  );
};
