import React, { FC, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { ModalCenter } from '../../../components/modals';
import CreateTableData from '../../../helpers/createTableData';
import { DeliveryDriver } from '../../../interfaces';
import { InputSearch } from '../../../components/inputs';
import ResizableReactTable from '../../../components/ResizableReactTable';
import { getDeliveryDriversByCoordsZone } from '../../../services/deliveryDrivers';
import { IconFlask } from '../../../components/icons';
import { driverIsApproved } from '../../../helpers';
import { ButtonPrimary } from '../../../components/buttons';
import { addDriverToService, reAsignDriverToService } from '../../../services/deliveryServices';

const columns = [
  { Header: 'Sandbox', accessor: 'sandbox' },
  { Header: 'Nombre', accessor: 'firstName' },
  { Header: 'Apellido', accessor: 'lastName' },
  { Header: 'Teléfono', accessor: 'phoneNumber.number' },
  { Header: 'Acciones', accessor: 'actions' },
];

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: 'Asignar' | 'Reasignar';
  zoneId: string;
  coords: number[];
  serviceId: string;
}

export const ModalDrivers: FC<Props> = ({ isOpen, setIsOpen, title, zoneId, coords, serviceId }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<DeliveryDriver[]>([]);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [manualPageSize] = useState([10, 20, 50, 100]);
  const [limit, setLimit] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);

  const getFilters = () => {
    const filters: any = {};
    filters.limit = limit;
    filters.page = pageIndex + 1;
    if (searchText) {
      filters.search = searchText;
    }
    filters.sortBy = '_id:desc';
    return filters;
  };

  const fetchData = async () => {
    try {
      const filters = getFilters();
      const data = await getDeliveryDriversByCoordsZone({ coords, zoneId, filters });
      const newData: any[] = [];
      data?.deliveryDrivers.map((item: DeliveryDriver) => {
        const obj = {
          ...item,
          approved: driverIsApproved(item),
          sandbox: item.isSandbox === true ? <IconFlask color="#00E5B2" /> : '',
          actions: (
            <ButtonPrimary
              label={title}
              onClick={() => {
                if (title === 'Asignar') {
                  addToService(item._id);
                }
                if (title === 'Reasignar') {
                  reAsignToService(item._id);
                }
              }}
            />
          ),
        };
        newData.push(obj);
        return item;
      });
      setData(newData);
      setTotal(data?.totalDeliveryDrivers || 0);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchText, limit, pageIndex, title]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const reactTableData = CreateTableData(data, columns);

  const addToService = (driverId: string) => {
    dispatch(addDriverToService(driverId, serviceId));
    toggleModal();
  };
  const reAsignToService = (driverId: string) => {
    dispatch(reAsignDriverToService(driverId, serviceId));
    toggleModal();
  };

  return (
    <ModalCenter
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={title}
      size="xl"
    >
      <Container className="dashboard">
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="react-table__wrapper">
                  <div className="card__title">
                    <h5 className="bold-text">Repartidores</h5>
                  </div>
                </div>
                <InputSearch
                  value={searchText}
                  onChange={(e: any) => {
                    // setPageIndex(0);
                    setSearchText(e.target.value);
                  }}
                  placeholder="Introduce nombre, apellido, teléfono"
                  style={{
                    marginBottom: '5px',
                    width: '75%',
                  }}
                />
                {
                  data.length === 0 ? <h3>No hay info que se pueda mostrar</h3> : (
                    <ResizableReactTable
                      reactTableData={reactTableData}
                      totalRecords={total}
                      manualPageSize={manualPageSize}
                      limit={limit}
                      setLimit={l => setLimit(l)}
                      pageIndex={pageIndex}
                      setPageIndex={p => setPageIndex(p)}
                      totalNoOfPages={Math.ceil(total / limit)}
                    />
                  )
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </ModalCenter>
  );
};
