import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../store';
import ResizableReactTable from '../../../components/ResizableReactTable';
import { formatDate } from '../../../helpers';
import CreateTableData from '../../../helpers/createTableData';
import { AddToInbox, ButtonView } from '../../../components/buttons/ButtonsIcon';
import { addDriverToRequest, getDeliveryServices } from '../../../services/deliveryServices';

interface Props {
  driverId: string,
}

export const CardAddToRequest: FC<Props> = ({ driverId }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [data, setData] = useState<any[]>([]);

  const columns = [
    { Header: 'Fecha', accessor: 'createdAt' },
    { Header: 'Negocio', accessor: 'business.name' },
    { Header: 'Total', accessor: 'priceDetail.finalPriceTotal' },
    { Header: 'Status', accessor: 'status' },
    { Header: 'Acciones', accessor: 'actions' },
  ];

  const { deliveryServices, pageIndex } = useAppSelector(state => state.deliveryServices);
  const { isSandbox } = useAppSelector(state => state.app);

  const _addDriverToRequest = (serviceId: string) => {
    dispatch(addDriverToRequest(driverId, serviceId));
  };

  useEffect(() => {
    if (deliveryServices.length > 0) {
      const newData: any[] = [];
      deliveryServices.map((item: any) => {

        const obj = {
          ...item,
          actions: (
            <>
              <AddToInbox
                onClick={() => _addDriverToRequest(item._id)}
              />
              <ButtonView
                onClick={() => history.push(`/dashboard/services/${item._id}`)}
              />
            </>
          ),
          // status: changeStatusLabel(item.status, 'service'),
          // paymentMethod: changePaymentMethodLabel(item.paymentMethod),
          // transport: changeTransportLabel(item.transport),
        };
        if (obj.createdAt) {
          obj.createdAt = formatDate(item.createdAt);
        }
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [deliveryServices]);
  const getFilters = () => {
    const filters: any = {};
    filters.limit = 10;
    filters.page = 1;
    filters.sortBy = '_id:desc';
    filters.status = 'requestingDriver';
    filters.isSandbox = isSandbox ? 'yes' : 'no';
    return filters;
  };

  const fetchData = () => {
    try {
      const filters = getFilters();
      dispatch(getDeliveryServices(filters));
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [isSandbox]);

  const reactTableData = CreateTableData(data, columns);
  return (
    <Card>
      <CardBody>
        <div>
          <div>
            <h4>Añadir a request</h4>
          </div>
        </div>
        <ResizableReactTable
          reactTableData={reactTableData}
          // totalRecords={totalInvoices}
          manualPageSize={[10]}
          limit={100}
          setLimit={() => { }}
          pageIndex={pageIndex}
        // setPageIndex={setPageIndex}
        // totalNoOfPages={Math.ceil(totalInvoices / 10)}
        />
      </CardBody>
    </Card>
  );
};
