import {
  // useContext,
  // useEffect,
  useState,
  useRef,
  useEffect,
} from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { Row } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';
import SaySomething from './SaySomething';
import MessageCard from './MessageCard';
import { getUrl } from '../../helpers/cloudinary';

// import { backendBusinessPanel } from '../../services/axios/backendService';

// interface Chat {
//   id: string,
// }
const OpenChatButton = ({ name, profileImageId, onPress }) => {
  return (
    <button type="button" onClick={onPress} className="openChat">
      <Row className="top">
        <Colxx xxs="2" className="d-flex justify-center align-items-center">
          <div className="avatar">
            <img
              src={profileImageId ? getUrl(profileImageId) : 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/OOjs_UI_icon_userAvatar.svg/2048px-OOjs_UI_icon_userAvatar.svg.png'}
              alt="avatar"
            />
          </div>
        </Colxx>
        <Colxx xss="8" className="d-flex flex-column justify-content-start align-items-center">
          <span className="label">Administrador</span>
          <span className="name">{name}</span>
        </Colxx>
        <Colxx xss="2" className="d-flex align-items-center">
          <span className="icon">
            <i className="iconsminds-speach-bubble" />
          </span>
        </Colxx>
      </Row>
    </button>
  );
};
const Chat = ({ messages, onSubmitChatMessage, currentUser, recipient }) => {
  const [showChat, setShowChat] = useState(false);
  const scrollBarRef = useRef(null);

  const [chatMessages, setChatMessages] = useState(messages);
  const [messageInput, setMessageInput] = useState('');
  // const submitChatMessage = async ({ text }:{ text: any}) => {
  //   try {
  //     const data = {
  //       text,
  //     };
  //     const resp = await backendBusinessPanel.post(`/publicService/${id}/messages`, data);
  //   } catch (e) {
  //     console.log('', e);
  //   }
  // };
  console.log('recipient', recipient);
  useEffect(() => {
    if (messages) {
      console.log('@chat:messages', messages);
      setChatMessages(messages);
    }
  }, [messages]);

  const handleSendButtonClick = () => {
    const arr = [...chatMessages];
      const obj = {
        sender: {
          thumb: '',
          name: currentUser.name,
        },
        item: {
          sender: currentUser._id,
          // sender: service.user._id,
          text: messageInput,
        },
        // currentUserId: service.user._id,
        currentUserId: currentUser._id,
      };
      arr.push(obj);
      // submitChatMessage({ text: messageInput });
      onSubmitChatMessage({ text: messageInput });
      setChatMessages(arr);
      setMessageInput('');
      focusScrollBottom();
  };
  const openChat = async () => {
    console.log('@openChat');
    // const data = await loadMessages();
    setShowChat(true);
  };
  const closeChat = () => {
    setShowChat(false);
  };
  const focusScrollBottom = () => {
    setTimeout(() => {
      if (scrollBarRef.current) {
        scrollBarRef.current._ps.element.scrollTop = scrollBarRef.current._ps.contentHeight;
      }
    }, 100);
  };
  return (
    <div>
      {(!showChat && <OpenChatButton profileImageId={recipient.profileImageId} name={recipient.name} onPress={() => openChat()} />)}
      {showChat === true && (
        <div className="chatContainer">
          <Row className="top">
            <Colxx xxs="2" className="d-flex justify-content-center align-items-center">
              <div className="avatar">
                <img
                  src={recipient.profileImageId ? getUrl(recipient.profileImageId) : 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/OOjs_UI_icon_userAvatar.svg/2048px-OOjs_UI_icon_userAvatar.svg.png'}
                  alt="avatar"
                />
              </div>
              {/* <div className="avataxr"><img srch="" alt="avatar" /></div> */}
            </Colxx>
            <Colxx xxs="8" className="d-flex flex-column justify-content-center">
              <span className="label">Administrador</span>
              <span className="name">{recipient.name}</span>
            </Colxx>
            <Colxx xxs="2" className="d-flex align-items-center">
              <button className="closeChat" type="button" onClick={() => closeChat()}>
                <i className="iconsminds-close" />
              </button>
            </Colxx>
          </Row>
          <Row className="">
            <Colxx xxs="12" className="chat-app">
              <PerfectScrollbar
                ref={scrollBarRef}
                // containerRef={(ref) => {}}
                options={{ suppressScrollX: true, wheelPropagation: false }}
              >
                <>
                  {chatMessages.map((v, k) => (
                    <MessageCard
                      sender={v.sender}
                      item={v.item}
                      currentUserid={v.currentUserId}
                      key={v._id}
                    />
                  ))}                  
                </>             
              </PerfectScrollbar>
            </Colxx>
          </Row>
          <SaySomething
            placeholder="Escribe tu mensaje"
            messageInput={messageInput}
            handleChatInputPress={() => {}}
            handleChatInputChange={(e) => {
              setMessageInput(e.target.value);
            }}
            handleSendButtonClick={handleSendButtonClick}
          />
        </div>
      )}
    </div>
  );  
};
export const ChatChat = ({ messages, onSubmitChatMessage, currentUser, recipient, disabled = false }) => {
  const [showChat, setShowChat] = useState(false);
  const scrollBarRef = useRef(null);

  const [chatMessages, setChatMessages] = useState(messages || []);
  const [messageInput, setMessageInput] = useState('');
  // const submitChatMessage = async ({ text }:{ text: any}) => {
  //   try {
  //     const data = {
  //       text,
  //     };
  //     const resp = await backendBusinessPanel.post(`/publicService/${id}/messages`, data);
  //   } catch (e) {
  //     console.log('', e);
  //   }
  // };
  const getColor = (item) => {
    console.log('item', item);
    return item.backgroundColor || 'white';
  };
  useEffect(() => {
    if (messages) {
      // console.log('@chat:messages', messages);
      setChatMessages(messages);
    }
  }, [messages]);

  const handleSendButtonClick = () => {
    const arr = [...chatMessages];
      const obj = {
        sender: {
          thumb: '',
          name: currentUser.name,
        },
        item: {
          sender: currentUser._id,
          // sender: service.user._id,
          text: messageInput,
        },
        // currentUserId: service.user._id,
        currentUserId: currentUser._id,
      };
      arr.push(obj);
      // submitChatMessage({ text: messageInput });
      onSubmitChatMessage({ text: messageInput });
      setChatMessages(arr);
      setMessageInput('');
      focusScrollBottom();
  };
  const openChat = async () => {
    console.log('@openChat');
    // const data = await loadMessages();
    setShowChat(true);
  };
  const closeChat = () => {
    setShowChat(false);
  };
  const focusScrollBottom = () => {
    setTimeout(() => {
      if (scrollBarRef.current) {
        scrollBarRef.current._ps.element.scrollTop = scrollBarRef.current._ps.contentHeight;
      }
    }, 100);
  };
  return (
    <div className="chatComponentContainer">
      {/* TOP */}
      <Row className="top">
        <Colxx xxs="2" className="d-flex justify-content-center align-items-center">
          <div className="avatar">
            <img
              src={recipient.profileImageId ? getUrl(recipient.profileImageId) : 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/OOjs_UI_icon_userAvatar.svg/2048px-OOjs_UI_icon_userAvatar.svg.png'}
              alt="avatar"
            />
          </div>
          {/* <div className="avataxr"><img srch="" alt="avatar" /></div> */}
        </Colxx>
        <Colxx xxs="8" className="d-flex flex-column justify-content-center">
          <span className="label">Administrador</span>
          <span className="name">{recipient.name}</span>
        </Colxx>
      </Row>
      {/* MESSAGES */}
      <Row className="">
        <Colxx xxs="12" className="chat-app">
          <PerfectScrollbar
            ref={scrollBarRef}
            // containerRef={(ref) => {}}
            options={{ suppressScrollX: true, wheelPropagation: false }}
          >
            <>
              {chatMessages.map((v, k) => (
                <MessageCard
                  sender={v.sender} 
                  item={v.item}
                  currentUserid={v.currentUserId}
                  key={v._id}
                  bubbleColor={getColor(v)}
                />
              ))}                  
            </>             
          </PerfectScrollbar>
        </Colxx>
      </Row>
      {/* INPUT */}
      <SaySomething
        placeholder="Escribe tu mensaje"
        messageInput={messageInput}
        handleChatInputPress={() => {}}
        handleChatInputChange={(e) => {
          setMessageInput(e.target.value);
        }}
        handleSendButtonClick={handleSendButtonClick}
        disabled={disabled}
      />
    </div>
  );  
};

export default Chat;