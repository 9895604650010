/* eslint-disable no-restricted-syntax */

const GOOGLE_PLACES_MAPPINGS = {
  postalCode: 'postal_code',
  country: 'country',
  state: 'administrative_area_level_1',
  city: 'locality',
  neighborhood: 'sublocality',
  street: 'route',
  exterior: 'street_number',
  interior: 'interior',
};

export const extractData = (place: any) => {
  const data: any = {
    name: place.formatted_address,
    address: {
      longitude: place.geometry.location.lng(),
      latitude: place.geometry.location.lat(),
    },
  };

  for (const [key, mapping] of Object.entries(GOOGLE_PLACES_MAPPINGS)) {
    const match = place.address_components.find((comp: any) => comp.types.includes(mapping));
    data.address[key] = match ? match.long_name : '';
  }

  return data;
};
