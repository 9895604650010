import { createSlice } from '@reduxjs/toolkit';

interface Props {
  className: string;
}

const initialState: Props = {
  className: 'theme-light',
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    changeThemeToDark: (state) => {
      state.className = 'theme-dark';
    },
    changeThemeToLight: (state) => {
      state.className = 'theme-light';
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeThemeToDark, changeThemeToLight } = themeSlice.actions;

export default themeSlice.reducer;
