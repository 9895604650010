import { useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

interface Props {
  label: string;
  onClick: (option: any) => void;
  options: any;
  selectedKey: string;
}

export const CustomDropdown = ({ label, onClick, options, selectedKey }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const selectedValue = options && options[selectedKey] ? options[selectedKey] : '--';
  return (
    <div>
      <p style={{ minHeight: '20.8px' }}>{label}</p>
      <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
        <DropdownToggle className="icon icon--right" outline>
          <p>{selectedValue} <ChevronDownIcon /></p>
        </DropdownToggle>
        <DropdownMenu className="dropdown__menu">
          {
            Object.keys(options).map(option => (
              <DropdownItem
                key={option}
                onClick={() => onClick(option)}
              >
                {options[option]}
              </DropdownItem>
            ))
          }
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
