import dashboardBackendApi from '../api/dashboardBackendApi';
import { handleAxiosError } from '../helpers/handleErrors';
import { serialize } from '../helpers/serialize';
import { LogResponse, LogsResponse } from '../interfaces';
import { setLog, setLogs } from '../store/slices/logs';

export const getLogs = (filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const resp = await dashboardBackendApi.get<LogsResponse>(`/support/logs?${_filters}`);
    dispatch(setLogs({
      logs: resp.data.logs,
      totalLogs: resp.data.totalLogs,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const getLog = (id: string) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.get<LogResponse>(`/support/logs/${id}`);
    dispatch(setLog({
      log: resp.data.log,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};
