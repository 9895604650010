import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Admin } from '../../../interfaces';
import { loginSuccessType } from './types';

interface AuthState {
  admin: Admin | null;
  jwtAccessToken: string | null;
  userType: string | null;
  loading: boolean;
  errorMessage: string | null;
}

const initialState: AuthState = {
  admin: null,
  jwtAccessToken: null,
  userType: null,
  loading: false,
  errorMessage: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<loginSuccessType>) => {
      state.admin = action.payload.admin;
      state.jwtAccessToken = action.payload.jwtAccessToken;
      state.userType = action.payload.userType;
      state.loading = false;
      state.errorMessage = null;
    },
    loginFailed: (state, { payload }: any) => {
      state.admin = null;
      state.loading = false;
      state.errorMessage = payload.errorMessage;
    },
    logout: (state) => {
      state.jwtAccessToken = null;
      state.admin = null;
      state.errorMessage = null;
    },
    cleanErrorMessage: (state) => {
      state.errorMessage = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loginSuccess, loginFailed, logout, cleanErrorMessage } = authSlice.actions;

export default authSlice.reducer;
