import { FC, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { DeliveryService } from '../../interfaces';
import { getDeliveryServicesByStatus } from '../../services/deliveryServices';
import TableCard from './components/TableCard';
import { useAppSelector } from '../../store';

type DataObject = {
  data: DeliveryService[];
  totalRecords: number;
};

interface Data {
  noDrivers: DataObject;
  accepted: DataObject;
  onRoute: DataObject;
  canceled: DataObject;
  done: DataObject;
  requestingDriver: DataObject;
  scheduled: DataObject;
  pendingPaid: DataObject;
}

const columnsForNoDrivers = [
  { Header: 'Fecha de creación', accessor: 'createdAt' },
  { Header: 'Tipo', accessor: 'type' },
  { Header: 'Negocio', accessor: 'business.name' },
  { Header: 'Sucursal', accessor: 'business.businessEstablishmentName' },
  { Header: 'Transporte', accessor: 'transport' },
  { Header: 'Total', accessor: 'priceDetail.finalPriceTotal' },
  { Header: 'Acciones', accessor: 'actions' },
];

const columnsForScheduled = [
  { Header: 'Fecha de creación', accessor: 'createdAt' },
  { Header: 'Fecha de programación', accessor: 'scheduledAt' },
  { Header: 'Tipo', accessor: 'type' },
  { Header: 'Negocio', accessor: 'business.name' },
  { Header: 'Sucursal', accessor: 'business.businessEstablishmentName' },
  { Header: 'Transporte', accessor: 'transport' },
  { Header: 'Precio Máximo', accessor: 'maxPrice' },
  { Header: 'Acciones', accessor: 'actions' },
];

const Home: FC = () => {
  const { app } = useAppSelector(state => state);
  const [data, setData] = useState<Data>({
    noDrivers: {
      data: [],
      totalRecords: 0,
    },
    accepted: {
      data: [],
      totalRecords: 0,
    },
    onRoute: {
      data: [],
      totalRecords: 0,
    },
    canceled: {
      data: [],
      totalRecords: 0,
    },
    done: {
      data: [],
      totalRecords: 0,
    },
    requestingDriver: {
      data: [],
      totalRecords: 0,
    },
    scheduled: {
      data: [],
      totalRecords: 0,
    },
    pendingPaid: {
      data: [],
      totalRecords: 0,
    },
  });

  const fetchData = async () => {
    const [noDrivers, accepted, onRoute, canceled, done, requestingDriver, scheduled, pendingPaid] = await Promise.all([
      getDeliveryServicesByStatus('noDrivers', app.isSandbox),
      getDeliveryServicesByStatus('accepted', app.isSandbox),
      getDeliveryServicesByStatus('onRoute', app.isSandbox),
      getDeliveryServicesByStatus('canceled', app.isSandbox),
      getDeliveryServicesByStatus('done', app.isSandbox),
      getDeliveryServicesByStatus('requestingDriver', app.isSandbox),
      getDeliveryServicesByStatus('scheduled', app.isSandbox),
      getDeliveryServicesByStatus('pendingPaymentLink', app.isSandbox),
    ]);
    setData({
      noDrivers: { ...noDrivers },
      accepted: { ...accepted },
      onRoute: { ...onRoute },
      canceled: { ...canceled },
      done: { ...done },
      requestingDriver: { ...requestingDriver },
      scheduled: { ...scheduled },
      pendingPaid: { ...pendingPaid },
    });
  };

  useEffect(() => {
    fetchData();
    return () => setData({
      noDrivers: {
        data: [],
        totalRecords: 0,
      },
      accepted: {
        data: [],
        totalRecords: 0,
      },
      onRoute: {
        data: [],
        totalRecords: 0,
      },
      canceled: {
        data: [],
        totalRecords: 0,
      },
      done: {
        data: [],
        totalRecords: 0,
      },
      requestingDriver: {
        data: [],
        totalRecords: 0,
      },
      scheduled: {
        data: [],
        totalRecords: 0,
      },
      pendingPaid: {
        data: [],
        totalRecords: 0,
      },
    });
  }, [app.isSandbox]);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Home</h3>
        </Col>
      </Row>
      <Row>
        <TableCard
          label="Sin Repartidores"
          {...data.noDrivers}
          columnsTable={columnsForNoDrivers}
        />
      </Row>
      <Row>
        <TableCard
          label="Buscando Repartidores"
          {...data.requestingDriver}
        />
      </Row>
      <Row>
        <TableCard
          label="Aceptados"
          {...data.accepted}
        />
      </Row>
      <Row>
        <TableCard
          label="En camino"
          {...data.onRoute}
        />
      </Row>
      <Row>
        <TableCard
          label="Cancelados"
          {...data.canceled}
        />
      </Row>
      <Row>
        <TableCard
          label="Terminados"
          {...data.done}
        />
      </Row>
      <Row>
        <TableCard
          label="Programados"
          {...data.scheduled}
          columnsTable={columnsForScheduled}
        />
      </Row>
      <Row>
        <TableCard
          label="Pendientes de pago"
          {...data.pendingPaid}
        />
      </Row>
    </Container>
  );
};

export default Home;
