import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import ResizableReactTable from '../../components/ResizableReactTable';
import { formatDateTime } from '../../helpers';
import CreateTableData from '../../helpers/createTableData';
import { getTemplates, updateTemplates } from '../../services/whatsapp';
import { useAppDispatch, useAppSelector } from '../../store';
import { ButtonView } from '../../components/buttons/ButtonsIcon';
import { ButtonPrimary } from '../../components/buttons';


const columns = [
  { Header: 'Fecha', accessor: 'createdAt' },
  { Header: 'Nombre', accessor: 'name' },
  { Header: 'Contenido', accessor: 'body' },
  { Header: 'Status', accessor: 'status' },
  { Header: 'Acciones', accessor: 'actions' },
];

const WhatsAppTemplates: FC = () => {
  const { templates, totalTemplates } = useAppSelector(state => state.whatsapp);
  const dispatch = useAppDispatch();
  const history = useHistory();

  // const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [manualPageSize] = useState([10, 20, 50, 100]);
  const [limit, setLimit] = useState(manualPageSize[0]);
  const [pageIndex, setPageIndex] = useState(0);


  const getFilters = () => {
    const filters: any = {};
    filters.limit = limit;
    filters.page = pageIndex + 1;
    filters.sortBy = '_id:desc';
    return filters;
  };

  const getAll = () => {
    try {
      const filters = getFilters();
      // dispatch(getChatsMesagges(filters));
      dispatch(getTemplates(filters));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAll();
  }, [limit, pageIndex]);


  useEffect(() => {
    if (templates.length > 0) {
      const newData: any[] = [];
      templates.map((item: any) => {
        const obj = {
          key: item._id,
          ...item,
          actions: (
            <>
              <ButtonView
                onClick={() => { }}
              />
            </>
          ),
          createdAt: formatDateTime(item.createdAt),
          name: item.name,
          body: item.body,
          status: item.status,
        };
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [templates]);

  const reactTableData = CreateTableData(data, columns);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title">
                  <h5 className="bold-text">Templates</h5>
                </div>
                <div>
                  <ButtonPrimary
                    label="Actualizar templates"
                    onClick={() => dispatch(updateTemplates())}
                  />
                  <ButtonPrimary
                    label="Crear nuevo"
                    onClick={() => history.push('/dashboard/whatsapptemplates/form')}
                  />
                </div>
              </div>
              {
                data.length === 0 ? <h3> No hay info que se pueda mostrar</h3> : (
                  <ResizableReactTable
                    reactTableData={reactTableData}
                    totalRecords={totalTemplates}
                    manualPageSize={manualPageSize}
                    limit={limit}
                    setLimit={setLimit}
                    pageIndex={pageIndex}
                    setPageIndex={setPageIndex}
                    totalNoOfPages={Math.ceil(totalTemplates / limit)}
                  />
                )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default WhatsAppTemplates;
