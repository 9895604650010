import React, { FC, useState } from 'react';
// import { useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { FormApi, SubmissionErrors } from 'final-form';
import { Button } from 'reactstrap';
import { InputField } from '../../../components/form/InputField';
import { ButtonPrimary } from '../../../components/buttons/ButtonPrimary';
import { PasswordInputField } from '../../../components/form/PasswordInputField';
// import { RootState } from '../../../store';
// import { DropdownInputField } from '../../../components/form';
import { DeliveryDriver } from '../../../interfaces';

interface Props {
  onSubmit: (values: any, form: FormApi<any, any>, callback?: ((errors?: SubmissionErrors) => void) | undefined) => void;
  deliveryDriver?: DeliveryDriver | null;
  setZone: React.Dispatch<React.SetStateAction<{}>>;
}

export const FormDeliveryDriver: FC<Props> = ({ onSubmit, deliveryDriver = null }) => {
  // const { zones } = useSelector((state: RootState) => state.zones);
  const [showPasswordField, setShowPasswordField] = useState(false);
  const validate = (values: any) => {
    const errors: any = {};
    if (!values.firstName) {
      errors.firstName = 'El nombre es obligatorio';
    }
    if (!values.lastName) {
      errors.lastName = 'El apellido es obligatorio';
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = 'El celular es obligatorio';
    }
    if (!deliveryDriver) {
      if (!values.password) {
        errors.password = 'La contraseña es obligatoria';
      }
    }
    if (showPasswordField) {
      if (!values.password) {
        errors.password = 'La contraseña es obligatoria';
      }
    }
    return errors;
  };

  // const optionsZones = zones.map((zone) => {
  //   return {
  //     value: zone._id,
  //     label: zone.name,
  //   };
  // });

  // const onSelectZone = (zone: any) => {
  //   setZone({
  //     _id: zone.value,
  //     name: zone.label,
  //   });

  //   return zone;
  // };

  return (
    <Form onSubmit={onSubmit} validate={validate} initialValues={deliveryDriver}>
      {({ handleSubmit }) => (
        <form className="form" onSubmit={handleSubmit}>
          <InputField
            label="Nombre"
            name="firstName"
            placeholder="Nombre del repartidor"
          />
          <InputField
            label="Apellido"
            name="lastName"
            placeholder="Apellidos del repartidor"
          />
          <InputField
            label="Celular"
            name="phoneNumber.number"
            placeholder="Celular del repartidor"
          />
          <InputField
            label="Visible"
            name="isVisible"
            type="checkbox"
          />
          <InputField
            label="Sandbox"
            name="isSandbox"
            type="checkbox"
          />
          <InputField
            label="Fundador"
            name="isFounder"
            type="checkbox"
          />
          {/* <DropdownInputField
            label="Zona"
            name="state"
            options={optionsZones}
            placeholder={deliveryDriver?.zone?.name ? deliveryDriver?.zone?.name : 'Selecciona una zona'}
            onSelect={onSelectZone}
          /> */}
          <InputField
            label="Correo"
            name="email"
            placeholder="Correo del repartidor"
          />
          {
            deliveryDriver && !showPasswordField ? (
              <ButtonPrimary
                label="Cambiar password"
                onClick={() => setShowPasswordField(true)}
              />
            ) : (
              <PasswordInputField
                label="Contraseña"
                name="password"
                placeholder="Contraseña del repartidor"
              />
            )
          }
          <div>
            <Button
              size="sm"
              color="primary"
              type="submit"
            >
              {deliveryDriver ? 'Editar repartidor' : 'Crear repartidor'}
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};
