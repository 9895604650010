import { useState, useEffect } from 'react';
import backendApi from '../../../api/backendApi';
// import NotificationManager from '../../../presentational/common/react-notifications/NotificationManager';

export const usePrice = (state: any) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const [price, setPrice] = useState<any>(null);

  const fetchData = async () => {
    try {
      const { data } = await backendApi.post('/api/deliveryservice/price', state);

      if (!data.success) throw new Error(data.message);
      console.log(data);

      setPrice(data.data.priceDetail);
    } catch (error) {
      console.error({ error });
      setError(String(error));
      // NotificationManager.error(
      //   'Could not fetch price',
      //   'Error',
      //   3000,
      //   null,
      //   null,
      //   'filled'
      // );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { fetchData(); }, []);

  return { price, loading, error };
};
