/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useState } from 'react';
import {
  Card,
  CardBody,
  Collapse,
} from 'reactstrap';
import s from './styles.module.scss';
import { DeliveryService } from '../../../interfaces';

interface Props {
  service: DeliveryService,
}

export const CardPaymentDetail: FC<Props> = ({ service }) => {

  const [isOpen, setIsOpen] = useState(false);

  const { priceDetail, acceptedPriceDetail, maxPrice, minPrice, priorityFee } = service;

  const VAT = (priceDetail.finalPriceTotal * priceDetail.deliveryVAT) / 100;

  return (
    <Card>
      <CardBody>
        <div className="c-pointer" onClick={() => setIsOpen(!isOpen)}>
          <h3>
            Detalle del pago
          </h3>
        </div>
        <Collapse isOpen={isOpen}>
          <div>
            <ul className={s.table}>
              <li>
                <p>Max price</p>
                <span>${maxPrice}</span>
              </li>
              <li>
                <p>Min price</p>
                <span>${minPrice}</span>
              </li>
              <li>
                <p>PriorityFeee</p>
                <span>${priorityFee}</span>
              </li>
            </ul>
            <ul className={s.table}>
              <li>
                <p>Subtotal Delivery Aceptado</p>
                <span>${acceptedPriceDetail?.deliveryTotal}</span>
              </li>
              <li>
                <p>Tarifa Subtotal Delivery Aceptada</p>
                <span>${acceptedPriceDetail?.deliveryFeeTotal}</span>
              </li>
              <li>
                <p>Precio Final Aceptado</p>
                <span>${acceptedPriceDetail?.finalPriceTotal}</span>
              </li>
              <li>
                <p>IVA</p>
                <span>${VAT.toFixed(2)}</span>
              </li>
              {/* <li>
              <p>Precio Final Total</p>
              <span>${(priceDetail.finalPriceTotal + VAT).toFixed(2)}</span>
            </li> */}
            </ul>
          </div>
        </Collapse>
      </CardBody>
    </Card>
  );
};
