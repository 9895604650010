import { FC, useEffect, useState } from 'react';
import { ModalCenter } from '../../../components/modals';
import { useAppDispatch, useAppSelector } from '../../../store';
import { sendMessageToDrivers } from '../../../services/deliveryDrivers';
import { FormSendMessage } from '../../../components/form';
import { parseWhatsAppData } from '../../../helpers';
import { getTemplates } from '../../../services/whatsapp';
import { ComponentWhatsApp, WhatsAppTemplate } from '../../../interfaces';

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  filters?: any;
}

export const ModalSendMessage: FC<Props> = ({ isOpen, setIsOpen, filters = null }) => {
  delete filters.limit;
  delete filters.page;
  delete filters.sortBy;
  const dispatch = useAppDispatch();
  const { templates } = useAppSelector(state => state.whatsapp);

  const [channel, setChannel] = useState<'Push' | 'WA' | 'SMS'>('Push');
  const [template, setTemplate] = useState<WhatsAppTemplate>();
  const [componentHeader, setComponentHeader] = useState<ComponentWhatsApp[]>([]);
  const [componentBody, setComponentBody] = useState<ComponentWhatsApp[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (channel === 'WA') {
        dispatch(getTemplates({ sortBy: '_id:desc' }));
      }
    };
    fetchData();
  }, [channel]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onSubmit = (data: any) => {
    data.channel = channel;
    if (channel === 'WA') {
      const obj = parseWhatsAppData({
        templateName: template?.name || '',
        componentHeader,
        componentBody,
        languageCode: template?.languageCode || '',
        buttons: template?.buttons || [],
      });
      data = { ...data, ...obj };
    }
    dispatch(sendMessageToDrivers(filters, data));
    setIsOpen(false);
  };

  return (
    <ModalCenter
      isOpen={isOpen}
      toggleModal={toggleModal}
      title="Enviar mensajes"
    >
      <FormSendMessage
        onSubmit={onSubmit}
        channel={channel}
        setChannel={setChannel}
        templates={templates}
        template={template}
        setTemplate={setTemplate}
        componentHeader={componentHeader}
        setComponentHeader={setComponentHeader}
        componentBody={componentBody}
        setComponentBody={setComponentBody}
      />
    </ModalCenter>
  );
};
