import { FC } from 'react';
import { RWebShare } from 'react-web-share';
import { Card, CardBody, Button } from 'reactstrap';
import { formatDate } from '../../../helpers';
import { useAppSelector } from '../../../store';

interface Props { }

export const CardInfo: FC<Props> = () => {

  const { admin } = useAppSelector(state => state.auth);

  if (!admin) {
    return (
      <Card>
        <CardBody className="profile__card">
          <div className="profile__information">
            <p>cargando...</p>
          </div>
        </CardBody>
      </Card>
    );
  }

  const { firstName, lastName, createdAt, email, phone, referralCode } = admin;


  return (
    <Card>
      <CardBody className="profile__card">
        <div className="profile__information">
          <div className="profile__data">

            <p className="profile__name">{`${firstName} ${lastName}`}</p>
            {createdAt && <p className="profile__work">Fecha de registro {formatDate(createdAt)}</p>}
            <p className="profile__contact">{email}</p>
            <p className="profile__contact" dir="ltr">
              {phone?.number}
            </p>
            {
              referralCode && (
                <div className="d-flex justify-content-between align-items-center">
                  <p className="profile__contact" dir="ltr">
                    Código de referido: {referralCode}
                  </p>
                  <div>
                    <RWebShare
                      data={{
                        text: 'Link de referido',
                        url: `https://negocios.repartimos.mx/user/register?referralCode=${referralCode}`,
                        title: 'Código de referido',
                      }}
                      onClick={() => console.log('shared successfully!')}
                    >
                      <Button>Compartir URL 🔗</Button>
                    </RWebShare>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
