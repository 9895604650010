
import { FC } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../store/index';
import { payProducts } from '../../../services/deliveryDrivers';

interface Props {
}

export const CardAccounyBalance: FC<Props> = () => {
  const { deliveryDriver } = useAppSelector(state => state.deliveryDrivers);
  const { isSandbox } = useAppSelector(state => state.app);

  const dispatch = useAppDispatch();

  const pay = async (isSandbox: boolean) => {
    if (deliveryDriver) {
      dispatch(payProducts({ driverId: deliveryDriver._id, isSandbox }));
    }
  };

  if (!deliveryDriver) {
    return (
      <Card>
        <CardBody className="profile__card">
          <div className="profile__information">
            <p>cargando...</p>
          </div>
        </CardBody>
      </Card>
    );
  }

  const defaultValues = {
    income1: '--',
    income2: '--',
    income3: '--',
    expense: '--',
    transactionBalance: '--',
    totalInvoicePending: '--',
    revenue: '--',
    productsAmountDeposited: '--',
    productsAmountIncome: '--',
    productBalance: '--',
    balance: '--',
  };
  const accountBalanceSandbox = deliveryDriver.accountBalanceSandbox || defaultValues;
  const accountBalance = deliveryDriver.accountBalance || defaultValues;
  return (
    <Card>
      {
        isSandbox ? (
          <CardBody>
            <h4>Account balance (sandbox)</h4>
            <p>
              <strong>Income1</strong> {accountBalanceSandbox.income1}
            </p>
            <p>
              <strong>Income2</strong> {accountBalanceSandbox.income2}
            </p>
            <p>
              <strong>Income3</strong> {accountBalanceSandbox.income3}
            </p>
            <p>
              <strong>Expense</strong> {accountBalanceSandbox.expense}
            </p>
            <p>
              <strong>TransactionBalance</strong> {accountBalanceSandbox.transactionBalance}
            </p>
            <p>
              <strong>TotalInvoicePending</strong> {accountBalanceSandbox.totalInvoicePending}
            </p>
            <p>
              <strong>Revenue</strong> {accountBalanceSandbox.revenue}
            </p>

            <br />
            <h4>Productos</h4>
            <p>
              <strong>productsAmountDeposited</strong> {accountBalanceSandbox.productsAmountDeposited}
            </p>
            <p>
              <strong>productsAmountIncome</strong> {accountBalanceSandbox.productsAmountIncome}
            </p>
            <p>
              <strong>productBalance</strong> {accountBalanceSandbox.productBalance}
            </p>
            <p>
              <strong>balance</strong> {accountBalanceSandbox.balance}
            </p>

            <br />
            <Button onClick={() => pay(true)} disabled={accountBalance.productBalance === 0}>Pagar productos (sandbox)</Button>
          </CardBody>
        ) : (
          <CardBody>
            <h4>Account balance</h4>
            <p>
              <strong>Income1</strong> {accountBalance.income1}
            </p>
            <p>
              <strong>Income2</strong> {accountBalance.income2}
            </p>
            <p>
              <strong>Income3</strong> {accountBalance.income3}
            </p>
            <p>
              <strong>Expense</strong> {accountBalance.expense}
            </p>
            <p>
              <strong>TransactionBalance</strong> {accountBalance.transactionBalance}
            </p>
            <p>
              <strong>TotalInvoicePending</strong> {accountBalance.totalInvoicePending}
            </p>
            <p>
              <strong>Revenue</strong> {accountBalance.revenue}
            </p>

            <br />
            <h4>Productos</h4>
            <p>
              <strong>productsAmountDeposited</strong> {accountBalance.productsAmountDeposited}
            </p>
            <p>
              <strong>productsAmountIncome</strong> {accountBalance.productsAmountIncome}
            </p>
            <p>
              <strong>productBalance</strong> {accountBalance.productBalance}
            </p>
            <p>
              <strong>balance</strong> {accountBalance.balance}
            </p>
            <br />
            <Button onClick={() => pay(false)} disabled={accountBalance.productBalance === 0}>Pagar productos</Button>
          </CardBody>
        )
      }
    </Card>
  );
};
