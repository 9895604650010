import { CSSProperties, FC } from 'react';
import { GoogleMap } from '@react-google-maps/api';

interface Props {
  style?: CSSProperties;
  center?: {
    lat: number;
    lng: number;
  }
}

const defaultCenter = {
  lat: 20.7167,
  lng: -103.4,
};

const defaultStyle: CSSProperties = {
  width: '350px',
  height: '350px',
};

export const MyGoogleMaps: FC<Props> = ({ style = defaultStyle, center = defaultCenter, children }) => {
  return (
    <GoogleMap
      mapContainerStyle={style}
      zoom={13}
      center={center}
    >
      {children}
    </GoogleMap>
  );
};
