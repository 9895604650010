import { FC } from 'react';
import { ModalRight } from '../../../components/modals/ModalRight';
import { FormBankData } from '../../../components/form';
import { BankData } from '../../../interfaces';
import { useAppDispatch } from '../../../store';
import { createBankData, updateBankData } from '../../../services/bankData';

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  bankData: BankData | null;
  driverId: string;
}

export const ModalBankData: FC<Props> = ({ isOpen, setIsOpen, bankData = null, driverId }) => {
  const dispatch = useAppDispatch();
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onSubmit = (data: any) => {
    data.accountType = data.accountType.value;
    data.deliveryDriverId = driverId;
    console.log(data);
    if (bankData) {
      dispatch(updateBankData(data, 'driver'));
    } else {
      dispatch(createBankData(data, 'driver'));
    }
    setIsOpen(false);
  };

  return (
    <ModalRight
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={bankData ? 'Editar información' : 'Nueva información'}
    >
      <FormBankData onSubmit={onSubmit} bankData={bankData} />
    </ModalRight>
  );
};
