import dashboardBackendApi from '../api/dashboardBackendApi';
import { serialize } from '../helpers/serialize';
import { createNewBusinessEstablishment, setBusinessEstablishments, setBusinessEstablishmentsByBusiness, updateNowBusinessEstablishment } from '../store/slices/businessEstablishments';
import { BusinessEstablishmentsResponse } from '../interfaces/businessEstablishment';
import { handleAxiosError } from '../helpers/handleErrors';

export const createEstablishment = (establishment: any) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.post('/support/businessEstablishments', establishment);
    dispatch(createNewBusinessEstablishment({
      businessEstablishment: resp.data.businessEstablishment,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const updateEstablishment = (id: string, establishment: any) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.put(`/support/businessEstablishments/${id}`, establishment);
    dispatch(updateNowBusinessEstablishment({
      id,
      businessEstablishment: resp.data.businessEstablishment,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const getEstablishmentsByBusinessId = (id: string, filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const resp = await dashboardBackendApi.get<BusinessEstablishmentsResponse>(`/support/businessEstablishments/business/${id}?${_filters}`);
    dispatch(setBusinessEstablishmentsByBusiness({
      businessEstablishments: resp.data.businessEstablishments,
      totalBusinessEstablishments: resp.data.totalBusinessEstablishments,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const updateApprovedOlaClickEstablishment = (id: string, approved: boolean) => async (dispatch: any) => {
  try {
    const { data } = await dashboardBackendApi.post(`/support/businessEstablishments/${id}/olaClick/approved`, { approved });
    dispatch(updateNowBusinessEstablishment({
      id,
      businessEstablishment: data.businessEstablishment,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const getEstablishments = (filters = {}) => async (dispatch: any) => {
  console.log('@getEstablishments');
  try {
    const _filters = serialize(filters);
    const { data } = await dashboardBackendApi.get<BusinessEstablishmentsResponse>(`/support/businessEstablishments?${_filters}`);
    dispatch(setBusinessEstablishments({
      businessEstablishments: data.businessEstablishments,
      totalBusinessEstablishments: data.totalBusinessEstablishments,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const sendTemplateToAdmins = (filters = {}, obj: any) => async (dispatch: any) => {
  console.log('@sendTemplateToAdmins');
  try {
    const _filters = serialize(filters);
    const { data } = await dashboardBackendApi.post<any>(`/support/businessEstablishments/sendTemplate?${_filters}`, obj);
    console.log(data);
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};
