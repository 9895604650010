/* eslint-disable no-nested-ternary */
import { FC, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Tippy from '@tippyjs/react';
import { LocationDetailsForm, LocationSearch } from './';
import { ButtonEdit } from '../../../components/buttons/ButtonsIcon';

interface Props {
  id: string;
  index: number;
  onSelect: (data: any) => void;
  value: any;
  onDelete: (index: number) => void;
  deleteEnabled: boolean;
}

export const LocationSelectorInput: FC<Props> = ({
  id,
  index,
  onDelete,
  onSelect,
  value,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isSorting,
  } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [showForm, setShowForm] = useState(false);

  return (
    <div
      className="location-selector-input"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {showForm && (
        <div className="form">
          <LocationDetailsForm
            value={value}
            onSelect={data => onSelect(data)}
            index={index}
            onHide={() => setShowForm(false)}
          />
        </div>
      )}

      <div className="counter-container">
        {index !== 0 && (
          <span className={`line ${(isSorting && 'active').toString()}`} />
        )}
        <Tippy
          content="Puedes arrastrar las locaciones para reordenarlas"
          placement="right"
        >
          <span className="counter">{index + 1}</span>
        </Tippy>
      </div>
      <div className="searchbar">
        <LocationSearch value={value} onSelect={onSelect} />
      </div>
      <div className="btns-container">
        <ButtonEdit
          onClick={() => setShowForm(!showForm)}
        />
        {index >= 2 && (
          <button
            type="button"
            className="delete-btn"
            onClick={() => onDelete(index)}
          >
            x
          </button>
        )}
      </div>
    </div>
  );
};
