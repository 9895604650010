import { FC, useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { ButtonView } from '../../../components/buttons/ButtonsIcon';
import ResizableReactTable from '../../../components/ResizableReactTable';
import CreateTableData from '../../../helpers/createTableData';
import { useAppDispatch, useAppSelector } from '../../../store';
import { DeliveryService } from '../../../interfaces';
import { changeStatusLabel, formatDateTime } from '../../../helpers';
import { getDeliveryServicesByBusinessByHunter } from '../../../services/hunter/business';

interface Props {
  from: any;
  to: any;
}

export const CardDeliveryServices: FC<Props> = ({ from, to }) => {
  const { business, businessDeliveryServices, totalBusinessDeliveryServices } = useAppSelector(state => state.businesses);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [data, setData] = useState<DeliveryService[]>([]);
  const [pageIndex, setPageIndex] = useState(0);
  const columns = [
    { Header: 'Fecha', accessor: 'createdAt' },
    { Header: 'Status', accessor: 'status' },
  ];

  const getFilters = () => {
    const filters: any = {};
    filters.page = pageIndex + 1;
    filters.limit = 10;
    filters.sortBy = '_id:desc';
    if (from && to) {
      filters.from = from;
      filters.to = to;
    }
    return filters;
  };

  useEffect(() => {
    if (business) {
      const filters = getFilters();
      dispatch(getDeliveryServicesByBusinessByHunter(business._id, filters));
    }
  }, [business, pageIndex, from, to]);

  useEffect(() => {
    if (businessDeliveryServices.length > 0) {
      const newData: any[] = [];
      businessDeliveryServices.map((item) => {
        const obj = {
          ...item,
          createdAt: formatDateTime(item.createdAt),
          actions: (
            <ButtonView
              onClick={() => history.push(`/dashboard/services/${item._id}`)}
            />
          ),
          status: changeStatusLabel(item.status, 'service'),
          maxPrice: `$${(item.maxPrice || 0).toFixed(2)} MXN`,
          acceptedPriceDetail: {
            finalPriceTotal: `$${(item.acceptedPriceDetail?.finalPriceTotal || 0).toFixed(2)} MXN`,
          },
        };
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [businessDeliveryServices]);

  const reactTableData = CreateTableData(data, columns);
  return (
    <Card>
      <CardBody>
        <div>
          <div>
            <h4>Servicios</h4>
          </div>
        </div>
        <ResizableReactTable
          reactTableData={reactTableData}
          totalRecords={totalBusinessDeliveryServices}
          manualPageSize={[10]}
          limit={10}
          setLimit={() => { }}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          totalNoOfPages={Math.ceil(totalBusinessDeliveryServices / 10)}
        />
      </CardBody>
    </Card>
  );
};
