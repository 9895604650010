import { FC } from 'react';
import { Field } from 'react-final-form';
import renderCheckBoxField from '../shared/form/CheckBox';

interface Props {
  name: string;
  label: string;
  disabled?: boolean;
  className?: string;
  defaultChecked?: boolean;
}

export const CheckBoxField: FC<Props> = ({ name, label, defaultChecked = true, disabled = false, className = '' }) => {
  return (
    <div className="form__form-group form__form-group-field">
      <Field
        name={name}
        type="checkbox"
        component={renderCheckBoxField}
        label={label}
        initialValue={defaultChecked}
        disabled={disabled}
        className={className}
      />
    </div>
  );
};
