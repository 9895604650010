import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, Button } from 'reactstrap';
import { RootState } from '../../../store/index';
import { formatDate } from '../../../helpers/date';
import s from './styles.module.scss';

interface Props {
  apiDeveloper: any,
  payAction: any,
}

export const CardApiDeveloperInfo: FC<Props> = ({ apiDeveloper, payAction }) => {
  // const { apiDeveloper } = useSelector((state: RootState) => state.apiDevelopers);
  // const businesses = apiDeveloper.businessIds.map((v: any) => <a href={`/dashboard/businesses/${v}`}>Ir a Negocio</a>);
  return (
    <Card>
      <CardBody>
        <div className={s.container}>
          <div>
            <h3>
              {apiDeveloper?.name} {!apiDeveloper?.sandboxMode ? '(PROD)' : ''}
            </h3>
            <span>
              {apiDeveloper?.description}
            </span>
          </div>
        </div>
        <div>
          <div>
            <p>App Id: <span>{apiDeveloper?.appId}</span></p>
          </div>
          <div>
            <p>App Secret: <span>{apiDeveloper?.appSecret}</span></p>
          </div>
          <div>
            <p>Activo: <span>{apiDeveloper?.isActive ? 'Si' : 'No'}</span></p>
          </div>
          <div>
            <p>Modo Sandbox : <span>{apiDeveloper?.sandboxMode ? 'Si' : 'No'}</span></p>
          </div>
          <div>
            <p>Fecha de creación: <span>{formatDate(apiDeveloper?.createdAt)}</span></p>
          </div>
          <div>
            <p>Adeudo: <span>{apiDeveloper?.negativeCredit || 0}</span></p>
            {apiDeveloper.negativeCredit > 0 && <Button onClick={() => payAction(apiDeveloper._id)}>Pagar adeudo</Button>}
          </div>
          {/* <div>
            {businesses}
          </div> */}
        </div>
      </CardBody>
    </Card>
  );
};
