/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import { WithWizard } from 'react-albus';
import { NavLink } from 'reactstrap';

interface Props {
  className: string;
  disableNav: any;
  topNavClick: any;
}

export const TopNavigation: FC<Props> = ({ className, disableNav, topNavClick }) => {
  const getClassName = (steps: any[], step: any, index: number, stepItem: any) => {
    if (steps.indexOf(step) === index) {
      return 'step-doing';
    }
    if (steps.indexOf(step) > index || stepItem.isDone) {
      stepItem.isDone = true;
      return 'step-done';
    }
    return 'step';
  };

  const itemClick = (stepItem: any, push: any) => {
    if (disableNav) {
      return;
    }
    topNavClick(stepItem, push);
  };
  return (
    <WithWizard
      render={({ next, previous, step, steps, go, push }) => (
        <ul
          className={`nav nav-tabs ${className}${disableNav ? ' disabled' : ''
            }`}
        >
          {steps.map((stepItem: any, index) => {
            if (!stepItem.hideTopNav) {
              return (
                <li
                  key={`topNavStep_${index}`}
                  className={`nav-item ${getClassName(
                    steps,
                    step,
                    index,
                    stepItem,
                  )}`}
                >
                  <NavLink
                    to="#"
                    location={{}}
                    className="nav-link"
                    onClick={() => itemClick(stepItem, push)}
                  >
                    <span>{stepItem.name}</span>
                    <small>{stepItem.desc}</small>
                  </NavLink>
                </li>
              );
            }
            return <span key={`topNavStep_${index}`} />;
          })}
        </ul>
      )}
    />
  );
};
