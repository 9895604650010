/* eslint-disable consistent-return */
import { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { getDeliveryService } from '../../services/deliveryServices';
import { RootState } from '../../store';
import {
  CardNotifications, CardActions, CardDeliveryMan, CardEstablishment, CardLocations, CardPaymentDetail, CardService, CardURL, MapDeliveryService,
  CardPaymentDetailForDriver,
  CardLogs,
} from './components';
import { DeliveryDriver, Driver, Quote } from '../../interfaces';
import { getDeliveryDriversByCoords } from '../../services/deliveryDrivers';
import { getDeliveryQuote } from '../../services/deliveryQuote';
import { CardQuotes } from './components/CardQuotes';
import { ModalCenter } from '../../components/modals';

const DeliveryService: FC = () => {
  const { deliveryService } = useSelector((state: RootState) => state.deliveryServices);
  const { location } = useHistory();
  const dispatch = useDispatch();
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const [isOpenNotifications, setIsOpenNotifications] = useState(false);
  const [isOpenLogs, setIsOpenLogs] = useState(false);

  const toggleModalNotifications = () => setIsOpenNotifications(value => !value);
  const toggleModalLogs = () => setIsOpenLogs(value => !value);

  const fetchData = () => {
    const array = location.pathname.split('/');
    const id = array[array.length - 1];
    dispatch(getDeliveryService(id));
  };
  const fetchQuote = async () => {
    if (deliveryService && deliveryService.deliveryQuoteId) {
      const data = await getDeliveryQuote(deliveryService.deliveryQuoteId);
      setQuotes(data?.deliveryQuote.quotes || []);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    fetchData();
  }, [location.pathname]);
  useEffect(() => {
    fetchQuote();
  }, [deliveryService]);

  useEffect(() => {
    if (deliveryService && !['done', 'canceled', 'noDrivers'].includes(deliveryService.status)) {
      const interval = setInterval(async () => {
        fetchData();
        if (['done', 'canceled', 'noDrivers'].includes(deliveryService.status)) {
          clearInterval(interval);
        }
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [deliveryService]);

  const getDriversByCoords = async () => {
    if (deliveryService) {
      const { locations } = deliveryService;
      const driversByCoords = await getDeliveryDriversByCoords({ coords: [locations[0].longitude, locations[0].latitude], distance: 3000 });
      setDrivers(() => driversByCoords.map((driver: DeliveryDriver) => ({
        _id: driver._id,
        name: `${driver.firstName} ${driver.lastName}`,
        coords: driver.coords,
        transportName: driver.transport.name,
        phoneNumber: driver.phoneNumber.number,
        isFounder: driver.isFounder,
        isVisible: driver.isVisible,
        appOpenedAt: driver.appOpenedAt,
      })));
    }
  };

  const removeDrivers = () => {
    setDrivers([]);
  };

  if (!deliveryService) {
    return (
      <Container className="dashboard">
        <Card>
          <CardBody>
            <p>Cargando</p>
          </CardBody>
        </Card>
      </Container>
    );
  }

  return (
    <Container className="dashboard">
      <Row>
        <Col xs={12} md={12} lg={12} xl={6}>
          <MapDeliveryService
            drivers={drivers}
            setDrivers={setDrivers}
          />
        </Col>
        <Col xs={12} md={12} lg={12} xl={6}>
          <Row>
            {/* {(deliveryService.status === 'requestingDriver' || deliveryService.status === 'accepted' || deliveryService.status === 'onRoute') && <CardActions onCancel={() => dispatch(cancelDeliveyService(deliveryService._id))} />} */}
            <CardActions
              deliveryService={deliveryService}
              getDriversByCoords={getDriversByCoords}
              removeDrivers={removeDrivers}
              drivers={drivers}
              toggleModalNotifications={toggleModalNotifications}
              toggleModalLogs={toggleModalLogs}
            />
            {quotes.length > 0 && <CardQuotes quotes={quotes} key={deliveryService._id} />}
            {deliveryService.status === 'requestingDriver' && <CardNotifications requests={deliveryService.requests} key={deliveryService._id} />}
          </Row>
          {
            deliveryService.publicURL && (
              <Row>
                <CardURL url={deliveryService.publicURL} key={deliveryService._id} />
              </Row>
            )
          }
          <Row>
            {
              deliveryService.driver && (
                <Col xs={12} md={12} lg={12} xl={6} xxl={6} style={{ padding: 0, paddingRight: 5 }}>
                  <CardDeliveryMan driver={deliveryService.driver} key={deliveryService._id} />
                </Col>
              )
            }
            {
              deliveryService.business && (
                <Col xs={12} md={12} lg={12} xl={6} xxl={6} style={{ padding: 0, paddingRight: 5 }}>
                  <CardEstablishment business={deliveryService.business} key={deliveryService._id} />
                </Col>
              )
            }
          </Row>
          <Row>
            <CardService deliveryService={deliveryService} key={deliveryService._id} />
          </Row>
          <Row>
            <CardPaymentDetail service={deliveryService} key={deliveryService._id} />
          </Row>
          <Row>
            <CardPaymentDetailForDriver service={deliveryService} priceDetail={deliveryService.priceDetail} deliveryTotalDetail={deliveryService.deliveryTotalDetail} key={deliveryService._id} />
          </Row>
          <Row>
            <CardLocations locations={deliveryService.locations} key={deliveryService._id} />
          </Row>
        </Col>
      </Row>
      <ModalCenter
        isOpen={isOpenNotifications}
        toggleModal={toggleModalNotifications}
        title="Notificaciones"
        size="xl"
      >
        <CardNotifications requests={deliveryService.requests} key={deliveryService._id} />
      </ModalCenter>
      <ModalCenter
        isOpen={isOpenLogs}
        toggleModal={toggleModalLogs}
        title="Logs"
        size="xl"
      >
        <CardLogs logs={deliveryService.logs} key={deliveryService._id} />
      </ModalCenter>
    </Container>
  );
};

export default DeliveryService;
