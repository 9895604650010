import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Card, CardBody, Input } from 'reactstrap';
import { Transport, TransportAvailable } from '../../../interfaces';
import { getTransport } from '../../../services/transport';
import { changeTransportLabel } from '../../../helpers/transport';

interface Props {
  transport: TransportAvailable;
  onChangeTransport: (e: ChangeEvent<HTMLInputElement>, id: string) => void;
  onChangeCheckedTransport: (e: ChangeEvent<HTMLInputElement>, id: string) => void;
}

export const CardTransportForm: FC<Props> = ({ transport, onChangeTransport, onChangeCheckedTransport }) => {
  const [transportGlobal, setTransportGlobal] = useState<Transport | null>(null);

  const getTransportGlobal = async () => {
    setTransportGlobal(await getTransport(transport._id));
  };

  useEffect(() => {
    if (!transportGlobal) {
      getTransportGlobal();
    }
  }, [transport.useGlobalPrices]);
  return (
    <Card>
      <CardBody>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: '20px' }}>
          <p style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '10px' }}>{changeTransportLabel(transport.name)}</p>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <label htmlFor="useGlobalPrices">Precios Globales</label>
            <div>
              <Input
                style={{ height: '20px' }}
                type="checkbox"
                checked={transport.useGlobalPrices}
                name="useGlobalPrices"
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeCheckedTransport(e, transport._id)}
              />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <label htmlFor="baseCost">Costo Base</label>
            <Input
              style={{ width: '25%' }}
              value={transport.useGlobalPrices ? transportGlobal?.baseCost : transport.baseCost}
              disabled={transport.useGlobalPrices}
              name="baseCost"
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeTransport(e, transport._id)}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <label htmlFor="baseDistanceInKm">KM base</label>
            <Input
              style={{ width: '25%' }}
              value={transport.useGlobalPrices ? transportGlobal?.baseDistanceInKm : transport.baseDistanceInKm}
              disabled={transport.useGlobalPrices}
              name="baseDistanceInKm"
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeTransport(e, transport._id)}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <label htmlFor="costKmExtra">KM extra precio</label>
            <Input
              style={{ width: '25%' }}
              value={transport.useGlobalPrices ? transportGlobal?.costKmExtra : transport.costKmExtra}
              disabled={transport.useGlobalPrices}
              name="costKmExtra"
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeTransport(e, transport._id)}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <label htmlFor="costLocationExtra">Locación extra precio</label>
            <Input
              style={{ width: '25%' }}
              value={transport.useGlobalPrices ? transportGlobal?.costLocationExtra : transport.costLocationExtra}
              disabled={transport.useGlobalPrices}
              name="costLocationExtra"
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeTransport(e, transport._id)}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
