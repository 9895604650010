import { PaymentMethodType } from '../interfaces';

export const changePaymentMethodLabel = (paymentMethod: PaymentMethodType) => {
  switch (paymentMethod) {
    case 'cash':
      return 'Efectivo';
    case 'card':
      return 'Tarjeta';

    default:
      return paymentMethod;
  }
};