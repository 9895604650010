import dashboardBackendApi from '../api/dashboardBackendApi';
import { serialize } from '../helpers/serialize';
import { createNewBusinessAdmin, setAdminList, updateNowBusinessAdmin } from '../store/slices/businesses';
import { BusinessAdminsResponse } from '../interfaces/businessAdmin';
import { handleAxiosError } from '../helpers/handleErrors';

export const createBusinessAdmin = (establishmentAdmin: any) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.post('/support/businessAdmins', establishmentAdmin);
    dispatch(createNewBusinessAdmin({
      businessAdmin: resp.data.businessAdmin,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const updateBusinessAdmin = (id: string, establishmentAdmin: any) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.put(`/support/businessAdmins/${id}`, establishmentAdmin);
    dispatch(updateNowBusinessAdmin({
      id,
      businessAdmin: resp.data.businessAdmin,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const getBusinessAdminsByBusinessId = (id: string, filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const resp = await dashboardBackendApi.get<BusinessAdminsResponse>(`/support/businessAdmins/business/${id}?${_filters}`);
    dispatch(setAdminList({
      businessAdmins: resp.data.businessAdmins,
      totalBusinessAdmins: resp.data.totalBusinessAdmins,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};
