import { FC } from 'react';
import { Button } from 'reactstrap';

interface Props {
  size?: string;
  onClick: () => void;
  label: string;
  disabled?: boolean;
}

export const ButtonPrimary: FC<Props> = ({ size = 'sm', onClick, label, disabled = false }) => (
  <Button
    size={size}
    color="primary"
    onClick={onClick}
    disabled={disabled}
  >
    {label}
  </Button>
);
