import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Button, Input } from 'reactstrap';
import { RootState } from '../../../store/index';
import { formatDate } from '../../../helpers';
import { ButtonPrimary } from '../../../components/buttons/ButtonPrimary';
import s from './styles.module.scss';
import { DatePicker } from '../../../components/DatePicker';
import { IconFlask } from '../../../components/icons';
import { updateBusiness } from '../../../services/business';

interface Props {
  from: any;
  setFrom: React.Dispatch<React.SetStateAction<null>>;
  to: any;
  setTo: React.Dispatch<React.SetStateAction<null>>;
}

export const CardInfo: FC<Props> = ({ from, setFrom, to, setTo }) => {
  const { business } = useSelector((state: RootState) => state.businesses);
  const history = useHistory();

  const dispatch = useDispatch();

  const [showEditMaxNegativeBalance, setShowEditMaxNegativeBalance] = useState(false);
  const [maxNegativeBalance, setMaxNegativeBalance] = useState(0);

  useEffect(() => {
    setMaxNegativeBalance(business?.maxNegativeBalance || 0);
  }, [business]);

  if (!business) {
    return (
      <Card>
        <CardBody>
          <h3>Cargando...</h3>
        </CardBody>
      </Card>
    );
  }

  const handleDateChange = (fromDate: any, toDate: any) => {
    setFrom(fromDate);
    setTo(toDate);
  };

  const changeMaxNegativeBalance = () => {
    dispatch(updateBusiness(business?._id, { maxNegativeBalance }));
    setShowEditMaxNegativeBalance(false);
  };

  return (
    <Card>
      <CardBody>
        <div className={s.container}>
          <div>
            <h3>
              {business.isSandbox && <IconFlask />}{business.name}
            </h3>
            <span>
              {business._id}
            </span>
          </div>
        </div>
        <div className={s.container}>
          <div className={s.containerImage}>
            <img
              className={s.image}
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/OOjs_UI_icon_userAvatar.svg/2048px-OOjs_UI_icon_userAvatar.svg.png"
              alt="avatar"
            />
          </div>
          <div>
            <p>Fecha de creación</p>
            <span>{formatDate(business.createdAt)}</span>
          </div>
        </div>

        <div className={s.container}>
          <div>
            <p>Categoría</p>
            <span>Comida</span>
          </div>
          <div>
            <p>Ciudades</p>
            <span>Guadalajara, Zapopan</span>
          </div>
        </div>
        <div className={s.container}>
          <div>
            <p>Sanbox mode</p>
            <span>{business.isSandbox === true ? 'Si' : 'No'}</span>
          </div>
          <div>
            <p>Saldo de créditos</p>
            <span>{business.accountBalance?.paymentBalance || 0}</span>
          </div>
          <div>
            <p>Limite de saldo vencido</p>
            {
              showEditMaxNegativeBalance ? (
                <div className="d-flex flex-wrap">
                  <Input
                    value={maxNegativeBalance}
                    bgSize="sm"
                    style={{ width: '100px' }}
                    onChange={e => setMaxNegativeBalance(Number(e.target.value))}
                    type="number"
                  />
                  <Button className="ml-3" size="sm" onClick={() => setShowEditMaxNegativeBalance(false)}>Cancelar</Button>
                  <ButtonPrimary size="sm" onClick={changeMaxNegativeBalance} label="Guardar" />
                </div>
              ) : (
                <div className="d-flex">
                  <span>{business.maxNegativeBalance || 0}</span>
                  <Button className="ml-3" size="sm" onClick={() => setShowEditMaxNegativeBalance(true)}>Editar</Button>
                </div>
              )
            }
          </div>
          {/* <div>
            <p>Total ingresos</p>
            <span>{business.accountBalance?.totalIncome || 0}</span>
          </div>
          <div>
            <p>Total gastos</p>
            <span>{business.accountBalance?.totalExpense || 0}</span>
          </div>

          <div>
            <p>Facturas pagadas</p>
            <span>{business.accountBalance?.totalInvoicePaid || 0}</span>
          </div>
          <div>
            <p>Facturas canceladas</p>credit
            <span>{business.accountBalance?.totalInvoiceCanceled || 0}</span>
          </div>
          <div>
            <p>Total de facturado</p>
            <span>{business.accountBalance?.totalInvoice || 0}</span>
          </div>

          <div>
            <p>Sado de servicios</p>
            <span>{business.accountBalance?.serviceBalance || 0}</span>
          </div>
          <div>
            <p>Crédito</p>
            <span>{business.accountBalance?.credit || 0}</span>
          </div> */}
        </div>
        <div className={s.container}>
          <ButtonPrimary
            label="Pedir Servicio"
            onClick={() => history.push(`/dashboard/businesses/serviceRequest/${business._id}`)}
          />
        </div>
        <DatePicker handleDateChange={handleDateChange} from={from} to={to} />
      </CardBody>
    </Card>
  );
};
