import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SliceState {
  isSandbox: boolean;
}

const initialState: SliceState = {
  isSandbox: false,
};

export const authSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleSandbox: (state) => {
      state.isSandbox = !state.isSandbox;
    },
  },
});

export const {
  toggleSandbox,
} = authSlice.actions;

export default authSlice.reducer;
