import { ChangeEvent, FC } from 'react';
import { Card, CardBody } from 'reactstrap';
import s from './styles.module.scss';
import { SupportAdmin } from '../../../interfaces';
import { SwitchButton } from '../../../components/buttons';
import { useAppDispatch } from '../../../store';
import { updateSupportAdmin } from '../../../services/supportAdmin';

interface Props {
  supportAdmin: SupportAdmin,
}

export const CardInfo: FC<Props> = ({ supportAdmin }) => {

  const dispatch = useAppDispatch();

  const { _id, firstName, lastName, email, isEnabled } = supportAdmin;

  const handleIsEnabledChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget;
    dispatch(updateSupportAdmin(_id, { isEnabled: checked }));
  };

  return (
    <Card>
      <CardBody>
        <div className={s.container}>
          <div>
            <h3>
              {_id}
            </h3>
            <span>
              {firstName} {lastName}
            </span>
          </div>
        </div>
        <div>
          <div>
            <p>Correo: <span>{email}</span></p>
          </div>
        </div>
        <p>
          Habilitado: <SwitchButton checked={isEnabled} onChange={handleIsEnabledChange} />
        </p>
      </CardBody>
    </Card>
  );
};
