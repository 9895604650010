import dashboardBackendApi from '../api/dashboardBackendApi';
import backendApi from '../api/backendApi';
import { serialize } from '../helpers/serialize';
import { BusinessesResponse, BusinessResponse } from '../interfaces/business';
import { resetStateBusinessEstablishments } from '../store/slices/businessEstablishments';
import {
  setBusinesses,
  setBusiness,
  createNewBusiness,
  updateNowBusiness,
  setDeliveryServicesByBusiness,
  setPaymentsByBusiness,
  setInvoicesByBusiness,
  updateBusinessInvoice,
  setInvoicesIssuedByBusiness,
  setInvoicesReceivedByBusiness,
} from '../store/slices/businesses';
import { sendSocketNotificationForBusiness } from './socket';
import { handleAxiosError } from '../helpers/handleErrors';

export const getBusinesses = (filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const { data } = await dashboardBackendApi.get<BusinessesResponse>(`/support/businesses?${_filters}`);
    dispatch(setBusinesses({
      businesses: data.businesses,
      totalBusinesses: data.totalBusinesses,
    }));
    dispatch(resetStateBusinessEstablishments());
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const getBusiness = (id: string) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.get<BusinessResponse>(`/support/businesses/${id}`);
    dispatch(setBusiness({
      business: resp.data.business,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};
export const refreshInttegrationSecret = (id: string) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.post<BusinessResponse>(`/support/businesses/${id}/integrationsecret`);
    dispatch(getBusiness(id));
    // dispatch(setBusiness({
    //   business: resp.data.business,
    // }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const getPaymentsByBusiness = (id: string, filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const resp = await dashboardBackendApi.get<any>(`/support/businesses/${id}/payments?${_filters}`);
    dispatch(setPaymentsByBusiness({
      payments: resp.data.payments,
      totalPayments: resp.data.totalPayments,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
    // return error.response;
  }
};

export const getInvoicesByBusiness = (id: string, filters: any) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const { data } = await dashboardBackendApi.get<any>(`/support/businesses/${id}/invoices?${_filters}`);
    if (filters.typeInvoice === 'issuedBy') {
      dispatch(setInvoicesIssuedByBusiness({
        invoices: data.invoices,
        totalInvoices: data.totalInvoices,
      }));
    }
    if (filters.typeInvoice === 'receivedBy') {
      dispatch(setInvoicesReceivedByBusiness({
        invoices: data.invoices,
        totalInvoices: data.totalInvoices,
      }));
    }
  } catch (error) {
    handleAxiosError(error, dispatch);
    // return error.response;
  }
};
export const getInvoicesIssuedByBusiness = (id: string, filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const { data } = await dashboardBackendApi.get<any>(`/support/businesses/${id}/invoicesIssuedBy?${_filters}`);
    dispatch(setInvoicesByBusiness({
      invoices: data.invoices,
      totalInvoices: data.totalInvoices,
    }));
    dispatch(setInvoicesIssuedByBusiness({
      invoices: data.invoices,
      totalInvoices: data.totalInvoices,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
    // return error.response;
  }
};
export const getInvoicesReceivedByBusiness = (id: string, filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const { data } = await dashboardBackendApi.get<any>(`/support/businesses/${id}/invoicesReceivedBy?${_filters}`);
    dispatch(setInvoicesReceivedByBusiness({
      invoices: data.invoices,
      totalInvoices: data.totalInvoices,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
    // return error.response;
  }
};

export const getDeliveryServicesByBusiness = (id: string, filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const resp = await dashboardBackendApi.get(`/support/businesses/${id}/services?${_filters}`);
    dispatch(setDeliveryServicesByBusiness({
      businessDeliveryServices: resp.data.businessDeliveryServices,
      totalBusinessDeliveryServices: resp.data.totalBusinessDeliveryServices,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const createBusiness = (business: any) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.post<BusinessResponse>('/support/businesses', business);
    dispatch(createNewBusiness({
      business: resp.data.business,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const updateBusiness = (id: string, business: any) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.put<BusinessResponse>(`/support/businesses/${id}`, business);
    dispatch(updateNowBusiness({
      id,
      business: resp.data.business,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

interface PayProducts {
  businessId: string,
  isSandbox: boolean,
}
export const payProducts = ({ businessId, isSandbox }: PayProducts) => async (dispatch: any) => {
  console.log('@service:payProducts');
  try {
    const resp = await dashboardBackendApi.post<BusinessResponse>(`/support/businesses/${businessId}/payproducts`, { isSandbox });
    console.log('resp', resp);
    dispatch(getBusiness(businessId));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
  return 0;
};

interface PayInvoices {
  businessId: string,
  isSandbox: boolean,
}
export const payPendingInvoicesByBusinessId = ({ businessId, isSandbox }: PayInvoices) => async (dispatch: any) => {
  console.log('@service:payPendingInvoicesByBusinessId');
  try {
    const resp = await dashboardBackendApi.post<BusinessResponse>(`/support/businesses/${businessId}/payinvoices`, { isSandbox });
    console.log('resp', resp);
    dispatch(getBusiness(businessId));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
  return 0;
};

interface ManualTransactionIncome {
  businessId: string,
  amount: number,
}
export const addManualTransactionIncome = ({ businessId, amount }: ManualTransactionIncome) => async (dispatch: any) => {
  console.log('@service:addManualTransactionIncome');
  try {
    const filters = {
      page: 1,
      limit: 10,
      sortBy: '_id:desc',
    };
    const resp = await backendApi.post<BusinessResponse>(`/api/support/business/${businessId}/transaction`, { amount });
    console.log('resp', resp);
    dispatch(getPaymentsByBusiness(businessId, filters));
    dispatch(getBusiness(businessId));
    await sendSocketNotificationForBusiness({
      businessId,
      title: 'Abono',
      message: `Se abono la cantidad de ${amount} a su negocio`,
    });
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
  return 0;
};

export const cancelBusinessInvoice = (invoiceId: string) => async (dispatch: any) => {
  console.log('@cancelInvoice');
  try {
    const resp = await dashboardBackendApi.post<any>(`/support/invoices/${invoiceId}/cancel`);
    dispatch(updateBusinessInvoice(resp.data.invoice));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};


