import dashboardBackendApi from '../api/dashboardBackendApi';
import { handleAxiosError } from '../helpers/handleErrors';
// import { createNewZone, setZone, setZones, updateNowZone } from '../store/slices/zones';

export const getAccountBalance = () => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.get<any>('/support/delivery/accountbalance/');
    return resp.data;
    // dispatch(setZones({
    //   zones: resp.data.zones,
    // }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
  return null;
};
export const getAccountBalanceSandbox = () => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.get<any>('/support/delivery/accountbalance?issandbox=true');
    return resp.data;
    // dispatch(setZones({
    //   zones: resp.data.zones,
    // }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
  return null;
};
