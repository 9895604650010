/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { ButtonView } from '../../components/buttons';

import ResizableReactTable from '../../components/ResizableReactTable';
import CreateTableData from '../../helpers/createTableData';
import { getBusinesses } from '../../services/business';
import { useAppSelector, useAppDispatch } from '../../store/index';
import { Business } from '../../interfaces/business';
import { formatDate } from '../../helpers';
import { ModalBusinessInvoices } from './components';

const columns = [
  { Header: 'Sandbox', accessor: 'sandbox' },
  { Header: 'Product Balance', accessor: 'productBalance' },
  { Header: 'Fecha de creación', accessor: 'createdAt' },
  { Header: 'Nombre', accessor: 'name' },
  { Header: 'Acciones', accessor: 'actions' },
];

const Businesses = () => {
  const {
    businesses,
    totalBusinesses,
    searchText,
  } = useAppSelector(state => state.businesses);
  const dispatch = useAppDispatch();

  const [data, setData] = useState<Business[]>([]);
  const [manualPageSize] = useState([10, 20, 50, 100]);
  const [limit, setLimit] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [business, setBusiness] = useState<any>(null);
  const { isSandbox } = useAppSelector(state => state.app);

  const getFilters = () => {
    const filters: any = {};
    filters.limit = limit;
    filters.page = pageIndex + 1;
    filters.isSandbox = isSandbox ? 'yes' : 'no';
    filters.sortBy = '_id:desc';
    filters.accountBalance = '>0';
    return filters;
  };

  const getAll = () => {
    try {
      const filters = getFilters();
      dispatch(getBusinesses(filters));
    } catch (error) {
      console.error({ error });
    }
  };

  useEffect(() => {
    if (!isOpen) {
      getAll();
    }
  }, [limit, pageIndex, searchText, isSandbox, isOpen]);

  useEffect(() => {
    if (businesses.length > 0) {
      const newData: any[] = [];
      businesses.map((item) => {
        const { accountBalance, accountBalanceSandbox } = item;
        const productBalance = !isSandbox ? (accountBalance?.productBalance || 0) : (accountBalanceSandbox?.productBalance || 0);
        const obj = {
          ...item,
          productBalance,
          actions: (
            <>
              <ButtonView
                onClick={() => {
                  setBusiness({ ...item, productBalance });
                  setIsOpen(true);
                }}
              />
            </>
          ),
        };
        if (obj.createdAt) {
          obj.createdAt = formatDate(item.createdAt);
        }
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [businesses]);

  const reactTableData = CreateTableData(data, columns);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title">
                  <h5 className="bold-text">PAGOS Negocios</h5>
                </div>
              </div>
              {business && <ModalBusinessInvoices business={business} isOpen={isOpen} setIsOpen={setIsOpen} />}
              {
                data.length === 0
                  ? <h3> No hay info que se pueda mostrar</h3>
                  : (
                    <ResizableReactTable
                      reactTableData={reactTableData}
                      totalRecords={totalBusinesses}
                      manualPageSize={manualPageSize}
                      limit={limit}
                      setLimit={l => setLimit(l)}
                      pageIndex={pageIndex}
                      setPageIndex={p => setPageIndex(p)}
                      totalNoOfPages={Math.ceil(totalBusinesses / limit)}
                    />
                  )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Businesses;
