import { useHistory } from 'react-router-dom';
import { FC, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { RootState } from '../../store';
import { ChatChat } from '../../components/applications/Chat';
import { getChatsMesaggesByAdminId, sendChatMessage } from '../../services/chatMessage';
import { SocketContext } from '../../context/SocketContext';

const Invoices: FC = () => {
  // const history = useHistory();
  const { location } = useHistory();
  const array = location.pathname.split('/');
  const dispatch = useDispatch();
  const { socket, online } = useContext(SocketContext);
  
  const { messages } = useSelector((state: RootState) => state.chatMessages);

  useEffect(() => {
    console.log('useffect socket', socket);
    socket?.on('messageForSupport', (data:any) => {  
      dispatch(getChatsMesaggesByAdminId(adminId));
    });
    return () => {
      socket?.off('messageForSupport');
    };
  }, [socket]);

  const adminId = array[array.length - 1];
  console.log('admiId', adminId);
  useEffect(() => {
    console.log('useEffect');
    dispatch(getChatsMesaggesByAdminId(adminId));
  }, [adminId]);
  
  const submitChatMessage = async ({ text }:{ text: string }) => {
    const data = {
      text,
      businessAdminId: adminId,
    };
    dispatch(sendChatMessage(data));
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} lg={12}>
          <ChatChat
            messages={messages}
            onSubmitChatMessage={submitChatMessage}
            recipient={{}}
            currentUser={{}}
          />
          {/* asdf */}
        </Col>
      </Row>
    </Container>
  );
};

export default Invoices;
