/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import { FC, useState } from 'react';
import { Form } from 'react-final-form';
import { Container, Row, Col, Card, CardBody, Button, Input } from 'reactstrap';
import { DropdownInputField, InputField, TextAreaField } from '../../components/form';
import Select from '../../components/shared/form/Select';
import { useAppDispatch } from '../../store';
import { createTemplate } from '../../services/whatsapp';

interface IButton {
  type: string;
  text?: string;
  url?: string;
  phone_number?: string;
}

const OPTIONS = [
  {
    label: 'Marketing',
    value: 'MARKETING',
  },
  {
    label: 'Utilidad',
    value: 'UTILITY',
  },
];

const OPTIONS_BUTTONS = [
  {
    label: 'URL',
    value: 'URL',
  },
  {
    label: 'Llamada',
    value: 'PHONE_NUMBER',
  },
  {
    label: 'Respuesta rápida',
    value: 'QUICK_REPLY',
  },
];

interface Props { }

const WhatsAppTemplateForm: FC<Props> = () => {

  const dispatch = useAppDispatch();

  const [numberOfHeaderVariables, setNumberOfHeaderVariables] = useState(0);
  const [numberOfBodyVariables, setNumberOfBodyVariables] = useState(0);
  const [buttons, setButtons] = useState<IButton[]>([]);

  const addNewButton = () => {
    setButtons([...buttons, { type: '' }]);
  };

  const removeButton = (index: number) => {
    setButtons(buttons.filter((_, i) => i !== index));
  };

  const removeHeaderVariable = () => {
    setNumberOfHeaderVariables(numberOfHeaderVariables - 1);
  };

  const removeBodyVariable = () => {
    setNumberOfBodyVariables(numberOfBodyVariables - 1);
  };

  const updateButton = (index: number, field: 'type' | 'text' | 'url' | 'phone_number', value: string) => {
    const newButtons = [...buttons];
    newButtons[index][field] = value;
    setButtons(newButtons);
  };

  const validate = (values: any) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = 'El nombre es obligatorio';
    }
    return errors;
  };


  const onSubmit = (values: any) => {
    try {
      const { name, category, headerText, headerExample1, bodyText, footerText } = values;
      const bodyExamples = [];
      for (let i = 1; i <= numberOfBodyVariables; i++) {
        bodyExamples.push(values[`bodyExample${i}`]);
      }
      const newButtons = buttons.map(b => (b.type === 'PHONE_NUMBER' ? {
        type: b.type,
        text: b.text,
        phone_number: b.phone_number,
      } : b));
      const data = {
        name: name.replaceAll(' ', '_'),
        category: category.value,
        header: {
          text: headerText,
          example: numberOfHeaderVariables > 0 ? [headerExample1] : [],
        },
        body: {
          text: bodyText,
          example: bodyExamples,
        },
        footer: {
          text: footerText,
        },
        buttons: newButtons,
      };
      console.log(data);
      dispatch(createTemplate(data));
    } catch (error) {
      console.error({ error });
    }
  };

  const selectCategory = (data: any) => {
    return data;
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={8} lg={8}>
          <Card>
            <CardBody>
              <h3>Nuevo Template</h3>
              <Form onSubmit={onSubmit} validate={validate} initialValues={{}}>
                {({ handleSubmit }) => {
                  return (
                    <form className="form" onSubmit={handleSubmit}>
                      <div className="d-flex flex-column w-100">
                        <InputField
                          label="Nombre"
                          name="name"
                          placeholder="Nombre"
                        />
                        <DropdownInputField
                          label="Categoria"
                          name="category"
                          options={OPTIONS}
                          placeholder="Selecciona un tipo de cuenta"
                          onSelect={selectCategory}
                        />
                        <InputField
                          label="Cabecera"
                          name="headerText"
                          placeholder="Cabecera"
                        />
                        <div className="d-flex flex-column">
                          <Button
                            onClick={() => setNumberOfHeaderVariables(value => value + 1)}
                            disabled={numberOfHeaderVariables > 0}
                          >
                            Agregar variable
                          </Button>
                          {
                            numberOfHeaderVariables > 0 && (
                              [...Array(Number(numberOfHeaderVariables))].map((_, index) => (
                                <div className="d-flex" style={{ gap: '10px' }} key={`variable-${index + 1}`}>
                                  <InputField
                                    label={`Variable ${index + 1}`}
                                    name={`header${index + 1}`}
                                    placeholder={`Variable ${index + 1}`}
                                  />
                                  <InputField
                                    key={index}
                                    label={`Ejemplo ${index + 1}`}
                                    name={`headerExample${index + 1}`}
                                    placeholder={`Ejemplo ${index + 1}`}
                                  />
                                  <Button
                                    onClick={removeHeaderVariable}
                                    style={{ border: 'none', background: 'none', color: 'red' }}
                                  >
                                    X
                                  </Button>
                                </div>
                              ))
                            )
                          }
                        </div>
                        <TextAreaField
                          label="Cuerpo"
                          name="bodyText"
                          placeholder="Cuerpo"
                        />
                        <div className="d-flex flex-column">
                          <Button
                            onClick={() => setNumberOfBodyVariables(value => value + 1)}
                            disabled={numberOfBodyVariables > 2}
                          >
                            Agregar variable
                          </Button>
                          {
                            numberOfBodyVariables > 0 && (
                              [...Array(Number(numberOfBodyVariables))].map((_, index) => (
                                <div className="d-flex" style={{ gap: '10px' }} key={`variable-${index + 1}`}>
                                  <InputField
                                    label={`Variable ${index + 1}`}
                                    name={`body${index + 1}`}
                                    placeholder={`Variable ${index + 1}`}
                                  />
                                  <InputField
                                    key={index}
                                    label={`Ejemplo ${index + 1}`}
                                    name={`bodyExample${index + 1}`}
                                    placeholder={`Ejemplo ${index + 1}`}
                                  />
                                </div>
                              ))
                            )
                          }
                          {
                            numberOfBodyVariables > 0 && (
                              <Button
                                onClick={removeBodyVariable}
                              >
                                Eliminar variable
                              </Button>
                            )
                          }
                        </div>
                        <InputField
                          label="Pie de página"
                          name="footerText"
                          placeholder="Pie de página"
                        />
                        <br />
                        <h3>Botones</h3>
                        {
                          buttons.map((button: any, index) => (
                            <div key={index}>
                              <div className="form__form-group">
                                <label className="form__form-group-label typography-message" htmlFor={`buttonType${index}`}>Tipo de botón</label>
                                <div className="form__form-group-field">
                                  <Select
                                    name={`buttonType${index}`}
                                    options={OPTIONS_BUTTONS}
                                    placeholder="Selecciona un tipo de botón"
                                    onChange={(data: any) => {
                                      updateButton(index, 'type', data.value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="d-flex" style={{ gap: '10px', alignItems: 'flex-end', height: '60px' }}>
                                {
                                  button.type && (
                                    <div className="form__form-group">
                                      <label className="form__form-group-label typography-message" htmlFor={`buttonText${index}`}>Texto del botón</label>
                                      <div className="form__form-group-field">
                                        <Input
                                          name={`buttonText${index}`}
                                          placeholder="Texto del botón"
                                          onChange={(e: any) => updateButton(index, 'text', e.target.value)}
                                        />
                                      </div>
                                    </div>
                                  )
                                }
                                {button.type === 'URL' && (
                                  <div className="form__form-group">
                                    <label className="form__form-group-label typography-message" htmlFor={`buttonText${index}`}>URL</label>
                                    <div className="form__form-group-field">
                                      <Input
                                        name={`buttonUrl${index}`}
                                        placeholder="URL"
                                        onChange={(e: any) => updateButton(index, 'url', e.target.value)}
                                      />
                                    </div>
                                  </div>
                                )}
                                {button.type === 'PHONE_NUMBER' && (
                                  <div className="form__form-group">
                                    <label className="form__form-group-label typography-message" htmlFor={`buttonText${index}`}>Número de teléfono</label>
                                    <div className="form__form-group-field">
                                      <Input
                                        name={`buttonPhoneNumber${index}`}
                                        placeholder="Número de teléfono"
                                        onChange={(e: any) => updateButton(index, 'phone_number', e.target.value)}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                              <Button
                                onClick={() => removeButton(index)}
                              >
                                Eliminar botón
                              </Button>
                            </div>
                          ))
                        }
                        <Button
                          onClick={addNewButton}
                        >
                          Agregar botón
                        </Button>
                      </div>
                      <div className="d-flex" style={{ justifyContent: 'flex-end' }}>
                        <Button
                          size="sm"
                          color="primary"
                          type="submit"
                        >
                          Crear
                        </Button>
                      </div>
                    </form>
                  );
                }}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default WhatsAppTemplateForm;
