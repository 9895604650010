import { FC } from 'react';
import { Field } from 'react-final-form';

interface Props {
  label: string;
  name: string;
  placeholder: string;
}

export const TextAreaField: FC<Props> = ({ label, name, placeholder }) => {
  return (
    <div className="form__form-group">
      <label className="form__form-group-label typography-message" htmlFor={name}>{label}</label>
      <div className="form__form-group-field">
        <Field
          name={name}
          type="text"
          component="textarea"
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};
