import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import {
  CardInfo,
  CardDeliveryServices,
  CardEstablishments,
} from './components';
import { getBusinessByHunter } from '../../services/hunter/business';

const EstablishmentParentDetail = () => {
  const { location } = useHistory();
  const dispatch = useDispatch();
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const fetchData = () => {
    const array = location.pathname.split('/');
    const id = array[array.length - 1];
    dispatch(getBusinessByHunter(id));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col xs={12} md={12} lg={6} xl={6}>
          <Row>
            <Col>
              <CardInfo
                from={from}
                setFrom={setFrom}
                to={to}
                setTo={setTo}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={12} lg={6} xl={6}>
          <Row>
            <Col>
              <CardDeliveryServices
                from={from}
                to={to}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CardEstablishments />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default EstablishmentParentDetail;
