import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import s from './styles.module.scss';
import { Business } from '../../../interfaces';
import { ButtonPrimary } from '../../../components/buttons';

interface Props {
  business: Business,
}

export const CardBusiness: FC<Props> = ({ business }) => {

  const history = useHistory();

  const { _id, name } = business;

  return (
    <Card>
      <CardBody>
        <div className={s.container}>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <h3>
                {name}
              </h3>
              <ButtonPrimary
                label="Ver"
                onClick={() => history.push(`/dashboard/businesses/${_id}`)}
              />
            </div>
            <span>
              {_id}
            </span>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
