import { createSlice } from '@reduxjs/toolkit';

interface Props {
  direction: String;
}

const initialState: Props = {
  direction: 'ltr',
};

export const rtlSlice = createSlice({
  name: 'rtl',
  initialState,
  reducers: {
    changeDirectionToLTR: (state) => {
      state.direction = 'ltr';
    },
    changeDirectionToRTL: (state) => {
      state.direction = 'rtl';
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeDirectionToLTR, changeDirectionToRTL } = rtlSlice.actions;

export default rtlSlice.reducer;
