import { Col, Container, Row } from 'reactstrap';
import { CardPasswordChange } from './components';

const Config = () => {
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Configuraciones Administrador</h3>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <CardPasswordChange />
        </Col>
      </Row>
    </Container>
  );
};

export default Config;
