// import DownIcon from 'mdi-react/ChevronDownIcon';
// import { Collapse } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import { toggleSandbox } from '../../store/slices/app';
import { SwitchButton } from '../../components/buttons';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

const TopBarSwitch = () => {
  const dispatch = useDispatch();
  const { isSandbox } = useSelector(state => state.app);

  // const [isSandbox, setIsSandbox] = useState(app.isSandbox);
  const handleToggleSandbox = () => {
    // setIsCollapsed(!isCollapsed);
    dispatch(toggleSandbox());
  };

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      paddingTop: 10,
    }}
    >
      <SwitchButton checked={isSandbox} onChange={handleToggleSandbox} />
      <span style={{ marginBottom: '0.45rem', marginLeft: 5 }}>Sandbox</span>
    </div>
  );
};

export default TopBarSwitch;
