import { FC, useEffect, useState } from 'react';
import { Button, Card, CardBody, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { getUrl } from '../../../helpers';
import { useAppDispatch, useAppSelector } from '../../../store';
import { ButtonPrimary } from '../../../components/buttons/ButtonPrimary';
import { Image } from './Image';
import { updateIsApprovedForDeliveryDriver } from '../../../services/deliveryDrivers';
import { CustomDropdown } from '../../../components/dropdowns';

interface Props { }

export const CardDocuments: FC<Props> = () => {

  const { deliveryDriver } = useAppSelector(state => state.deliveryDrivers);
  const dispatch = useAppDispatch();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [imageType, setImageType] = useState('');
  const [titleModal, setTitleModal] = useState('');
  const [rejectionText, setRejectionText] = useState('');
  const [rejectedDropdown, setRejectedDropdown] = useState({
    label: 'Motivo',
    selectedKey: 'selectOption',
    options: {
      selectOption: 'Selecciona una opción',
      'Falta fotografía de identificación oficial': 'Falta fotografía de identificación oficial',
      'Fotografía borrosa': 'Fotografía borrosa',
      'Fotografía obscura': 'Fotografía obscura',
      other: 'Otro',
    },
  });
  useEffect(() => {
    switch (imageType) {
      case 'profileImage':
        setTitleModal('Foto de perfil');
        break;
      case 'docPersonalId':
        setTitleModal('Documentación personal');
        break;

      default:
        setTitleModal('');
        break;
    }
  }, [imageType]);

  const onClick = (values: any) => {
    try {
      if (deliveryDriver) {
        dispatch(updateIsApprovedForDeliveryDriver(deliveryDriver._id, values));
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const toggleModal = () => setModalIsOpen(value => !value);

  const modalOpen = (type: string) => {
    setImageType(type);
    toggleModal();
  };

  const onClickModal = () => {
    const { selectedKey } = rejectedDropdown;
    if (selectedKey !== 'selectOption') {
      if (selectedKey === 'other') {
        console.log('other again');
        if (rejectionText) {
          onClick({
            [imageType]: {
              isApproved: false,
              imageId: null,
              rejectionText,
            },
          });
          toggleModal();
        }
      } else {
        onClick({
          [imageType]: {
            isApproved: false,
            imageId: null,
            rejectionText: selectedKey,
          },
        });
        toggleModal();
      }
    }
  };

  const changeRejectedFilter = (rejected: any) => {
    setRejectedDropdown({
      ...rejectedDropdown,
      selectedKey: rejected,
    });
  };

  return (
    <>
      <Card>
        <CardBody>
          <div>
            <div>
              <h4>Documentos</h4>
            </div>
          </div>
          <div className="d-flex flex-wrap">
            {
              deliveryDriver?.profileImage?.imageId && (
                <div className="d-flex flex-column align-items-center" style={{ width: '250px' }}>
                  <Image
                    title="Foto de perfil"
                    url={getUrl(deliveryDriver.profileImage.imageId, 200, 200)}
                    urlFull={getUrl(deliveryDriver.profileImage.imageId)}
                    alt="profileImage"
                    isApproved={deliveryDriver.profileImage.isApproved}
                  />
                  <div>
                    <Button
                      onClick={() => modalOpen('profileImage')}
                      color="danger"
                      size="sm"
                    >
                      No Aprobar
                    </Button>
                    {
                      !deliveryDriver.profileImage.isApproved && (
                        <ButtonPrimary
                          onClick={() => {
                            onClick({
                              profileImage: {
                                isApproved: true,
                                imageId: deliveryDriver.profileImage.imageId,
                              },
                            });
                          }}
                          label="Aprobar"
                        />
                      )
                    }
                  </div>
                </div>
              )
            }
            {
              deliveryDriver?.docPersonalId?.imageId && (
                <div className="d-flex flex-column align-items-center" style={{ width: '250px' }}>
                  <Image
                    title="Documentación personal"
                    url={getUrl(deliveryDriver.docPersonalId.imageId, 200, 200)}
                    urlFull={getUrl(deliveryDriver.docPersonalId.imageId)}
                    alt="docPersonalId"
                    isApproved={deliveryDriver.docPersonalId.isApproved}
                  />
                  <div>
                    <Button
                      onClick={() => modalOpen('docPersonalId')}
                      color="danger"
                      size="sm"
                    >
                      No Aprobar
                    </Button>
                    {
                      !deliveryDriver.docPersonalId.isApproved && (
                        <ButtonPrimary
                          onClick={() => {
                            onClick({
                              docPersonalId: {
                                isApproved: true,
                                imageId: deliveryDriver.docPersonalId.imageId,
                              },
                            });
                          }}
                          label="Aprobar"
                        />
                      )
                    }
                  </div>
                </div>
              )
            }
          </div>
        </CardBody>
      </Card>
      <Modal
        isOpen={modalIsOpen}
        toggle={toggleModal}
        backdrop
      >
        <ModalHeader toggle={toggleModal}>
          <p>{titleModal}</p>
        </ModalHeader>
        <ModalBody>
          <CustomDropdown
            {...rejectedDropdown}
            onClick={changeRejectedFilter}
          />
          {
            rejectedDropdown.selectedKey === 'other' && (
              <div className="form__form-group">
                <label className="form__form-group-label typography-message" htmlFor="rejectionText">Motivo</label>
                <div className="form__form-group-field">
                  <Input
                    name="rejectionText"
                    type="textarea"
                    placeholder="Motivo por el cual no fue aprobada"
                    onChange={e => setRejectionText(e.target.value)}
                  />
                </div>
              </div>
            )
          }
          <div>
            <Button
              size="sm"
              color="primary"
              onClick={toggleModal}
            >
              Cancelar
            </Button>
            <Button
              size="sm"
              color="danger"
              onClick={onClickModal}
            >
              No aprobar
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
