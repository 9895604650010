import { FC, useState, useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import ResizableReactTable from '../../../components/ResizableReactTable';
import CreateTableData from '../../../helpers/createTableData';
import { LogService } from '../../../interfaces';
import { formatTime } from '../../../helpers';
import { ButtonView } from '../../../components/buttons';

interface Props {
  logs: LogService[];
}

export const CardLogs: FC<Props> = ({ logs }) => {
  const [data, setData] = useState<any[]>([]);
  const [log, setLog] = useState<LogService | null>(null);
  const [content, setContent] = useState(<div>Sin Data</div>);
  const columns = [
    { Header: 'Fecha', accessor: 'createdAt' },
    { Header: 'Nombre', accessor: 'eventName' },
    { Header: 'Acciones', accessor: 'actions' },
  ];

  useEffect(() => {
    setData(logs.map(log => ({
      ...log,
      createdAt: formatTime(log.createdAt),
      actions: (
        <ButtonView
          onClick={() => setLog(log)}
        />
      ),
    })));
  }, []);

  useEffect(() => {
    if (log) {
      try {
        // Intentar analizar log.data como JSON
        const jsonData = JSON.parse(log.data);

        // Si tiene éxito, mostrar el JSON formateado
        setContent(<pre>{JSON.stringify(jsonData, null, 2)}</pre>);
      } catch (error) {
        // Si hay un error, mostrar log.data sin formato
        setContent(<p>{log.data}</p>);
      }
    }
  }, [log]);

  const reactTableData = CreateTableData(data, columns);
  return (
    <Card>
      <CardBody>
        <div>
          <h3>
            Logs
          </h3>
        </div>
        {
          log && (
            <div>
              <br />
              <p>Name: {log.eventName}</p>
              {content}
            </div>
          )
        }
        <ResizableReactTable
          reactTableData={reactTableData}
          totalRecords={1}
          manualPageSize={[0]}
          limit={10}
          setLimit={() => { }}
          pageIndex={0}
          setPageIndex={() => { }}
          totalNoOfPages={1}
        />
      </CardBody>
    </Card>
  );
};
