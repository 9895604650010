import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from 'reactstrap';
import ResizableReactTable from '../../components/ResizableReactTable';
import CreateTableData from '../../helpers/createTableData';
import { getDeliveryDrivers } from '../../services/deliveryDrivers';
import { ButtonView } from '../../components/buttons/ButtonsIcon';
import { useAppSelector } from '../../store';
import { DeliveryDriver } from '../../interfaces';
import { driverIsApproved, formatDate } from '../../helpers';
import { setLimit, setPageIndex } from '../../store/slices/deliveryDrivers';
import { IconFlask } from '../../components/icons';

const DeliveryDrivers: FC = () => {
  const {
    deliveryDrivers,
    totalDeliveryDrivers,
    limit,
    pageIndex,
    from,
    to,
  } = useAppSelector(state => state.deliveryDrivers);
  const dispatch = useDispatch();
  const history = useHistory();
  const { app } = useAppSelector(state => state);
  const [data, setData] = useState<DeliveryDriver[]>([]);
  const [manualPageSize] = useState([10, 20, 50, 100]);

  const columns = [
    { Header: 'Sandbox', accessor: 'sandbox' },
    { Header: 'Product Balance', accessor: 'productBalance' },
    { Header: 'Nombre', accessor: 'firstName' },
    { Header: 'Apellido', accessor: 'lastName' },
    { Header: 'Teléfono', accessor: 'phoneNumber.number' },
    { Header: 'Aprobado', accessor: 'approved' },
    { Header: 'Zona', accessor: 'zone.name' },
    { Header: 'Acciones', accessor: 'actions' },
  ];

  const getFilters = () => {
    const filters: any = {};

    filters.isSandbox = app.isSandbox ? 'yes' : 'no';
    filters.accountBalance = '<0';
    filters.limit = 10;
    filters.page = 1;
    return filters;
  };

  const getAll = () => {
    try {
      const filters = getFilters();
      dispatch(getDeliveryDrivers(filters));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAll();
  }, [limit, pageIndex, from, to, app.isSandbox]);

  useEffect(() => {
    if (deliveryDrivers.length > 0) {
      const newData: any[] = [];
      deliveryDrivers.map((item) => {
        const { accountBalance, accountBalanceSandbox } = item;
        const obj = {
          ...item,
          approved: driverIsApproved(item),
          sandbox: item.isSandbox === true ? <IconFlask color="#00E5B2" /> : '',
          productBalance: app.isSandbox ? accountBalance?.productBalance : accountBalanceSandbox?.productBalance || 0,
          actions: (
            <>
              <ButtonView
                onClick={() => history.push(`/dashboard/deliveryDrivers/${item._id}`)}
              />
            </>
          ),
        };
        if (obj.createdAt) {
          obj.createdAt = formatDate(item.createdAt);
        }
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [deliveryDrivers]);

  const reactTableData = CreateTableData(data, columns);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title">
                  <h5 className="bold-text">COBROS Repartidores</h5>
                </div>
              </div>
              {
                data.length === 0 ? <h3>No hay info que se pueda mostrar</h3> : (
                  <ResizableReactTable
                    reactTableData={reactTableData}
                    totalRecords={totalDeliveryDrivers}
                    manualPageSize={manualPageSize}
                    limit={limit}
                    setLimit={l => dispatch(setLimit(l))}
                    pageIndex={pageIndex}
                    setPageIndex={p => dispatch(setPageIndex(p))}
                    totalNoOfPages={Math.ceil(totalDeliveryDrivers / limit)}
                  />
                )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DeliveryDrivers;
