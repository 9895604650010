import { FC, PropsWithChildren, useState } from 'react';

interface Props {
  text: string;
}

export const CustomTooltip: FC<PropsWithChildren<Props>> = ({ children, text }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  return (
    <div className="custom-tooltip" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children}
      {isTooltipVisible && (
        <div className="custom-tooltip-content">
          <span className="custom-tooltip-text">{text}</span>
        </div>
      )}
    </div>
  );
};
