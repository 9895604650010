import { FC } from 'react';
import { OptionsSelector } from '../../../components/selectors';

type OptionsType = {
  icon: string;
  value: 'cash' | 'card';
}

const OPTIONS: OptionsType[] = [
  { icon: 'cash-register-2', value: 'cash' },
  { icon: 'credit-card', value: 'card' },
];

interface Props {
  value: 'cash' | 'card';
  onChange: (data: any) => void;
}

export const PaymentSelector: FC<Props> = ({ value, onChange }) => {
  console.log(OPTIONS);
  return (
    <div className="payment-container">
      <OptionsSelector
        options={OPTIONS}
        value={OPTIONS.find(opt => opt.value === value)}
        onChange={({ value: val }) => onChange(val)}
      />
    </div>
  );
};
