import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeliveryService, Payment, DeliveryDriver, Invoice } from '../../../interfaces';
import {
  payloadSetDeliveryDrivers,
  payloadSetDeliveryServicesByDeliveryDriver,
  payloadSetInvoicesByDeliveryDriver,
  payloadSetPaymentsByDeliveryDriver,
} from './payloadTypes';

interface DeliveryDriversState {
  deliveryDrivers: DeliveryDriver[];
  totalDeliveryDrivers: number;
  deliveryDriver: DeliveryDriver | null;
  deliveryServices: DeliveryService[];
  totalDeliveryServices: number;
  payments: Payment[];
  totalPayments: number;
  invoices: Invoice[];
  totalInvoices: number;
  // filters
  limit: number;
  pageIndex: number;
  from: any;
  to: any;
  searchText: string;
  filters: {
    zoneId: string;
    approved: string;
    visible: string;
    transport: string;
    isSandbox: string;
    isFounder: string;
  };
}

const initialState: DeliveryDriversState = {
  deliveryDrivers: [],
  totalDeliveryDrivers: 0,
  deliveryDriver: null,
  deliveryServices: [],
  totalDeliveryServices: 0,
  payments: [],
  totalPayments: 0,
  invoices: [],
  totalInvoices: 0,
  // filters
  limit: 10,
  pageIndex: 0,
  from: null,
  to: null,
  searchText: '',
  filters: {
    zoneId: 'all',
    approved: 'all',
    visible: 'all',
    transport: 'all',
    isSandbox: 'all',
    isFounder: 'all',
  },
};

export const deliveryDriversSlice = createSlice({
  name: 'deliveryDrivers',
  initialState,
  reducers: {
    resetDeliverydriver: (state) => {
      state = initialState;
    },
    setDeliveryDrivers: (state, action: PayloadAction<payloadSetDeliveryDrivers>) => {
      state.deliveryDrivers = action.payload.deliveryDrivers || [];
      state.totalDeliveryDrivers = action.payload.totalDeliveryDrivers;
      state.deliveryDriver = null;
      state.deliveryServices = [];
      state.totalDeliveryServices = 0;
      state.payments = [];
      state.totalPayments = 0;
      state.invoices = [];
      state.totalInvoices = 0;
    },
    setDeliveryDriver: (state, action) => {
      state.deliveryDriver = action.payload.deliveryDriver;
    },
    setDeliveryServicesByDeliveryDriver: (state, action: PayloadAction<payloadSetDeliveryServicesByDeliveryDriver>) => {
      state.deliveryServices = action.payload.deliveryServices || [];
      state.totalDeliveryServices = action.payload.totalDeliveryServices || 0;
    },
    setPaymentsByDeliveryDriver: (state, action: PayloadAction<payloadSetPaymentsByDeliveryDriver>) => {
      state.payments = action.payload.payments || [];
      state.totalPayments = action.payload.totalPayments || 0;
    },
    setInvoicesByDeliveryDriver: (state, action: PayloadAction<payloadSetInvoicesByDeliveryDriver>) => {
      state.invoices = action.payload.invoices || [];
      state.totalInvoices = action.payload.totalInvoices || 0;
    },
    createNewDeliveryDriver: (state, action) => {
      state.totalDeliveryDrivers += 1;
      if (state.totalDeliveryDrivers <= 10) {
        state.deliveryDrivers.push(action.payload.deliveryDriver);
      }
    },
    updateNowDeliveryDriver: (state, action) => {
      const updateList = state.deliveryDrivers.map((deliveryDriver) => {
        if (deliveryDriver._id === action.payload.id) {
          return action.payload.deliveryDriver;
        }
        return deliveryDriver;
      });
      state.deliveryDrivers = updateList;
      if (state.deliveryDriver) {
        state.deliveryDriver = action.payload.deliveryDriver;
      }
    },
    setLimit: (state, { payload }) => {
      state.limit = payload;
    },
    setPageIndex: (state, { payload }) => {
      state.pageIndex = payload;
    },
    setFrom: (state, { payload }) => {
      state.from = payload;
    },
    setTo: (state, { payload }) => {
      state.to = payload;
    },
    setSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    setFilters: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        [payload.filter]: payload.value,
      };
    },
    updateDriverInvoice: (state, { payload }) => {
      state.invoices = state.invoices.map(invoice => (invoice._id === payload._id ? payload : invoice));
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setDeliveryDrivers,
  setDeliveryDriver,
  setDeliveryServicesByDeliveryDriver,
  setPaymentsByDeliveryDriver,
  setInvoicesByDeliveryDriver,
  createNewDeliveryDriver,
  updateNowDeliveryDriver,
  setLimit,
  setPageIndex,
  setFrom,
  setTo,
  setSearchText,
  setFilters,
  resetDeliverydriver,
  updateDriverInvoice,
} = deliveryDriversSlice.actions;

export default deliveryDriversSlice.reducer;
