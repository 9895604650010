import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import PasswordField from '../shared/form/Password';

export const PasswordInputField = ({ label, name, placeholder }) => {
  return (
    <div className="form__form-group">
      <label className="form__form-group-label typography-message" htmlFor={name}>{label}</label>
      <div className="form__form-group-field">
        <Field
          name={name}
          component={PasswordField}
          placeholder={placeholder}
          className="input-without-border-radius"
        />
      </div>
    </div>
  );
};

PasswordInputField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

PasswordInputField.defaultProps = {
  placeholder: '',
};
