/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import ResizableReactTable from '../../components/ResizableReactTable';
import { formatDateTime } from '../../helpers';
import CreateTableData from '../../helpers/createTableData';
import { useAppDispatch, useAppSelector } from '../../store';
import { ButtonPrimary, ButtonView } from '../../components/buttons';
import { DatePicker } from '../../components/DatePicker';
import { getZones } from '../../services/zone';
import { CustomDropdown } from '../../components/dropdowns';
import { getEstablishments } from '../../services/businessEstablishment';
import { InputSearch } from '../../components/inputs';
import { setFrom, setTo, setPageIndex, setSearchText, setLimit, setFilters } from '../../store/slices/businessEstablishments';
import { ModalCardEstablishment } from '../Business/components/ModalCardEstablishment';
import { ModalSendMessage } from './components';

const columns = [
  { Header: 'Fecha', accessor: 'createdAt' },
  { Header: 'Nombre', accessor: 'name' },
  { Header: 'Negocio', accessor: 'business' },
  { Header: 'RappiId', accessor: 'rappiId' },
  { Header: 'Teléfono', accessor: 'phone.number' },
  { Header: 'Zona', accessor: 'zone.name' },
  // { Header: 'Total Quotes', accessor: 'totalQuotes' },
  // { Header: 'Total Servicios', accessor: 'totalServices' },
  { Header: 'OlaClick', accessor: 'olaClick' },
  { Header: 'Acciones', accessor: 'actions' },
];

const BusinessEstablishments = () => {
  const history = useHistory();

  const dispatch = useAppDispatch();
  const {
    businessEstablishments,
    totalBusinessEstablishments,
    limit = 10,
    pageIndex = 0,
    from = null,
    to = null,
    searchText = '',
    filters = {
      zoneId: 'all',
      olaClick: 'all',
      rappiId: 'all',
    },
  } = useAppSelector(state => state.businessEstablishments);
  const { zones } = useAppSelector(state => state.zones);
  const { isSandbox } = useAppSelector(state => state.app);

  const [isOpenModalEstablihment, setIsOpenModalEstablihment] = useState(false);
  const [isOpenSendMessage, setIsOpenSendMessage] = useState(false);
  const [establishmentId, setEstablishmentId] = useState('');
  const [data, setData] = useState<any[]>([]);
  const [manualPageSize] = useState([10, 20, 50, 100]);
  const [zoneDropdown, setZoneDropdown] = useState({
    label: 'Zona',
    selectedKey: filters.zoneId || 'all',
    options: { all: 'Todas' },
  });
  const [olaClickDropdown, setOlaClickDropdown] = useState({
    label: 'OlaClick',
    selectedKey: filters.olaClick || 'all',
    options: { all: 'Todas', none: 'No tiene', yes: 'Aprobado', no: 'Por aprobar' },
  });
  const [rappiIdDropdown, setRappiIdDropdown] = useState({
    label: 'RappiId',
    selectedKey: filters.rappiId || 'all',
    options: { all: 'Todas', yes: 'con RappiId', no: 'sin RappiId' },
  });

  const getFilters = () => {
    const filters: any = {};
    if (from && to) {
      filters.from = from;
      filters.to = to;
    }
    if (zoneDropdown.selectedKey !== 'all') {
      filters.zoneId = zoneDropdown.selectedKey;
    }
    if (olaClickDropdown.selectedKey !== 'all') {
      filters.olaClick = olaClickDropdown.selectedKey;
    }
    if (rappiIdDropdown.selectedKey !== 'all') {
      filters.rappiId = rappiIdDropdown.selectedKey;
    }
    if (searchText) {
      filters.search = searchText;
    }
    filters.isSandbox = isSandbox ? 'yes' : 'no';
    filters.limit = limit;
    filters.page = pageIndex + 1;
    filters.sortBy = '_id:desc';
    return filters;
  };

  useEffect(() => {
    dispatch(getZones({ sortBy: 'name' }));
  }, []);

  useEffect(() => {
    const options: any = { all: 'Todas', none: 'Sin Zona' };
    zones.forEach((zone) => {
      options[zone._id] = zone.name;
    });
    setZoneDropdown({
      ...zoneDropdown,
      options,
    });
  }, [zones]);

  const getAll = () => {
    try {
      const filters = getFilters();
      dispatch(getEstablishments(filters));
    } catch (error) {
      console.error({ error });
    }
  };

  useEffect(() => {
    getAll();
  }, [limit, pageIndex, from, to, zoneDropdown, isSandbox, searchText, olaClickDropdown, rappiIdDropdown]);

  useEffect(() => {
    if (businessEstablishments.length > 0) {
      const newData: any[] = [];
      businessEstablishments.map((item: any) => {
        const obj = {
          ...item,
          business: item.businessId && (
            <>
              <ButtonView
                onClick={() => history.push(`/dashboard/businesses/${item.businessId}`)}
              />
            </>
          ),
          actions: (
            <>
              <ButtonView
                onClick={() => {
                  setEstablishmentId(item._id);
                  setIsOpenModalEstablihment(true);
                }}
              />
            </>
          ),
          // totalQuotes: item.totalQuotes || 0,
          // totalServices: item.totalServices || 0,
          olaClick: item.olaClickSettings
            ? item.olaClickSettings.approved
              ? 'Aprobado'
              : 'Por aprobar'
            : 'No tiene',
        };
        if (obj.createdAt) {
          obj.createdAt = formatDateTime(item.createdAt);
        }
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [businessEstablishments]);

  const handleDateChange = (fromDate: any, toDate: any) => {
    dispatch(setFrom(fromDate));
    dispatch(setTo(toDate));
    dispatch(setPageIndex(0));
  };

  const changeZoneFilter = (zoneId: any) => {
    setZoneDropdown({
      ...zoneDropdown,
      selectedKey: zoneId,
    });
    dispatch(setFilters({
      filter: 'zoneId',
      value: zoneId,
    }));
    dispatch(setPageIndex(0));
  };

  const changeOlaClickFilter = (value: any) => {
    setOlaClickDropdown({
      ...olaClickDropdown,
      selectedKey: value,
    });
    dispatch(setFilters({
      filter: 'olaClick',
      value,
    }));
    dispatch(setPageIndex(0));
  };

  const changeRappiIdFilter = (value: any) => {
    setRappiIdDropdown({
      ...rappiIdDropdown,
      selectedKey: value,
    });
    dispatch(setFilters({
      filter: 'rappiId',
      value,
    }));
    dispatch(setPageIndex(0));
  };

  const reactTableData = CreateTableData(data, columns);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title">
                  <h5 className="bold-text">Sucursales</h5>
                </div>
                <div>
                  <ButtonPrimary
                    label="Enviar notificación"
                    onClick={() => {
                      setIsOpenSendMessage(true);
                    }}
                  />
                </div>
              </div>
              <Row
                style={{ paddingLeft: '20px' }}
              >
                <CustomDropdown
                  {...zoneDropdown}
                  onClick={changeZoneFilter}
                />
                <CustomDropdown
                  {...olaClickDropdown}
                  onClick={changeOlaClickFilter}
                />
                <CustomDropdown
                  {...rappiIdDropdown}
                  onClick={changeRappiIdFilter}
                />
              </Row>
              <InputSearch
                value={searchText}
                onChange={(e: any) => {
                  dispatch(setPageIndex(0));
                  dispatch(setSearchText(e.target.value));
                }}
                placeholder="Introduce nombre, zona, teléfono"
                style={{
                  marginBottom: '5px',
                  width: '75%',
                }}
              />
              <Row>
                <DatePicker handleDateChange={handleDateChange} from={from} to={to} />
              </Row>
              <ModalCardEstablishment isOpen={isOpenModalEstablihment} setIsOpen={setIsOpenModalEstablihment} id={establishmentId} />
              <ModalSendMessage isOpen={isOpenSendMessage} setIsOpen={setIsOpenSendMessage} filters={getFilters()} />
              {
                data.length === 0
                  ? <h3>No hay info que se pueda mostrar</h3>
                  : (
                    <ResizableReactTable
                      reactTableData={reactTableData}
                      totalRecords={totalBusinessEstablishments}
                      manualPageSize={manualPageSize}
                      limit={limit}
                      setLimit={l => dispatch(setLimit(l))}
                      pageIndex={pageIndex}
                      setPageIndex={(p: any) => {
                        dispatch(setPageIndex(p));
                      }}
                      totalNoOfPages={Math.ceil(totalBusinessEstablishments / limit)}
                    />
                  )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BusinessEstablishments;
