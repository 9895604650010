import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { payloadCreateBusinessEstablishment, payloadSetBusinessEstablishments, payloadUpdateBusinessEstablishment } from './payloadTypes';
import { BusinessEstablishment } from '../../../interfaces/businessEstablishment';

interface Props {
  businessEstablishments: BusinessEstablishment[];
  totalBusinessEstablishments: number;
  businessEstablishmentsByBusiness: BusinessEstablishment[];
  totalBusinessEstablishmentsByBusiness: number;
  businessEstablishment: BusinessEstablishment | null;
  // filters
  limit: number;
  pageIndex: number;
  from: any;
  to: any;
  searchText: string;
  filters: {
    zoneId: string;
    olaClick: string;
    rappiId: string;
  };
}

const initialState: Props = {
  businessEstablishments: [],
  totalBusinessEstablishments: 0,
  businessEstablishmentsByBusiness: [],
  totalBusinessEstablishmentsByBusiness: 0,
  businessEstablishment: null,
  // filters
  limit: 10,
  pageIndex: 0,
  from: null,
  to: null,
  searchText: '',
  filters: {
    zoneId: 'all',
    olaClick: 'all',
    rappiId: 'all',
  },
};

export const businessEstablishmentsSlice = createSlice({
  name: 'businessEstablishments',
  initialState,
  reducers: {
    setBusinessEstablishments: (state, action: PayloadAction<payloadSetBusinessEstablishments>) => {
      state.businessEstablishments = action.payload.businessEstablishments || [];
      state.totalBusinessEstablishments = action.payload.totalBusinessEstablishments;
    },
    setBusinessEstablishmentsByBusiness: (state, action: PayloadAction<payloadSetBusinessEstablishments>) => {
      state.businessEstablishmentsByBusiness = action.payload.businessEstablishments || [];
      state.totalBusinessEstablishmentsByBusiness = action.payload.totalBusinessEstablishments;
    },
    createNewBusinessEstablishment: (state, action: PayloadAction<payloadCreateBusinessEstablishment>) => {
      state.totalBusinessEstablishmentsByBusiness += 1;
      if (state.totalBusinessEstablishmentsByBusiness <= 10) {
        state.businessEstablishmentsByBusiness.push(action.payload.businessEstablishment);
      }
    },
    updateNowBusinessEstablishment: (state, action: PayloadAction<payloadUpdateBusinessEstablishment>) => {
      const updateList = state.businessEstablishmentsByBusiness.map((businessEstablishment) => {
        if (businessEstablishment._id === action.payload.id) {
          return action.payload.businessEstablishment;
        }
        return businessEstablishment;
      });
      state.businessEstablishmentsByBusiness = updateList;
    },
    resetStateBusinessEstablishments: (state) => {
      state.businessEstablishments = [];
      state.totalBusinessEstablishments = 0;
      state.businessEstablishmentsByBusiness = [];
      state.totalBusinessEstablishmentsByBusiness = 0;
      state.businessEstablishment = null;
    },
    // filters
    setLimit: (state, { payload }) => {
      state.limit = payload;
    },
    setPageIndex: (state, { payload }) => {
      console.log('setPageIndex', payload);
      state.pageIndex = payload;
    },
    setFrom: (state, { payload }) => {
      state.from = payload;
    },
    setTo: (state, { payload }) => {
      state.to = payload;
    },
    setSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    setFilters: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        [payload.filter]: payload.value,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBusinessEstablishments,
  setBusinessEstablishmentsByBusiness,
  createNewBusinessEstablishment,
  updateNowBusinessEstablishment,
  resetStateBusinessEstablishments,
  setLimit,
  setPageIndex,
  setFrom,
  setTo,
  setSearchText,
  setFilters,
} = businessEstablishmentsSlice.actions;

export default businessEstablishmentsSlice.reducer;
