import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { getDeliveryDriver } from '../../services/deliveryDrivers';
import {
  CardAppInfo,
  CardInfo, CardInvoices, CardServices, MapDeliveryDriver, CardPayments, CardAddToRequest,
  CardAccounyBalance,
  CardBankData,
} from './components';
import { CardDocuments } from './components/CardDocuments';

const DeliveryDriver: FC = () => {
  const { location } = useHistory();
  const dispatch = useDispatch();
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const fetchData = () => {
    const array = location.pathname.split('/');
    const id = array[array.length - 1];
    dispatch(getDeliveryDriver(id));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const array = location.pathname.split('/');
  const driverId = array[array.length - 1];
  return (
    <Container className="dashboard">
      <Row xs={12}>
        <Col xs={12} md={6}>
          <Row>
            <CardInfo
              from={from}
              setFrom={setFrom}
              to={to}
              setTo={setTo}
            />
          </Row>
          <Row>
            <CardBankData />
          </Row>
          <Row>
            <CardAccounyBalance />
          </Row>
          <Row>
            <CardAppInfo />
          </Row>
          <Row>
            <CardDocuments />
          </Row>

          <Row>
            <CardServices from={from} to={to} />
          </Row>
          <Row>
            <CardPayments from={from} to={to} />
          </Row>
          <Row>
            <CardInvoices from={from} to={to} />
          </Row>
        </Col>
        <Col xs={12} md={6}>
          <Row>
            {driverId && <CardAddToRequest driverId={driverId} />}
          </Row>
          <MapDeliveryDriver />
        </Col>
      </Row>
    </Container>
  );
};

export default DeliveryDriver;
