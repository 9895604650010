import { FC } from 'react';
import { Form } from 'react-final-form';
import { FormApi, SubmissionErrors } from 'final-form';
import { Button } from 'reactstrap';
import { InputField } from '../../../components/form/InputField';

interface Props {
  onSubmit: (values: any, form: FormApi<any, any>, callback?: ((errors?: SubmissionErrors) => void) | undefined) => void;
}

export const FormManualTransaction: FC<Props> = ({ onSubmit }) => {
  const validate = (values: any) => {
    const errors: any = {};
    if (!values.amount || Number(values.amount) <= 0) {
      errors.amount = 'El Monto es obligatorio y debe de ser mayor a 0';
    }
    return errors;
  };

  return (
    <Form onSubmit={onSubmit} validate={validate} initialValues={{}}>
      {({ handleSubmit }) => (
        <form className="form" onSubmit={handleSubmit}>
          <InputField
            label="Monto"
            name="amount"
            placeholder="Monto"
            type="number"
          />
          <div>
            <Button
              size="sm"
              color="primary"
              type="submit"
            >
              Crear Transacción
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};
