// import { useDispatch } from 'react-redux';
import {
  // Button,
  Card,
  CardBody,
} from 'reactstrap';
import { useAppSelector } from '../../../store/index';
// import { formatDate } from '../../../helpers';
import s from './styles.module.scss';
// import { payProducts } from '../../../services/business';

// import { DatePicker } from '../../../components/DatePicker';

// interface Props {
//   from: any;
//   setFrom: React.Dispatch<React.SetStateAction<null>>;
//   to: any;
//   setTo: React.Dispatch<React.SetStateAction<null>>;
// }

export const CardAccountBalance = () => {
  const { business } = useAppSelector(state => state.businesses);
  const { isSandbox } = useAppSelector(state => state.app);
  // const history = useHistory();
  // const dispatch = useDispatch();
  if (!business) {
    return (
      <Card>
        <CardBody>
          <h3>Cargando...</h3>
        </CardBody>
      </Card>
    );
  }

  // const pay = async () => {
  //   console.log('@pay');
  //   dispatch(payProducts({ businessId: business._id, isSandbox }));
  // };
  return (
    <>
      <Card>
        <CardBody>
          {
            isSandbox ? (
              <div className={s.container}>
                <h4>Account balance (sandbox)</h4>
                <p>
                  <strong>Total ingresos</strong> {business.accountBalanceSandbox?.totalIncome || 0}
                </p>
                <p>
                  <strong>Total gastos</strong> {business.accountBalanceSandbox?.totalExpense || 0}
                </p>
                <p>
                  <strong>Facturas pagadas</strong> {business.accountBalanceSandbox?.totalInvoicePaid || 0}
                </p>
                <p>
                  <strong>Facturas canceladas</strong> {business.accountBalanceSandbox?.totalInvoiceCanceled || 0}
                </p>
                <p>
                  <strong>Total de facturado</strong> {business.accountBalanceSandbox?.totalInvoice || 0}
                </p>

                <p>
                  <strong>Sado de servicios</strong> {business.accountBalanceSandbox?.serviceBalance || 0}
                </p>
                <p>
                  <strong>Crédito</strong> {business.accountBalanceSandbox?.credit || 0}
                </p>
                <p>
                  <strong>productsAmountSent</strong> {business.accountBalanceSandbox?.productsAmountSent || 0}
                </p>
                <p>
                  <strong>productsAmountReceived</strong> {business.accountBalanceSandbox?.productsAmountReceived || 0}
                </p>
                <p>
                  <strong>productBalance</strong> {business.accountBalanceSandbox?.productBalance || 0}
                </p>
                <br />
                {/* <Button onClick={() => pay()} disabled={business.accountBalanceSandbox?.productBalance === 0}>Pagar productos</Button> */}
              </div>
            ) : (
              <div className={s.container}>
                <h4>Account balance</h4>
                <p>
                  <strong>Total ingresos</strong> {business.accountBalance?.totalIncome || 0}
                </p>
                <p>
                  <strong>Total gastos</strong> {business.accountBalance?.totalExpense || 0}
                </p>

                <p>
                  <strong>Facturas pagadas</strong> {business.accountBalance?.totalInvoicePaid || 0}
                </p>
                <p>
                  <strong>Facturas canceladas</strong> {business.accountBalance?.totalInvoiceCanceled || 0}
                </p>
                <p>
                  <strong>Total de facturado</strong> {business.accountBalance?.totalInvoice || 0}
                </p>

                <p>
                  <strong>Sado de servicios</strong> {business.accountBalance?.serviceBalance || 0}
                </p>
                <p>
                  <strong>Crédito</strong> {business.accountBalance?.credit || 0}
                </p>
                <p>
                  <strong>productsAmountSent</strong> {business.accountBalance?.productsAmountSent || 0}
                </p>
                <p>
                  <strong>productsAmountReceived</strong> {business.accountBalance?.productsAmountReceived || 0}
                </p>
                <p>
                  <strong>productBalance</strong> {business.accountBalance?.productBalance || 0}
                </p>
                <br />
                {/* <Button onClick={() => pay()} disabled={business.accountBalance?.productBalance === 0}>Pagar productos</Button> */}
              </div>
            )
          }
        </CardBody>
      </Card>
    </>
  );
};
