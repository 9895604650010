import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { RootState } from '../../store';
import { CardApiDeveloperInfo } from './components';
import { getApiDeveloper, payOffNeegativeCredit } from '../../services/apiDeveloper';

const ApiDeveloper: FC = () => {
  const { location } = useHistory();
  const dispatch = useDispatch();
  const { apiDeveloper, apiDeveloperSandbox } = useSelector((state: RootState) => state.apiDevelopers);
  
  console.log('apiDeveloper', apiDeveloper);
  const fetchData = () => {
    const array = location.pathname.split('/');
    const id = array[array.length - 1];
    dispatch(getApiDeveloper(id));
  };
  useEffect(() => {
    fetchData();
  }, []);
  const payAction = async (id: string) => {
    console.log('payoff');
    dispatch(payOffNeegativeCredit(id));
  };
  return (
    <Container className="dashboard">
      <Row>
        <Col md={4} lg={4}>
          {apiDeveloper && <CardApiDeveloperInfo apiDeveloper={apiDeveloper} payAction={payAction} />}
        </Col>
        <Col md={4} lg={4}>
          {apiDeveloperSandbox && <CardApiDeveloperInfo apiDeveloper={apiDeveloperSandbox} payAction={payAction} />}
        </Col>
      </Row>
    </Container>
  );
};

export default ApiDeveloper;
