import { FC } from 'react';
import { Form } from 'react-final-form';
import { Button } from 'reactstrap';
import { FormApi, SubmissionErrors } from 'final-form';
import { InputField } from '../../../components/form/InputField';
import { BusinessEstablishment } from '../../../interfaces/businessEstablishment';

interface Props {
  onSubmit: (values: any, form: FormApi<any, any>, callback?: ((errors?: SubmissionErrors) => void) | undefined) => void;
  businessEstablishment: BusinessEstablishment | null;
  place?: any;
}

export const FormEstablishment: FC<Props> = ({ onSubmit, businessEstablishment = null, place, children }) => {
  const validate = (values: any) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = 'El nombre es obligatorio';
    }
    if (!values.phone) {
      errors.phone = 'El teléfono es obligatorio';
    }
    return errors;
  };

  return (
    <Form onSubmit={onSubmit} validate={validate} initialValues={place || businessEstablishment}>
      {({ handleSubmit }) => (
        <form className="form" onSubmit={handleSubmit}>
          <p>General</p>
          <InputField
            label="Nombre"
            name="name"
            placeholder="Nombre de la sucursal"
          />
          <InputField
            label="Teléfono"
            name="phone.number"
            placeholder="Teléfono de la sucursal"
          />

          <p>Dirección</p>
          <InputField
            label="Calle"
            name="address.street"
            placeholder="Calle"
          />
          <InputField
            label="Número Exterior"
            name="address.exterior"
            placeholder="Número Exterior"
          />
          <InputField
            label="Número Interior"
            name="address.interior"
            placeholder="Número Interior"
          />
          <InputField
            label="Colonia"
            name="address.neighborhood"
            placeholder="Colonia"
          />
          <InputField
            label="Código Postal"
            name="address.postalCode"
            placeholder="Código Postal"
          />
          <InputField
            label="Ciudad"
            name="address.city"
            placeholder="Ciudad"
          />
          <InputField
            label="Estado"
            name="address.state"
            placeholder="Estado"
          />
          <InputField
            label="País"
            name="address.country"
            placeholder="País"
          />

          {children}

          <div style={{ marginTop: '15px' }}>
            <Button
              size="sm"
              color="primary"
              type="submit"
            >
              {businessEstablishment ? 'Editar sucursal' : 'Crear sucursal'}
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};
