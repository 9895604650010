import { DeliveryDriver } from '../interfaces';

export const driverIsApproved = (driver: DeliveryDriver) => {

  if (driver.isApproved) return 'Aprobado';

  if (driver.profileImage.imageId && driver.docPersonalId.imageId) {
    return 'Por aprobar';
  }

  return 'Incompleto';
};