import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { getBusiness } from '../../services/business';
import {
  CardInfo,
  CardEstablishments,
  CardAdmins,
  CardPayments,
  MapEstablishmentParent,
  CardDeliveryServices,
  CardAccountBalance,
  CardClients,
  CardQuotes,
  CardIntegrations,
  CardInvoicesIssuedBy,
  CardInvoicesReceivedBy,
} from './components';

const EstablishmentParentDetail = () => {
  const { location } = useHistory();
  const dispatch = useDispatch();
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const fetchData = () => {
    const array = location.pathname.split('/');
    const id = array[array.length - 1];
    dispatch(getBusiness(id));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container className="dashboard">
      <Row style={{ background: '#fff', padding: '20px 0px', height: 450, margin: '20px 0px' }}>
        <Col xs={12} md={12} lg={12} xl={12}>
          <MapEstablishmentParent />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={6} xl={6}>
          <Row>
            <Col>
              <CardInfo
                from={from}
                setFrom={setFrom}
                to={to}
                setTo={setTo}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CardIntegrations />
            </Col>
          </Row>
          <Row>
            <Col>
              <CardAccountBalance />
            </Col>
          </Row>

          <Row>
            <Col>
              <CardPayments
                from={from}
                to={to}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CardInvoicesIssuedBy
                from={from}
                to={to}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CardInvoicesReceivedBy
                from={from}
                to={to}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={12} lg={6} xl={6}>
          {/* <MapEstablishmentParent /> */}
          <Row>
            <Col>
              <CardDeliveryServices
                from={from}
                to={to}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CardQuotes
                from={from}
                to={to}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CardEstablishments />
            </Col>
          </Row>
          <Row>
            <Col>
              <CardAdmins />
            </Col>
          </Row>
          <Row>
            <Col>
              <CardClients />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default EstablishmentParentDetail;
