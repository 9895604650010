import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { BusinessClient } from '../../../interfaces';
import { RootState } from '../../../store/index';

interface Props {
  value: any;
  onChange: (data: any) => void;
  className?: string;
}

export const ClientSelector: FC<Props> = ({ value, onChange, className = '' }) => {
  const { businessClients } = useSelector((state: RootState) => state.businesses);
  const [selectedClient, setSelectedClient] = useState<any>();

  if (businessClients.length === 0) return null;

  const clientOptions = businessClients.map((client: BusinessClient) => ({
    label: `${client.firstName} ${client.lastName}`,
    value: client,
  }));

  const selectedClientLocations = selectedClient?.value.locations.map((loc: any) => ({ label: loc.name, value: loc }));


  const selectedClientName = `${selectedClient?.value?.firstName} ${selectedClient?.value?.lastName}`;
  const selectedClientPhone = selectedClient?.value?.phone?.number;

  return (
    <div className={className}>
      <Select
        value={selectedClient}
        onChange={setSelectedClient}
        options={clientOptions}
      />

      {selectedClientLocations && (
        <Select
          value={value}
          onChange={(data: any) => onChange({ ...data, address: data.value, contact: { phone: selectedClientPhone, name: selectedClientName } })}
          options={selectedClientLocations}
        />
      )}
    </div>
  );
};
