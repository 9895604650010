import { FC } from 'react';
import { useHistory } from 'react-router-dom';
// import { format } from 'date-fns';
import { Card, CardBody } from 'reactstrap';
import { Driver } from '../../../interfaces';

interface Props {
  driver: Driver;
}

export const CardDeliveryMan: FC<Props> = ({ driver }) => {
  const history = useHistory();
  return (
    <Card onClick={() => driver._id && history.push(`/dashboard/deliveryDrivers/${driver._id}`)} style={{ cursor: 'pointer' }}>
      <CardBody
        className="profile__card"
      >
        <div className="profile__information">
          <div className="profile__avatar">
            <img
              src={driver.imageProfileId ? driver.imageProfileId : 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/OOjs_UI_icon_userAvatar.svg/2048px-OOjs_UI_icon_userAvatar.svg.png'}
              alt="avatar"
            />
          </div>
          <div className="profile__data">
            <p className="profile__name">{driver.name}</p>
            {/* <p className="profile__work">Fecha de registro {format(new Date(), 'yyyy-MM-dd')}</p>
            <p className="profile__contact">w@w.com</p> */}
            <p className="profile__contact" dir="ltr">
              {driver.phoneNumber}
            </p>
            {/* <p className="profile__contact" dir="ltr">
              No Aprobado
            </p> */}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
