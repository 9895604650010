import { createContext, FC } from 'react';
import { Socket } from 'socket.io-client';
import { useSocket } from '../hooks/useSocket';

interface ContextProps {
  socket?: Socket<any, any>;
  online?: boolean;
}

export const SocketContext = createContext<ContextProps>({});

export const SocketProvider: FC = ({ children }) => {

  const { socket, online } = useSocket();

  return (
    <SocketContext.Provider value={{ socket, online }}>
      {children}
    </SocketContext.Provider>
  );
};