import { ChangeEvent, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { TransportAvailable } from '../../interfaces';
import { CardForm, CardTransportForm, Map } from './components';

const ZoneForm = () => {
  // Store Polygon path in state
  const [path, setPath] = useState([
    { lat: 20.77078108327232, lng: -103.24406580448148 },
    { lat: 20.650699527458066, lng: -103.19595102787014 },
    { lat: 20.568945012389758, lng: -103.42323069095607 },
    { lat: 20.77642761193429, lng: -103.40680078983304 },
  ]);
  const [center, setCenter] = useState({ lat: 20.503974427252164, lng: -103.47801129744668 });
  const [transportsAvailable, setTransportsAvailable] = useState<TransportAvailable[]>([]);

  const onChangeTransport = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    setTransportsAvailable(transportsAvailable.map((transport) => {
      if (transport._id === id) {
        transport = {
          ...transport,
          [e.target.name]: e.target.value,
        };
      }
      return transport;
    }));
  };

  const onChangeCheckedTransport = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    setTransportsAvailable(transportsAvailable.map((transport) => {
      if (transport._id === id) {
        transport = {
          ...transport,
          [e.target.name]: e.target.checked,
        };
      }
      return transport;
    }));
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col xs={12} md={12} lg={12} xl={6}>
          <Row>
            <CardForm
              path={path}
              setPath={setPath}
              setCenter={setCenter}
              onChangeCheckedTransport={onChangeCheckedTransport}
              transportsAvailable={transportsAvailable}
              setTransportsAvailable={setTransportsAvailable}
            />
          </Row>
          {
            transportsAvailable.map((transport) => {
              if (transport.isEnabled) {
                return (
                  <Row key={transport._id}>
                    <CardTransportForm
                      onChangeCheckedTransport={onChangeCheckedTransport}
                      transport={transport}
                      onChangeTransport={onChangeTransport}
                    />
                  </Row>
                );
              }
              return null;
            })
          }
        </Col>
        <Col xs={12} md={12} lg={12} xl={6}>
          <Map
            path={path}
            setPath={setPath}
            center={center}
            setCenter={setCenter}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ZoneForm;
