import { Route, Switch } from 'react-router-dom';
import Config from '../../../Config';
import DeliveryDrivers from '../../../DeliveryDrivers';
import DeliveryDriver from '../../../DeliveryDriver';
import Home from '../../../Home';
import DeliveryService from '../../../DeliveryService';
import DeliveryServices from '../../../DeliveryServices';
import Zones from '../../../Zones';
import Zone from '../../../Zone';
import ZoneForm from '../../../ZoneForm';
import Businesses from '../../../Businesses';
import Business from '../../../Business';
import BusinessDeliveryServiceRequest from '../../../BusinessDeliveryServiceRequest';
import Notifications from '../../../Notifications';
import Invoices from '../../../Invoices';
import InvoicesPending from '../../../InvoicesPending';
import Payments from '../../../Payments';
import ApiDevelopers from '../../../ApiDevelopers';
import ApiDeveloper from '../../../ApiDeveloper';
import ChatMessages from '../../../ChatMessages';
import ChatMessagesDetail from '../../../ChatMessagesDetail';
import Delivery from '../../../Delivery';
import DeliveryDriverIncome from '../../../DeliveryDriversIncome';
import BusinessesIncome from '../../../BusinessesIncome';
import BusinessesExpense from '../../../BusinessesExpense';
import DeliveryProviders from '../../../DeliveryProviders';
import DeliveryProvider from '../../../DeliveryProvider';
import DeliveryQuotes from '../../../DeliveryQuotes';
import AdminSettings from '../../../AdminSettings';
import BusinessEstablishments from '../../../BusinessEstablishments';
import Logs from '../../../Logs';
import SupportAdmins from '../../../SupportAdmins';
import SupportAdmin from '../../../SupportAdmin';
import HunterHome from '../../../HunterHome';
import HunterBusinesses from '../../../HunterBusinesses';
import HunterBusiness from '../../../HunterBusiness';
import WhatsAppSessions from '../../../WhatsAppSessions';
import WhatsAppSessionMessages from '../../../WhatsAppSessionMessages';
import WhatsAppTemplates from '../../../WhatsAppTemplates';
import WhatsAppTemplateForm from '../../../WhatsAppTemplateForm';
import Campaigns from '../../../Campaigns';
import { useAppSelector } from '../../../../store';

const Dashboard = () => {

  const { userType } = useAppSelector(state => state.auth);

  return (
    <>
      {
        userType === 'supportAdmin' && (
          <Switch>
            <Route path="/dashboard/home" component={Home} />
            <Route exact path="/dashboard/deliveryDrivers" component={DeliveryDrivers} />
            <Route exact path="/dashboard/deliveryDrivers/income" component={DeliveryDriverIncome} />
            <Route path="/dashboard/deliveryDrivers/:id" component={DeliveryDriver} />
            <Route exact path="/dashboard/businesses" component={Businesses} />
            <Route exact path="/dashboard/businesses/income" component={BusinessesIncome} />
            <Route exact path="/dashboard/businesses/expense" component={BusinessesExpense} />
            <Route path="/dashboard/businesses/serviceRequest/:id" component={BusinessDeliveryServiceRequest} />
            <Route path="/dashboard/businesses/:id" component={Business} />
            <Route exact path="/dashboard/services" component={DeliveryServices} />
            <Route path="/dashboard/services/:id" component={DeliveryService} />
            <Route exact path="/dashboard/zones/" component={Zones} />
            <Route exact path="/dashboard/zones/:id" component={Zone} />
            <Route path="/dashboard/zones/form/:id" component={ZoneForm} />
            <Route exact path="/dashboard/notifications" component={Notifications} />
            <Route exact path="/dashboard/invoices" component={Invoices} />
            <Route exact path="/dashboard/invoicespending" component={InvoicesPending} />
            <Route exact path="/dashboard/payments" component={Payments} />
            <Route path="/dashboard/config" component={Config} />
            <Route exact path="/dashboard/apiDevelopers" component={ApiDevelopers} />
            <Route path="/dashboard/apiDevelopers/:id" component={ApiDeveloper} />
            <Route exact path="/dashboard/ChatMessages/" component={ChatMessages} />
            <Route path="/dashboard/chatmessages/:bussinesAdminId" component={ChatMessagesDetail} />
            <Route path="/dashboard/delivery/" component={Delivery} />
            <Route exact path="/dashboard/deliveryProviders/" component={DeliveryProviders} />
            <Route path="/dashboard/deliveryProviders/:id" component={DeliveryProvider} />
            <Route exact path="/dashboard/deliveryQuotes/" component={DeliveryQuotes} />
            <Route path="/dashboard/adminSettings/" component={AdminSettings} />
            <Route exact path="/dashboard/businessEstablishments/" component={BusinessEstablishments} />
            <Route exact path="/dashboard/logs/" component={Logs} />
            <Route exact path="/dashboard/supportAdmins/" component={SupportAdmins} />
            <Route path="/dashboard/supportAdmins/:id" component={SupportAdmin} />
            <Route exact path="/dashboard/whatsapp/" component={WhatsAppSessions} />
            <Route path="/dashboard/whatsapp/session/:id" component={WhatsAppSessionMessages} />
            <Route exact path="/dashboard/whatsapptemplates/" component={WhatsAppTemplates} />
            <Route exact path="/dashboard/whatsapptemplates/form" component={WhatsAppTemplateForm} />
            <Route exact path="/dashboard/campaigns/" component={Campaigns} />
          </Switch>
        )
      }
      {
        userType === 'hunter' && (
          <Switch>
            <Route exact path="/dashboard/home" component={HunterHome} />
            <Route exact path="/dashboard/businesses" component={HunterBusinesses} />
            <Route path="/dashboard/businesses/:id" component={HunterBusiness} />
          </Switch>
        )
      }
    </>
  );
};

export default Dashboard;
