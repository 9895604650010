import dashboardBackendApi from '../api/dashboardBackendApi';
import { handleAxiosError } from '../helpers/handleErrors';
import { serialize } from '../helpers/serialize';
import { DeliveryDriverResponse, DeliveryDriversResponse, DeliveryServicesByDeliveryDriverResponse } from '../interfaces';
import {
  createNewDeliveryDriver,
  setDeliveryDrivers,
  setDeliveryDriver,
  updateNowDeliveryDriver,
  setDeliveryServicesByDeliveryDriver,
  setInvoicesByDeliveryDriver,
  setPaymentsByDeliveryDriver,
  resetDeliverydriver,
  updateDriverInvoice,
} from '../store/slices/deliveryDrivers';

export const getDeliveryDrivers = (filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const resp = await dashboardBackendApi.get<DeliveryDriversResponse>(`/support/deliveryDrivers?${_filters}`);
    dispatch(setDeliveryDrivers({
      deliveryDrivers: resp.data.deliveryDrivers,
      totalDeliveryDrivers: resp.data.totalDeliveryDrivers,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const getDeliveryDriver = (id: string) => async (dispatch: any) => {
  try {
    const { data } = await dashboardBackendApi.get<DeliveryDriverResponse>(`/support/deliveryDrivers/${id}`);
    dispatch(setDeliveryDriver({
      deliveryDriver: {
        ...data.deliveryDriver,
        bankData: data.bankData,
      },
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
    // return error.response;
  }
};

export const getDeliveryServicesByDeliveryDriver = (id: string, filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const resp = await dashboardBackendApi.get<DeliveryServicesByDeliveryDriverResponse>(`/support/deliveryDrivers/${id}/services?${_filters}`);
    dispatch(setDeliveryServicesByDeliveryDriver({
      deliveryServices: resp.data.deliveryServices,
      totalDeliveryServices: resp.data.totalDeliveryServices,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
    // return error.response;
  }
};

export const getPaymentsByDeliveryDriver = (id: string, filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const resp = await dashboardBackendApi.get<any>(`/support/deliveryDrivers/${id}/payments?${_filters}`);
    dispatch(setPaymentsByDeliveryDriver({
      payments: resp.data.payments,
      totalPayments: resp.data.totalPayments,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
    // return error.response;
  }
};

export const getInvoicesByDeliveryDriver = (id: string, filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const resp = await dashboardBackendApi.get<any>(`/support/deliveryDrivers/${id}/invoices?${_filters}`);
    dispatch(setInvoicesByDeliveryDriver({
      invoices: resp.data.invoices,
      totalInvoices: resp.data.totalInvoices,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
    // return error.response;
  }
};
// =====
// for pending invoices modal view
export const getPendingInvoicesByDeliveryDriver = (id: string, filters = {}) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.get<any>(`/support/deliveryDrivers/${id}/invoices/pending`);
    dispatch(setInvoicesByDeliveryDriver({
      invoices: resp.data.invoices,
      totalInvoices: resp.data.totalInvoices,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
    // return error.response;
  }
};
export const payPendingInvoicesByDeliveryDriver = (id: string) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.post<any>(`/support/deliveryDrivers/${id}/invoices/pending`);
    // dispatch(setInvoicesByDeliveryDriver({
    //   invoices: resp.data.invoices,
    //   totalInvoices: resp.data.totalInvoices,
    // }));
    dispatch(resetDeliverydriver());
  } catch (error) {
    handleAxiosError(error, dispatch);
    // return error.response;
  }
};
// ====

export const createDeliveryDriver = (deliveryDriver: any) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.post<DeliveryDriverResponse>('/support/deliveryDrivers', deliveryDriver);
    dispatch(createNewDeliveryDriver({
      deliveryDriver: resp.data.deliveryDriver,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
    // return error.response;
  }
};

export const updateDeliveryDriver = (id: string, deliveryDriver: any) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.put<DeliveryDriverResponse>(`/support/deliveryDrivers/${id}`, deliveryDriver);
    dispatch(updateNowDeliveryDriver({
      id,
      deliveryDriver: resp.data.deliveryDriver,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
    // return error.response;
  }
};

export const updateIsApprovedForDeliveryDriver = (id: string, deliveryDriver: any) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.put<DeliveryDriverResponse>(`/support/deliveryDrivers/${id}/isApproved`, deliveryDriver);
    dispatch(updateNowDeliveryDriver({
      id,
      deliveryDriver: resp.data.deliveryDriver,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
    // return error.response;
  }
};

export const sendMessageToDrivers = (filters = {}, data: any) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const resp = await dashboardBackendApi.post<any>(`/support/deliveryDrivers/sendMessage?${_filters}`, data);
    console.log(resp);
    // dispatch(setDeliveryDrivers({
    //   deliveryDrivers: resp.data.deliveryDrivers,
    //   totalDeliveryDrivers: resp.data.totalDeliveryDrivers,
    // }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const sendMessageToDriver = (id: string, data: any) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.post<any>(`/support/deliveryDrivers/sendMessage/${id}`, data);
    console.log(resp);
    // dispatch(setDeliveryDriver({
    //   deliveryDriver: resp.data.deliveryDriver,
    // }));
  } catch (error) {
    handleAxiosError(error, dispatch);
    // return error.response;
  }
};

interface PayProducts {
  driverId: string,
  isSandbox: boolean,
}
export const payProducts = ({ driverId, isSandbox }: PayProducts) => async (dispatch: any) => {
  console.log('@services:payProducts');
  try {
    const resp = await dashboardBackendApi.post<any>(`/support/deliveryDrivers/${driverId}/payproducts`, { isSandbox });
    dispatch(getDeliveryDriver(driverId));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
  return 0;
};

export const cancelDriverInvoice = (invoiceId: string) => async (dispatch: any) => {
  console.log('@cancelInvoice');
  try {
    const resp = await dashboardBackendApi.post<any>(`/support/invoices/${invoiceId}/cancel`);
    console.log('resp', resp);
    dispatch(updateDriverInvoice(resp.data.invoice));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const getDeliveryDriversByCoords = async ({ coords, distance }: any) => {
  console.log('@getDeliveryDriversByCoords');
  try {
    const { data } = await dashboardBackendApi.post<any>('/support/deliveryDrivers/coords', { coords, distance });
    return data.drivers;
  } catch (error) {
    console.log({ error });
    return null;
  }
};
export const getDeliveryDriversByCoordsZone = async ({ coords, zoneId, filters }: any) => {
  console.log('@getDeliveryDriversByCoordsZone');
  try {
    const _filters = serialize(filters);
    const { data } = await dashboardBackendApi.post<any>(`/support/deliveryDrivers/coordsZone?${_filters}`, { coords, zoneId });
    return {
      totalDeliveryDrivers: data.totalDeliveryDrivers,
      deliveryDrivers: data.deliveryDrivers,
    };
  } catch (error) {
    console.log({ error });
    return null;
  }
};
