import { FC, useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import ResizableReactTable from '../../../components/ResizableReactTable';
import { formatDate } from '../../../helpers';
import CreateTableData from '../../../helpers/createTableData';
import { Invoice } from '../../../interfaces';
import { cancelBusinessInvoice, getInvoicesReceivedByBusiness } from '../../../services/business';
import { useAppDispatch, useAppSelector } from '../../../store';
import { ButtonView } from '../../../components/buttons';
import { ModalInvoice } from '../../Invoices/components';
import { markAsPaid } from '../../../services/invoice';

interface Props {
  from: any;
  to: any;
}

const getStatusLabel = (status: string) => {
  switch (status) {
    case 'paid': return 'Pagado';
    case 'pending': return 'Pendiente';
    default: return status;
  }
};
export const CardInvoicesReceivedBy: FC<Props> = ({ from, to }) => {
  const dispatch = useAppDispatch();
  const { isSandbox } = useAppSelector(state => state.app);
  const {
    business,
    invoicesReceivedBy,
    totalInvoicesReceivedBy,
  } = useAppSelector(state => state.businesses);
  const [data, setData] = useState<any[]>([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const columns = [
    { Header: 'Fecha', accessor: 'createdAt' },
    { Header: 'Fecha de pago', accessor: 'paymentDate' },
    { Header: 'Status', accessor: 'status' },
    { Header: 'Monto', accessor: 'total' },
    { Header: 'Sandbox', accessor: 'sandbox' },
    { Header: 'Acciones', accessor: 'actions' },
  ];

  useEffect(() => {
    if (invoicesReceivedBy.length > 0) {
      const newData: any[] = [];
      invoicesReceivedBy.map((item: Invoice) => {
        const obj = {
          ...item,
          createdAt: formatDate(item.createdAt),
          paymentDate: item.status !== 'paid' ? '' : formatDate(item.paymentDate),
          total: `$${item.total.toFixed(2)} MXN`,
          sandbox: item.isSandbox === true ? 'Si' : 'No',
          status: getStatusLabel(item.status),
          actions: (
            <>
              <ButtonView
                onClick={() => {
                  setInvoice(item);
                  setIsOpen(true);
                }}
              />
            </>
          ),
        };
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [invoicesReceivedBy]);

  const getFilters = () => {
    const filters: any = {};
    filters.page = pageIndex + 1;
    filters.limit = 10;
    filters.sortBy = '_id:desc';
    filters.isSandbox = isSandbox ? 'yes' : 'no';
    if (from && to) {
      filters.from = from;
      filters.to = to;
    }
    return filters;
  };

  const fetchData = () => {
    if (business) {
      const filters = getFilters();
      dispatch(getInvoicesReceivedByBusiness(business._id, filters));
    }
  };

  useEffect(() => {
    fetchData();
  }, [business, pageIndex, from, to, isSandbox]);

  const _markAsPaid = (invoiceId: string) => {
    console.log('@markAsPaid:component', invoiceId);
    try {
      dispatch(markAsPaid(invoiceId));
    } catch (e) {
      console.error(e);
    }
  };

  const markAsCancel = (invoiceId: string) => {
    try {
      dispatch(cancelBusinessInvoice(invoiceId));
      setIsOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  const reactTableData = CreateTableData(data, columns);
  return (
    <Card>
      <CardBody>
        <div>
          <div>
            <h4>Facturas</h4>
          </div>
        </div>
        {invoice && <ModalInvoice isOpen={isOpen} setIsOpen={setIsOpen} invoice={invoice} markAsPaid={_markAsPaid} markAsCancel={markAsCancel} />}
        <ResizableReactTable
          reactTableData={reactTableData}
          totalRecords={totalInvoicesReceivedBy}
          manualPageSize={[10]}
          limit={10}
          setLimit={() => { }}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          totalNoOfPages={Math.ceil(totalInvoicesReceivedBy / 10)}
        />
      </CardBody>
    </Card>
  );
};
