import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import ResizableReactTable from '../../../components/ResizableReactTable';
import CreateTableData from '../../../helpers/createTableData';
import { RootState } from '../../../store';
import { getTransports } from '../../../services/transport';
import { ButtonEdit } from '../../../components/buttons/ButtonsIcon';
import { Transport } from '../../../interfaces';
import { changeTransportLabel } from '../../../helpers/transport';
import { formatDate } from '../../../helpers';
import { ModalTransport } from './';
import { ButtonPrimary } from '../../../components/buttons/ButtonPrimary';

interface Props { }

const columns = [
  { Header: 'Fecha de creación', accessor: 'createdAt' },
  { Header: 'Nombre', accessor: 'name' },
  { Header: 'Costo base', accessor: 'baseCost' },
  { Header: 'Distancia base', accessor: 'baseDistanceInKm' },
  { Header: 'Costo Km extra', accessor: 'costKmExtra' },
  { Header: 'Costo locación extra', accessor: 'costLocationExtra' },
  { Header: 'Acciones', accessor: 'actions' },
];

export const CardTransports: FC<Props> = () => {
  const [data, setData] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [transport, setTransport] = useState<Transport | null>(null);
  const { transports } = useSelector((state: RootState) => state.transports);
  const dispatch = useDispatch();

  const fetchData = () => {
    dispatch(getTransports());
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (transports.length > 0) {
      const newData: any[] = [];
      transports.map((item) => {
        const obj = {
          ...item,
          actions: (
            <>
              <ButtonEdit
                onClick={() => {
                  setTransport(item);
                  setIsOpen(true);
                }}
              />
            </>
          ),
          name: changeTransportLabel(item.name),
          baseCost: `$${item.baseCost} MXN`,
          baseDistanceInKm: `${item.baseDistanceInKm} km`,
          costKmExtra: `$${item.costKmExtra} MXN`,
          costLocationExtra: `$${item.costLocationExtra} MXN`,
        };
        if (obj.createdAt) {
          obj.createdAt = formatDate(item.createdAt);
        }
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [transports]);

  const reactTableData = CreateTableData(data, columns);

  return (
    <Card>
      <CardBody>
        <div className="react-table__wrapper">
          <div className="card__title">
            <h5 className="bold-text">Transportes</h5>
          </div>
          <div>
            <ButtonPrimary
              label="Crear Transporte"
              onClick={() => {
                setTransport(null);
                setIsOpen(true);
              }}
            />
          </div>
        </div>
        <ModalTransport isOpen={isOpen} setIsOpen={setIsOpen} transport={transport} />
        {
          data.length === 0 ? <h3>No hay info que se pueda mostrar</h3> : (
            <ResizableReactTable
              reactTableData={reactTableData}
              pagination={false}
            />
          )
        }
      </CardBody>
    </Card>
  );
};
