import { FormApi, SubmissionErrors } from 'final-form';
import { FC } from 'react';
import { Form } from 'react-final-form';
import { Button } from 'reactstrap';
import { InputField } from '../../../components/form/InputField';
import { Transport } from '../../../interfaces';

interface Props {
  onSubmit: (values: any, form: FormApi<any, any>, callback?: ((errors?: SubmissionErrors) => void) | undefined) => void;
  transport?: Transport | null;
}

export const FormTransport: FC<Props> = ({ onSubmit, transport = null }) => {
  const validate = (values: any) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = 'El nombre es obligatorio';
    }
    return errors;
  };

  return (
    <Form onSubmit={onSubmit} validate={validate} initialValues={transport}>
      {({ handleSubmit }) => (
        <form className="form" onSubmit={handleSubmit}>
          <InputField
            label="Nombre"
            name="name"
            placeholder="Nombre del transporte"
          />
          <InputField
            label="Slug"
            name="slug"
            placeholder="Slug del transporte"
          />
          <InputField
            label="Costo base"
            name="baseCost"
            placeholder="Costo base"
          />
          <InputField
            label="Kilometros base"
            name="baseDistanceInKm"
            placeholder="Kilometros base"
          />
          <InputField
            label="Costo kilometro extra"
            name="costKmExtra"
            placeholder="Costo kilometro extra"
          />
          <InputField
            label="Costo locación extra"
            name="costLocationExtra"
            placeholder="Costo locación extra"
          />
          <div>
            <Button
              size="sm"
              color="primary"
              type="submit"
            >
              {transport ? 'Editar transporte' : 'Crear transporte'}
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};
