import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Props {
  chats: [],
  messages: [],
  totalCount: number,
}

const initialState: Props = {
  // invoices: [],
  // invoicesPending: [],
  // totalInvoices: 0,
  // txtFile: '',
  chats: [],
  totalCount: 0,
  messages: [],
};

export const chatsMessagesSlice = createSlice({
  name: 'whatsapp',
  initialState,
  reducers: {
    setChats: (state, action: PayloadAction<any>) => {
      state.chats = action.payload.chats || [];
      state.totalCount = action.payload.totalCount || 0;
    },
    setMessages: (state, action: PayloadAction<any>) => {
      state.messages = action.payload.messages || [];
    },    
  },
});

// Action creators are generated for each case reducer function
export const {
  // setInvoices,
  // setInvoicesPending,
  // setTxtFile,
  setChats,
  setMessages,
} = chatsMessagesSlice.actions;

export default chatsMessagesSlice.reducer;
