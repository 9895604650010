import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import FormField from '../shared/form/FormField';

export const InputField = ({ label, name, placeholder, type, defaultValue }) => {
  return (
    <div className="form__form-group">
      <label className="form__form-group-label typography-message" htmlFor={name}>{label}</label>
      <div className="form__form-group-field">
        <Field
          name={name}
          type={type}
          component={FormField}
          placeholder={placeholder}
          defaultValue={defaultValue}
        />
      </div>
    </div>
  );
};

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  defaultValue: PropTypes.string,
};

InputField.defaultProps = {
  placeholder: '',
  type: 'text',
  defaultValue: undefined,
};
