/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { ButtonView } from '../../components/buttons';
import { InputSearch } from '../../components/inputs';

import ResizableReactTable from '../../components/ResizableReactTable';
import CreateTableData from '../../helpers/createTableData';
import { getBusinesses } from '../../services/business';
import { useAppSelector, useAppDispatch } from '../../store/index';
import { Business } from '../../interfaces/business';
import { formatDate } from '../../helpers';
import { setLimit, setPageIndex, setSearchText } from '../../store/slices/businesses';

const columns = [
  { Header: 'Sandbox', accessor: 'sandbox' },
  { Header: 'Product Balance', accessor: 'productBalance' },
  { Header: 'Fecha de creación', accessor: 'createdAt' },
  { Header: 'Nombre', accessor: 'name' },
  { Header: 'Acciones', accessor: 'actions' },
];

const BusinessesIncome = () => {
  const {
    businesses,
    totalBusinesses,
    limit,
    pageIndex,
    searchText,
  } = useAppSelector(state => state.businesses);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { isSandbox } = useAppSelector(state => state.app);

  const [data, setData] = useState<Business[]>([]);
  const [manualPageSize] = useState([10, 20, 50, 100]);

  const getFilters = () => {
    const filters: any = {};
    filters.limit = limit;
    filters.page = pageIndex + 1;
    filters.isSandbox = isSandbox ? 'yes' : 'no';
    filters.sortBy = '_id:desc';
    filters.accountBalance = '<0';
    return filters;
  };

  const getAll = () => {
    try {
      const filters = getFilters();
      dispatch(getBusinesses(filters));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAll();
  }, [limit, pageIndex, searchText, isSandbox]);



  useEffect(() => {
    if (businesses.length > 0) {
      const newData: any[] = [];
      businesses.map((item) => {
        const { accountBalance, accountBalanceSandbox } = item;
        const obj = {
          ...item,
          productBalance: !isSandbox ? (accountBalance?.productBalance || 0) : (accountBalanceSandbox?.productBalance || 0),
          actions: (
            <>
              <ButtonView
                onClick={() => history.push(`/dashboard/businesses/${item._id}`)}
              />
            </>
          ),
        };
        if (obj.createdAt) {
          obj.createdAt = formatDate(item.createdAt);
        }
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [businesses]);

  const reactTableData = CreateTableData(data, columns);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title">
                  <h5 className="bold-text">COBROS Negocios</h5>
                </div>
              </div>
              <InputSearch
                value={searchText}
                onChange={e => dispatch(setSearchText(e.target.value))}
                placeholder="Introduce nombre, sitio web, teléfono"
                style={{
                  marginBottom: '5px',
                  width: '75%',
                }}
              />
              {
                data.length === 0
                  ? <h3> No hay info que se pueda mostrar</h3>
                  : (
                    <ResizableReactTable
                      reactTableData={reactTableData}
                      totalRecords={totalBusinesses}
                      manualPageSize={manualPageSize}
                      limit={limit}
                      setLimit={l => dispatch(setLimit(l))}
                      pageIndex={pageIndex}
                      setPageIndex={p => dispatch(setPageIndex(p))}
                      totalNoOfPages={Math.ceil(totalBusinesses / limit)}
                    />
                  )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BusinessesIncome;
