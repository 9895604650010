import { FC } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { PolygonZone } from './PolygonZone';

interface Props { }

export const Map: FC<Props> = () => {
  const { zones } = useSelector((state: RootState) => state.zones);
  const mapContainerStyle = {
    minHeight: '800px',
    height: '95%',
    width: '95%',
  };

  const center = { lat: 20.79389196674323, lng: -103.40336756229398 };

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={5}
      center={center}
    >
      {
        zones.map(zone => (
          <PolygonZone
            zone={zone}
            key={zone._id}
          />
        ))
      }
    </GoogleMap>
  );
};
