import { FormApi, SubmissionErrors } from 'final-form';
import { FC } from 'react';
import { Form } from 'react-final-form';
import { Button } from 'reactstrap';
import { DropdownInputField, InputField, PasswordInputField } from '../../../components/form';
import { SupportAdmin } from '../../../interfaces';

interface Props {
  onSubmit: (values: any, form: FormApi<any, any>, callback?: ((errors?: SubmissionErrors) => void) | undefined) => void;
  supportAdmin?: SupportAdmin | null;
}

const OPTIONS = [
  {
    label: 'Administrador de soporte',
    value: 'supportAdmin',
  },
  {
    label: 'Vendedor',
    value: 'hunter',
  },
];

export const FormSupportAdmin: FC<Props> = ({ onSubmit, supportAdmin = null }) => {
  const validate = (values: any) => {
    const errors: any = {};
    if (!values.email) {
      errors.email = 'El correo es obligatorio';
    }
    if (!values.userType) {
      errors.userType = 'El tipo de usuario es obligatorio';
    }
    if (!supportAdmin) {
      if (!values.password) {
        errors.password = 'La contraseña es obligatoria';
      }
    }
    return errors;
  };

  const selectUserType = (data: any) => {
    console.log(data);
    return data;
  };

  return (
    <Form onSubmit={onSubmit} validate={validate} initialValues={supportAdmin}>
      {({ handleSubmit }) => (
        <form className="form" onSubmit={handleSubmit}>
          <InputField
            label="Nombre"
            name="firstName"
            placeholder="Nombre"
          />
          <InputField
            label="Apellido"
            name="lastName"
            placeholder="Apellido"
          />
          <InputField
            label="Correo"
            name="email"
            type="email"
            placeholder="Correo"
          />
          <InputField
            label="Celular"
            name="phoneNumber.number"
            placeholder="Teléfono"
          />
          <PasswordInputField
            label="Contraseña"
            name="password"
            placeholder="Contraseña"
          />
          <DropdownInputField
            label="Tipo de usuario"
            name="userType"
            options={OPTIONS}
            placeholder="Selecciona un tipo de cuenta"
            onSelect={selectUserType}
          />
          <InputField
            label="Código para referir"
            name="referralCode"
            placeholder="Código"
          />
          <div>
            <Button
              size="sm"
              color="primary"
              type="submit"
            >
              {supportAdmin ? 'Editar' : 'Crear'}
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};
