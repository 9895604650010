import { combineReducers } from '@reduxjs/toolkit';
import auth from './slices/auth';
import deliveryDrivers from './slices/deliveryDrivers';
import deliveryServices from './slices/deliveryServices';
import invoices from './slices/invoices';
import payments from './slices/payments';
import theme from './slices/theme';
import rtl from './slices/rtl';
import sidebar from './slices/sidebar';
import businesses from './slices/businesses';
import businessEstablishments from './slices/businessEstablishments';
import transports from './slices/transports';
import zones from './slices/zones';
import apiDevelopers from './slices/apiDevelopers';
import notifications from './slices/notifications';
import chatMessages from './slices/chatMessages';
import deliveryProviders from './slices/deliveryProviders';
import deliveryQuotes from './slices/deliveryQuotes';
import app from './slices/app';
import logs from './slices/logs';
import supportAdmins from './slices/supportAdmins';
import whatsapp from './slices/whatsapp';
import campaigns from './slices/campaigns';

const combinedReducer = combineReducers({
  auth,
  deliveryDrivers,
  deliveryServices,
  invoices,
  payments,
  theme,
  rtl,
  sidebar,
  businesses,
  businessEstablishments,
  transports,
  zones,
  apiDevelopers,
  notifications,
  chatMessages,
  deliveryProviders,
  deliveryQuotes,
  app,
  logs,
  supportAdmins,
  whatsapp,
  campaigns,
});

export const rootReducer = (state: any, action: any) => {
  if (action.type === 'RESET') {
    state = undefined;
  }
  return combinedReducer(state, action);
};
