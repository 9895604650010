
import { FC, useState } from 'react';
import { Button, Card, CardBody, Input } from 'reactstrap';
import { RootState, useAppDispatch, useAppSelector } from '../../../store/index';
import { formatDate } from '../../../helpers/date';
import { updateLogoDeliveryProvider } from '../../../services/deliveryProvider';
import { getUrl } from '../../../helpers';

interface Props { }

export const CardInfo: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { deliveryProvider } = useAppSelector((state: RootState) => state.deliveryProviders);
  const [image, setImage] = useState<any>(null);

  if (!deliveryProvider) {
    return (
      <Card>
        <CardBody className="profile__card">
          <div className="profile__information">
            <p>cargando...</p>
          </div>
        </CardBody>
      </Card>
    );
  }

  const updateLogo = () => {
    dispatch(updateLogoDeliveryProvider(deliveryProvider._id, image));
  };

  return (
    <>
      <Card>
        <CardBody>
          <h4>{deliveryProvider._id}</h4>
          <p>
            <strong>Nombre: </strong> {deliveryProvider.name}
          </p>
          <p>
            <strong>Slug: </strong> {deliveryProvider.slug}
          </p>
          {
            deliveryProvider.token && (
              <p>
                <strong>Token: </strong> {deliveryProvider.token}
              </p>
            )
          }
          <p>
            <strong>Fecha de creación: </strong> {formatDate(deliveryProvider.createdAt)}
          </p>
          {
            deliveryProvider.logoImageId ? (
              <>
                <div className="d-flex align-items-center">
                  <p>
                    <strong>Logo: </strong>
                  </p>
                  <div className="ml-3">
                    <img alt="Profile" src={getUrl(deliveryProvider.logoImageId, 150, 80)} />
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <Input
                    type="file"
                    id="business-logo"
                    name="image"
                    className="ml-2"
                    style={{ width: '130px' }}
                    onChange={(event: any) => {
                      const reader = new FileReader();
                      reader.readAsDataURL(event.currentTarget.files[0]);
                      reader.onloadend = () => {
                        setImage(reader.result);
                      };
                      reader.onerror = () => {
                        console.error('Error!!');
                      };
                    }}
                  />
                  <Button
                    className="ml-2 align-content-end"
                    size="sm"
                    style={{ marginBottom: '0' }}
                    disabled={!image}
                    onClick={updateLogo}
                  >
                    Cambiar
                  </Button>
                </div>
              </>
            ) : (
              <div className="d-flex align-items-center">
                <p>
                  <strong>Logo: </strong>
                </p>
                <Input
                  type="file"
                  id="business-logo"
                  name="image"
                  className="ml-2"
                  style={{ width: '130px' }}
                  onChange={(event: any) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(event.currentTarget.files[0]);
                    reader.onloadend = () => {
                      setImage(reader.result);
                    };
                    reader.onerror = () => {
                      console.error('Error!!');
                    };
                  }}
                />
                <Button
                  className="ml-2 align-content-end"
                  size="sm"
                  style={{ marginBottom: '0' }}
                  disabled={!image}
                  onClick={updateLogo}
                >
                  Enviar
                </Button>
              </div>
            )
          }
        </CardBody>
      </Card>
    </>
  );
};
