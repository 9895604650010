import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { TransportSelector, PaymentSelector, CashSelector } from './';
import { RootState } from '../../../store';

interface Props {
  state: {
    cash: any;
    paymentMethod: 'cash' | 'card';
    transport: string;
  };
  dispatch: React.Dispatch<any>;
}

export const StepTwo: FC<Props> = ({ state, dispatch }) => {
  const { transports } = useSelector((state: RootState) => state.transports);

  if (transports.length === 0) return <div className="loading" />;

  return (
    <div className="service step-two">
      <div>
        <h3>¿Necesitaremos dinero?</h3>
        <CashSelector
          value={state.cash}
          onChange={(amount: number) => dispatch({ type: 'changeCash', payload: amount })}
        />
      </div>

      <div>
        <h3>Método de pago</h3>
        <PaymentSelector
          value={state.paymentMethod}
          onChange={(method: string) => dispatch({ type: 'changePayment', payload: method })}
        />
      </div>

      <div>
        <h3>Transporte</h3>
        <TransportSelector
          options={transports}
          value={state.transport}
          onChange={(method: string) => dispatch({ type: 'changeTransport', payload: method })}
        />
      </div>
    </div>
  );
};
