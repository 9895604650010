/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { LocationDetails, StateButton } from './';
import { usePrice } from '../hooks';
import { changeTransportLabel } from '../../../helpers';
import { IconCard } from '../../../components/cards/IconCard';
import backendApi from '../../../api/backendApi';
import { extractDataFromState } from '../../../helpers/extract';

interface Props {
  state: any;
}

export const StepThree: FC<Props> = ({ state }) => {
  const history = useHistory();

  const data = extractDataFromState(state);
  const { price, loading } = usePrice(data);

  const requestServiceHandler = async () => {
    try {
      const response = await backendApi.post('/api/deliveryservice/request', data);

      const { message, success } = response.data;

      if (!success) throw message;

      setTimeout(() => history.push('/dashboard/home'), 1500);
      return 'Pedido realizado!';
    } catch (error) {
      console.error(error);
      throw typeof error === 'string'
        ? error
        : 'No se pudo hacer el pedido. Intenta más tarde';
    }
  };

  if (loading) return <div className="loading" />;

  return (
    <div className="service step-three">
      <h1>Revisa el servicio</h1>

      <div className="locations">
        {state.locations.map((loc: any, index: number) => (
          <LocationDetails key={index} location={loc} />
        ))}
      </div>

      <div>
        <IconCard
          title="Método de pago"
          value={state.paymentMethod === 'card' ? 'Tarjeta de Credito/Debito' : 'Efectivo'}
          icon={state.paymentMethod}
        />
      </div>

      <div>
        <IconCard
          title="Transporte"
          // value={messages[`service.parameters.transport.${state.transport}`]}
          value={changeTransportLabel(state.transport)}
          icon={state.transport}
        />
      </div>

      {price && (
        <div className="prices">
          <IconCard
            title="Subtotal"
            value={`$${price.deliveryTotal}`}
            icon="dollar"
          />
          <IconCard
            title="Tarifa de servicio"
            value={`$${price.deliveryFeeTotal}`}
            icon="dollar"
          />
          <IconCard
            title="Total a pagar"
            value={`$${price.finalPriceTotal}`}
            icon="dollar"
          />
        </div>
      )}

      <div className="submit-container">
        <StateButton
          id="request-service-btn"
          onClick={requestServiceHandler}
          noFinally
        >
          Pedir servicio
        </StateButton>
      </div>
    </div>
  );
};
