import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalRight } from '../../../components/modals/ModalRight';
import { createDeliveryDriver, updateDeliveryDriver } from '../../../services/deliveryDrivers';
import { FormDeliveryDriver } from './';
import { DeliveryDriver } from '../../../interfaces';

// ModalDeliveryDriver.propTypes = {
//   isOpen: PropTypes.bool.isRequired,
//   setIsOpen: PropTypes.func.isRequired,
//   deliveryDriver: PropTypes.shape(),
// };

// ModalDeliveryDriver.defaultProps = {
//   deliveryDriver: null,
// };

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  deliveryDriver?: DeliveryDriver | null;
}

export const ModalDeliveryDriver: FC<Props> = ({ isOpen, setIsOpen, deliveryDriver = null }) => {
  const dispatch = useDispatch();
  const [zone, setZone] = useState<any>(deliveryDriver?.zone);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onSubmit = (data: any) => {
    if (zone) {
      data.zone = zone;
    }
    if (deliveryDriver) {
      dispatch(updateDeliveryDriver(deliveryDriver._id, data));
    } else {
      dispatch(createDeliveryDriver(data));
    }
    setIsOpen(false);
  };

  return (
    <ModalRight
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={deliveryDriver ? 'Editar Repartidor' : 'Nuevo Repartidor'}
    >
      <FormDeliveryDriver onSubmit={onSubmit} deliveryDriver={deliveryDriver} setZone={setZone} />
    </ModalRight>
  );
};
