import React, { CSSProperties } from 'react';
import { Input } from 'reactstrap';

interface Props {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  size?: 'sm' | 'lg';
  style?: CSSProperties;
}

export const InputSearch = ({ value, onChange, placeholder = '', size = 'sm', style = {} }: Props) => {
  return (
    <Input
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      bsSize={size}
      style={style}
    />
  );
};

// InputSearch.propTypes = {
//   value: PropTypes.string.isRequired,
//   onChange: PropTypes.func.isRequired,
//   placeholder: PropTypes.string,
//   size: PropTypes.string,
//   style: PropTypes.shape(),
// };

// InputSearch.defaultProps = {
//   placeholder: '',
//   size: 'sm',
//   style: {},
// };
