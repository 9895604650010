/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleMap, Marker, Polyline, InfoWindow } from '@react-google-maps/api';
import { RootState } from '../../../store/index';
import { Location, Driver } from '../../../interfaces';

import { CustomMarker } from '../../../components/maps';
import driverImg from '../../../assets/img/pines/pin-repartidor-1.png';
import driverImg2 from '../../../assets/img/pines/pin-repartidor-2.png';
import driverFounderImg from '../../../assets/img/pines/pin-dorado-02.png';
import driverFounderImg2 from '../../../assets/img/pines/pin-dorado-01.png';
import { InfoObj, InfoWindowComponent } from '../../Zone/components/MapZone';
import { ButtonPrimary } from '../../../components/buttons';
import { addDriverToRequest, addDriverToService, reAsignDriverToService } from '../../../services/deliveryServices';

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

interface Props {
  drivers: Driver[];
  setDrivers: React.Dispatch<React.SetStateAction<Driver[]>>;
}
export const MapDeliveryService: FC<Props> = ({ drivers, setDrivers }) => {
  const { deliveryService } = useSelector((state: RootState) => state.deliveryServices);
  const dispatch = useDispatch();

  const [path, setPath] = useState<{ lat: number, lng: number }[]>([]);
  const [infoObj, setInfoObj] = useState<InfoObj | null>();

  useEffect(() => {
    if (deliveryService && deliveryService.route.length > 0) {
      setPath(() => deliveryService.route.map(route => ({
        lat: route.latitude,
        lng: route.longitude,
      })));
    }
  }, [deliveryService]);

  useEffect(() => {
    setDrivers(deliveryService?.driversToRequest.map(driver => ({
      ...driver,
      isVisible: true,
    })) || []);
  }, [deliveryService]);

  // const driversToRequest = deliveryService?.driversToRequest || [];
  // console.log('driversToRequest', driversToRequest);

  const addToRequest = (driverId: string) => {
    deliveryService && dispatch(addDriverToRequest(driverId, deliveryService._id));
  };
  const addToService = (driverId: string) => {
    deliveryService && dispatch(addDriverToService(driverId, deliveryService._id));
  };
  const reAsignToService = (driverId: string) => {
    deliveryService && dispatch(reAsignDriverToService(driverId, deliveryService._id));
  };

  return (
    <GoogleMap
      mapContainerStyle={{ width: '95%', height: '85vh' }}
      zoom={13}
      center={{
        lat: deliveryService ? deliveryService.locations[0].latitude : 20.7167,
        lng: deliveryService ? deliveryService.locations[0].longitude : -103.4,
      }}
    >
      {
        deliveryService && deliveryService.locations.map((location: Location, i) => (
          <Marker
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            label={{ text: ALPHABET[i], color: 'white' }}
            // onLoad={onLoad}
            position={{
              lng: location.longitude,
              lat: location.latitude,
            }}
          />
        ))
      }
      {
        deliveryService && deliveryService.route.length > 0 && (
          <>
            <CustomMarker
              imgUrl={deliveryService?.driver?.isFounder ? driverFounderImg : driverImg}
              latitude={deliveryService.route[deliveryService.route.length - 1].latitude}
              longitude={deliveryService.route[deliveryService.route.length - 1].longitude}
              width={30}
              height={30}
              onClick={() => setInfoObj({
                _id: deliveryService?.driver?._id || '',
                title: deliveryService?.driver?.name || '',
                subtitle: deliveryService?.driver?.transportName || '--',
                latitude: deliveryService.route[deliveryService.route.length - 1].latitude,
                longitude: deliveryService.route[deliveryService.route.length - 1].longitude,
                desc: deliveryService?.driver?.phoneNumber || '',
                imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/OOjs_UI_icon_userAvatar.svg/2048px-OOjs_UI_icon_userAvatar.svg.png',
                linkUrl: `https://soporte.repartimos.mx/dashboard/deliveryDrivers/${deliveryService?.driver?._id}`,
                isVisible: true,
              })}
            />
            <Polyline
              // onLoad={onLoad}
              path={path}
            // options={options}
            />
          </>
        )
      }
      {drivers.length > 0 && drivers.map((driver: Driver) => {
        // console.log('::::::', driver);
        // const longitude = 0;
        // const latitude = 0;
        const longitude = driver.coords?.length > 0 ? driver.coords[0] : 0;
        const latitude = driver.coords?.length > 0 ? driver.coords[1] : 0;
        // const imageUrl = driver.profileImage.imageId ? getUrl(driver.profileImage.imageId) : 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/OOjs_UI_icon_userAvatar.svg/2048px-OOjs_UI_icon_userAvatar.svg.png';
        // console.log('lat', latitude, longitude);
        return (
          <CustomMarker
            key={driver._id}
            imgUrl={
              driver.isFounder
                ? driver.isVisible
                  ? driverFounderImg
                  : driverFounderImg2
                : driver.isVisible
                  ? driverImg
                  : driverImg2
            }
            latitude={latitude}
            longitude={longitude}
            width={30}
            height={30}
            onClick={() => setInfoObj({
              _id: driver._id,
              title: driver.name,
              subtitle: driver.transportName || '--',
              latitude,
              longitude,
              desc: driver.phoneNumber || '',
              imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/OOjs_UI_icon_userAvatar.svg/2048px-OOjs_UI_icon_userAvatar.svg.png',
              linkUrl: `https://soporte.repartimos.mx/dashboard/deliveryDrivers/${driver._id}`,
              addToRequest: deliveryService?.status === 'requestingDriver' && <ButtonPrimary label="Añadir al request" size="sm" onClick={() => addToRequest(driver._id)} />,
              addToService: deliveryService?.status === 'requestingDriver' && <ButtonPrimary label="Asignar servicio" size="sm" onClick={() => addToService(driver._id)} />,
              reAsignToService: deliveryService?.status === 'onRoute' && <ButtonPrimary label="Reasignar servicio" size="sm" onClick={() => reAsignToService(driver._id)} />,
              isVisible: driver.isVisible,
              appOpenedAt: driver.appOpenedAt,
            })}
          />
        );
      })}
      {infoObj && (
        <InfoWindow
          onLoad={() => { }}
          position={{
            lng: infoObj.longitude,
            lat: infoObj.latitude,
          }}
          onCloseClick={() => setInfoObj(null)}
        // options={{ closeBoxURL: '', enableEventPropagation: true }}
        >
          <InfoWindowComponent {...infoObj} />
        </InfoWindow>
      )}
    </GoogleMap>
  );
};