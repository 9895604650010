/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

interface Props {
  title: string;
  alt?: string;
  url: string;
  urlFull: string;
  isApproved: boolean;
}

export const Image: FC<Props> = ({ title, alt, url, urlFull, isApproved }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <span>{title}</span>
      <div
        style={{
          width: '200px',
          height: '200px',
          cursor: 'pointer',
        }}
        onClick={() => setIsModalOpen(!isModalOpen)}
      >
        <img
          alt={alt}
          src={url}
        />
      </div>
      <span className="text-center">
        {isApproved ? 'Aprobada' : 'No Aprobada'}
      </span>
      <Modal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(!isModalOpen)}
      >
        <ModalHeader toggle={() => setIsModalOpen(false)} />
        <ModalBody>
          <img
            alt={alt}
            src={urlFull}
          />
        </ModalBody>
      </Modal>
    </>
  );
};
