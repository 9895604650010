import { AccountTypeBankData, BankData } from '../interfaces';

export const changeAccoutTypeLabel = (accountType: AccountTypeBankData) => {
  switch (accountType) {
    case 'account':
      return 'Cuenta';
    case 'card':
      return 'Tarjeta';
    case 'clabe':
      return 'CLABE';

    default:
      return accountType;
  }
};

export const valueAccountType = ({ accountType, accountNumber, cardNumber, clabe }: BankData) => {
  const regex = new RegExp('\\d{1,4}', 'g');

  switch (accountType) {
    case 'account':
      return accountNumber;
    case 'card':
      return cardNumber?.match(regex)?.join(' ');
    case 'clabe':
      return clabe;
    default:
      return '';
  }
};