/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import ResizableReactTable from '../../components/ResizableReactTable';
import { formatDateTime } from '../../helpers';
import CreateTableData from '../../helpers/createTableData';
import { useAppDispatch, useAppSelector } from '../../store';
import { ButtonView } from '../../components/buttons';
import { DatePicker } from '../../components/DatePicker';
import { getLogs } from '../../services/logs';
import { setPageIndex, setLimit } from '../../store/slices/logs';
import { ModalLog } from './components';

const columns = [
  { Header: 'Fecha', accessor: 'createdAt' },
  { Header: 'Nombre', accessor: 'name' },
  { Header: 'Env', accessor: 'env' },
  { Header: 'Acciones', accessor: 'actions' },
];

const Logs = () => {
  const history = useHistory();

  const dispatch = useAppDispatch();
  const {
    logs,
    totalLogs,
    limit = 10,
    pageIndex = 0,
  } = useAppSelector(state => state.logs);

  const [log, setLog] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [data, setData] = useState<any[]>([]);
  const [manualPageSize] = useState([10, 20, 50, 100]);

  const getFilters = () => {
    const filters: any = {};
    if (from && to) {
      filters.from = from;
      filters.to = to;
    }
    filters.limit = limit;
    filters.page = pageIndex + 1;
    filters.sortBy = '_id:desc';
    return filters;
  };

  const getAll = () => {
    try {
      const filters = getFilters();
      dispatch(getLogs(filters));
    } catch (error) {
      console.error({ error });
    }
  };

  useEffect(() => {
    getAll();
  }, [limit, pageIndex, from, to]);

  useEffect(() => {
    if (logs.length > 0) {
      const newData: any[] = [];
      logs.map((item: any) => {
        const obj = {
          ...item,
          business: item.businessId && (
            <>
              <ButtonView
                onClick={() => history.push(`/dashboard/businesses/${item.businessId}`)}
              />
            </>
          ),
          actions: (
            <>
              <ButtonView
                onClick={() => {
                  setLog(item);
                  setIsOpenModal(true);
                }}
              />
            </>
          ),
        };
        if (obj.createdAt) {
          obj.createdAt = formatDateTime(item.createdAt);
        }
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [logs]);

  const handleDateChange = (fromDate: any, toDate: any) => {
    setFrom(fromDate);
    setTo(toDate);
    dispatch(setPageIndex(0));
  };

  const reactTableData = CreateTableData(data, columns);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title">
                  <h5 className="bold-text">Logs</h5>
                </div>
              </div>
              <Row>
                <DatePicker handleDateChange={handleDateChange} from={from} to={to} />
              </Row>
              {log && <ModalLog isOpen={isOpenModal} setIsOpen={setIsOpenModal} log={log} />}
              {
                data.length === 0
                  ? <h3>No hay info que se pueda mostrar</h3>
                  : (
                    <ResizableReactTable
                      reactTableData={reactTableData}
                      totalRecords={totalLogs}
                      manualPageSize={manualPageSize}
                      limit={limit}
                      setLimit={l => dispatch(setLimit(l))}
                      pageIndex={pageIndex}
                      setPageIndex={(p: any) => {
                        dispatch(setPageIndex(p));
                      }}
                      totalNoOfPages={Math.ceil(totalLogs / limit)}
                    />
                  )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Logs;
