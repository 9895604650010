/* eslint-disable react/no-array-index-key */
import { FormApi, SubmissionErrors } from 'final-form';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { Button, ButtonGroup, Input } from 'reactstrap';
import { DropdownInputField, TextAreaField } from './';
import { ComponentWhatsApp, WhatsAppTemplate } from '../../interfaces';

interface IButton {
  type: string;
  text: string;
  url?: string;
  phone_number?: string;
}
interface Props {
  onSubmit: (values: any, form: FormApi<any, any>, callback?: ((errors?: SubmissionErrors) => void) | undefined) => void;
  channel: "Push" | "WA" | "SMS";
  setChannel: Dispatch<SetStateAction<"Push" | "WA" | "SMS">>;
  templates: WhatsAppTemplate[];
  template: WhatsAppTemplate | undefined;
  setTemplate: Dispatch<SetStateAction<WhatsAppTemplate | undefined>>;
  componentHeader: ComponentWhatsApp[];
  setComponentHeader: Dispatch<SetStateAction<ComponentWhatsApp[]>>;
  componentBody: ComponentWhatsApp[];
  setComponentBody: Dispatch<SetStateAction<ComponentWhatsApp[]>>;
  chanels?: ("Push" | "WA" | "SMS")[];
}

export const FormSendMessage: FC<Props> = ({
  onSubmit,
  channel,
  setChannel,
  templates,
  template,
  setTemplate,
  componentHeader,
  setComponentHeader,
  componentBody,
  setComponentBody,
  chanels = ['Push', 'WA', 'SMS'],
}) => {
  const [textHeader, setTextHeader] = useState('');
  const [textBody, setTextBody] = useState('');
  const [textFooter, setTextFooter] = useState('');
  const [buttons, setButtons] = useState<IButton[]>([]);

  useEffect(() => {
    if (textHeader) {
      const result = template?.header?.replace(/{{(\w+)}}/g, (match: any, key: number) => componentHeader[key - 1].text || match) || '';
      setTextHeader(result);
    }
    if (textBody) {
      const result = template?.body?.replace(/{{(\w+)}}/g, (match: any, key: number) => componentBody[key - 1].text || match) || '';
      setTextBody(result);
    }
  }, [componentHeader, componentBody]);

  const options: any[] = templates.map((template: any) => {
    return {
      value: template,
      label: template.name,
    };
  });

  const validate = (values: any) => {
    const errors: any = {};
    if (channel === 'Push' || channel === 'SMS') {
      if (!values.text) {
        errors.text = 'El texto es obligatorio';
      }
    }
    return errors;
  };

  const selectTemplate = (data: any) => {
    setTemplate(data.value);
    const { header, body } = data.value;
    const componentFooter = data.value.footer;
    const componentButtons = data.value.buttons;

    setTextBody(body);

    const countBody = (body.match(/{{(\w+)}}/g) || []).length;

    const newComponentBody = [];
    for (let i = 0; i < countBody; i += 1) {
      newComponentBody.push({
        type: 'text',
        text: '',
      });
    }
    setComponentBody(newComponentBody);
    if (header) {
      setTextHeader(header);
      const countHeader = (header.match(/{{(\w+)}}/g) || []).length;
      const newComponentHeader = [];
      for (let i = 0; i < countHeader; i += 1) {
        newComponentHeader.push({
          type: 'text',
          text: '',
        });
      }
      setComponentHeader(newComponentHeader);
    }
    if (componentFooter) {
      setTextFooter(componentFooter);
    }
    if (componentButtons && componentButtons.length > 0) {
      const newButtons = componentButtons.map((button: any) => {
        if (button.type === 'URL') {
          return {
            ...button,
            url: '',
          };
        }
        if (button.type === 'PHONE_NUMBER') {
          return {
            ...button,
            phone_number: '',
          };
        }
        return button;
      });
      setButtons(newButtons);
    }
  };

  const changeArrayTextHeader = (value: string, i: number) => {
    setComponentHeader(comp => comp.map((val, index) => (index === i ? {
      type: 'text',
      text: value,
    } : val)));
  };

  const changeArrayTextBody = (value: string, i: number) => {
    setComponentBody(comp => comp.map((val, index) => (index === i ? {
      type: 'text',
      text: value,
    } : val)));
  };

  // const updateButton = (value: string, index: number, field: 'url' | 'phone_number') => {
  //   setButtons(buttons => buttons.map((b, i) => (index === i ? {
  //     ...b,
  //     [field]: value,
  //   } : b)));
  // };

  return (
    <Form onSubmit={onSubmit} validate={validate} initialValues={{}}>
      {({ handleSubmit }) => (
        <form className="form" onSubmit={handleSubmit}>
          <ButtonGroup>
            {
              chanels.includes('Push') && (
                <Button
                  color="primary"
                  outline
                  onClick={() => setChannel('Push')}
                  active={channel === 'Push'}
                >
                  Push Notification
                </Button>
              )
            }
            {
              chanels.includes('SMS') && (
                <Button
                  color="primary"
                  outline
                  onClick={() => setChannel('SMS')}
                  active={channel === 'SMS'}
                >
                  SMS
                </Button>
              )
            }
            {
              chanels.includes('WA') && (
                <Button
                  color="primary"
                  outline
                  onClick={() => setChannel('WA')}
                  active={channel === 'WA'}
                >
                  Whatsapp
                </Button>
              )
            }
          </ButtonGroup>
          {
            (channel === 'Push' || channel === 'SMS') && (
              <TextAreaField
                label="Texto"
                name="text"
                placeholder="Texto"
              />
            )
          }
          {
            channel === 'WA' && (
              <>
                <DropdownInputField
                  label="Template"
                  name="state"
                  options={options}
                  placeholder={template?.name || 'Selecciona un template'}
                  onSelect={selectTemplate}
                />
                {
                  textHeader && (
                    <>
                      <p className="w-100" style={{ wordWrap: 'break-word' }}>Header:</p>
                      <p className="w-100" style={{ wordWrap: 'break-word' }}>{textHeader}</p>
                      {
                        componentHeader.map((value, index) => (
                          <div className="form__form-group" key={index}>
                            <label className="form__form-group-label typography-message" htmlFor="text">{index + 1}</label>
                            <div className="form__form-group-field">
                              <Input
                                name="textHeader"
                                value={value.text}
                                onChange={e => changeArrayTextHeader(e.target.value, index)}
                                placeholder="Texto"
                              />
                            </div>
                          </div>
                        ))
                      }
                    </>
                  )
                }
                {
                  textBody && (
                    <>
                      <p className="w-100" style={{ wordWrap: 'break-word' }}>Body:</p>
                      <p className="w-100" style={{ wordWrap: 'break-word' }}>{textBody}</p>
                      {
                        componentBody.map((value, index) => (
                          <div className="form__form-group" key={index}>
                            <label className="form__form-group-label typography-message" htmlFor="text">{index + 1}</label>
                            <div className="form__form-group-field">
                              <Input
                                name="textBody"
                                value={value.text}
                                onChange={e => changeArrayTextBody(e.target.value, index)}
                                placeholder="Texto"
                              />
                            </div>
                          </div>
                        ))
                      }
                    </>
                  )
                }
                {
                  textFooter && (
                    <>
                      <p className="w-100" style={{ wordWrap: 'break-word' }}>Footer:</p>
                      <p className="w-100" style={{ wordWrap: 'break-word' }}>{textFooter}</p>
                    </>
                  )
                }
                {
                  buttons && buttons.length > 0 && (
                    <>
                      <p className="mt-4 w-100" style={{ wordWrap: 'break-word' }}>Buttons:</p>
                      {
                        buttons.map((button, index) => (
                          <div className="form__form-group" key={index}>
                            <label className="form__form-group-label typography-message" htmlFor="text">{button.text}</label>
                            {/* {
                              button.type === 'URL' && (
                                <div className="form__form-group-field">
                                  <Input
                                    name="url"
                                    value={button.url}
                                    onChange={e => updateButton(e.target.value, index, 'url')}
                                    placeholder="Texto"
                                  />
                                </div>
                              )
                            }
                            {
                              button.type === 'PHONE_NUMBER' && (
                                <div className="form__form-group-field">
                                  <Input
                                    name="phone_number"
                                    value={button.phone_number}
                                    onChange={e => updateButton(e.target.value, index, 'phone_number')}
                                    placeholder="Texto"
                                  />
                                </div>
                              )
                            } */}
                          </div>
                        ))
                      }
                    </>
                  )
                }
              </>
            )
          }
          <div className="w-100 d-flex justify-content-end">
            <Button
              size="sm"
              color="primary"
              type="submit"
            >
              Enviar
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};
