import { FC } from 'react';
import { WithWizard } from 'react-albus';
import { Button } from 'reactstrap';

interface Props {
  className: string;
  onClickPrev: (goToPrev: any, steps: any, step: any) => void;
  prevLabel: string;
  onClickNext: (goToNext: any, steps: any, step: any) => void;
  nextLabel: string;
  finalLabel?: string;
  withSubmit?: boolean;
  nextDisabled?: boolean;
  prevDisabled?: boolean;
  loading?: boolean;
}

export const BottomNavigation: FC<Props> = ({
  className,
  onClickPrev,
  prevLabel,
  onClickNext,
  nextLabel,
  finalLabel = nextLabel,
  withSubmit = false,
  nextDisabled = false,
  prevDisabled = false,
  loading = false,
}) => {
  return (
    <WithWizard
      render={({ next, previous, step, steps }) => {
        const currentStep = steps.indexOf(step);
        const isLast = currentStep >= steps.length - 1;
        const isSubmitStep = currentStep === steps.length - 2;

        return (
          <div className={`wizard-buttons ${className}`}>
            <Button
              disabled={prevDisabled}
              color="primary"
              className={`mr-1 ${currentStep <= 0 ? 'disabled' : ''}`}
              onClick={() => {
                onClickPrev(previous, steps, step);
              }}
            >
              {prevLabel}
            </Button>

            <Button
              type={withSubmit && isLast ? 'submit' : 'button'}
              disabled={nextDisabled || loading}
              color="primary"
              className={`${isLast || nextDisabled ? 'disabled ' : 'btn-multiple-state '
                }${loading && 'show-spinner'}`}
              onClick={() => {
                onClickNext(next, steps, step);
              }}
            >
              <span className="spinner d-inline-block">
                <span className="bounce1" />
                <span className="bounce2" />
                <span className="bounce3" />
              </span>
              <span className="label">
                {withSubmit && isSubmitStep ? finalLabel : nextLabel}
              </span>
            </Button>
          </div>
        );
      }}
    />
  );
};
