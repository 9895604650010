import React, { useEffect, useState } from 'react';
import ReactTableBase from './shared/table/ReactTableBase';
import ReactTablePagination from './ReactTablePagination';

interface Props {
  reactTableData: {
    tableHeaderData: {
      key: string;
      name: string;
    }[];
    tableRowsData: any;
  };
  totalRecords?: number;
  manualPageSize?: number[];
  limit?: number;
  setLimit?: React.Dispatch<React.SetStateAction<number>>;
  pageIndex?: number;
  setPageIndex?: React.Dispatch<React.SetStateAction<number>>;
  totalNoOfPages?: number;
  pagination?: boolean;
}

const ResizableReactTable = ({
  reactTableData,
  totalRecords = 0,
  manualPageSize = [10],
  limit = 10,
  setLimit = () => { },
  pageIndex = 0,
  setPageIndex = () => { },
  totalNoOfPages = 1,
  pagination = true,
}: Props) => {
  const [pageOptions, setPageOptions] = useState(() => {
    const array = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < totalNoOfPages; i++) {
      array.push(i);
    }
    return array;
  });

  useEffect(() => {
    setPageOptions(() => {
      const array = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < totalNoOfPages; i++) {
        array.push(i);
      }
      return array;
    });
  }, [totalNoOfPages]);

  const tableConfig = {
    isSortable: false,
    isEditable: false,
    isResizable: true,
    manualPageSize,
  };

  return (
    <>
      <ReactTableBase
        key="common"
        columns={reactTableData.tableHeaderData}
        data={reactTableData.tableRowsData}
        tableConfig={tableConfig}
        totalRecords={totalRecords}
      />
      {
        pagination && (
          <ReactTablePagination
            page={reactTableData.tableRowsData}
            gotoPage={setPageIndex}
            previousPage={() => setPageIndex(pageIndex - 1)}
            nextPage={() => setPageIndex(pageIndex + 1)}
            canPreviousPage={pageIndex > 0}
            canNextPage={pageIndex < pageOptions.length - 1}
            pageOptions={pageOptions}
            pageSize={limit}
            pageIndex={pageIndex}
            setPageSize={setLimit}
            manualPageSize={tableConfig.manualPageSize}
            dataLength={totalRecords}
          />
        )
      }
    </>
  );
};

export default ResizableReactTable;
