import { Notification } from '../interfaces';


export const createNotificationAlert = (data: Notification) => {
  const { category, type } = data;
  let title = '';
  let color = '';
  let message = '';
  let url: string | null = null;
  console.log({ data });
  switch (category) {
    case 'deliveryServiceLocation':
    case 'deliveryService':
      title = 'Servicio';
      url = `/dashboard/services/${data.data.deliveryServiceId}`;
      break;
    case 'deliveryDriver':
      title = 'Repartidor';
      url = `/dashboard/deliveryDrivers/${data.data.deliveryDriverId}`;
      break;
    case 'business':
      title = 'Negocio';
      url = `/dashboard/businesses/${data.data.businessId}`;
      break;
    default:
      title = category;
      break;
  }
  switch (type) {
    case 'create':
      color = 'primary';
      message = 'Creado';
      break;
    case 'cancel':
      color = 'danger';
      message = 'Cancelado';
      break;
    default:
      color = 'primary';
      message = type;
      break;
  }
  return {
    title,
    color,
    message,
    url,
  };
};