import React, { FC, useEffect, useState } from 'react';
import { Campaign } from '../../../interfaces';
import { ModalRight } from '../../../components/modals';
import { changeCampaignChannelLabel, changeCampaignTypeLabel, formatDate } from '../../../helpers';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  campaign: Campaign;
}

export const ModalCampaign: FC<Props> = ({ isOpen, setIsOpen, campaign }) => {
  const { _id, type, channel, text, createdAt, adminsRecipients, clientsRecipients, driversRecipients, failsAdminsRecipients, failsClientsRecipients, failsDriversRecipients, businessId, waNumberId } = campaign;

  const [filters, setFilters] = useState(<div>Sin filtros</div>);

  useEffect(() => {
    if (campaign.filters) {
      try {
        // Intentar analizar campaign.filters como JSON
        const jsonData = JSON.parse(campaign.filters);

        // Si tiene éxito, mostrar el JSON formateado
        setFilters(<pre>{JSON.stringify(jsonData, null, 2)}</pre>);
      } catch (error) {
        // Si hay un error, mostrar campaign.filters sin formato
        setFilters(<p>{campaign.filters}</p>);
      }
    }
  }, [campaign.filters]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ModalRight
      isOpen={isOpen}
      toggleModal={toggleModal}
      title="Factura"
    >
      <div>
        <h5>
          ID: {_id}
        </h5>
        <br />
        <p>Tipo: {changeCampaignTypeLabel(type)}</p>
        <p>Canal: {changeCampaignChannelLabel(channel)}</p>
        <p>{channel === 'WA' ? 'Nombre del template' : 'Texto'}: {text}</p>
        {channel === 'WA' && <p>Id del número: {waNumberId}</p>}
        {businessId && (<p>Id del negocio: {businessId}</p>)}
        <p>Fecha de creación: {formatDate(createdAt)}</p>
        {
          type === 'admin' && (
            <>
              <p>Administradores: {adminsRecipients.length}</p>
              <p>Administradores con error: {failsAdminsRecipients.length}</p>
            </>
          )
        }
        {
          type === 'client' && (
            <>
              <p>Clientes: {clientsRecipients.length}</p>
              <p>Clientes con error: {failsClientsRecipients.length}</p>
            </>
          )
        }
        {
          type === 'driver' && (
            <>
              <p>Repartidores: {driversRecipients.length}</p>
              <p>Repartidores con error: {failsDriversRecipients.length}</p>
            </>
          )
        }
        <p>Filtros: {filters}</p>
      </div>
    </ModalRight>
  );
};
