import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface Props extends RouteProps {
  isAuthenticated: boolean;
  component: React.ComponentType<RouteProps>;
}

export const PrivateRoute = ({ isAuthenticated, component: Component, ...rest }: Props) => (
  <Route
    {...rest}
    component={(props: any) => (
      (isAuthenticated)
        ? (<Component {...props} />)
        : (<Redirect to="/" />)
    )}
  />
);

// PrivateRoute.propTypes = {
//   isAuthenticated: PropTypes.bool.isRequired,
//   component: PropTypes.func.isRequired,
// };
