import dashboardBackendApi from '../api/dashboardBackendApi';
import { handleAxiosError } from '../helpers/handleErrors';
import { serialize } from '../helpers/serialize';
import { Zone } from '../interfaces';
import { createNewZone, setZone, setZones, updateNowZone } from '../store/slices/zones';

export const getZones = (filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const { data } = await dashboardBackendApi.get<any>(`/support/zones?${_filters}`);
    const zones = data.zones.map((zone: Zone) => {
      const zoneWithEstablishments = data.zonesWithTotalEstablishments.find((z: any) => z._id === zone._id);
      return {
        ...zone,
        totalEstablishments: zoneWithEstablishments?.count || 0,
      };
    });
    dispatch(setZones({
      zones,
      totalZones: data.totalZones,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const getZone = (id: string, filters?: any) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const { data } = await dashboardBackendApi.get<any>(`/support/zones/${id}?${_filters}`);
    console.log('DATA', data);
    dispatch(setZone({
      zone: data.zone,
      driversInZone: data.driversInZone,
      establishmentsInZone: data.establishmentsInZone,
      statistics: data.statistics,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const createZone = (zone: any) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.post<any>('/support/zones', zone);
    console.log(resp.data);
    dispatch(createNewZone({
      zone: resp.data.zone,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
    // return error.response;
  }
};

export const updateZone = (zone: any, id: string) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.put<any>(`/support/zones/${id}`, zone);
    dispatch(updateNowZone({
      zone: resp.data.zone,
      id,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
    // return error.response;
  }
};
