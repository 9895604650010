import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { Notification } from '../../../interfaces';
import { payloadAddNotificationShow, payloadSetNotifications } from './payloadTypes';

interface NotificationsState {
  notifications: any[];
  totalNotifications: number;
  notificationsShow: any[];
  // filters
  limit: number;
  pageIndex: number;
}

const initialState: NotificationsState = {
  notifications: [],
  totalNotifications: 0,
  notificationsShow: [],
  // filters
  limit: 10,
  pageIndex: 0,
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<payloadSetNotifications>) => {
      state.notifications = action.payload.notifications || [];
      state.totalNotifications = action.payload.totalNotifications;
    },
    addNotificationShow: (state, action: PayloadAction<payloadAddNotificationShow>) => {
      state.notificationsShow.push(action.payload.notification);
    },
    deleteNotificationShow: (state) => {
      state.notificationsShow.pop();
    },
    setLimit: (state, { payload }) => {
      state.limit = payload;
    },
    setPageIndex: (state, { payload }) => {
      state.pageIndex = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setNotifications,
  addNotificationShow,
  deleteNotificationShow,
  setLimit,
  setPageIndex,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
