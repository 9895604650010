

export const changeCampaignTypeLabel = (type: 'driver' | 'client' | 'admin') => {
  switch (type) {
    case 'driver':
      return 'Repartidor';
    case 'client':
      return 'Clientw';
    case 'admin':
      return 'Administrador';
    default:
      return type;
  }
};

export const changeCampaignChannelLabel = (channel: 'WA' | 'SMS' | 'Push') => {
  switch (channel) {
    case 'WA':
      return 'WhatsApp';
    case 'SMS':
      return 'SMS';
    case 'Push':
      return 'Push';
    default:
      return channel;
  }
};
