import { FC, useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import ResizableReactTable from '../../../components/ResizableReactTable';
import CreateTableData from '../../../helpers/createTableData';
import { useAppDispatch, useAppSelector } from '../../../store';
import { BusinessEstablishment } from '../../../interfaces/businessEstablishment';
import { getEstablishmentsByBusinessIdByHunter } from '../../../services/hunter/businessEstablishment';

export const CardEstablishments: FC = () => {
  const { business } = useAppSelector(state => state.businesses);
  const { businessEstablishmentsByBusiness, totalBusinessEstablishmentsByBusiness } = useAppSelector(state => state.businessEstablishments);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<BusinessEstablishment[]>([]);
  const [pageIndex, setPageIndex] = useState(0);
  const columns = [
    { Header: 'Nombre', accessor: 'name' },
    { Header: 'Teléfono', accessor: 'phone.number' },
  ];

  const getFilters = () => {
    const filters: any = {};
    filters.page = pageIndex + 1;
    filters.sortBy = '_id:desc';
    return filters;
  };

  useEffect(() => {
    if (business) {
      const filters = getFilters();
      dispatch(getEstablishmentsByBusinessIdByHunter(business._id, filters));
    }
  }, [business, pageIndex]);

  useEffect(() => {
    if (businessEstablishmentsByBusiness.length > 0) {
      const newData: any[] = [];
      businessEstablishmentsByBusiness.map((item) => {
        const obj = {
          ...item,
        };
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [businessEstablishmentsByBusiness]);

  const reactTableData = CreateTableData(data, columns);
  return (
    <Card>
      <CardBody>
        <div>
          <div>
            <h4>Sucursales</h4>
          </div>
        </div>
        <ResizableReactTable
          reactTableData={reactTableData}
          totalRecords={totalBusinessEstablishmentsByBusiness}
          manualPageSize={[0]}
          limit={10}
          setLimit={() => { }}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          totalNoOfPages={Math.ceil(totalBusinessEstablishmentsByBusiness / 10)}
        />
      </CardBody>
    </Card>
  );
};
