import dashboardBackendApi from '../api/dashboardBackendApi';
import { handleAxiosError } from '../helpers/handleErrors';
import { serialize } from '../helpers/serialize';
import { createNewAPIDeveloper, setAPIDeveloper, setAPIDevelopers, updateNowAPIDeveloper } from '../store/slices/apiDevelopers';

export const getApiDevelopers = (filters: {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const resp = await dashboardBackendApi.get<any>(`/support/apiDevelopers?${_filters}`);
    dispatch(setAPIDevelopers({
      apiDevelopers: resp.data.apiDevelopers,
      totalApiDevelopers: resp.data.totalApiDevelopers,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const getApiDeveloper = (id: string) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.get<any>(`/support/apiDevelopers/${id}`);
    dispatch(setAPIDeveloper({
      apiDeveloper: resp.data.apiDeveloper,
      apiDeveloperSandbox: resp.data.apiDeveloperSandbox,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const createApiDeveloper = (apiDeveloper: any) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.post<any>('/support/apiDevelopers', apiDeveloper);
    console.log(resp.data);
    dispatch(createNewAPIDeveloper({
      apiDeveloper: resp.data.apiDeveloper,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
    // return error.response;
  }
};

export const updateApiDeveloper = (id: string, apiDeveloper: any) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.put<any>(`/support/apiDevelopers/${id}`, apiDeveloper);
    dispatch(updateNowAPIDeveloper({
      apiDeveloper: resp.data.apiDeveloper,
      id,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
    // return error.response;
  }
};
export const payOffNeegativeCredit = (id: string) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.post<any>(`/support/apiDevelopers/${id}/payoff`);
    // dispatch(setAPIDeveloper({
    //   apiDeveloper: resp.data.apiDeveloper,
    //   apiDeveloperSandbox: resp.data.apiDeveloperSandbox,
    // }));
  } catch (error) {
    handleAxiosError(error, dispatch);
    // return error.response;
  }
};
