import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { getBusinessClientsByBusinessId } from '../../services/businessClient';
import { RootState } from '../../store';
import { StepsRequest } from './components';

const BusinessDeliveryServiceRequest: FC = () => {

  const dispatch = useDispatch();
  const { business } = useSelector((state: RootState) => state.businesses);

  useEffect(() => {
    if (business) {
      dispatch(getBusinessClientsByBusinessId(business._id));
    }
  }, [business]);

  return (
    <Container className="dashboard">
      <Row>
        <Col>
          <StepsRequest />
        </Col>
      </Row>
    </Container>
  );
};

export default BusinessDeliveryServiceRequest;
