import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker, DateRange } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import {
  format,
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
} from 'date-fns';
import { es } from 'date-fns/locale';
import s from './DatePicker.module.scss';

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

function createStaticRanges(ranges) {
  return ranges.map(range => ({ ...staticRangeHandler, ...range }));
}

const defaultStaticRanges = createStaticRanges([
  {
    label: 'Hoy',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Ayer',
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
  },

  {
    label: 'Esta semana',
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
  },
  {
    label: 'Última semana',
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
    }),
  },
  {
    label: 'Este mes',
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
  },
  {
    label: 'Mes anterior',
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  },
  {
    label: 'Todas las fechas',
    range: () => ({
      startDate: undefined,
      endDate: undefined,
    }),

  },
]);

export const DatePicker = ({ from, to, editableDateInputs, text, handleDateChange }) => {
  const [state, setState] = useState([
    {
      startDate: from,
      endDate: to,
      key: 'selection',
    },
  ]);

  const [date, setDate] = useState({
    startDate: from,
    endDate: to,
  });

  const [showDatePicker, setShowDatePicker] = useState(false);

  const refDatePicker = useRef(null);
  const refSpan = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (refDatePicker.current && !refDatePicker.current.contains(event.target) && refSpan.current && !refSpan.current.contains(event.target)) {
        setShowDatePicker(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refDatePicker, refSpan]);

  useEffect(() => {
    if (date.startDate && format(date.startDate, 'mm-dd-yyyy hh:mm') !== format(date.endDate, 'mm-dd-yyyy hh:mm')) {
      setShowDatePicker(false);
    }
    if (date.startDate === undefined && date.endDate === undefined) {
      setShowDatePicker(false);
    }
  }, [date]);

  const { startDate, endDate } = date;

  const onChange = (item) => {
    setDate(item.selection);
    setState([item.selection]);
    handleDateChange(item.selection.startDate, item.selection.endDate);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className={`${s.datePickerContainer}`}>
        <span className={`${s.datePickerLabel}`}>{text}</span>
        <span
          onClick={() => setShowDatePicker(!showDatePicker)}
          ref={refSpan}
          className={`${s.datePickerValue}`}
          onKeyPress={() => setShowDatePicker(!showDatePicker)}
          role="button"
          tabIndex={0}
        >
          {
            startDate
              ? `${format(startDate, 'dd MMM yyyy', { locale: es })} - `
              : 'Todas las fechas'
          }
          {endDate && format(endDate, 'dd MMM yyyy', { locale: es })}
          <i className="glyphicon glyphicon-calendar" />
        </span>
      </div>
      {
        showDatePicker && (
          <div ref={refDatePicker} className={s.datePicker}>
            <DateRangePicker
              editableDateInputs={editableDateInputs}
              onChange={onChange}
              showSelectionPreview
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={state}
              direction="horizontal"
              startDatePlaceholder="Fecha inicial"
              endDatePlaceholder="Fecha final"
              inputRanges={[]}
              staticRanges={defaultStaticRanges}
              locale={locales.es}
              minDate={new Date('2020')}
              maxDate={new Date('2025')}
            />
          </div>
        )
      }

    </div>
  );
};

DatePicker.propTypes = {
  from: PropTypes.instanceOf(Date),
  to: PropTypes.instanceOf(Date),
  editableDateInputs: PropTypes.bool,
  text: PropTypes.string,
  handleDateChange: PropTypes.func.isRequired,
};

DatePicker.defaultProps = {
  from: new Date(),
  to: addDays(new Date(), 7),
  editableDateInputs: false,
  text: 'Fecha',
};

export const DatePickerShort = ({ from, to, editableDateInputs, handleDateChange }) => {
  const [state, setState] = useState([
    {
      startDate: from,
      endDate: to,
      key: 'selection',
    },
  ]);

  const onChange = (item) => {
    setState([item.selection]);
    handleDateChange(item.selection.startDate, item.selection.endDate);
  };

  return (
    <DateRange
      editableDateInputs={editableDateInputs}
      onChange={onChange}
      moveRangeOnFirstSelection={false}
      months={2}
      ranges={state}
      direction="horizontal"
      startDatePlaceholder="Fecha inicial"
      endDatePlaceholder="Fecha final"
    />
  );
};

DatePickerShort.propTypes = {
  from: PropTypes.instanceOf(Date),
  to: PropTypes.instanceOf(Date),
  editableDateInputs: PropTypes.bool,
  handleDateChange: PropTypes.func.isRequired,
};

DatePickerShort.defaultProps = {
  from: null,
  to: null,
  editableDateInputs: false,
};
