import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Log } from '../../../interfaces';
import { payloadSetLogs, payloadSetLog } from './payloadTypes';

interface LogState {
  logs: Log[];
  totalLogs: number;
  log: Log | null;
  // filters
  limit: number;
  pageIndex: number;
}

const initialState: LogState = {
  logs: [],
  totalLogs: 0,
  log: null,
  // filters
  limit: 10,
  pageIndex: 0,
};

export const logsSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    setLogs: (state, action: PayloadAction<payloadSetLogs>) => {
      state.logs = action.payload.logs || [];
      state.totalLogs = action.payload.totalLogs;
      state.log = null;
    },
    setLog: (state, action: PayloadAction<payloadSetLog>) => {
      state.log = action.payload.log;
    },
    setLimit: (state, { payload }) => {
      state.limit = payload;
    },
    setPageIndex: (state, { payload }) => {
      state.pageIndex = payload;
    },
    resetFilters: (state) => {
      state.pageIndex = initialState.pageIndex;
      state.limit = initialState.limit;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLogs,
  setLog,
  setLimit,
  setPageIndex,
  resetFilters,
} = logsSlice.actions;

export default logsSlice.reducer;
