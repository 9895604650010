import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import ResizableReactTable from '../../components/ResizableReactTable';
import { formatDateTime } from '../../helpers';
import CreateTableData from '../../helpers/createTableData';
import { getDeliveryQuotes } from '../../services/deliveryQuote';
import { useAppDispatch, useAppSelector } from '../../store';
import { ButtonView } from '../../components/buttons';
import { DatePicker } from '../../components/DatePicker';
import { getZones } from '../../services/zone';
import { CustomDropdown } from '../../components/dropdowns';

const columns = [
  { Header: 'Fecha', accessor: 'createdAt' },
  { Header: 'Zona', accessor: 'zone.name' },
  { Header: 'Precio máximo', accessor: 'maxPrice' },
  { Header: 'Precio mínimo', accessor: 'minPrice' },
  { Header: 'Repartimos mts', accessor: 'repartimosMts' },
  { Header: 'Rappi mts', accessor: 'rappiMts' },
  { Header: 'Negocio', accessor: 'business' },
];

const DeliveryQuotes = () => {
  const history = useHistory();

  const dispatch = useAppDispatch();
  const { deliveryQuotes, totalDeliveryQuotes } = useAppSelector(state => state.deliveryQuotes);
  const { zones } = useAppSelector(state => state.zones);
  const { isSandbox } = useAppSelector(state => state.app);

  const [data, setData] = useState<any[]>([]);
  const [manualPageSize] = useState([10, 20, 50, 100]);
  const [limit, setLimit] = useState(manualPageSize[0]);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [zoneDropdown, setZoneDropdown] = useState({
    label: 'Zona',
    selectedKey: 'all',
    options: { all: 'Todas' },
  });

  const getFilters = () => {
    const filters: any = {};
    if (from && to) {
      filters.from = from;
      filters.to = to;
    }
    if (zoneDropdown.selectedKey !== 'all') {
      filters.zoneId = zoneDropdown.selectedKey;
    }
    filters.isSandbox = isSandbox ? 'yes' : 'no';
    filters.limit = limit;
    filters.page = pageIndex + 1;
    filters.sortBy = '_id:desc';
    return filters;
  };

  useEffect(() => {
    dispatch(getZones({ sortBy: 'name' }));
  }, []);

  useEffect(() => {
    const options: any = { all: 'Todas' };
    zones.forEach((zone) => {
      options[zone._id] = zone.name;
    });
    setZoneDropdown({
      ...zoneDropdown,
      options,
    });
  }, [zones]);

  useEffect(() => {
    const filters = getFilters();
    dispatch(getDeliveryQuotes(filters));
  }, [limit, pageIndex, from, to, zoneDropdown, isSandbox]);

  useEffect(() => {
    if (deliveryQuotes.length > 0) {
      const newData: any[] = [];
      deliveryQuotes.map((item) => {
        const obj = {
          ...item,
          maxPrice: `$${(item.maxPrice).toFixed(2)} MXN`,
          minPrice: `$${(item.minPrice).toFixed(2)} MXN`,
          repartimosMts: item.quotes.find(q => q.providerSlug === 'repartimos')?.mts?.toFixed(2) || 0,
          rappiMts: item.quotes.find(q => q.providerSlug === 'rappi')?.mts?.toFixed(2) || 0,
          business: item.businessId && (
            <>
              <ButtonView
                onClick={() => history.push(`/dashboard/businesses/${item.businessId}`)}
              />
            </>
          ),
        };
        if (obj.createdAt) {
          obj.createdAt = formatDateTime(item.createdAt);
        }
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [deliveryQuotes]);

  const handleDateChange = (fromDate: any, toDate: any) => {
    setFrom(fromDate);
    setTo(toDate);
    setPageIndex(0);
  };

  const changeZoneFilter = (zoneId: any) => {
    setZoneDropdown({
      ...zoneDropdown,
      selectedKey: zoneId,
    });
    setPageIndex(0);
  };

  const reactTableData = CreateTableData(data, columns);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title">
                  <h5 className="bold-text">Delivery Quotes</h5>
                </div>
              </div>
              <Row
                style={{ paddingLeft: '20px' }}
              >
                <CustomDropdown
                  {...zoneDropdown}
                  onClick={changeZoneFilter}
                />
              </Row>
              <Row>
                <DatePicker handleDateChange={handleDateChange} from={from} to={to} />
              </Row>
              {
                data.length === 0
                  ? <h3>No hay info que se pueda mostrar</h3>
                  : (
                    <ResizableReactTable
                      reactTableData={reactTableData}
                      totalRecords={totalDeliveryQuotes}
                      manualPageSize={manualPageSize}
                      limit={limit}
                      setLimit={setLimit}
                      pageIndex={pageIndex}
                      setPageIndex={setPageIndex}
                      totalNoOfPages={Math.ceil(totalDeliveryQuotes / limit)}
                    />
                  )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DeliveryQuotes;
