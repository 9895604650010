import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { ButtonView } from '../../components/buttons/ButtonsIcon';
import ResizableReactTable from '../../components/ResizableReactTable';
import { formatDate } from '../../helpers';
import CreateTableData from '../../helpers/createTableData';
import { getDeliveryProviders } from '../../services/deliveryProvider';
import { RootState } from '../../store';

const DeliveryProviders = () => {
  const [data, setData] = useState<any[]>([]);
  const [manualPageSize] = useState([10, 20, 50, 100]);
  const [limit, setLimit] = useState(manualPageSize[0]);
  const [pageIndex, setPageIndex] = useState(0);
  const dispatch = useDispatch();
  const { deliveryProviders } = useSelector((state: RootState) => state.deliveryProviders);

  useEffect(() => {
    dispatch(getDeliveryProviders());
  }, []);

  const history = useHistory();

  const columns = [
    { Header: 'Fecha', accessor: 'createdAt' },
    { Header: 'Nombre', accessor: 'name' },
    { Header: 'Slug', accessor: 'slug' },
    { Header: 'Acciones', accessor: 'actions' },
  ];

  useEffect(() => {
    if (deliveryProviders.length > 0) {
      const newData: any[] = [];
      deliveryProviders.map((item) => {
        const obj = {
          ...item,
          actions: (
            <>
              <ButtonView
                onClick={() => history.push(`/dashboard/deliveryProviders/${item._id}`)}
              />
            </>
          ),
        };
        if (obj.createdAt) {
          obj.createdAt = formatDate(item.createdAt);
        }
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [deliveryProviders]);

  const reactTableData = CreateTableData(data, columns);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title">
                  <h5 className="bold-text">Delivery Providers</h5>
                </div>
              </div>
              {
                data.length === 0
                  ? <h3>No hay info que se pueda mostrar</h3>
                  : (
                    <ResizableReactTable
                      reactTableData={reactTableData}
                      totalRecords={deliveryProviders.length}
                      manualPageSize={manualPageSize}
                      limit={limit}
                      setLimit={setLimit}
                      pageIndex={pageIndex}
                      setPageIndex={setPageIndex}
                      totalNoOfPages={Math.ceil(deliveryProviders.length / limit)}
                    />
                  )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DeliveryProviders;
