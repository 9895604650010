import { FC } from 'react';
import { OptionsSelector } from '../../../components/selectors';
import { extractTransportOptions } from '../../../helpers/extract';

const OPTIONS = [
  { icon: 'motorcycle', value: 'moto' },
  { icon: 'car', value: 'car' },
];

interface Props {
  value: any;
  onChange: (data: any) => void;
  options: any;
}

export const TransportSelector: FC<Props> = ({ value, onChange, options = OPTIONS }) => {
  const processedOptions = extractTransportOptions(options);

  return (
    <div className="transport-container">
      <OptionsSelector
        options={processedOptions}
        value={processedOptions.find((opt: any) => opt.value === value)}
        onChange={({ value }: any) => onChange(value)}
      />
    </div>
  );
};
