import { useMemo } from 'react';

const CreateTableData = (data, columnsFields) => {
  const columns = useMemo(() => columnsFields, []);

  // rows();
  const reactTableData = { tableHeaderData: columns, tableRowsData: data };
  return reactTableData;
};

export default CreateTableData;
