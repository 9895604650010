import dashboardBackendApi from '../api/dashboardBackendApi';
import { handleAxiosError } from '../helpers/handleErrors';
import { DeliveryProvidersResponse, DeliveryProviderResponse } from '../interfaces';
import { setDeliveryProviders, setDeliveryProvider } from '../store/slices/deliveryProviders';

export const getDeliveryProviders = () => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.get<DeliveryProvidersResponse>('/support/deliveryProviders');
    dispatch(setDeliveryProviders({
      deliveryProviders: resp.data.deliveryProviders,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const getDeliveryProvider = (id: string) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.get<DeliveryProviderResponse>(`/support/deliveryProviders/${id}`);
    dispatch(setDeliveryProvider({
      deliveryProvider: resp.data.deliveryProvider,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const updateLogoDeliveryProvider = (id: string, image: any) => async (dispatch: any) => {
  try {
    const resp = await dashboardBackendApi.put<DeliveryProviderResponse>(`/support/deliveryProviders/${id}/logo`, { image });
    dispatch(setDeliveryProvider({
      deliveryProvider: resp.data.deliveryProvider,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

