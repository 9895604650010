import { useEffect } from 'react';
import { loginUser } from '../../services/auth';
import LogInForm from './components/LogInForm';
import { useAppDispatch, useAppSelector } from '../../store';
import { cleanErrorMessage } from '../../store/slices/auth';

const LogIn = () => {
  const { errorMessage } = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        dispatch(cleanErrorMessage());
      }, 3000);
    }
  }, [errorMessage]);

  const onSubmit = async (obj) => {
    dispatch(loginUser(obj));
  };

  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">
              Repartimos
            </h3>
          </div>
          {
            errorMessage && (
              <div className="alert alert-danger" role="alert">
                <p>{errorMessage}</p>
              </div>
            )
          }
          <LogInForm onSubmit={onSubmit} />
        </div>
      </div>
    </div>
  );
};

export default LogIn;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/component/form.scss to add styles
