import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DeliveryProvider,
} from '../../../interfaces';

interface Props {
  deliveryProviders: DeliveryProvider[];
  deliveryProvider: DeliveryProvider | null;
}

const initialState: Props = {
  deliveryProviders: [],
  deliveryProvider: null,
};

export const deliveryProvidersSlice = createSlice({
  name: 'deliveryProviders',
  initialState,
  reducers: {
    setDeliveryProviders: (state, action: PayloadAction<any>) => {
      state.deliveryProviders = action.payload.deliveryProviders || [];
    },
    setDeliveryProvider: (state, action: PayloadAction<any>) => {
      state.deliveryProvider = action.payload.deliveryProvider || [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setDeliveryProviders,
  setDeliveryProvider,
} = deliveryProvidersSlice.actions;

export default deliveryProvidersSlice.reducer;
