/* eslint-disable no-restricted-syntax */
export const extractAddresses = (locations: any[]) => {
  const areComplete = locations.every((location: any) => Boolean(location?.address?.latitude) && Boolean(location?.address?.longitude));
  if (!areComplete) return false;

  return true;
};

export const extractAddressName = (address: any) => {
  const { city, exterior, neighborhood, street, postalCode, name } = address;
  const locationName = [name, neighborhood, street, exterior, city, postalCode]
    .filter(Boolean)
    .join(', ');

  return locationName;
};

const TRANSPORT_ICONS: any = {
  motorcycle: 'motorcycle',
  car: 'car',
  walking: 'trekking',
  bicycle: 'bicycle-1',
  van: 'jeep',
};

const TRANSPORT_TRANSLATIONS: any = {
  motorcycle: 'motorcycle',
  car: 'car',
  walking: 'walking',
  bicycle: 'bike',
};
export const extractTransportOptions = (transports: any[]) => {
  return transports.map((transport: any) => ({
    icon: TRANSPORT_ICONS[transport.name],
    value: transport.name,
    translation: TRANSPORT_TRANSLATIONS[transport.name],
  }));
};

export const extractDataFromState = (state: any) => {
  const root = JSON.parse(localStorage.getItem('persist:root') || '');
  const { business: { _id: businessId } } = JSON.parse(root.businesses);
  const data: any = {
    locations: [],
    // TODO: remove placeholder
    businessId,
    transport: 'motorcycle',
    paymentMethod: 'cash',
  };

  const { locations } = state;

  // SET LOCATIONS
  for (const { address } of locations) {
    const { latitude, longitude } = address;
    data.locations.push({
      latitude,
      longitude,
    });
  }

  // SET PAYMENT METHOD
  data.paymentMethod = state.paymentMethod;

  // SET TRANSPORT
  data.transport = state.transport;

  return data;
};
