import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { ModalRight } from '../../../components/modals/ModalRight';
import { FormApiDeveloper } from '.';
import { createApiDeveloper, updateApiDeveloper } from '../../../services/apiDeveloper';
import { APIDeveloper } from '../../../interfaces/apiDeveloper';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  apiDeveloper: APIDeveloper | null;
}

export const ModalApiDeveloper: FC<Props> = ({ isOpen, setIsOpen, apiDeveloper = null }) => {
  const dispatch = useDispatch();
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onSubmit = (data: any) => {
    if (apiDeveloper) {
      dispatch(updateApiDeveloper(apiDeveloper._id, data));
    } else {
      dispatch(createApiDeveloper(data));
    }
    setIsOpen(false);
  };

  return (
    <ModalRight
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={apiDeveloper ? 'Editar Developer' : 'Nuevo Developer'}
    >
      <FormApiDeveloper onSubmit={onSubmit} apiDeveloper={apiDeveloper} />
    </ModalRight>
  );
};
