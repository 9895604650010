import { FC, useEffect, useState } from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import ResizableReactTable from '../../../components/ResizableReactTable';
import { formatDate } from '../../../helpers';
import CreateTableData from '../../../helpers/createTableData';
import { getPaymentsByBusiness } from '../../../services/business';
import { useAppDispatch, useAppSelector } from '../../../store';
import { ButtonPrimary } from '../../../components/buttons';
import { ModalManualTransaction } from './ModalManualTransaction';
import { CustomDropdown } from '../../../components/dropdowns';

const columns = [
  { Header: 'Fecha', accessor: 'createdAt' },
  { Header: 'Fecha de pago', accessor: 'paymentDate' },
  { Header: 'Status', accessor: 'status' },
  { Header: 'Tipo', accessor: 'type' },
  { Header: 'Monto', accessor: 'total' },
];

interface Props {
  from: any;
  to: any;
}

export const CardPayments: FC<Props> = ({ from, to }) => {
  const dispatch = useAppDispatch();
  const { isSandbox } = useAppSelector(state => state.app);
  const { business, payments, totalPayments } = useAppSelector(state => state.businesses);
  const [data, setData] = useState<any[]>([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [typeDropdown, setTypeDropdown] = useState({
    label: 'type',
    selectedKey: 'all',
    options: { all: 'Todos', income: 'income', expense: 'expense' },
  });

  useEffect(() => {
    if (payments.length > 0) {
      const newData: any[] = [];
      payments.map((item) => {
        const obj = {
          ...item,
          createdAt: formatDate(item.createdAt),
          paymentDate: formatDate(item.paymentDate),
          total: `$${item.total.toFixed(2)} MXN`,
        };
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [payments]);

  const getFilters = () => {
    const filters: any = {};
    filters.page = pageIndex + 1;
    filters.limit = 10;
    filters.sortBy = '_id:desc';
    filters.isSandbox = isSandbox ? 'yes' : 'no';
    if (typeDropdown.selectedKey !== 'all') {
      filters.type = typeDropdown.selectedKey;
    }
    if (from && to) {
      filters.from = from;
      filters.to = to;
    }
    return filters;
  };

  const fetchData = () => {
    if (business) {
      const filters = getFilters();
      dispatch(getPaymentsByBusiness(business._id, filters));
    }
  };

  useEffect(() => {
    fetchData();
  }, [business, pageIndex, from, to, isSandbox, typeDropdown]);

  const changeTypeFilter = (value: any) => {
    setTypeDropdown({
      ...typeDropdown,
      selectedKey: value,
    });
    setPageIndex(0);
  };

  const reactTableData = CreateTableData(data, columns);

  if (!business) {
    return (
      <Card>
        <CardBody>
          <h3>Cargando...</h3>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardBody>
        <div>
          <div>
            <h4>Pagos</h4>
          </div>
        </div>
        <Row style={{ alignItems: 'end', justifyContent: 'space-between' }}>
          <div className="mr-3">
            <ButtonPrimary
              label="Añadir transacción"
              onClick={() => setIsOpen(true)}
            />
          </div>
          <CustomDropdown
            {...typeDropdown}
            onClick={changeTypeFilter}
          />
        </Row>
        <ModalManualTransaction isOpen={isOpen} setIsOpen={setIsOpen} />
        <ResizableReactTable
          reactTableData={reactTableData}
          totalRecords={totalPayments}
          manualPageSize={[10]}
          limit={10}
          setLimit={() => { }}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          totalNoOfPages={Math.ceil(totalPayments / 10)}
        />
      </CardBody>
    </Card>
  );
};
