import React, { FC, useCallback, useRef } from 'react';
import { GoogleMap, Polygon } from '@react-google-maps/api';

type LatLng = {
  lat: number;
  lng: number;
};

interface Props {
  path: LatLng[];
  setPath: React.Dispatch<React.SetStateAction<LatLng[]>>;
  center: LatLng;
  setCenter: React.Dispatch<React.SetStateAction<LatLng>>;
}

export const Map: FC<Props> = ({ path, setPath, center, setCenter }) => {
  const mapContainerStyle = {
    minHeight: '750px',
    height: 'calc(100vh - 350px)',
    width: '95%',
  };

  const options = {
    fillColor: 'lightblue',
    fillOpacity: 0.3,
    strokeColor: 'red',
    strokeOpacity: 1,
    strokeWeight: 2,
    zIndex: 1,
  };


  // Define refs for Polygon instance and listeners
  const polygonRef = useRef<any>(null);
  const listenersRef = useRef<any[] | any>([]);

  // Call setPath with new edited path
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng: any, i: number) => {
          if (path.length >= i + 1) {
            if (latLng.lat() !== path[i].lat) {
              setCenter({ lat: latLng.lat(), lng: latLng.lng() });
            }
          }
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPath(nextPath);
    }
  }, [setPath]);

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback((polygon) => {
    polygonRef.current = polygon;
    const path = polygon.getPath();
    listenersRef.current.push(
      path.addListener('set_at', onEdit),
      path.addListener('insert_at', onEdit),
      path.addListener('remove_at', onEdit),
    );
  }, [onEdit]);

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis: any) => lis.remove());
    polygonRef.current = null;
  }, []);

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={9}
      center={center}
    >
      {/* <Polygon
        paths={paths}
        options={options}
        draggable
        onDragEnd={onMarkerDragEnd}
      /> */}
      <Polygon
        // Make the Polygon editable / draggable
        editable
        draggable
        path={path}
        options={options}
        // Event used when manipulating and adding points
        onMouseUp={onEdit}
        // Event used when dragging the whole Polygon
        onDragEnd={onEdit}
        onLoad={onLoad}
        onUnmount={onUnmount}
      />
    </GoogleMap>
  );
};
