import dashboardBackendApi from '../api/dashboardBackendApi';
import { handleAxiosError, handleLoginFailedError } from '../helpers/handleErrors';
import { AuthResponse } from '../interfaces';
import { loginFailed, loginSuccess } from '../store/slices/auth';

export const fetchAdminData = () => async (dispatch: any) => {
  try {
    const { data } = await dashboardBackendApi.get<AuthResponse>('/support/auth/fetchAdminData');
    dispatch(loginSuccess({
      admin: data.admin,
      jwtAccessToken: data.token,
      userType: data.userType,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const fetchHunterData = () => async (dispatch: any) => {
  try {
    const { data } = await dashboardBackendApi.get<AuthResponse>('/support/auth/fetchHunterData');
    dispatch(loginSuccess({
      admin: data.admin,
      jwtAccessToken: data.token,
      userType: data.userType,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const loginUser = (credentials: { email: string; password: string; }) => async (dispatch: any) => {
  try {
    const { data } = await dashboardBackendApi.post<AuthResponse>('/support/auth/login', credentials);
    dispatch(loginSuccess({
      admin: data.admin,
      jwtAccessToken: data.token,
      userType: data.userType,
    }));
  } catch (error) {
    handleLoginFailedError(error, dispatch);
  }
};
