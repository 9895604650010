import { createSlice } from '@reduxjs/toolkit';

interface Props {
  show: boolean;
  collapse: boolean;
}

const initialState: Props = {
  show: false,
  collapse: false,
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    changeSidebarVisibility: (state) => {
      state.collapse = !state.collapse;
    },
    changeMobileSidebarVisibility: (state) => {
      state.show = !state.show;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeSidebarVisibility, changeMobileSidebarVisibility } = sidebarSlice.actions;

export default sidebarSlice.reducer;
