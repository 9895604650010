import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import ResizableReactTable from '../../components/ResizableReactTable';
import { formatDateTime } from '../../helpers';
import CreateTableData from '../../helpers/createTableData';
import { Invoice } from '../../interfaces';
// import { getInvoices } from '../../services/invoice';
import { getChatsMesagges } from '../../services/whatsapp';
import { RootState } from '../../store';
import { ModalInvoice } from './components';
import { ButtonView } from '../../components/buttons/ButtonsIcon';
import { setSession } from '../../store/slices/whatsapp';


const columns = [
  { Header: 'Último mensaje', accessor: 'lastMessageFromUserAt' },
  { Header: 'Nombre', accessor: 'name' },
  { Header: 'Teléfono', accessor: 'source' },
  { Header: 'Activa', accessor: 'isActive' },
  { Header: 'Status', accessor: 'status' },
  // { Header: 'Fecha de pago', accessor: 'paymentDate' },
  // { Header: 'Tipo', accessor: 'type' },
  // { Header: 'Status', accessor: 'status' },
  // { Header: 'Número', accessor: 'number' },
  // { Header: 'Subtotal', accessor: 'subtotal' },
  // { Header: 'Total', accessor: 'total' },
  // { Header: 'Emisor', accessor: 'issuedBy.name' },
  // { Header: 'Receptor', accessor: 'receivedBy.name' },
  { Header: 'Acciones', accessor: 'actions' },
];

// TODO: review
interface ChatItem {
  createdAt: string,
  lastMessageFromUserAt: string,
  firstName: string,
  lastName: string,
  businessName: string,
  businessId: string,
  businessAdminId: string,
  _id: string,
  user?: any,
  isActive: boolean,
  status: string,
}
const View: FC = () => {
  // const { invoices, totalInvoices } = useSelector((state: RootState) => state.invoices);
  const { chats, totalCount } = useSelector((state: RootState) => state.whatsapp);
  const dispatch = useDispatch();
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [manualPageSize] = useState([10, 20, 50, 100]);
  const [limit, setLimit] = useState(manualPageSize[0]);
  const [pageIndex, setPageIndex] = useState(0);
  const [invoice, setInvoice] = useState<Invoice | null>(null);


  const getFilters = () => {
    const filters: any = {};
    filters.limit = limit;
    filters.page = pageIndex + 1;
    filters.sortBy = '_id:desc';
    return filters;
  };

  const getAll = () => {
    try {
      const filters = getFilters();
      dispatch(getChatsMesagges(filters));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAll();
  }, [limit, pageIndex]);

  const viewDetail = (item:ChatItem) => {
    dispatch(setSession(item));
    history.push(`/dashboard/whatsapp/session/${item._id}`);
  };
  useEffect(() => {
    if (chats.length > 0) {
      const newData: any[] = [];
      chats.map((item:ChatItem) => {
        const obj = {
          key: item._id,
          ...item,
          actions: (
            <>
              <ButtonView
                onClick={() => viewDetail(item)}
                // onClick={() => history.push(`/dashboard/chatmessages/${item.businessAdminId}`)}

                // onClick={() => {
                  // setInvoice(item);
                  // setIsOpen(true);
                // }}
              />
            </>
          ),
          name: item.user ? item.user.name : '--',
          source: item.user ? item.user.source : '--',
          isActive: item.isActive ? 'on' : 'off',
          lastMessageFromUserAt: formatDateTime(item.lastMessageFromUserAt),
        };
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [chats]);

  const reactTableData = CreateTableData(data, columns);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title">
                  <h5 className="bold-text">Chats</h5>
                </div>
              </div>
              {invoice && <ModalInvoice isOpen={isOpen} setIsOpen={setIsOpen} invoice={invoice} />}
              {
                data.length === 0 ? <h3> No hay info que se pueda mostrar</h3> : (
                  <ResizableReactTable
                    reactTableData={reactTableData}
                    totalRecords={totalCount}
                    manualPageSize={manualPageSize}
                    limit={limit}
                    setLimit={setLimit}
                    pageIndex={pageIndex}
                    setPageIndex={setPageIndex}
                    totalNoOfPages={Math.ceil(totalCount / limit)}
                  />
                )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default View;
