import { FC } from 'react';
import { Form } from 'react-final-form';
import { FormApi, SubmissionErrors } from 'final-form';
import { Button } from 'reactstrap';
import { BankData } from '../../interfaces';
import { DropdownInputField, InputField } from './';

interface Props {
  onSubmit: (values: any, form: FormApi<any, any>, callback?: ((errors?: SubmissionErrors) => void) | undefined) => void;
  onCancel?: () => void;
  bankData: BankData | null
}

const OPTIONS = [
  {
    label: 'CLABE',
    value: 'clabe',
  },
  {
    label: 'Cuenta bancaria',
    value: 'account',
  },
  {
    label: 'Tarjeta',
    value: 'card',
  },
];

export const FormBankData: FC<Props> = ({ onSubmit, bankData, onCancel }) => {

  const validate = (values: any) => {
    const errors: any = {};
    if (!values.bankName) {
      errors.bankName = 'El nombre del banco es obligatorio';
    }
    if (!values.holderName) {
      errors.holderName = 'El nombre del titular es obligatorio';
    }
    if (!values.accountType) {
      errors.accountType = 'El tipo de cuenta es obligatorio';
    }
    return errors;
  };

  const selectAccountType = (data: any) => {
    return data;
  };

  return (
    <Form onSubmit={onSubmit} validate={validate} initialValues={bankData}>
      {({ handleSubmit, form }) => (
        <form className="form" onSubmit={handleSubmit}>
          <InputField
            label="Nombre del banco"
            name="bankName"
            placeholder="Nombre del banco"
          />
          <InputField
            label="Nombre del titular"
            name="holderName"
            placeholder="Nombre del titular"
          />
          <DropdownInputField
            label="Tipo de cuenta"
            name="accountType"
            options={OPTIONS}
            placeholder="Selecciona un tipo de cuenta"
            onSelect={selectAccountType}
          />
          <InputField
            label="CLABE"
            name="clabe"
            placeholder="CLABE"
          />
          <InputField
            label="Cuenta bancaria"
            name="accountNumber"
            placeholder="Cuenta bancaria"
          />
          <InputField
            label="Tarjeta"
            name="cardNumber"
            placeholder="Tarjeta"
          />
          {
            onCancel ? (
              <div className="d-flex justify-content-between">
                <Button
                  size="sm"
                  onClick={onCancel}
                >
                  Cancelar
                </Button>
                <Button
                  size="sm"
                  color="primary"
                  type="submit"
                >
                  {bankData ? 'Editar información' : 'Crear información'}
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  size="sm"
                  color="primary"
                  type="submit"
                >
                  {bankData ? 'Editar información' : 'Crear información'}
                </Button>
              </div>
            )
          }
        </form>
      )}
    </Form>
  );
};
