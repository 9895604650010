import { FC } from 'react';
import { IconCard } from '../../../components/cards/IconCard';

interface Props {
  location: any;
}

export const LocationDetails: FC<Props> = ({ location: { address } }) => {
  const { city, exterior, neighborhood, street, postalCode, name } = address;
  const addressName = [name, neighborhood, street, exterior, city, postalCode]
    .filter(Boolean)
    .join(', ');

  return (
    <div>
      <IconCard
        title="Direcciones"
        value={addressName}
        icon="location"
      />
    </div>
  );
};
