import { FC } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { RootState } from '../../../store';
import { BusinessEstablishment } from '../../../interfaces/businessEstablishment';
import { extractAddressName } from '../../../helpers/extract';

interface Props {
  className: string;
  value: any;
  onChange: (data: any) => void;
}

export const EstablishmentSelector: FC<Props> = ({ className, value, onChange }) => {
  const { businessEstablishments } = useSelector((state: RootState) => state.businessEstablishments);

  const establishmentOptions = businessEstablishments.map((est: BusinessEstablishment) => ({
    label: est.name,
    value: est,
  }));

  if (businessEstablishments.length === 0) return null;

  return (
    <div className={className}>
      <Select
        value={value}
        onChange={(data: any) => onChange({
          ...data,
          address: {
            ...data.value.address,
            name: extractAddressName(data.value.address),
          },
        })}
        options={establishmentOptions}
      />
    </div>
  );
};
