import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Container, Row } from 'reactstrap';
import { MapZone } from './components';
import { useAppDispatch, useAppSelector } from '../../store';
import { getZone } from '../../services/zone';
import { CustomDropdown } from '../../components/dropdowns';

const Zone = () => {
  const { location } = useHistory();

  const dispatch = useAppDispatch();
  const { zone, driversInZone, statisticsInZone, establishmentsInZone } = useAppSelector(state => state.zones);
  const [markersType, setMarkersType] = useState('all');
  const [isSandboxDropdown, setIsSandboxDropdown] = useState({
    label: 'Sandbox',
    // selectedKey: filters?.isSandbox || '',
    selectedKey: 'all',
    options: { all: 'Todos', yes: 'Si', no: 'No' },
  });
  const fetchData = () => {
    const array = location.pathname.split('/');
    const id = array[array.length - 1];
    const filters = getFilters();
    dispatch(getZone(id, filters));
  };

  useEffect(() => {
    fetchData();
  }, [isSandboxDropdown]);

  const changeIsSandboxFilter = (value: any) => {
    setIsSandboxDropdown({
      ...isSandboxDropdown,
      selectedKey: value,
    });
  };

  const getFilters = () => {
    const filters: any = {};
    if (isSandboxDropdown.selectedKey !== 'all') {
      filters.isSandbox = isSandboxDropdown.selectedKey;
    }
    return filters;
  };

  if (!zone) {
    return (
      <Container>
        <Card>
          <CardBody>
            <h1>Cargando</h1>
          </CardBody>
        </Card>
      </Container>
    );
  }

  return (
    <Container className="dashboard">
      <Row xs={12} className="mb-3">
        <MapZone
          driversInZone={driversInZone}
          establishmentsInZone={establishmentsInZone}
          lng={zone.area.coordinates[0][0][0]}
          lat={zone.area.coordinates[0][0][1]}
          markersType={markersType}
        />
        <div role="group" className="btn-group">
          <button
            type="button"
            className={`btn btn-primary ${markersType === 'all' ? 'active' : ''}`}
            onClick={() => setMarkersType('all')}
          >
            Todo
          </button>
          <button
            type="button"
            className={`btn btn-primary ${markersType === 'driver' ? 'active' : ''}`}
            onClick={() => setMarkersType('driver')}
          >
            Repartidor
          </button>
          <button
            type="button"
            className={`btn btn-primary ${markersType === 'business' ? 'active' : ''}`}
            onClick={() => setMarkersType('business')}
          >
            Negocios
          </button>
        </div>
      </Row>
      <Row xs={12}>
        <Card>
          <CardBody>
            <Row
              style={{ paddingLeft: '20px' }}
            >
              <CustomDropdown
                {...isSandboxDropdown}
                onClick={changeIsSandboxFilter}
              />
            </Row>
            <div className="react-table__wrapper">
              <div className="card__title">
                <h5 className="bold-text">{zone.name}</h5>
              </div>
            </div>
            <div className="d-flex">
              <div className="d-flex flex-column align-items-center mr-5">
                <p className="font-weight-bold">{establishmentsInZone.length}</p>
                <i className="font-weight-bold lnr lnr-store" />
              </div>
              <div className="d-flex flex-column align-items-center mr-5">
                <p className="font-weight-bold">|</p>
                {/* <i className="font-weight-bold lnr lnr-store" /> */}
              </div>
              <div className="d-flex flex-column align-items-center mr-5">
                <p className="font-weight-bold">{driversInZone.length}</p>
                <i className="font-weight-bold lnr lnr-users" />
              </div>
              <div className="d-flex flex-column align-items-center mr-5">
                <p className="font-weight-bold">{statisticsInZone.bici}</p>
                <i className="font-weight-bold lnr lnr-bicycle" />
              </div>
              <div className="d-flex flex-column align-items-center mr-5">
                <p className="font-weight-bold">{statisticsInZone.moto}</p>
                <i className="font-weight-bold lnr lnr-bus" />
              </div>
              <div className="d-flex flex-column align-items-center mr-5">
                <p className="font-weight-bold">{statisticsInZone.auto}</p>
                <i className="font-weight-bold lnr lnr-car" />
              </div>
              <div className="d-flex flex-column align-items-center mr-5">
                <p className="font-weight-bold">{statisticsInZone.minivan}</p>
                <i className="font-weight-bold lnr lnr-train" />
              </div>
            </div>
          </CardBody>
        </Card>
      </Row>
    </Container>
  );
};

export default Zone;
