import { FC, useState, useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import ResizableReactTable from '../../../components/ResizableReactTable';
import CreateTableData from '../../../helpers/createTableData';
import { Quote } from '../../../interfaces';
import { changeStatusLabel } from '../../../helpers/status';
import { formatTime } from '../../../helpers';
import { ButtonCopy } from '../../../components/buttons';
import { CustomTooltip } from '../../../components/CustomTooltip';

interface Props {
  quotes: Quote[];
}

export const CardQuotes: FC<Props> = ({ quotes }) => {
  const [data, setData] = useState<any[]>([]);

  const columns = [
    { Header: 'ID', accessor: 'actions' },
    { Header: 'Tiempo', accessor: 'createdAt' },
    { Header: 'Provider', accessor: 'providerSlug' },
    { Header: 'Metros', accessor: 'mts' },
    { Header: 'Status', accessor: 'status' },
    { Header: 'Precio', accessor: 'price' },
  ];

  useEffect(() => {
    setData(quotes.map(quote => ({
      ...quote,
      status: changeStatusLabel(quote.status, 'quote'),
      mts: quote.mts || 0,
      createdAt: formatTime(quote.createdAt),
      price: `$${quote.priceDetail.finalPriceTotal.toFixed(2)} MXN`,
      actions: (
        <>
          <CustomTooltip text={quote.orderId}>
            <ButtonCopy
              onClick={() => navigator.clipboard.writeText(quote.orderId)}
            />
          </CustomTooltip>
        </>
      ),
    })));
  }, []);

  const reactTableData = CreateTableData(data, columns);
  return (
    <Card>
      <CardBody>
        <div>
          <h3>
            Orders
          </h3>
        </div>
        <ResizableReactTable
          reactTableData={reactTableData}
          totalRecords={1}
          manualPageSize={[0]}
          limit={10}
          setLimit={() => { }}
          pageIndex={0}
          setPageIndex={() => { }}
          totalNoOfPages={1}
        />
      </CardBody>
    </Card>
  );
};
