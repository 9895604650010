import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ModalRight } from '../../../components/modals/ModalRight';
// import { formatDate } from '../../../helpers';
// import { Invoice } from '../../../interfaces';
import ResizableReactTable from '../../../components/ResizableReactTable';
import CreateTableData from '../../../helpers/createTableData';
import { formatDate } from '../../../helpers';
import {
  getPendingInvoicesByDeliveryDriver,
  payPendingInvoicesByDeliveryDriver,
 } from '../../../services/deliveryDrivers';
import s from './styles.module.scss';
import { RootState } from '../../../store';
import { ButtonView } from '../../../components/buttons/ButtonsIcon';
import { ButtonPrimary } from '../../../components/buttons/ButtonPrimary';
import { getUrl } from '../../../helpers/cloudinary';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  // invoice: Invoice;
  driverId: string,
  row: any,
}

const columns = [
  // { Header: 'Nombre', accessor: 'driverName' },
  { Header: 'Fecha', accessor: 'createdAt' },
  { Header: 'Monto', accessor: 'total' },
  { Header: '', accessor: 'actions' },
  // { Header: 'Total de facturas', accessor: 'count' },
  // { Header: 'Datos bancarios', accessor: 'hasBankData' },
  // { Header: 'Acciones', accessor: 'actions' },
];

export const ModalInvoice: FC<Props> = ({ isOpen, setIsOpen, driverId, row }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { invoices, totalInvoices } = useSelector((state: RootState) => state.deliveryDrivers);
  
  // table
  const [data, setData] = useState<any[]>([]);


  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const payInvoices = () => {
    dispatch(payPendingInvoicesByDeliveryDriver(driverId));
    setIsOpen(false);
  };

  useEffect(() => {
    if (driverId) {
      const filters = {
        status: 'pending',
      };
      dispatch(getPendingInvoicesByDeliveryDriver(driverId, filters)); 
    } else {
      setIsOpen(false);
    }
  }, [driverId]);

  useEffect(() => {
    if (invoices.length > 0) {
      const newData: any[] = [];
      invoices.map((item:any) => {
        const obj = {
          ...item,
          createdAt: formatDate(item.createdAt),
          // ),
          // // createdAt: formatDate(item.createdAt),
          // // paymentDate: formatDate(item.paymentDate),
          // // subtotal: `$${item.subtotal.toFixed(2)} MXN`,
          // // driver: `${item.driverName}`, 
          total: `$${item.total.toFixed(2)} MXN`,
          // hasBankData: item.bankdata ? '' : 'Incompletos',
          actions: (
            <>
              <ButtonView
                onClick={() => history.push(`/dashboard/services/${item.deliveryServiceId}`)}
              />
            </>
          ),
        };
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [invoices]);

  const reactTableData = CreateTableData(data, columns);
  const profileImageId = getUrl(row.driverProfileImage);
  console.log('driverProfileImage', profileImageId);
  return (
    <ModalRight
      isOpen={isOpen}
      toggleModal={toggleModal}
      title="Facturas pendientes"
    >
      <div className={s.container}>
        <a href={`/dashboard/deliveryDrivers/${row._id}`}>
          <div
            className="avatar"
            style={{
              width: 60,
              height: 60,
              display: 'inline-block',
              borderRadius: 99,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: profileImageId ? `url('${profileImageId}')` : 'url("https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/OOjs_UI_icon_userAvatar.svg/2048px-OOjs_UI_icon_userAvatar.svg.png")',
            }}
          />
        </a>
        <p>{row.driverName}</p>
        {/* <h5>ID: {invoice._id}</h5>
        <br />
        <p>Emisor: {invoice.issuedBy.name}</p>
        <p>Receptor: {invoice.receivedBy.name}</p>
        <p>Status: {invoice.status}</p>
        <p>Concepto: {invoice.concept}</p>
        <p>Divisa: {invoice.currency}</p>
        <p>Subtotal: ${invoice.subtotal.toFixed(2)} MXN</p>
        <p>Impuesto: ${invoice.tax.toFixed(2)} MXN</p>
        <p>Total: ${invoice.total.toFixed(2)} MXN</p>
        <p>Fecha de pago: {formatDate(invoice.paymentDate)}</p> */}
        <br />
        {/* <button type="button" onClick={payInvoices}>Pagar facturas</button> */}
        <ButtonPrimary
          label="Pagar facturas"
          onClick={payInvoices}
        />
        <br />
        <ResizableReactTable
          reactTableData={reactTableData}
          totalRecords={totalInvoices}
          // manualPageSize={manualPageSize}
          // limit={limit}
          // setLimit={setLimit}
          // pageIndex={pageIndex}
          // setPageIndex={setPageIndex}
          // totalNoOfPages={Math.ceil(totalInvoices / limit)}
        />
      </div>
    </ModalRight>
  );
};
