import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Payment } from '../../../interfaces';

interface Props {
  payments: Payment[];
  totalPayments: number;
  filters: {
    isSandbox: string,
  };
}

const initialState: Props = {
  payments: [],
  totalPayments: 0,
  filters: {
    isSandbox: 'all',
  },
};

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setPayments: (state, action: PayloadAction<any>) => {
      state.payments = action.payload.payments || [];
      state.totalPayments = action.payload.totalPayments || 0;
    },
    setFilters: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        [payload.filter]: payload.value,
      };
    },

  },
});

// Action creators are generated for each case reducer function
export const {
  setPayments,
  setFilters,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
