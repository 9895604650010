import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SupportAdmin } from '../../../interfaces';
import { payloadSetSupportAdmins, payloadSetSupportAdmin, payloadCreateSupportAdmin, payloadUpdateSupportAdmin } from './payloadTypes';

interface Props {
  supportAdmins: SupportAdmin[];
  totalSupportAdmins: number;
  supportAdmin: SupportAdmin | null;
}

const initialState: Props = {
  supportAdmins: [],
  totalSupportAdmins: 0,
  supportAdmin: null,
};

export const SupportAdminsSlice = createSlice({
  name: 'SupportAdmins',
  initialState,
  reducers: {
    setSupportAdmins: (state, action: PayloadAction<payloadSetSupportAdmins>) => {
      state.supportAdmins = action.payload.supportAdmins || [];
      state.totalSupportAdmins = action.payload.totalSupportAdmins || 0;
      state.supportAdmin = null;
    },
    setSupportAdmin: (state, action: PayloadAction<payloadSetSupportAdmin>) => {
      state.supportAdmin = action.payload.supportAdmin;
    },
    createNewSupportAdmin: (state, action: PayloadAction<payloadCreateSupportAdmin>) => {
      state.supportAdmins.push(action.payload.supportAdmin);
    },
    updateNowSupportAdmin: (state, action: PayloadAction<payloadUpdateSupportAdmin>) => {
      const updateSupportAdmins = state.supportAdmins.map((supportAdmin) => {
        if (supportAdmin._id === action.payload.id) {
          return action.payload.supportAdmin;
        }
        return supportAdmin;
      });
      state.supportAdmins = updateSupportAdmins;
      if (state.supportAdmin) {
        state.supportAdmin = action.payload.supportAdmin;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSupportAdmins,
  setSupportAdmin,
  createNewSupportAdmin,
  updateNowSupportAdmin,
} = SupportAdminsSlice.actions;

export default SupportAdminsSlice.reducer;
