import dashboardBackendApi from '../api/dashboardBackendApi';
import { handleAxiosError } from '../helpers/handleErrors';
import { serialize } from '../helpers/serialize';
import { setInvoices, setInvoicesPending, setTxtFile, updateInvoice } from '../store/slices/invoices';

export const getInvoices = (filters = {}) => async (dispatch: any) => {
  try {
    const _filters = serialize(filters);
    const resp = await dashboardBackendApi.get<any>(`/support/invoices?${_filters}`);
    dispatch(setInvoices({
      invoices: resp.data.invoices,
      totalInvoices: resp.data.totalInvoices,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

// main invoices pending table view
export const getInvoicesPending = (filters = {}) => async (dispatch: any) => {
  console.log('@getInvoicesPending');
  try {
    const _filters = serialize(filters);
    const resp = await dashboardBackendApi.get<any>(`/support/invoices/pending?${_filters}`);
    console.log({ resp });
    dispatch(setInvoicesPending({
      invoices: resp.data.invoices,
      totalInvoices: resp.data.totalInvoices,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};
export const markAsPaid = (invoiceId: string) => async (dispatch: any) => {
  console.log('@markAsPaid:service');
  try {
    // const _filters = serialize(filters);
    const resp = await dashboardBackendApi.post<any>(`/support/invoices/${invoiceId}/pay`);
    console.log('resp', resp);
    // dispatch(setInvoicesPending({
    //   invoices: resp.data.invoices,
    //   // totalInvoices: resp.data.totalInvoices,
    // }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const getTxtFile = (filters = {}) => async (dispatch: any) => {
  console.log('@getTextFile');
  dispatch(setTxtFile({
    txtFile: '',
  }));
  try {
    const _filters = serialize(filters);
    const resp = await dashboardBackendApi.get<any>(`/support/invoices/pending/export?${_filters}`);
    dispatch(setTxtFile({
      txtFile: resp.data.url,
    }));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};

export const cancelInvoice = (invoiceId: string) => async (dispatch: any) => {
  console.log('@cancelInvoice');
  try {
    const resp = await dashboardBackendApi.post<any>(`/support/invoices/${invoiceId}/cancel`);
    console.log('resp', resp);
    dispatch(updateInvoice(resp.data.invoice));
  } catch (error) {
    handleAxiosError(error, dispatch);
  }
};
