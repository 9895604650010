import olaClick from '../assets/img/integrations/olaClick.png';
import poster from '../assets/img/integrations/poster.png';
import woocommerce from '../assets/img/integrations/woocommerce.png';

export const getIntegrationLogo = (integrationName: string): { url: string, width: string } => {
  switch (integrationName) {
    case 'olaclick':
      return {
        url: olaClick,
        width: '50px',
      };
    case 'poster':
      return {
        url: poster,
        width: '100px',
      };
    case 'woocommerce':
      return {
        url: woocommerce,
        width: '150px',
      };
    default:
      return {
        url: '',
        width: '0px',
      };
  }
};