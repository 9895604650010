import { FC } from 'react';
import { useHistory } from 'react-router-dom';
// import { format } from 'date-fns';
import { Card, CardBody } from 'reactstrap';
import { BusinessDeliveryService } from '../../../interfaces';

interface Props {
  business: BusinessDeliveryService;
}

export const CardEstablishment: FC<Props> = ({ business }) => {
  const history = useHistory();
  return (
    <Card onClick={() => history.push(`/dashboard/businesses/${business._id}`)} style={{ cursor: 'pointer' }}>
      <CardBody className="profile__card">
        <div className="profile__information">
          <div className="profile__avatar">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/OOjs_UI_icon_userAvatar.svg/2048px-OOjs_UI_icon_userAvatar.svg.png"
              alt="avatar"
            />
          </div>
          <div className="profile__data">
            <p className="profile__name">{business.name}</p>
            <p>{business.businessEstablishmentName}</p>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
