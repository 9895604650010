import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { ButtonView } from '../../components/buttons/ButtonsIcon';
import ResizableReactTable from '../../components/ResizableReactTable';
import { formatDate } from '../../helpers';
import CreateTableData from '../../helpers/createTableData';
import { Invoice } from '../../interfaces';
import { cancelInvoice, getInvoices, markAsPaid } from '../../services/invoice';
import { RootState, useAppSelector } from '../../store';
import { ModalInvoice } from './components';
import { CustomDropdown } from '../../components/dropdowns';
import { DatePicker } from '../../components/DatePicker';

const columns = [
  { Header: 'Fecha de creación', accessor: 'createdAt' },
  { Header: 'Fecha de pago', accessor: 'paymentDate' },
  { Header: 'Concepto', accessor: 'concept' },
  { Header: 'Status', accessor: 'status' },
  { Header: 'Subtotal', accessor: 'subtotal' },
  { Header: 'Total', accessor: 'total' },
  { Header: 'Emisor', accessor: 'issuedBy.name' },
  { Header: 'Receptor', accessor: 'receivedBy.name' },
  { Header: 'Acciones', accessor: 'actions' },
];

const Invoices: FC = () => {
  const { invoices, totalInvoices } = useSelector((state: RootState) => state.invoices);
  const dispatch = useDispatch();
  const { isSandbox } = useAppSelector(state => state.app);

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [manualPageSize] = useState([10, 20, 50, 100]);
  const [limit, setLimit] = useState(manualPageSize[0]);
  const [pageIndex, setPageIndex] = useState(0);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [statusDropdown, setStatusDropdown] = useState({
    label: 'Status',
    selectedKey: 'all',
    options: { all: 'Todos', pending: 'Pendientes', paid: 'Pagadas', canceled: 'Canceladas' },
  });
  const [issuedByDropdown, setIssuedByDropdown] = useState({
    label: 'Emisor',
    selectedKey: 'all',
    options: { all: 'Todos', business: 'Negocio', deliveryDriver: 'Repartidor', repartimos: 'Repartimos', rappi: 'rappi', borzo: 'borzo', lalamove: 'lalamove' },
  });
  const [receivedByDropdown, setReceivedByDropdown] = useState({
    label: 'Receptor',
    selectedKey: 'all',
    options: { all: 'Todos', business: 'Negocio', deliveryDriver: 'Repartidor', repartimos: 'Repartimos', rappi: 'rappi', borzo: 'borzo', lalamove: 'lalamove' },
  });
  const [conceptDropdown, setConceptDropdown] = useState({
    label: 'Concepto',
    selectedKey: 'all',
    options: { all: 'Todos', 'Delivery Service': 'Servicio', 'Delivery Service fee': 'Tarifa de servicio', 'Delivery Service Cancel': 'Servicio cancelado', Products: 'Productos', 'Business credit': 'Crédito de negocio' },
  });

  const getFilters = () => {
    const filters: any = {};
    filters.limit = limit;
    filters.page = pageIndex + 1;
    filters.sortBy = '_id:desc';
    filters.isSandbox = isSandbox ? 'yes' : 'no';
    if (from && to) {
      filters.from = from;
      filters.to = to;
    }
    if (statusDropdown.selectedKey !== 'all') {
      filters.status = statusDropdown.selectedKey;
    }
    if (issuedByDropdown.selectedKey !== 'all') {
      filters.issuedBy = issuedByDropdown.selectedKey;
    }
    if (receivedByDropdown.selectedKey !== 'all') {
      filters.receivedBy = receivedByDropdown.selectedKey;
    }
    if (conceptDropdown.selectedKey !== 'all') {
      filters.concept = conceptDropdown.selectedKey;
    }

    return filters;
  };

  const getAll = () => {
    try {
      const filters = getFilters();
      dispatch(getInvoices(filters));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAll();
  }, [limit, pageIndex, isSandbox, statusDropdown, issuedByDropdown, receivedByDropdown, conceptDropdown, from, to]);

  useEffect(() => {
    if (invoices.length > 0) {
      const newData: any[] = [];
      invoices.map((item) => {
        const obj = {
          ...item,
          actions: (
            <>
              <ButtonView
                onClick={() => {
                  setInvoice(item);
                  setIsOpen(true);
                }}
              />
            </>
          ),
          createdAt: formatDate(item.createdAt),
          paymentDate: item.paymentDate ? formatDate(item.paymentDate) : '--',
          subtotal: `$${item.subtotal.toFixed(2)} MXN`,
          total: `$${item.total.toFixed(2)} MXN`,
        };
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [invoices]);

  const reactTableData = CreateTableData(data, columns);

  const _markAsPaid = (invoiceId: string) => {
    console.log('@markAsPaid:component', invoiceId);
    try {
      dispatch(markAsPaid(invoiceId));
    } catch (e) {
      console.error(e);
    }
  };

  const markAsCancel = (invoiceId: string) => {
    try {
      dispatch(cancelInvoice(invoiceId));
      setIsOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  const changeStatusFilter = (status: any) => {
    setStatusDropdown({
      ...statusDropdown,
      selectedKey: status,
    });
  };

  const changeIssuedByFilter = (issuedBy: any) => {
    setIssuedByDropdown({
      ...issuedByDropdown,
      selectedKey: issuedBy,
    });
  };

  const changeReceivedByFilter = (receivedBy: any) => {
    setReceivedByDropdown({
      ...receivedByDropdown,
      selectedKey: receivedBy,
    });
  };

  const changeConceptFilter = (concept: any) => {
    setConceptDropdown({
      ...conceptDropdown,
      selectedKey: concept,
    });
  };

  const handleDateChange = (fromDate: any, toDate: any) => {
    setFrom(fromDate);
    setTo(toDate);
    setPageIndex(0);
  };

  console.log(invoices);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title">
                  <h5 className="bold-text">Facturas</h5>
                </div>
              </div>
              <Row style={{ paddingLeft: '20px' }}>
                <CustomDropdown
                  {...statusDropdown}
                  onClick={changeStatusFilter}
                />
                <CustomDropdown
                  {...issuedByDropdown}
                  onClick={changeIssuedByFilter}
                />
                <CustomDropdown
                  {...receivedByDropdown}
                  onClick={changeReceivedByFilter}
                />
                <CustomDropdown
                  {...conceptDropdown}
                  onClick={changeConceptFilter}
                />
              </Row>
              <Row>
                <DatePicker handleDateChange={handleDateChange} from={from} to={to} />
              </Row>
              {invoice && <ModalInvoice isOpen={isOpen} setIsOpen={setIsOpen} invoice={invoice} markAsPaid={_markAsPaid} markAsCancel={markAsCancel} />}
              {
                data.length === 0 ? <h3> No hay info que se pueda mostrar</h3> : (
                  <ResizableReactTable
                    reactTableData={reactTableData}
                    totalRecords={totalInvoices}
                    manualPageSize={manualPageSize}
                    limit={limit}
                    setLimit={setLimit}
                    pageIndex={pageIndex}
                    setPageIndex={setPageIndex}
                    totalNoOfPages={Math.ceil(totalInvoices / limit)}
                  />
                )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Invoices;
