import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIDeveloper } from '../../../interfaces';
import { payloadCreateAPIDeveloper, payloadSetAPIDeveloper, payloadSetAPIDevelopers, payloadUpdateAPIDeveloper } from './payloadTypes';

interface Props {
  apiDevelopers: APIDeveloper[];
  totalApiDevelopers: number;
  apiDeveloper: APIDeveloper | null;
  apiDeveloperSandbox: APIDeveloper | null;
}

const initialState: Props = {
  apiDevelopers: [],
  totalApiDevelopers: 0,
  apiDeveloper: null,
  apiDeveloperSandbox: null,
};

export const apiDevelopersSlice = createSlice({
  name: 'apiDevelopers',
  initialState,
  reducers: {
    setAPIDevelopers: (state, action: PayloadAction<payloadSetAPIDevelopers>) => {
      state.apiDevelopers = action.payload.apiDevelopers || [];
      state.totalApiDevelopers = action.payload.totalApiDevelopers || 0;
      state.apiDeveloper = null;
    },
    setAPIDeveloper: (state, action: PayloadAction<payloadSetAPIDeveloper>) => {
      state.apiDeveloper = action.payload.apiDeveloper;
      state.apiDeveloperSandbox = action.payload.apiDeveloperSandbox;
    },
    createNewAPIDeveloper: (state, action: PayloadAction<payloadCreateAPIDeveloper>) => {
      state.apiDevelopers.push(action.payload.apiDeveloper);
    },
    updateNowAPIDeveloper: (state, action: PayloadAction<payloadUpdateAPIDeveloper>) => {
      const updateAPIDevelopers = state.apiDevelopers.map((apiDeveloper) => {
        if (apiDeveloper._id === action.payload.id) {
          return action.payload.apiDeveloper;
        }
        return apiDeveloper;
      });
      state.apiDevelopers = updateAPIDevelopers;
      state.apiDeveloper = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAPIDevelopers,
  setAPIDeveloper,
  createNewAPIDeveloper,
  updateNowAPIDeveloper,
} = apiDevelopersSlice.actions;

export default apiDevelopersSlice.reducer;
