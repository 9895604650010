import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalRight } from '../../../components/modals/ModalRight';
import { addManualTransactionIncome } from '../../../services/business';
import { RootState } from '../../../store';
import { FormManualTransaction } from './FormManualTransaction';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalManualTransaction: FC<Props> = ({ isOpen, setIsOpen }) => {
  const { business } = useSelector((state: RootState) => state.businesses);
  const dispatch = useDispatch();
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onSubmit = (data: { amount: number }) => {
    dispatch(addManualTransactionIncome({
      businessId: business?._id ?? '',
      amount: data.amount,
    }));
    setIsOpen(false);
  };

  return (
    <ModalRight
      isOpen={isOpen}
      toggleModal={toggleModal}
      title="Nueva Transacción"
    >
      <FormManualTransaction onSubmit={onSubmit} />
    </ModalRight>
  );
};
