import { FC } from 'react';
import { Card, CardBody } from 'reactstrap';
import s from './styles.module.scss';
import { DeliveryTotalDetail, PriceDetail, DeliveryService } from '../../../interfaces';

interface Props {
  priceDetail: PriceDetail;
  service: DeliveryService,
  deliveryTotalDetail: DeliveryTotalDetail;
}

export const CardPaymentDetailForDriver: FC<Props> = ({ service, priceDetail, deliveryTotalDetail = {} }) => {

  const VAT = (priceDetail.finalPriceTotal * priceDetail.deliveryVAT) / 100;

  return (
    <Card>
      <CardBody>
        <div>
          <h3>
            Detalle del pago para el repartidor (repartimos)
          </h3>
        </div>
        <div>
          <ul className={s.table}>
            <li>
              <p>Subtotal Delivery</p>
              <span>${priceDetail.deliveryTotal}</span>
            </li>
            <li>
              <p>Tarifa Subtotal Delivery</p>
              <span>${priceDetail.deliveryFeeTotal}</span>
            </li>
            <li>
              <p>Precio Final</p>
              <span>${priceDetail.finalPriceTotal}</span>
            </li>
            <li>
              <p>IVA</p>
              <span>${VAT.toFixed(2)}</span>
            </li>
            {/* <li>
              <p>Precio Final Total</p>
              <span>${(priceDetail.finalPriceTotal + VAT).toFixed(2)}</span>
            </li> */}
          </ul>
        </div>
      </CardBody>
    </Card>
  );
};
