import { FC, useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import ResizableReactTable from '../../../components/ResizableReactTable';
import { formatDate } from '../../../helpers';
import CreateTableData from '../../../helpers/createTableData';
import { getPaymentsByDeliveryDriver } from '../../../services/deliveryDrivers';
import { useAppDispatch, useAppSelector } from '../../../store';

interface Props {
  from: any;
  to: any;
}

export const CardPayments: FC<Props> = ({ from, to }) => {
  const dispatch = useAppDispatch();
  const { deliveryDriver, payments, totalPayments } = useAppSelector(state => state.deliveryDrivers);
  const { isSandbox } = useAppSelector(state => state.app);
  const [data, setData] = useState<any[]>([]);
  const [pageIndex, setPageIndex] = useState(0);
  const columns = [
    { Header: 'Fecha', accessor: 'createdAt' },
    { Header: 'Fecha de pago', accessor: 'paymentDate' },
    { Header: 'Status', accessor: 'status' },
    { Header: 'Tipo', accessor: 'type' },
    { Header: 'Total', accessor: 'total' },
  ];

  useEffect(() => {
    if (payments.length > 0) {
      const newData: any[] = [];
      payments.map((item) => {
        const obj = {
          ...item,
          createdAt: formatDate(item.createdAt),
          paymentDate: formatDate(item.paymentDate),
          type: item.type === 'income' ? 'Ingreso' : item.type,
        };
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [payments]);

  const getFilters = () => {
    const filters: any = {};
    filters.page = pageIndex + 1;
    filters.limit = 10;
    filters.sortBy = '_id:desc';
    filters.isSandbox = isSandbox ? 'yes' : 'no';
    if (from && to) {
      filters.from = from;
      filters.to = to;
    }
    return filters;
  };

  const fetchData = () => {
    if (deliveryDriver) {
      const filters = getFilters();
      dispatch(getPaymentsByDeliveryDriver(deliveryDriver._id, filters));
    }
  };

  useEffect(() => {
    fetchData();
  }, [deliveryDriver, pageIndex, from, to, isSandbox]);

  const reactTableData = CreateTableData(data, columns);
  return (
    <Card>
      <CardBody>
        <div>
          <div>
            <h4>Transacciones</h4>
          </div>
        </div>
        <ResizableReactTable
          reactTableData={reactTableData}
          totalRecords={totalPayments}
          manualPageSize={[10]}
          limit={10}
          setLimit={() => { }}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          totalNoOfPages={Math.ceil(totalPayments / 10)}
        />
      </CardBody>
    </Card>
  );
};
