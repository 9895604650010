import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Zone, DeliveryDriver, BusinessEstablishment,
} from '../../../interfaces';
import {
  payloadCreateZone, payloadSetZone, payloadSetZones,
  payloadUpdateZone,
} from './payloadTypes';

interface Props {
  zones: Zone[];
  totalZones: number;
  zone: Zone | null;
  driversInZone: DeliveryDriver[];
  establishmentsInZone: BusinessEstablishment[];
  statisticsInZone: {
    bici: number;
    moto: number;
    auto: number;
    minivan: number;
  };
}

const initialState: Props = {
  zones: [],
  totalZones: 0,
  zone: null,
  driversInZone: [],
  establishmentsInZone: [],
  statisticsInZone: {
    bici: 0,
    moto: 0,
    auto: 0,
    minivan: 0,
  },
};

export const zonesSlice = createSlice({
  name: 'zones',
  initialState,
  reducers: {
    setZones: (state, action: PayloadAction<payloadSetZones>) => {
      state.zones = action.payload.zones || [];
      state.totalZones = action.payload.totalZones || 0;
      state.zone = null;
      state.driversInZone = [];
      state.statisticsInZone = {
        bici: 0,
        moto: 0,
        auto: 0,
        minivan: 0,
      };
    },
    setZone: (state, action: PayloadAction<payloadSetZone>) => {
      state.zone = action.payload.zone;
      state.driversInZone = action.payload.driversInZone;
      state.establishmentsInZone = action.payload.establishmentsInZone || [];
      state.statisticsInZone = action.payload.statistics;
    },
    createNewZone: (state, action: PayloadAction<payloadCreateZone>) => {
      state.zones.push(action.payload.zone);
    },
    updateNowZone: (state, action: PayloadAction<payloadUpdateZone>) => {
      const updateZones = state.zones.map((zone) => {
        if (zone._id === action.payload.id) {
          return action.payload.zone;
        }
        return zone;
      });
      state.zones = updateZones;
      state.zone = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setZones,
  setZone,
  createNewZone,
  updateNowZone,
} = zonesSlice.actions;

export default zonesSlice.reducer;
