import React, { FC, useEffect, useState } from 'react';
import axios from 'axios';
import { Button } from 'reactstrap';
import dashboardBackendApi from '../../../api/dashboardBackendApi';
import { BankData, BusinessEstablishment } from '../../../interfaces';
import { ModalRight } from '../../../components/modals';
import { changeAccoutTypeLabel, formatAddress } from '../../../helpers';
import { ButtonPrimary, SwitchButton } from '../../../components/buttons';
import { FormBankData } from '../../../components/form';
import { useAppDispatch } from '../../../store';
import { createBankData, updateBankData } from '../../../services/bankData';
import { updateApprovedOlaClickEstablishment } from '../../../services/businessEstablishment';
import backendApi from '../../../api/backendApi';

interface Props {
  id: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalCardEstablishment: FC<Props> = ({ id, isOpen, setIsOpen }) => {

  const dispatch = useAppDispatch();

  const [establishment, setEstablishment] = useState<BusinessEstablishment>();
  const [showFormBankdata, setShowFormBankdata] = useState(false);
  const [errorCalculateZone, setErrorCalculateZone] = useState('');
  const [errorGenerateRappiId, setErrorGenerateRappiId] = useState('');

  const fetchData = async () => {
    try {
      const { data } = await dashboardBackendApi.get(`/support/businessEstablishments/${id}`);
      if (data && data.businessEstablishment) {
        setEstablishment({
          ...data.businessEstablishment,
          bankData: data.bankData,
        });
      }
    } catch (error) {
      console.error({ error });
    }
  };

  useEffect(() => {
    if (id && isOpen) {
      fetchData();
    }
  }, [id, isOpen]);

  const toggleModal = () => setIsOpen(isOpen => !isOpen);

  if (!establishment) {
    return (
      <ModalRight
        isOpen={isOpen}
        toggleModal={toggleModal}
        title="Cargando"
      />
    );
  }

  const { bankData } = establishment;

  const valueAccountType = ({ accountType, accountNumber, cardNumber, clabe }: BankData) => {
    const regex = new RegExp('\\d{1,4}', 'g');

    switch (accountType) {
      case 'account':
        return accountNumber;
      case 'card':
        return cardNumber?.match(regex)?.join(' ');
      case 'clabe':
        return clabe;
      default:
        return '';
    }
  };

  const onSubmit = (data: any) => {
    data.accountType = data.accountType.value;
    data.businessEstablishmentId = id;
    data.businessId = establishment.businessId;
    if (bankData) {
      dispatch(updateBankData(data, 'business'));
    } else {
      dispatch(createBankData(data, 'business'));
    }
    fetchData();
    setShowFormBankdata(false);
  };

  const changeApprovedOlaClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget;
    dispatch(updateApprovedOlaClickEstablishment(establishment._id, checked));
    setEstablishment({
      ...establishment,
      olaClickSettings: {
        ...establishment.olaClickSettings,
        approved: checked,
      },
    });
  };

  const generateRappiId = async () => {
    try {
      await backendApi.post(`/api/support/businessestablishment/${id}/rappi`);
      fetchData();
    } catch (error) {
      console.error({ error });
      if (axios.isAxiosError(error)) {
        setErrorGenerateRappiId(error.response?.data.message || 'Hubo un error');
        setTimeout(() => {
          setErrorGenerateRappiId('');
        }, 5000);
      }
    }
  };

  const calculateZone = async () => {
    try {
      await dashboardBackendApi.post(`/support/businessEstablishments/${id}/zone`);
      fetchData();
    } catch (error) {
      console.error({ error });
      if (axios.isAxiosError(error)) {
        setErrorCalculateZone(error.response?.data.message || 'Hubo un error');
        setTimeout(() => {
          setErrorCalculateZone('');
        }, 5000);
      }
    }
  };

  console.log({ olaClick: establishment?.olaClickSettings });

  return (
    <ModalRight
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={establishment.name}
    >
      <div className="mb-2">
        <div>
          <span>
            {establishment._id}
          </span>
        </div>
      </div>
      <div className="mb-2">
        <div className="mb-2">
          <p className="mb-1">Dirección</p>
          <span>
            {formatAddress(establishment.address)}
          </span>
        </div>
        <div className="mb-2">
          <p className="mb-1">Teléfono</p>
          <span>{establishment.phone.number}</span>
        </div>
      </div>
      <div className="mb-2">
        <p className="mb-1">Zona</p>
        {
          establishment.zone?.name ? (
            <span>
              {establishment.zone.name}
            </span>
          ) : (
            <>
              <Button
                onClick={calculateZone}
                size="sm"
              >
                Calcular zona
              </Button>
              {
                errorCalculateZone && (<><br /><span style={{ color: 'red' }}>{errorCalculateZone}</span></>)
              }
            </>
          )
        }
      </div>
      <div className="mb-2">
        <p className="mb-1">RappiId</p>
        {
          establishment.rappiId ? (
            <span>
              {establishment.rappiId}
            </span>
          ) : (
            <>
              <Button
                onClick={generateRappiId}
                size="sm"
              >
                Generar rappiId
              </Button>
              {
                errorGenerateRappiId && (<><br /><span style={{ color: 'red' }}>{errorGenerateRappiId}</span></>)
              }
            </>
          )
        }
      </div>
      {
        establishment.olaClickSettings && (
          <div className="mb-2">
            <div className="mb-2 d-flex flex-column">
              <p className="mb-1">OlaClick</p>
              <span style={{ textTransform: 'capitalize' }}>
                <strong>Plan:</strong> {establishment.olaClickSettings?.plan}
              </span>
              <span>
                <strong>Menu:</strong> {establishment.olaClickSettings?.urlMenu}
              </span>
              <span>
                <strong>Habilitado:</strong> {establishment.olaClickSettings?.enabled ? 'Si' : 'No'}
              </span>
              <span>
                <strong>Orden:</strong> {establishment.olaClickSettings?.createOnOrderAccepted ? 'Al aceptar orden en OlaClick' : 'Al marcarla lista para enviar'}
              </span>
              <span>
                <strong>Aprobar servicios antes de solicitarlos:</strong> {establishment.olaClickSettings?.manualApproval ? 'Si' : 'No'}
              </span>
              <span>
                <strong>Cobrarle al cliente el envío:</strong> {establishment.olaClickSettings?.collectDeliveryPrice ? 'Si' : 'No'}
              </span>
              <span>
                <strong>Aprobado:</strong>  <SwitchButton checked={establishment.olaClickSettings?.approved || false} onChange={changeApprovedOlaClick} />
              </span>
            </div>
          </div>
        )
      }
      <div className="mb-2">
        <div className="mb-2">
          <p className="mb-1">Información bancaría</p>
          {
            showFormBankdata && (
              <FormBankData onSubmit={onSubmit} onCancel={() => setShowFormBankdata(false)} bankData={bankData || null} />
            )
          }
          {
            !showFormBankdata && bankData && (
              <div className="d-flex flex-column">
                <span>
                  <strong>Nombre del banco: </strong> {bankData.bankName}
                </span>
                <span>
                  <strong>Nombre del titular: </strong> {bankData.holderName}
                </span>
                <span>
                  <strong>Tipo: </strong> {changeAccoutTypeLabel(bankData.accountType)}
                </span>
                <span>
                  <strong>{changeAccoutTypeLabel(bankData.accountType)}: </strong> {valueAccountType(bankData)}
                </span>
                <ButtonPrimary
                  onClick={() => setShowFormBankdata(true)}
                  label="Editar info"
                />
              </div>
            )
          }
          {
            !showFormBankdata && !bankData && (
              <div className="d-flex flex-column">
                <span>No existe información</span>
                <ButtonPrimary
                  onClick={() => setShowFormBankdata(true)}
                  label="Crear info"
                />
              </div>
            )
          }
        </div>
      </div>
    </ModalRight>
  );
};
