import { FC } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RootState } from '../../store';

const MainWrapper: FC = ({ children }) => {
  const { theme } = useSelector((state: RootState) => state);

  return (
    <div className={`${theme.className} ltr-support`} dir="ltr">
      <div className="wrapper">
        {children}
      </div>
    </div>
  );
};

export default withRouter((MainWrapper));
