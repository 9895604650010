import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { getDeliveryProvider } from '../../services/deliveryProvider';
import { CardInfo } from './components';

const DeliveryProvider: FC = () => {
  const { location } = useHistory();
  const dispatch = useDispatch();

  const fetchData = () => {
    const array = location.pathname.split('/');
    const id = array[array.length - 1];
    dispatch(getDeliveryProvider(id));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container className="dashboard">
      <Row xs={12}>
        <Col xs={12} md={6}>
          <Row>
            <CardInfo />
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default DeliveryProvider;
