import { FC, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import ResizableReactTable from '../../components/ResizableReactTable';
import { formatDateTime } from '../../helpers';
import CreateTableData from '../../helpers/createTableData';
import { useAppDispatch, useAppSelector } from '../../store';
import { ButtonView } from '../../components/buttons/ButtonsIcon';
import { getCampaigns } from '../../services/campaign';
import { Campaign } from '../../interfaces';
import { ModalCampaign } from './components';


const columns = [
  { Header: 'Fecha', accessor: 'createdAt' },
  { Header: 'Texto', accessor: 'text' },
  { Header: 'Tipo', accessor: 'type' },
  { Header: 'Canal', accessor: 'channel' },
  { Header: 'Acciones', accessor: 'actions' },
];

const Campaigns: FC = () => {
  const { campaigns, totalCampaigns } = useAppSelector(state => state.campaigns);
  const { isSandbox } = useAppSelector(state => state.app);
  const dispatch = useAppDispatch();

  const [data, setData] = useState<any[]>([]);
  const [manualPageSize] = useState([10, 20, 50, 100]);
  const [limit, setLimit] = useState(manualPageSize[0]);
  const [pageIndex, setPageIndex] = useState(0);
  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);


  const getFilters = () => {
    const filters: any = {};
    filters.limit = limit;
    filters.page = pageIndex + 1;
    filters.sortBy = '_id:desc';
    filters.isSandbox = isSandbox ? 'yes' : 'no';
    return filters;
  };

  const getAll = () => {
    try {
      const filters = getFilters();
      dispatch(getCampaigns(filters));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAll();
  }, [limit, pageIndex, isSandbox]);


  useEffect(() => {
    if (campaigns.length > 0) {
      const newData: any[] = [];
      campaigns.map((item: any) => {
        const obj = {
          key: item._id,
          ...item,
          actions: (
            <>
              <ButtonView
                onClick={() => {
                  setCampaign(item);
                  setModalIsOpen(true);
                }}
              />
            </>
          ),
          createdAt: formatDateTime(item.createdAt),
        };
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [campaigns]);

  const reactTableData = CreateTableData(data, columns);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title">
                  <h5 className="bold-text">Campañas</h5>
                </div>
              </div>
              {
                data.length === 0 ? <h3> No hay info que se pueda mostrar</h3> : (
                  <ResizableReactTable
                    reactTableData={reactTableData}
                    totalRecords={totalCampaigns}
                    manualPageSize={manualPageSize}
                    limit={limit}
                    setLimit={setLimit}
                    pageIndex={pageIndex}
                    setPageIndex={setPageIndex}
                    totalNoOfPages={Math.ceil(totalCampaigns / limit)}
                  />
                )
              }
              {campaign && (<ModalCampaign isOpen={modalIsOpen} setIsOpen={setModalIsOpen} campaign={campaign} />)}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Campaigns;
