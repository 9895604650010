import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
} from 'reactstrap';
import ResizableReactTable from '../../components/ResizableReactTable';
import CreateTableData from '../../helpers/createTableData';
import { getDeliveryDrivers } from '../../services/deliveryDrivers';
import { ButtonPrimary } from '../../components/buttons/ButtonPrimary';
import { ButtonEdit, ButtonView } from '../../components/buttons/ButtonsIcon';
import { ModalDeliveryDriver, ModalSendMessage } from './components';
import { DatePicker } from '../../components/DatePicker';
import { InputSearch } from '../../components/inputs';
import { useAppSelector } from '../../store';
import { DeliveryDriver } from '../../interfaces';
import { driverIsApproved, formatDate } from '../../helpers';
import { setFilters, setFrom, setLimit, setPageIndex, setSearchText, setTo } from '../../store/slices/deliveryDrivers';
import { CustomDropdown } from '../../components/dropdowns';
import { getZones } from '../../services/zone';
import { getTransports } from '../../services/transport';
import { IconFlask } from '../../components/icons';

const DeliveryDrivers: FC = () => {
  const {
    deliveryDrivers,
    totalDeliveryDrivers,
    limit,
    pageIndex,
    from,
    to,
    searchText,
    filters,
  } = useAppSelector(state => state.deliveryDrivers);
  const { zones } = useAppSelector(state => state.zones);
  const { transports } = useAppSelector(state => state.transports);
  const dispatch = useDispatch();
  const history = useHistory();

  const [deliveryDriver, setDeliveryDriver] = useState<DeliveryDriver | null>(null);
  const { app } = useAppSelector(state => state);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSendMessage, setIsOpenSendMessage] = useState(false);
  const [data, setData] = useState<DeliveryDriver[]>([]);
  const [manualPageSize] = useState([10, 20, 50, 100]);

  const [visibleDropdown, setVisibleDropdown] = useState({
    label: 'Visible',
    selectedKey: filters.visible,
    options: { all: 'Todos', yes: 'Si', no: 'No' },
  });

  const [approvedDropdown, setApprovedDropdown] = useState({
    label: 'Aprobado',
    selectedKey: filters.approved,
    options: { all: 'Todos', yes: 'Aprobado', completed: 'Por aprobar', incompleted: 'Incompleto' },
  });

  const [zoneDropdown, setZoneDropdown] = useState({
    label: 'Zona',
    selectedKey: filters.zoneId,
    options: { all: 'Todas' },
  });

  const [transportDropdown, setTransportDropdown] = useState({
    label: 'Transporte',
    selectedKey: filters.transport,
    options: { all: 'Todas' },
  });

  // const [isSandboxDropdown, setIsSandboxDropdown] = useState({
  //   label: 'Sandbox',
  //   selectedKey: filters?.isSandbox || '',
  //   options: { all: 'Todos', yes: 'Si', no: 'No' },
  // });

  const [isFounderDropdown, setIsFounderDropdown] = useState({
    label: 'Fundador',
    selectedKey: filters?.isFounder || '',
    options: { all: 'Todos', yes: 'Si', no: 'No' },
  });

  useEffect(() => {
    dispatch(getZones({ sortBy: 'name' }));
    dispatch(getTransports());
  }, []);
  useEffect(() => {
    const options: any = { all: 'Todas' };
    zones.forEach((zone) => {
      options[zone._id] = zone.name;
    });
    setZoneDropdown({
      ...zoneDropdown,
      options,
    });
  }, [zones]);
  useEffect(() => {
    const options: any = { all: 'Todas' };
    transports.forEach((zone) => {
      options[zone.slug] = zone.name;
    });
    setTransportDropdown({
      ...transportDropdown,
      options,
    });
  }, [transports]);

  const columns = [
    { Header: 'Sandbox', accessor: 'sandbox' },
    { Header: 'Fecha de creación', accessor: 'createdAt' },
    { Header: 'Nombre', accessor: 'firstName' },
    { Header: 'Apellido', accessor: 'lastName' },
    { Header: 'Teléfono', accessor: 'phoneNumber.number' },
    { Header: 'Visible', accessor: 'isVisible' },
    { Header: 'Aprobado', accessor: 'approved' },
    { Header: 'Zona', accessor: 'zone.name' },
    { Header: 'Acciones', accessor: 'actions' },
  ];

  const _resetFilters = () => {
    changeZoneFilter('all');
    changeTransportFilter('all');
    changeVisibleFilter('all');
    changeVisibleFilter('all');
    // changeIsSandboxFilter('all');
    dispatch(setSearchText(''));
  };
  const getFilters = () => {
    const filters: any = {};
    filters.limit = limit;
    filters.page = pageIndex + 1;
    if (from && to) {
      filters.from = from;
      filters.to = to;
    }
    if (searchText) {
      filters.search = searchText;
    }
    filters.sortBy = '_id:desc';
    // proximos filtros
    if (zoneDropdown.selectedKey !== 'all') {
      filters.zoneId = zoneDropdown.selectedKey;
    }

    if (transportDropdown.selectedKey !== 'all') {
      filters.transportSlug = transportDropdown.selectedKey;
    }

    if (approvedDropdown.selectedKey !== 'all') {
      filters.approved = approvedDropdown.selectedKey;
    }

    if (visibleDropdown.selectedKey !== 'all') {
      filters.isVisible = visibleDropdown.selectedKey;
    }
    // aprobado
    // bloqueado
    // if (isSandboxDropdown.selectedKey !== 'all') {
    //   filters.isSandbox = isSandboxDropdown.selectedKey;
    // }
    filters.isSandbox = app.isSandbox ? 'yes' : 'no';
    if (isFounderDropdown.selectedKey !== 'all') {
      filters.isFounder = isFounderDropdown.selectedKey;
    }
    return filters;
  };

  const getAll = () => {
    try {
      const filters = getFilters();
      dispatch(getDeliveryDrivers(filters));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAll();
  }, [limit, pageIndex, from, to, searchText, visibleDropdown, zoneDropdown, transportDropdown, approvedDropdown, isFounderDropdown, app.isSandbox]);

  useEffect(() => {
    if (deliveryDrivers.length > 0) {
      const newData: any[] = [];
      deliveryDrivers.map((item) => {
        const obj = {
          ...item,
          isVisible: item.isVisible ? 'Si' : 'No',
          approved: driverIsApproved(item),
          sandbox: item.isSandbox === true ? <IconFlask color="#00E5B2" /> : '',
          actions: (
            <>
              <ButtonView
                onClick={() => history.push(`/dashboard/deliveryDrivers/${item._id}`)}
              />
              <ButtonEdit
                onClick={() => {
                  setDeliveryDriver(item);
                  setIsOpen(true);
                }}
              />
            </>
          ),
        };
        if (obj.createdAt) {
          obj.createdAt = formatDate(item.createdAt);
        }
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [deliveryDrivers]);

  const reactTableData = CreateTableData(data, columns);

  const handleDateChange = (fromDate: any, toDate: any) => {
    dispatch(setFrom(fromDate));
    dispatch(setTo(toDate));
    dispatch(setPageIndex(0));
  };

  const changeZoneFilter = (zoneId: any) => {
    setZoneDropdown({
      ...zoneDropdown,
      selectedKey: zoneId,
    });
    dispatch(setFilters({
      filter: 'zoneId',
      value: zoneId,
    }));
  };

  const changeTransportFilter = (transport: any) => {
    setTransportDropdown({
      ...transportDropdown,
      selectedKey: transport,
    });
    dispatch(setFilters({
      filter: 'transport',
      value: transport,
    }));
  };

  const changeApprovedFilter = (approved: any) => {
    setApprovedDropdown({
      ...approvedDropdown,
      selectedKey: approved,
    });
    dispatch(setFilters({
      filter: 'approved',
      value: approved,
    }));
  };

  const changeVisibleFilter = (visible: any) => {
    setVisibleDropdown({
      ...visibleDropdown,
      selectedKey: visible,
    });
    dispatch(setFilters({
      filter: 'visible',
      value: visible,
    }));
  };

  // const changeIsSandboxFilter = (value: any) => {
  //   setIsSandboxDropdown({
  //     ...isSandboxDropdown,
  //     selectedKey: value,
  //   });
  //   dispatch(setFilters({
  //     filter: 'isSandbox',
  //     value,
  //   }));
  // };

  const changeIsFounderFilter = (value: any) => {
    setIsFounderDropdown({
      ...isFounderDropdown,
      selectedKey: value,
    });
    dispatch(setFilters({
      filter: 'isFounder',
      value,
    }));
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title">
                  <h5 className="bold-text">Repartidores</h5>
                </div>
                <div>
                  <ButtonPrimary
                    label="Enviar notificación"
                    onClick={() => {
                      setIsOpenSendMessage(true);
                    }}
                  />
                  <ButtonPrimary
                    label="Crear repartidor"
                    onClick={() => {
                      setDeliveryDriver(null);
                      setIsOpen(true);
                    }}
                  />
                </div>
              </div>
              <Row
                style={{ paddingLeft: '20px' }}
              >
                <CustomDropdown
                  {...zoneDropdown}
                  onClick={changeZoneFilter}
                />
                <CustomDropdown
                  {...transportDropdown}
                  onClick={changeTransportFilter}
                />
                <CustomDropdown
                  {...approvedDropdown}
                  onClick={changeApprovedFilter}
                />
                <CustomDropdown
                  {...visibleDropdown}
                  onClick={changeVisibleFilter}
                />
                <CustomDropdown
                  {...isFounderDropdown}
                  onClick={changeIsFounderFilter}
                />
                {/* <CustomDropdown
                  {...isSandboxDropdown}
                  onClick={changeIsSandboxFilter}
                /> */}
                <Button style={{ height: 40, marginTop: 20, alignSelf: 'center' }} size="sm" onClick={() => _resetFilters()}>Resetear filtros</Button>
              </Row>
              <InputSearch
                value={searchText}
                onChange={(e: any) => {
                  dispatch(setPageIndex(0));
                  dispatch(setSearchText(e.target.value));
                }}
                placeholder="Introduce nombre, apellido, correo, teléfono"
                style={{
                  marginBottom: '5px',
                  width: '75%',
                }}
              />
              <Row>
                <DatePicker handleDateChange={handleDateChange} from={from} to={to} />
              </Row>
              <ModalDeliveryDriver isOpen={isOpen} setIsOpen={setIsOpen} deliveryDriver={deliveryDriver} />
              <ModalSendMessage isOpen={isOpenSendMessage} setIsOpen={setIsOpenSendMessage} filters={getFilters()} />
              {
                data.length === 0 ? <h3>No hay info que se pueda mostrar</h3> : (
                  <ResizableReactTable
                    reactTableData={reactTableData}
                    totalRecords={totalDeliveryDrivers}
                    manualPageSize={manualPageSize}
                    limit={limit}
                    setLimit={l => dispatch(setLimit(l))}
                    pageIndex={pageIndex}
                    setPageIndex={p => dispatch(setPageIndex(p))}
                    totalNoOfPages={Math.ceil(totalDeliveryDrivers / limit)}
                  />
                )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DeliveryDrivers;
