
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
// import { ButtonPrimary } from '../../../components/buttons';
// import { DatePicker } from '../../../components/DatePicker';
import { RootState } from '../../../store/index';
// import { ModalSendMessage } from './ModalSendMessage';

interface Props {
}

export const CardAppInfo: FC<Props> = () => {
  const { deliveryDriver } = useSelector((state: RootState) => state.deliveryDrivers);

  // const [isOpenSendMessage, setIsOpenSendMessage] = useState(false);

  if (!deliveryDriver) {
    return (
      <Card>
        <CardBody className="profile__card">
          <div className="profile__information">
            <p>cargando...</p>
          </div>
        </CardBody>
      </Card>
    );
  }
  // const { _id, firstName, lastName, email, phoneNumber, isApproved, createdAt, isVisible } = deliveryDriver;

  // const handleDateChange = (fromDate: any, toDate: any) => {
  //   setFrom(fromDate);
  //   setTo(toDate);
  // };

  return (
    <>
      <Card>
        <CardBody>
          <h4>App</h4>
          <p>
            <strong>Notificaciones</strong> {deliveryDriver.appPermissions.notifications}
          </p>
          <p>
            <strong>Background location</strong> {deliveryDriver.appPermissions.backgroundLocation}
          </p>
          <p>
            <strong>Foreground location</strong> {deliveryDriver.appPermissions.foregroundLocation}
          </p>
        </CardBody>
      </Card>
    </>
  );
};
