import { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import ResizableReactTable from '../../components/ResizableReactTable';
import CreateTableData from '../../helpers/createTableData';
import { getDeliveryServices } from '../../services/deliveryServices';
import { ButtonView } from '../../components/buttons/ButtonsIcon';
import { DatePicker } from '../../components/DatePicker';

import { changeStatusLabel } from '../../helpers/status';
import { useAppDispatch, useAppSelector } from '../../store';
import { InputSearch } from '../../components/inputs';
import { changePaymentMethodLabel, changeTransportLabel, formatDate } from '../../helpers';
import { CustomDropdown } from '../../components/dropdowns';
import { resetFilters, setFilters, setLimit, setPageIndex, setSearchText } from '../../store/slices/deliveryServices';
import { getZones } from '../../services/zone';
// import { ButtonPrimary } from '../../components/buttons';

const columns = [
  { Header: 'Fecha de creación', accessor: 'createdAt' },
  // { Header: 'Tipo', accessor: 'type' },
  { Header: 'Status', accessor: 'status' },
  { Header: 'Repartidor', accessor: 'driver.name' },
  { Header: 'Negocio', accessor: 'business.name' },
  // { Header: 'Transporte', accessor: 'transport' },
  { Header: 'Efectivo requerido', accessor: 'requiredCash' },
  { Header: 'Precio Máximo', accessor: 'maxPrice' },
  { Header: 'Total Aceptado', accessor: 'acceptedPriceDetail.finalPriceTotal' },
  { Header: 'Zona', accessor: 'zone.name' },
  { Header: 'Acciones', accessor: 'actions' },
];

const DeliveryServices: FC = () => {
  const history = useHistory();
  const { deliveryServices, totalDeliveryServices, limit, pageIndex, searchText, filters } = useAppSelector(state => state.deliveryServices);
  const { isSandbox } = useAppSelector(state => state.app);
  const { zones } = useAppSelector(state => state.zones);
  const dispatch = useAppDispatch();
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const [data, setData] = useState<any[]>([]);
  const [manualPageSize] = useState([10, 20, 50, 100]);
  const [statusDropdown, setStatusDropdown] = useState({
    label: 'Status',
    selectedKey: filters.status,
    options: { all: 'Todos', requestingDriver: 'Preguntando a repartidores', scheduled: 'Programados', accepted: 'Aceptados', onRoute: 'En camino', done: 'Terminados', canceled: 'Cancelados', noDrivers: 'Sin Repartidores' },
  });
  const [zoneDropdown, setZoneDropdown] = useState({
    label: 'Zona',
    selectedKey: filters.zoneId || 'all',
    options: { all: 'Todas' },
  });
  const [providerDropdown, setProviderDropdown] = useState({
    label: 'Provider',
    selectedKey: filters.provider || 'all',
    options: { all: 'Todos', repartimos: 'Repartimos', uber: 'Uber', rappi: 'Rappi', lalamove: 'Lalamove', borzo: 'Borzo' },
  });
  const [requiredCashDropdown, setRequiredCashDropdown] = useState({
    label: 'Efectivo Requerido',
    selectedKey: filters.requiredCash || 'all',
    options: { all: 'Todos', yes: 'Si', no: 'No' },
  });
  const [integrationDropdown, setIntegrationDropdown] = useState({
    label: 'Integracion',
    selectedKey: filters.integration || 'all',
    options: { all: 'Todas', none: 'Ninguna', olaclick: 'OlaClick', poster: 'Poster', woocommerce: 'Woocommerce' },
  });


  // const resetFilters = () => {
  //   // setStatusDropdown({
  //   //   label: 'Status',x
  //   //   selectedKey: filters.status,
  //   //   options: { all: 'Todos', requestingDriver: 'Preguntando a repartidores', scheduled: 'Programados', accepted: 'Aceptados', onRoute: 'En camino', done: 'Terminados', canceled: 'Cancelados', noDrivers: 'Sin Repartidores' },
  //   // })
  //   dispatch(resetFilters());
  //   // dispatch(setFrom(null));
  //   // dispatch(setTo(null));
  //   // dispatch(setPageIndex(0));
  //   // dispatch(setLimit(10));
  //   // dispatch(setSearchText(''));
  //   // dispatch(setFilters({
  //   //   filter: 'status',
  //   //   value: 'all',
  //   // }));
  //   // getAll();
  // };

  const getFilters = () => {
    const filters: any = {};
    filters.limit = limit;
    filters.page = pageIndex + 1;
    if (from && to) {
      filters.from = from;
      filters.to = to;
    }
    if (zoneDropdown.selectedKey !== 'all') {
      filters.zoneId = zoneDropdown.selectedKey;
    }
    if (providerDropdown.selectedKey !== 'all') {
      filters.provider = providerDropdown.selectedKey;
    }
    if (requiredCashDropdown.selectedKey !== 'all') {
      filters.requiredCash = requiredCashDropdown.selectedKey;
    }
    if (integrationDropdown.selectedKey !== 'all') {
      filters.integration = integrationDropdown.selectedKey;
    }
    filters.sortBy = '_id:desc';
    if (searchText) {
      filters.search = searchText;
    }
    if (statusDropdown.selectedKey !== 'all') {
      filters.status = statusDropdown.selectedKey;
    }
    filters.isSandbox = isSandbox ? 'yes' : 'no';
    return filters;
  };

  const getAll = () => {
    try {
      const filters = getFilters();
      dispatch(getDeliveryServices(filters));
    } catch (error) {
      console.error({ error });
    }
  };

  const _resetFilters = () => {
    setStatusDropdown({
      ...statusDropdown,
      selectedKey: 'all',
    });
    setZoneDropdown({
      ...zoneDropdown,
      selectedKey: 'all',
    });
    setProviderDropdown({
      ...providerDropdown,
      selectedKey: 'all',
    });
    setRequiredCashDropdown({
      ...requiredCashDropdown,
      selectedKey: 'all',
    });
    setIntegrationDropdown({
      ...integrationDropdown,
      selectedKey: 'all',
    });
    dispatch(resetFilters());
  };

  useEffect(() => {
    getAll();
  }, [limit, pageIndex, from, to, searchText, statusDropdown, isSandbox, zoneDropdown, providerDropdown, requiredCashDropdown, integrationDropdown]);

  useEffect(() => {
    dispatch(getZones({ sortBy: 'name' }));
  }, []);
  useEffect(() => {
    const options: any = { all: 'Todas', none: 'Sin Zona' };
    zones.forEach((zone) => {
      options[zone._id] = zone.name;
    });
    setZoneDropdown({
      ...zoneDropdown,
      options,
    });
  }, [zones]);

  useEffect(() => {
    if (deliveryServices.length > 0) {
      const newData: any[] = [];
      deliveryServices.map((item) => {
        // const VAT = (item.priceDetail.finalPriceTotal * item.priceDetail.deliveryVAT) / 100;
        const obj = {
          ...item,
          actions: (
            <>
              <ButtonView
                onClick={() => history.push(`/dashboard/services/${item._id}`)}
              />
            </>
          ),
          status: changeStatusLabel(item.status, 'service'),
          paymentMethod: changePaymentMethodLabel(item.paymentMethod),
          transport: changeTransportLabel(item.transport),
          maxPrice: `$${(item.maxPrice || 0).toFixed(2)} MXN`,
          requiredCash: `$${(item.requiredCash || 0).toFixed(2)} MXN`,
          acceptedPriceDetail: {
            finalPriceTotal: `$${(item.acceptedPriceDetail?.finalPriceTotal || 0).toFixed(2)} MXN`,
          },
        };
        if (obj.createdAt) {
          obj.createdAt = formatDate(item.createdAt);
        }
        newData.push(obj);
        return item;
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [deliveryServices]);

  const reactTableData = CreateTableData(data, columns);

  const handleDateChange = (fromDate: any, toDate: any) => {
    setFrom(fromDate);
    setTo(toDate);
    dispatch(setPageIndex(0));
  };

  const changeStatusFilter = (status: string) => {
    setStatusDropdown({
      ...statusDropdown,
      selectedKey: status,
    });
    dispatch(setFilters({
      filter: 'status',
      value: status,
    }));
  };

  const changeZoneFilter = (zoneId: any) => {
    setZoneDropdown({
      ...zoneDropdown,
      selectedKey: zoneId,
    });
    dispatch(setFilters({
      filter: 'zoneId',
      value: zoneId,
    }));
    dispatch(setPageIndex(0));
  };

  const changeProviderFilter = (provider: any) => {
    setProviderDropdown({
      ...providerDropdown,
      selectedKey: provider,
    });
    dispatch(setFilters({
      filter: 'provider',
      value: provider,
    }));
    dispatch(setPageIndex(0));
  };
  const changeRequiredCashFilter = (value: any) => {
    setRequiredCashDropdown({
      ...requiredCashDropdown,
      selectedKey: value,
    });
    dispatch(setFilters({
      filter: 'requiredCash',
      value,
    }));
    dispatch(setPageIndex(0));
  };
  const changeIntegrationFilter = (value: any) => {
    setIntegrationDropdown({
      ...integrationDropdown,
      selectedKey: value,
    });
    dispatch(setFilters({
      filter: 'integration',
      value,
    }));
    dispatch(setPageIndex(0));
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title">
                  <h5 className="bold-text">Servicios</h5>
                </div>
              </div>
              <Row
                style={{ paddingLeft: '20px' }}
              >
                <CustomDropdown
                  {...zoneDropdown}
                  onClick={changeZoneFilter}
                />
                <CustomDropdown
                  {...providerDropdown}
                  onClick={changeProviderFilter}
                />
                <CustomDropdown
                  {...statusDropdown}
                  onClick={changeStatusFilter}
                />
                <CustomDropdown
                  {...integrationDropdown}
                  onClick={changeIntegrationFilter}
                />
                <CustomDropdown
                  {...requiredCashDropdown}
                  onClick={changeRequiredCashFilter}
                />
                <Button style={{ height: 40, marginTop: 20, alignSelf: 'center' }} size="sm" onClick={() => _resetFilters()}>Resetear filtros</Button>
              </Row>
              <Row>
                <DatePicker handleDateChange={handleDateChange} from={from} to={to} />
              </Row>
              <InputSearch
                value={searchText}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  dispatch(setPageIndex(0));
                  dispatch(setSearchText(e.target.value));
                }}
                placeholder="Introduce repartidor, negocio, sucursal"
                style={{
                  marginBottom: '5px',
                  width: '75%',
                }}
              />
              {
                data.length === 0 ? <h3>No hay info que se pueda mostrar</h3> : (
                  <ResizableReactTable
                    reactTableData={reactTableData}
                    totalRecords={totalDeliveryServices}
                    manualPageSize={manualPageSize}
                    limit={limit}
                    setLimit={l => dispatch(setLimit(l))}
                    pageIndex={pageIndex}
                    setPageIndex={p => dispatch(setPageIndex(p))}
                    totalNoOfPages={Math.ceil(totalDeliveryServices / limit)}
                  />
                )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DeliveryServices;
